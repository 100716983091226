import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
//import { debounce } from "lodash";

// Helper functions
// const DATABASE_API = require("../../api/db-functions");

export default class Table extends Component {
  render() {
    return (
      <div className="table-outer">
        <div className="table-header flex-container">
          <div className="column-1 flex-container">
            <input
              className="select"
              type="checkbox"
              name="all"
              onClick={this.props.toggleSelectAllPages}
            />
            <h3>Pages</h3>
          </div>

          <div
            className="status sort-order-1"
            data-tip="Sort by page status"
            data-for="status"
          >
            <h3>
              Status <img src="/images/select-arrows.svg" alt="select arrows" />
            </h3>
            <ReactTooltip id="status" />
          </div>

          <div
            className="type sort-order-1"
            data-tip="Sort by schema type"
            data-for="type"
          >
            <h3>
              Type <img src="/images/select-arrows.svg" alt="select arrows" />
            </h3>
            <ReactTooltip id="type" />
          </div>

          <h3
            className="updated"
            data-for="last-updated"
            data-tip="Sort by last updated"
          >
            Last updated
          </h3>
          <ReactTooltip id="last-updated" />
        </div>

        <div className="table-container table">
          <ul className="pages-container">{this.props.pageOutput}</ul>
        </div>
      </div>
    );
  }
}
