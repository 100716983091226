import $ from "jquery";
import moment from "moment";

// default schema JSON structure
import {
  ArticleSchema,
  BlogPostingSchema,
  WebSiteSchema,
  WebPageSchema,
  EventSchema,
  LegalServiceSchema,
} from "../../../schemas/index";

const UTIL = require("../../../util/util");
const { COMPONENTS, LOCAL_BUSINESS } = require("../../../schemas/index");

async function jQueryScripts() {
  $(".custom-select-dropdown .current-selection").on("click", function () {
    $(this).siblings(".items").slideToggle();
  });

  $(".accordion-toggle").on("click", function () {
    $(this).siblings(".accordion-content").slideToggle();
    $(this).toggleClass("opened");
  });
}

async function userInputScripts() {
  function loadHelpers() {
    // Search
    $(".dd-search #txtSearchValue").keyup(function () {
      var valThis = $("#txtSearchValue").val();

      $(".dropdown-select ul > li").each(function () {
        var text = $(this).text();
        text.toLowerCase().indexOf(valThis.toLowerCase()) > -1
          ? $(this).show()
          : $(this).hide();
      });
    });
  }

  // Event listeners

  // Open/close
  $(document).on("click", ".dropdown-select", function (event) {
    if ($(event.target).hasClass("dd-searchbox")) return;

    $(".dropdown-select").not($(this)).removeClass("open");
    $(this).toggleClass("open");
    if ($(this).hasClass("open")) {
      $(this).find(".option").attr("tabindex", 0);
      $(this).find(".selected").focus();
    } else {
      $(this).find(".option").removeAttr("tabindex");
      $(this).focus();
    }
  });

  // Close when clicking outside
  $(document).on("click", function (event) {
    if ($(event.target).closest(".dropdown-select").length === 0) {
      $(".dropdown-select").removeClass("open");
      $(".dropdown-select .option").removeAttr("tabindex");
    }
    event.stopPropagation();
  });

  // Option click
  $(document).on("click", ".dropdown-select .option", function (event) {
    $(this).closest(".list").find(".selected").removeClass("selected");
    $(this).addClass("selected");
    var text = $(this).data("display-text") || $(this).text();
    $(this).closest(".dropdown-select").find(".current").text(text);
    $(this)
      .closest(".dropdown-select")
      .prev("select")
      .val($(this).data("value"))
      .trigger("change");
  });

  // Keyboard events
  $(document).on("keydown", ".dropdown-select", function (event) {
    var focused_option = $(
      $(this).find(".list .option:focus")[0] ||
        $(this).find(".list .option.selected")[0]
    );
    // Space or Enter
    //if (event.keyCode == 32 || event.keyCode == 13) {
    if (event.keyCode === 13) {
      if ($(this).hasClass("open")) focused_option.trigger("click");
      else $(this).trigger("click");

      return false;
      // Down
    } else if (event.keyCode === 40) {
      if (!$(this).hasClass("open")) $(this).trigger("click");
      else focused_option.next().focus();

      return false;
      // Up
    } else if (event.keyCode === 38) {
      if (!$(this).hasClass("open")) $(this).trigger("click");
      else {
        focused_option = $(
          $(this).find(".list .option:focus")[0] ||
            $(this).find(".list .option.selected")[0]
        );
        focused_option.prev().focus();
      }
      return false;
      // Esc
    } else if (event.keyCode === 27) {
      if ($(this).hasClass("open")) $(this).trigger("click");

      return false;
    }
  });

  $(document).ready(function () {
    loadHelpers();
  });

  $(window).load(function () {
    loadHelpers();
  });
}

async function jQuerySchemaDrawers() {
  if ($(".schema-fields-drawer .title").length > 1) {
    $(".schema-fields-drawer .title").parent().addClass("closed");
  }

  $(".schema-fields-drawer .title .indicator").click(function (e) {
    e.stopImmediatePropagation();
    e.preventDefault();

    if ($(this).parent().parent().hasClass("closed")) {
      $(this).parent().siblings(".content").slideDown();
      $(this).parent().parent().removeClass("closed");
    } else {
      $(this).parent().parent().addClass("closed");
      $(this).parent().siblings(".content").slideUp();
    }
  });

  $(".remove-schema").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  });
}

async function jQueryTabController() {
  $(".tabs > *").on("click", function () {
    let index = $(this).index() + 1;

    $(".tabs > *").removeClass("active");
    $(this).addClass("active");

    $(".tab-content-container > *").hide();
    $(".tab-content-container > *:nth-child(" + index + ")").show();
  });
}

async function schemaSelector() {
  function loadHelpers() {
    // Search
    $(".dd-search #txtSearchValue").keyup(function () {
      var valThis = $("#txtSearchValue").val();

      $(".dropdown-select ul > li").each(function () {
        var text = $(this).text();
        text.toLowerCase().indexOf(valThis.toLowerCase()) > -1
          ? $(this).show()
          : $(this).hide();
      });
    });
  }

  // Event listeners

  // Open/close
  $(document).on("click", ".dropdown-select", function (event) {
    if ($(event.target).hasClass("dd-searchbox")) {
      return;
    }
    $(".dropdown-select").not($(this)).removeClass("open");
    $(this).toggleClass("open");
    if ($(this).hasClass("open")) {
      $(this).find(".option").attr("tabindex", 0);
      $(this).find(".selected").focus();
    } else {
      $(this).find(".option").removeAttr("tabindex");
      $(this).focus();
    }
  });

  // Close when clicking outside
  $(document).on("click", function (event) {
    if ($(event.target).closest(".dropdown-select").length === 0) {
      $(".dropdown-select").removeClass("open");
      $(".dropdown-select .option").removeAttr("tabindex");
    }
    event.stopPropagation();
  });

  // Option click
  $(document).on("click", ".dropdown-select .option", function (event) {
    $(this).closest(".list").find(".selected").removeClass("selected");
    $(this).addClass("selected");
    var text = $(this).data("display-text") || $(this).text();
    $(this).closest(".dropdown-select").find(".current").text(text);
    $(this)
      .closest(".dropdown-select")
      .prev("select")
      .val($(this).data("value"))
      .trigger("change");
  });

  // Keyboard events
  $(document).on("keydown", ".dropdown-select", function (event) {
    var focused_option = $(
      $(this).find(".list .option:focus")[0] ||
        $(this).find(".list .option.selected")[0]
    );
    // Space or Enter
    //if (event.keyCode == 32 || event.keyCode == 13) {
    if (event.keyCode === 13) {
      if ($(this).hasClass("open")) {
        focused_option.trigger("click");
      } else {
        $(this).trigger("click");
      }
      return false;
      // Down
    } else if (event.keyCode === 40) {
      if (!$(this).hasClass("open")) {
        $(this).trigger("click");
      } else {
        focused_option.next().focus();
      }
      return false;
      // Up
    } else if (event.keyCode === 38) {
      if (!$(this).hasClass("open")) {
        $(this).trigger("click");
      } else {
        focused_option = $(
          $(this).find(".list .option:focus")[0] ||
            $(this).find(".list .option.selected")[0]
        );
        focused_option.prev().focus();
      }
      return false;
      // Esc
    } else if (event.keyCode === 27) {
      if ($(this).hasClass("open")) {
        $(this).trigger("click");
      }
      return false;
    }
  });

  $(document).ready(function () {
    loadHelpers();

    $("select#schema-selector").change((event) => {
      //console.log(event);
      //console.log("val", $("select#schema-selector").val());
    });
  });
}

const autofill = async (selectedSchema, page, globalVariables) => {
  let autofilledJSON = {};

  // WebSite
  if (selectedSchema === "WebSite") {
    WebSiteSchema["name"] = UTIL.cleanText(page.name);
    WebSiteSchema["url"] = page.absolute_url;

    autofilledJSON = WebSiteSchema;
  }
  // WebPage
  else if (selectedSchema === "WebPage") {
    WebPageSchema["name"] = UTIL.cleanText(page.name);
    WebPageSchema["url"] = page.absolute_url;
    WebPageSchema["description"] = page.meta.meta_description;

    autofilledJSON = WebPageSchema;
  }
  // Article
  else if (selectedSchema === "Article") {
    ArticleSchema["headline"] = UTIL.cleanText(page.name);
    ArticleSchema["url"] = page.url ? page.url : page.absolute_url;
    ArticleSchema["mainEntityOfPage"]["@id"] = page.url
      ? page.url
      : page.absolute_url;
    ArticleSchema["description"] = page.meta
      ? page.meta.meta_description
        ? page.meta.meta_description
        : page.meta_description
      : "";
    ArticleSchema["author"]["@type"] = "Person";
    ArticleSchema["author"]["name"] = page.blog_post_author
      ? UTIL.cleanText(page.blog_post_author.display_name)
      : "";
    ArticleSchema["author"]["url"] =
      page.parent_blog.absolute_url &&
      page.parent_blog.absolute_url + "/author/" + page.blog_post_author.slug;

    ArticleSchema["datePublished"] = moment(page.publish_date).format(
      "YYYY-MM-DD"
    );
    ArticleSchema["dateModified"] = moment(page.updated).format("YYYY-MM-DD");

    if (typeof globalVariables !== "undefined") {
      if ("articleFeaturedImage" in globalVariables) {
        ArticleSchema["image"] = globalVariables.articleFeaturedImage;
      }
      if ("articlePublisher" in globalVariables)
        ArticleSchema["publisher"]["name"] = globalVariables.articlePublisher;
      if ("articlePublisherLogoURL" in globalVariables)
        ArticleSchema["publisher"]["logo"]["url"] =
          globalVariables.articlePublisherLogoURL;
    }

    if (page.featured_image) ArticleSchema["image"] = page.featured_image;

    autofilledJSON = ArticleSchema;
  }
  // BlogPosting
  else if (selectedSchema === "BlogPosting") {
    BlogPostingSchema["headline"] = UTIL.cleanText(page.name);
    BlogPostingSchema["url"] = "url" in page ? page.url : page.absolute_url;
    BlogPostingSchema["mainEntityOfPage"]["@id"] = page.url
      ? page.url
      : page.absolute_url;
    BlogPostingSchema["description"] = page.meta
      ? page.meta.meta_description
        ? page.meta.meta_description
        : page.meta_description
      : "";
    BlogPostingSchema["author"]["@type"] = "Person";
    BlogPostingSchema["author"]["name"] = page.blog_post_author
      ? UTIL.cleanText(page.blog_post_author.display_name)
      : "";
    BlogPostingSchema["author"]["url"] =
      "parent_blog" in page
        ? page.parent_blog.absolute_url +
          "/author/" +
          page.blog_post_author.slug
        : "";

    BlogPostingSchema["datePublished"] = moment(page.publish_date).format(
      "YYYY-MM-DD"
    );
    BlogPostingSchema["dateModified"] = moment(page.updated).format(
      "YYYY-MM-DD"
    );

    if (typeof globalVariables !== "undefined") {
      if ("articleFeaturedImage" in globalVariables) {
        BlogPostingSchema["image"] = globalVariables.articleFeaturedImage;
      }
      if ("articlePublisher" in globalVariables)
        BlogPostingSchema["publisher"]["name"] =
          globalVariables.articlePublisher;
      if ("articlePublisherLogoURL" in globalVariables)
        BlogPostingSchema["publisher"]["logo"]["url"] =
          globalVariables.articlePublisherLogoURL;
    }

    if (page.featured_image) BlogPostingSchema["image"] = page.featured_image;

    autofilledJSON = BlogPostingSchema;
  }
  // Event
  else if (selectedSchema === "Event") {
    EventSchema["name"] = UTIL.cleanText(page.name);
    EventSchema["description"] =
      page.meta_description && UTIL.cleanText(page.meta_description);
    EventSchema["image"] = page.featured_image;
    autofilledJSON = EventSchema;
  }
  // LegalService
  else if (selectedSchema === "LegalService") {
    LegalServiceSchema["name"] = UTIL.cleanText(page.name);
    LegalServiceSchema["url"] = page.absolute_url;

    if ("address" in globalVariables) {
      LegalServiceSchema["address"]["addressLocality"] =
        globalVariables.address.addressLocality;
      LegalServiceSchema["address"]["addressRegion"] =
        globalVariables.address.addressRegion;
      LegalServiceSchema["address"]["postalCode"] =
        globalVariables.address.postalCode;
      LegalServiceSchema["address"]["streetAddress"] =
        globalVariables.address.streetAddress;
      LegalServiceSchema["address"]["addressCountry"] =
        globalVariables.address.addressCountry;
    }

    autofilledJSON = LegalServiceSchema;
  }

  return autofilledJSON;
};

const formatSchema = async (schema) => {
  if (schema["@graph"])
    schema["@graph"].forEach((singleSchema) => {
      if (singleSchema["@context"]) delete singleSchema["@context"];
    });

  return schema;
};

const schemaAnalysis = async (schema) => {
  let errors = {};

  // Handle analysis for schemas that contain a graph
  if (schema["@graph"]) {
    // Iterate through each schema in the graph
    schema["@graph"].forEach((schema) => {
      const currentSchema = schema["@type"];

      const iterate = (schema) => {
        Object.keys(schema).forEach((key) => {
          if (typeof schema[key] === "object") {
            iterate(schema[key]);
          } else if (
            schema[key] === "" ||
            schema[key] === null ||
            schema[key] === undefined
          )
            if (errors[currentSchema])
              errors[currentSchema].push(key + " is missing a value.");
            else {
              errors[currentSchema] = [];
              errors[currentSchema].push(key + " is missing a value.");
            }
        });
      };

      iterate(schema);
    });

    return errors;
  }
  // Handle analysis if it is the legacy schema structure (array instead of graph)
  else if (Array.isArray(schema)) {
    schema.forEach((individualSchema) => {
      const currentSchema = individualSchema["@type"];

      const iterate = (individualSchema) => {
        Object.keys(individualSchema).forEach((key) => {
          if (typeof individualSchema[key] === "object") {
            iterate(individualSchema[key]);
          } else if (
            individualSchema[key] === "" ||
            individualSchema[key] === null ||
            individualSchema[key] === undefined
          )
            if (errors[currentSchema])
              errors[currentSchema].push(key + " is missing a value.");
            else {
              errors[currentSchema] = [];
              errors[currentSchema].push(key + " is missing a value.");
            }
        });
      };

      iterate(individualSchema);
    });

    return errors;
  }
  // Handle single schema
  else {
    Object.keys(schema).forEach((key) => {
      if (
        schema[key] === "" ||
        schema[key] === null ||
        schema[key] === undefined
      )
        if (errors[schema]) errors[schema].push(key + " is missing a value.");
        else {
          errors[schema] = [];
          errors[schema].push(key + " is missing a value.");
        }
    });
    return errors;
  }
};

// Checks list of standard schemas and LocalBusiness schemas we support.
/**
 * @param {string} selectedSchemas
 */
const schemasAllowed = async (selectedSchemas) => {
  let success = true;

  if (selectedSchemas.length > 0) {
    let allowedComponents = [];

    for (const component in COMPONENTS) allowedComponents.push(component);

    selectedSchemas.forEach((schema) => {
      if (
        !allowedComponents.includes(schema) &&
        !LOCAL_BUSINESS.includes(schema)
      )
        success = false;
    });

    return success;
  }
};

const swap = (firstIndex, secondIndex, array) => {
  const temp = array[firstIndex];
  array[firstIndex] = array[secondIndex];
  array[secondIndex] = temp;
  return array;
};

export {
  jQueryScripts,
  jQueryTabController,
  jQuerySchemaDrawers,
  userInputScripts,
  autofill,
  schemaSelector,
  formatSchema,
  schemaAnalysis,
  schemasAllowed,
  swap,
};
