/*
 * Form structure
 */
import AggregateRating from "./AggregateRating";
import Article from "./Article/article";
import BlogPosting from "./BlogPosting";
import Book from "./Book/book";
import BreadcrumbList from "./BreadcrumbList/breadcrumbs";
import ClaimReview from "./ClaimReview/claimReview";
import Course from "./Course/course";
import Event from "./Event/event";
import FAQPage from "./FAQ/faq";
import HowTo from "./HowTo";
import JobPosting from "./JobPosting/job-posting";
import LocalBusiness from "./LocalBusiness/localBusiness";
import LegalService from "./LegalService";
import NewsArticle from "./NewsArticle";
import Organization from "./Organization/organization";
import Person from "./Person/person";
import PodcastEpisode from "./PodcastEpisode";
import Product from "./Product/product";
import Recipe from "./Recipe/recipe";
import Review from "./Review";
import Service from "./Service/service";
import SoftwareApplication from "./SoftwareApplication/softwareApplication";
import VideoObject from "./Video/video";
import WebApplication from "./WebApplication/webApplication";
import WebSite from "./WebSite/website";
import WebPage from "./WebPage";

/*
 * Schema / JSON-LD
 */
const SCHEMA_JSON = {
  AggregateRating: require("./AggregateRating/schema.json"),
  Article: require("./Article/article.json"),
  Book: require("./Book/book.json"),
  BlogPosting: require("./BlogPosting/schema.json"),
  BreadcrumbList: require("./BreadcrumbList/breadcrumbs.json"),
  ClaimReview: require("./ClaimReview/claimReview.json"),
  Course: require("./Course/course.json"),
  Event: require("./Event/event.json"),
  FAQPage: require("./FAQ/faq.json"),
  HowTo: require("./HowTo/schema.json"),
  JobPosting: require("./JobPosting/job-posting.json"),
  LegalService: require("./LegalService/schema.json"),
  LocalBusiness: require("./LocalBusiness/localBusiness.json"),
  NewsArticle: require("./NewsArticle/schema.json"),
  Organization: require("./Organization/organization.json"),
  Person: require("./Person/person.json"),
  PodcastEpisode: require("./PodcastEpisode/podcastEpisode.json"),
  Product: require("./Product/product.json"),
  Recipe: require("./Recipe/recipe.json"),
  Review: require("./Review/schema.json"),
  Service: require("./Service/service.json"),
  SoftwareApplication: require("./SoftwareApplication/softwareApplication.json"),
  VideoObject: require("./Video/video.json"),
  WebApplication: require("./WebApplication/webApplication.json"),
  WebSite: require("./WebSite/website.json"),
  WebPage: require("./WebPage/schema.json"),
};

/*
 * Schema
 */
const ArticleSchema = require("./Article/article.json");
const BlogPostingSchema = require("./BlogPosting/schema.json");
const BookSchema = require("./Book/book.json");
const WebSiteSchema = require("./WebSite/website.json");
const WebPageSchema = require("./WebPage/schema.json");
const EventSchema = require("./Event/event.json");
const ProductSchema = require("./Product/product.json");
const VideoObjectSchema = require("./Video/video.json");
const JobPostingSchema = require("./JobPosting/job-posting.json");
const PersonSchema = require("./Person/person.json");
const FAQPagesSchema = require("./FAQ/faq.json");
const LegalServiceSchema = require("./LegalService/schema.json");
const RecipeSchema = require("./Recipe/recipe.json");
const ServiceSchema = require("./Service/service.json");
const SoftwareApplicationSchema = require("./SoftwareApplication/softwareApplication.json");

/*
 * Components map
 */
const COMPONENTS = {
  AggregateRating,
  Article,
  BlogPosting,
  Book,
  BreadcrumbList,
  ClaimReview,
  Course,
  Event,
  FAQPage,
  HowTo,
  JobPosting,
  LocalBusiness,
  LegalService,
  NewsArticle,
  Organization,
  Person,
  PodcastEpisode,
  Product,
  Recipe,
  Review,
  Service,
  SoftwareApplication,
  VideoObject,
  WebApplication,
  WebSite,
  WebPage,
};

/*
 * Allowed / client-facing schemas
 * Items NOT in this list will not be shown to users
 */
const SCHEMAS = [
  "AggregateRating",
  "Article",
  "BlogPosting",
  "Book",
  "BreadcrumbList",
  "ClaimReview",
  "Course",
  "Event",
  "FAQPage",
  "HowTo",
  "JobPosting",
  "LocalBusiness",
  "LegalService",
  "NewsArticle",
  "Organization",
  "Person",
  "PodcastEpisode",
  "Product",
  "Recipe",
  "Review",
  "Service",
  "SoftwareApplication",
  "VideoObject",
  "WebApplication",
  "WebSite",
  "WebPage",
];

/*
 * Types for LocalBusiness schema
 */
const LOCAL_BUSINESS = [
  "AnimalShelter",
  "ArchiveOrganization",
  "AutomotiveBusiness",
  "ChildCare",
  "Dentist",
  "DryCleaningOrLaundry",
  "EmergencyService",
  "EmploymentAgency",
  "EntertainmentBusiness",
  "FinancialService",
  "FoodEstablishment",
  "GovernmentOffice",
  "HealthAndBeautyBusiness",
  "HomeAndConstructionBusiness",
  "InternetCafe",
  "LegalService",
  "Library",
  "LodgingBusiness",
  "MedicalBusiness",
  "ProfessionalService",
  "RadioStation",
  "RealEstateAgent",
  "RecyclingCenter",
  "Restaurant",
  "SelfStorage",
  "ShoppingCenter",
  "SportsActivityLocation",
  "Store",
  "TelevisionStation",
  "TouristInformationCenter",
  "TravelAgency",
];

/*
 * Schemas with autofill capability
 */
const AUTOFILL = [
  "WebSite",
  "WebPage",
  "Article",
  "BlogPosting",
  "Event",
  "LegalService",
];

/*
 * Schemas that are allowed to add multiples of
 */
const MULTIPLES = ["VideoObject"];

export {
  COMPONENTS,
  LOCAL_BUSINESS,
  SCHEMAS,
  SCHEMA_JSON,
  AUTOFILL,
  MULTIPLES,
  ArticleSchema,
  BlogPostingSchema,
  BookSchema,
  WebSiteSchema,
  WebPageSchema,
  EventSchema,
  LegalServiceSchema,
  ProductSchema,
  VideoObjectSchema,
  JobPostingSchema,
  PersonSchema,
  FAQPagesSchema,
  ServiceSchema,
  SoftwareApplicationSchema,
  RecipeSchema,
};
