import React, { Component } from "react";
//import axios from "axios";

/* Components / Views */
import Header from "../../components/header/header";
import Accounts from "./accounts";
//import Members from "./members";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //pages: [],
    };

    this.renderPage = this.renderPage.bind(this);
  }

  componentDidMount = async () => {};

  renderPage = () => {
    return (
      <div id="dashboard" className="content-window">
        <Header title="Admin Dashboard" />

        {<Accounts />}

        {/*<Health />*/}
      </div>
    );
  };

  render() {
    return this.renderPage();
  }
}
