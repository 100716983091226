import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";

import { Typography } from "@mui/material";

// Icons
//import InfoIcon from "@material-ui/icons/Info";

let schemaJSON = require("./job-posting.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class JobPosting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      url: "",
      internalURL: "",
      optionalString: "",
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "JobPosting"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentName === "potentialAction") {
      schemaJSON["potentialAction"] = {
        "@type": "SearchAction",
        target: "http://example.com/search?&q={query}",
        query: "required",
      };
    }

    if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = UTIL.cleanText(value);
    else if (parentName) schemaJSON[parentName][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6070190)}
          >
            Learn More
          </span>
        </Typography>

        <h4>Title</h4>
        <input
          type="text"
          id="title"
          name="title"
          onChange={this.handleChange}
          value={schemaJSON["title"]}
        />
        <h4>Job description</h4>
        <textarea
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />
        <h4>Company name</h4>
        <input
          type="text"
          id="companyName"
          data-parentname="hiringOrganization"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["hiringOrganization"]["name"]}
        />
        <h4>Company URL</h4>
        <input
          type="text"
          id="companyURL"
          data-parentname="hiringOrganization"
          name="url"
          onChange={this.handleChange}
          value={schemaJSON["hiringOrganization"]["url"]}
        />
        <h4>Industry</h4>
        <input
          type="text"
          id="industry"
          name="industry"
          onChange={this.handleChange}
          value={schemaJSON["industry"]}
        />
        <label htmlFor="datePosted">
          <strong>Date Posted</strong>
        </label>
        <input
          type="datetime-local"
          id="datePosted"
          name="datePosted"
          onChange={this.handleChange}
          value={schemaJSON?.datePosted}
        />
        <label htmlFor="validThrough">
          <strong>Valid Through</strong>
        </label>
        <input
          type="datetime-local"
          id="validThrough"
          name="validThrough"
          onChange={this.handleChange}
          value={schemaJSON?.validThrough}
        />
      </div>
    );
  }
}
