import React, { Component } from "react";
import $ from "jquery";
import { store } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./organization.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Organization extends Component {
  constructor(props) {
    super(props);

    this.sameAs = [];
    this.contactPoint = [];

    this.state = {
      showSameAs: this.sameAs,
      showContactPoint: this.contactPoint,
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Organization"
      );

      this.generateInputFields();
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    schemaJSON.sameAs.map((sameAs, index) => {
      $("#sameAs-" + index).val(sameAs);
      return 1;
    });

    schemaJSON.contactPoint.map((contactPoint, index) => {
      $("#telephone-" + index).val(contactPoint["telephone"]);
      $("#contactType-" + index).val(contactPoint["contactType"]);
      $("#contactOption-" + index).val(contactPoint["contactOption"]);
      $("#areaServed-" + index).val(contactPoint["areaServed"]);
      return 1;
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    // if there is a parent object with the attribute "data-parentname" and it is a review
    if (parentName === "contactPoint") {
      const contactpointNum = e.target.getAttribute("data-contactpoint-number");
      schemaJSON[parentName][contactpointNum][name] = UTIL.cleanText(value);
    } else if (parentName === "sameAs") {
      const sameAsNum = e.target.getAttribute("data-sameas-number");
      schemaJSON[parentName][sameAsNum] = UTIL.cleanText(value);
    } else if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = UTIL.cleanText(value);
    else if (parentName) schemaJSON[parentName][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    await this.props.setSchema(this.state.schema);
  };

  contactPointFields = (counter, contactPoint) => {
    return (
      <div className="contactpoint-fields" key={"contactPoint" + counter}>
        <h5>Contact point #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeContactPoint(counter)}
        >
          Remove
        </span>

        <label>
          <h4>Telephone #</h4>
        </label>
        <input
          type="text"
          id={"telephone-" + counter}
          name="telephone"
          data-parentname="contactPoint"
          data-contactpoint-number={counter}
          placeholder="+1-800-392-2999"
          onChange={this.handleChange}
          value={contactPoint?.telephone}
        />

        <label className="d-flex align-center">
          <h4>Contact type</h4>
          <InfoIcon
            data-for="contact-type-tip"
            data-tip="A person or organization can have different contact points, for
            different purposes. For example, a sales contact point, a PR contact
            point and so on. This property is used to specify the kind of contact
            point."
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="contact-type-tip" />
        </label>
        <input
          type="text"
          id={"contactType-" + counter}
          name="contactType"
          data-parentname="contactPoint"
          data-contactpoint-number={counter}
          onChange={this.handleChange}
          value={contactPoint?.contactType}
        />

        <label className="d-flex align-center">
          <h4>Contact option</h4>
          <InfoIcon
            data-for="contact-option-tip"
            data-tip="An option available on this contact point (e.g. a toll-free number or
              support for hearing-impaired callers). Currently supports:
              HearingImpairedSupported, TollFree"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="contact-option-tip" />
        </label>
        <input
          type="text"
          id={"contactOption-" + counter}
          name="contactOption"
          data-parentname="contactPoint"
          data-contactpoint-number={counter}
          placeholder="TollFree"
          onChange={this.handleChange}
          value={contactPoint?.contactOption}
        />

        <label className="d-flex align-center">
          <h4>Area Served</h4>
          <InfoIcon
            data-for="contact-option-tip"
            data-tip="The geographic area where a service or offered item is provided."
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="contact-option-tip" />
        </label>
        <input
          type="text"
          id={"areaServed-" + counter}
          name="areaServed"
          placeholder="US"
          data-parentname="contactPoint"
          data-contactpoint-number={counter}
          onChange={this.handleChange}
          value={contactPoint?.areaServed}
        />
      </div>
    );
  };

  addContactPoint = async () => {
    let counter = this.contactPoint.length;
    let contactPoint = {
      "@type": "ContactPoint",
      telephone: "",
      contactType: "",
      contactOption: "",
      areaServed: "",
    };

    this.contactPoint.push(this.contactPointFields(counter));

    this.setState({ showContactPoint: this.contactPoint });

    // add a new faq object to the array of faqs
    schemaJSON["contactPoint"].push(contactPoint);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeContactPoint = async (index) => {
    // remove
    this.contactPoint.splice(index, 1);
    schemaJSON.contactPoint.splice(index, 1);

    // update
    await this.setState({
      showContactPoint: this.showContactPoint,
      schema: schemaJSON,
    });
    this.props.setSchema(this.state.schema);

    store.addNotification({
      message: "Contact point item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  sameAsFields = (counter, sameAs) => {
    return (
      <div className="sameas-fields" key={"sameas-" + counter}>
        <h5>sameAs #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeSameAs(counter)}
        >
          Remove
        </span>

        <input
          type="text"
          id={"sameAs-" + counter}
          name="sameAs"
          data-parentname="sameAs"
          data-sameas-number={counter}
          placeholder="https://www.facebook.com/SampleOrganization"
          onChange={this.handleChange}
          value={sameAs}
        />
      </div>
    );
  };

  addSameAs = async () => {
    let counter = this.sameAs.length;
    this.sameAs.push(this.sameAsFields(counter));
    this.setState({ showSameAs: this.showSameAs });
    schemaJSON["sameAs"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeSameAs = async (index) => {
    // remove
    this.sameAs.splice(index, 1);
    schemaJSON.sameAs.splice(index, 1);

    // update
    await this.setState({ showSameAs: this.showSameAs, schema: schemaJSON });
    this.props.setSchema(this.state.schema);

    store.addNotification({
      message: "sameAs item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  generateInputFields = () => {
    schemaJSON.sameAs.map((sameAs, counter) =>
      this.sameAs.push(this.sameAsFields(counter, sameAs))
    );

    schemaJSON.contactPoint.map((contactPoint, counter) =>
      this.contactPoint.push(this.contactPointFields(counter, contactPoint))
    );
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6070159)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Organization name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name of organization"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label className="d-flex align-center">
          <h4>URL</h4>
          <InfoIcon
            data-for="url-tip"
            data-tip="URL of the current page"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="url-tip" />
        </label>
        <input
          type="text"
          id="url"
          name="url"
          placeholder="URL of organization"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <label className="d-flex align-center">
          <h4>Logo</h4>
          <InfoIcon
            data-for="logo-tip"
            data-tip="URL of the logo you want to display"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="logo-tip" />
        </label>
        <input
          type="text"
          id="logo"
          name="logo"
          placeholder="https://www.oracle.com/webfolder/s/brand/assets/i/specimens/identity/logo/primary-badge.png"
          onChange={this.handleChange}
          value={schemaJSON["logo"]}
        />

        {/* sameAs */}
        <div className="sameas-items">
          <label className="d-flex align-center">
            <h4>Same as</h4>
            <InfoIcon
              data-for="sameas-tip"
              data-tip="URL of a reference Web page that unambiguously indicates the item's
            identity. E.g. the URL of the item's Wikipedia page, Wikidata entry,
            or official website."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="sameas-tip" />
          </label>

          <div className="fields-container">{this.sameAs}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addSameAs}
          >
            Add sameAs
          </button>
        </div>

        {/* contactPoints */}
        <div className="contactpoint-items" style={{ marginTop: "10px" }}>
          <label className="d-flex align-center">
            <h4>Contact points</h4>
            <InfoIcon
              data-for="contact-points-tip"
              data-tip="A contact point for a person or organization."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="contact-points-tip" />
          </label>

          <div className="fields-container">{this.contactPoint}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addContactPoint}
          >
            Add contactPoint
          </button>
        </div>
      </div>
    );
  }
}
