import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";

import Account from "../../../../components/header/Account";
//import DomainSelector from "../../../components/header/DomainSelector";

import { Button } from "@mui/material";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <div className="site-header-container">
        <div id="site-header">
          {this.props.title && <h1>{this.props.title}</h1>}

          <Account />
        </div>

        {/* top bar */}
        <div className="top-bar flex-container">
          <div className="left ta-left col-2">
            <a className="back c-teal d-flex align-center" href="/templates">
              <img
                className="mr-5"
                src="/images/back-teal.svg"
                alt="back arrow"
              />
              <span className="text">Back to Templates</span>
            </a>
          </div>
          <div className="buttons ta-right col-2">
            {/*<Button
              classes={{
                root: "secondary",
              }}
              onClick={this.props.toggleRevisionsModal}
            >
              Revisions
            </Button>*/}

            <Button
              classes={{
                root: "primary",
              }}
              onClick={this.props.save}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
