import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./webApplication.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class WebApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "WebApplication" // change this to match the component name
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="application-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6076916)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Application name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Application name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Description</h4>
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label>
          <h4>Browser requirements</h4>
        </label>
        <input
          type="text"
          id="browserRequirements"
          name="browserRequirements"
          onChange={this.handleChange}
          value={schemaJSON["browserRequirements"]}
        />

        <div className="flex-container">
          <div className="col-2">
            <label>
              <h4>Application category</h4>
            </label>
            <input
              type="text"
              id="applicationCategory"
              name="applicationCategory"
              onChange={this.handleChange}
              value={schemaJSON["applicationCategory"]}
            />
          </div>
          <div className="col-2">
            <label>
              <h4>Operation system</h4>
            </label>
            <input
              type="text"
              id="operatingSystem"
              name="operatingSystem"
              onChange={this.handleChange}
              value={schemaJSON["operatingSystem"]}
            />
          </div>
        </div>

        <label>
          <h4>Name of item reviewed</h4>
        </label>
        <input
          type="text"
          id="itemReviewed"
          name="itemReviewed"
          data-parentname="aggregateRating"
          onChange={this.handleChange}
          value={schemaJSON["aggregateRating"]["itemReviewed"]}
        />

        <div className="flex-container">
          <div className="col-3">
            <label>
              <h4>Rating value</h4>
            </label>
            <input
              type="number"
              id="ratingValue"
              name="ratingValue"
              data-parentname="aggregateRating"
              min="0"
              max="5"
              onChange={this.handleChange}
              value={schemaJSON["aggregateRating"]["ratingValue"]}
            />
          </div>
          <div className="col-3">
            <label>
              <h4>Rating count</h4>
            </label>
            <input
              type="number"
              id="ratingCount"
              name="ratingCount"
              min="0"
              data-parentname="aggregateRating"
              onChange={this.handleChange}
              value={schemaJSON["aggregateRating"]["ratingCount"]}
            />
          </div>
          <div className="col-3">
            <label>
              <h4>Best rating</h4>
            </label>
            <input
              type="number"
              id="bestRating"
              name="bestRating"
              min="0"
              max="5"
              data-parentname="aggregateRating"
              onChange={this.handleChange}
              value={schemaJSON["aggregateRating"]["bestRating"]}
            />
          </div>
        </div>

        <div className="flex-container">
          <div className="col-2">
            <label>
              <h4>Price</h4>
            </label>
            <input
              type="number"
              id="price"
              name="price"
              data-parentname="offers"
              onChange={this.handleChange}
              value={schemaJSON["offers"]["price"]}
            />
          </div>
          <div className="col-2">
            <label>
              <h4>Currency (i.e. USD)</h4>
            </label>
            <input
              type="text"
              id="priceCurrency"
              name="priceCurrency"
              onChange={this.handleChange}
              value={schemaJSON["offers"]["priceCurrency"]}
            />
          </div>
        </div>
      </div>
    );
  }
}
