import React, { Component } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  //DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

import HistoryIcon from "@mui/icons-material/History";
import Modal from "../../../../components/modal/modal";

const UTIL = require("../../../../util/util");
const API = require("../../../../api/db-functions");

export default class Revisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revision: {},
      revisionDate: "",

      // modals
      dialogToggle: false,
    };
  }

  componentDidMount = async () => {};

  toggle = (stateName) => {
    this.setState({ [stateName]: !this.state[stateName] });
  };

  confirmRevision = async (revision) => {
    await this.setState({ revision: revision });
    this.toggle("dialogToggle");

    this.state.revision.updatedAt
      ? this.setState({
          revisionDate: UTIL.timeConverter(this.state.revision.updatedAt),
        })
      : this.setState({
          revisionDate: UTIL.timeConverter(this.state.revision.updated_at),
        });
  };

  updateRevision = async (accessToken, postId, revisionId) => {
    let response;

    if (this.props.state.isArticle) {
      response = await API.updateArticleRevision(
        accessToken,
        postId,
        revisionId
      );
    }
    if (this.props.state.isPage) {
      response = await API.updatePageRevision(accessToken, postId, revisionId);
    }

    if (response.status === 200) window.location.reload();
  };

  render() {
    const revisions = this.props.revisions;
    //console.log(revisions);

    return this.state.dialogToggle ? (
      <Modal
        toggleState={this.state.dialogToggle}
        toggle={() => this.toggle("dialogToggle")}
        title={"Are you sure you want to revert?"}
        message={
          "This will revert the current page back to the selected time and date of " +
          this.state.revisionDate
        }
        yesAction={() =>
          this.updateRevision(
            this.props.accessToken,
            this.props.postId,
            this.state.revision.id
          )
        }
      />
    ) : (
      <Dialog
        open={this.props.toggle}
        onClose={() => this.props.toggleDialog("revisionsModalToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">Revisions</DialogTitle>

        <DialogContent>
          <Box style={{ marginTop: "20px" }}>
            {/* v3 API */}
            {Object.keys(revisions).length !== 0 && "results" in revisions && (
              <ul className="revisions">
                {revisions.results.map((revision) => (
                  <li
                    className={
                      "revision-item " + (revision.id === "-1" ? "active" : "")
                    }
                    key={revision.id}
                    onClick={() =>
                      revision.id !== "-1" && this.confirmRevision(revision)
                    }
                  >
                    <div className="updated">
                      {UTIL.timeConverter(revision.updatedAt)}
                    </div>
                    <div className="published-by">{revision.user.fullName}</div>
                    {revision.id === "-1" && (
                      <div className="current-version">Current version</div>
                    )}

                    <HistoryIcon className="revert" />
                  </li>
                ))}
              </ul>
            )}

            {/* v2 API */}
            {Object.keys(revisions).length !== 0 && !("results" in revisions) && (
              <ul className="revisions">
                {}
                {revisions.map((revision) => (
                  <li
                    className={
                      "revision-item " + (revision.id === -1 ? "active" : "")
                    }
                    key={revision.id}
                    onClick={() =>
                      revision.id !== -1 && this.confirmRevision(revision)
                    }
                  >
                    <div className="updated">
                      {UTIL.timeConverter(revision.updated_at)}
                    </div>
                    <div className="published-by">
                      {revision.user.full_name}
                    </div>
                    {revision.id === -1 && (
                      <div className="current-version">Current version</div>
                    )}

                    <HistoryIcon className="revert" />
                  </li>
                ))}
              </ul>
            )}
          </Box>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={() => this.props.toggleDialog("revisionsModalToggle")}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
