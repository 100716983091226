import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./course.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Course extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    // load existing schema
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Course"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    await this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6079676)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Course name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name of course"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Course description</h4>
        </label>
        <div className="description">
          A description of the course. Display limit of 60 characters.
        </div>
        <textarea
          type="text"
          id="description"
          name="description"
          maxLength="60"
          placeholder="Description of course."
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label>
          <h4>Organization name</h4>
        </label>
        <div className="description">
          The organization that publishes the source content of the course. For
          example, UC Berkeley.
        </div>
        <input
          type="text"
          id="orgName"
          name="name"
          data-parentname="provider"
          onChange={this.handleChange}
          value={schemaJSON["provider"]["name"]}
        />

        <label>
          <h4>Organization sameAs</h4>
        </label>
        <div className="description">
          URL of a reference Web page that unambiguously indicates the item's
          identity. E.g. the URL of the item's Wikipedia page, Wikidata entry,
          or official website.
        </div>
        <input
          type="text"
          id="sameAs"
          name="sameAs"
          data-parentname="provider"
          onChange={this.handleChange}
          value={schemaJSON["provider"]["sameAs"]}
        />
      </div>
    );
  }
}
