import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";
//import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
//import { debounce } from "lodash";

// Components
//import SidebarSingle from "./sidebar-single";

// Helper functions
// const DATABASE_API = require("../../api/db-functions");
const UTIL = require("../../../util/util");
//const HELPER = require("../util/localUtil");

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsToggle: false,
    };
  }
  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  render() {
    let page = this.props.page;

    return (
      <li
        className="page-item flex-container"
        id={page.id}
        data-schema={
          UTIL.hasSchema(page.head_html) || UTIL.hasSchema(page.headHtml)
            ? "true"
            : "false"
        }
        data-status={
          UTIL.hasSchema(page.head_html) || UTIL.hasSchema(page.headHtml)
            ? "1"
            : "2"
        }
        data-testresult={page.testResult}
        data-type={
          page.analytics_page_type ? page.analytics_page_type : page.subcategory
        }
        data-domain={page.live_domain}
        key={page.id}
        onClick={this.props.selectSinglePage(page)}
      >
        <div
          className="page-info flex-container"
          onClick={this.props.selectSinglePage(page)}
        >
          <input
            className="select"
            type="checkbox"
            name={page.name}
            onClick={this.props.toggleSelectedPage(page)}
          />

          <h4 className="page-title">
            <a href={"/" + this.props.currentDomain + "/page/" + page.id}>
              {page.name}
            </a>
          </h4>

          {/*
          <a
            className="view-page"
            href={page.absolute_url ? page.absolute_url : page.url}
            target="_blank"
            rel="noopener noreferrer"
            data-tip="View the page in a new window"
          >
            <img
              className="btn-icon"
              src="/images/open-external.svg"
              alt="external link"
            />
            <ReactTooltip />
          </a>
          */}
        </div>

        {UTIL.hasSchema(page.headHtml ? page.headHtml : page.head_html) ? (
          <div className="status" onClick={this.props.selectSinglePage(page)}>
            <span className="published">Published</span>

            {/*page.testResult === false ? (
              <span
                className="warning"
                onClick={this.props.selectSinglePage(page)}
              >
                <ErrorOutlineIcon
                  data-for="status-warning"
                  data-tip="This schema may have potential empty properties. Please review."
                  style={{ color: "#FFCC00", width: "20px" }}
                />
                <ReactTooltip id="status-warning" />
              </span>
            ) : (
              ""
            )*/}
          </div>
        ) : (
          <div className="status" onClick={this.props.selectSinglePage(page)}>
            <span className="no-schema">No Schema</span>
          </div>
        )}

        <div className="type" onClick={this.props.selectSinglePage(page)}>
          <span
            className={
              "label " + page.analytics_page_type
                ? page.analytics_page_type
                : page.subcategory
            }
          >
            {/*{page.analytics_page_type.replace("-", " ")} */}
            {UTIL.getSchemaType(page)}
          </span>
        </div>

        <div
          className="updated-date"
          onClick={this.props.selectSinglePage(page)}
        >
          {UTIL.timeConverter(page.updated)}
        </div>

        {/*<div className="options-container flex-container">
          <svg
            className="btn-icon option-btn"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            onClick={() => this.toggle("optionsToggle")}
          >
            <g
              id="Wireframes"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Dashboard---Domain-Options"
                transform="translate(-1360.000000, -189.000000)"
              >
                <g id="BODY" transform="translate(120.000000, 122.000000)">
                  <g id="Row-1" transform="translate(0.000000, 47.000000)">
                    <g
                      id="Options"
                      transform="translate(1240.000000, 20.000000)"
                    >
                      <rect
                        id="Guide"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      ></rect>
                      <path
                        d="M12,17 C13.1045695,17 14,17.8954305 14,19 C14,20.1045695 13.1045695,21 12,21 C10.8954305,21 10,20.1045695 10,19 C10,17.8954305 10.8954305,17 12,17 Z M12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 Z M12,3 C13.1045695,3 14,3.8954305 14,5 C14,6.1045695 13.1045695,7 12,7 C10.8954305,7 10,6.1045695 10,5 C10,3.8954305 10.8954305,3 12,3 Z"
                        id="Options-Icon"
                        fill="#7F7F7F"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          </div>

        <div
          className={"options " + (this.state.optionsToggle ? "opened" : "")}
        >
          <ul>
            <li>
              <a href={"/" + this.props.currentDomain + "/page/" + page.id}>
                {UTIL.hasSchema(page.head_html) ? "Edit Schema" : "Add Schema"}
              </a>
            </li>
            <li onClick={() => this.props.removeExistingSchema(page)}>
              Delete Schema
            </li>
          </ul>
        </div>*/}

        <div className="hover-menu">
          <a
            href={"/" + this.props.currentDomain + "/page/" + page.id}
            className="btn x-small bg-orange c-white"
          >
            Edit
          </a>

          <a
            href={page.absolute_url ? page.absolute_url : page.url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn x-small bg-orange c-white"
          >
            View
          </a>

          {/*<span
            className="btn x-small bg-orange c-white"
            onClick={() => this.props.removeExistingSchema(page)}
          >
            Remove Schema
          </span>*/}
        </div>
      </li>
    );
  }
}
