import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./book.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Book extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Book"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subparentname = e.target.getAttribute("data-subparentname");

    if (parentname === "dataFeedElement" && subparentname === "author")
      schemaJSON[parentname][0][subparentname][name] = UTIL.cleanText(value);
    else if (
      parentname === "dataFeedElement" &&
      subparentname === "workExample"
    )
      schemaJSON[parentname][0][subparentname][0][name] = UTIL.cleanText(value);
    else if (parentname === "dataFeedElement")
      schemaJSON[parentname][0][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      if (name === "articleType") {
        schemaJSON["dataFeedElement"][0]["author"]["@type"] = value;
      } else {
        schemaJSON[name] = value;
      }
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="application-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6414090)}
          >
            Learn More
          </span>
        </Typography>

        <label htmlFor="name">
          <h4>Book title</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Book title"
          data-parentname="dataFeedElement"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["name"]}
        />

        <div className="author">
          <label>
            <h4>Author</h4>
          </label>

          <div className="flex-container">
            <select
              className="col-2"
              id="authorType"
              name="@type"
              data-parentname="dataFeedElement"
              data-subparentname="author"
              onChange={this.handleChange}
              value={schemaJSON["dataFeedElement"][0]["author"]["@type"]}
            >
              <option value="">- Author @type -</option>
              <option value="Person">Person</option>
              <option value="Organization">Organization</option>
            </select>

            <input
              className="col-2"
              type="text"
              id="authorName"
              name="name"
              data-parentname="dataFeedElement"
              data-subparentname="author"
              placeholder="Author Name"
              onChange={this.handleChange}
              value={schemaJSON["dataFeedElement"][0]["author"]["name"]}
            />
          </div>
        </div>

        <label htmlFor="@id">
          <h4>@id</h4>
        </label>
        <div className="description">
          A globally unique ID for the book in URL format. It must be unique to
          your organization. The ID must be stable and not change over time. URL
          format is suggested though not required. It doesn't have to be a
          working link. The domain used for the @id value must be owned by your
          organization.
        </div>
        <input
          type="text"
          id="@id"
          name="@id"
          placeholder=""
          data-parentname="dataFeedElement"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["@id"]}
        />

        <label htmlFor="url">
          <h4>URL</h4>
        </label>
        <div className="description">
          The URL on your website where the book is introduced or described.
          This link helps accurately reconcile the content in your feed with the
          content in Google's databases.
        </div>
        <input
          type="text"
          id="url"
          name="url"
          placeholder=""
          data-parentname="dataFeedElement"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["url"]}
        />

        <label htmlFor="sameAs">
          <h4>Same as</h4>
        </label>
        <div className="description">
          The URL of a reference web page that unambiguously indicates the
          work's identity. For example, a Wikipedia, Wikidata, VIAF, or Library
          of Congress page for the book.
        </div>
        <input
          type="text"
          id="sameAs"
          name="sameAs"
          placeholder=""
          onChange={this.handleChange}
          data-parentname="dataFeedElement"
          value={schemaJSON["dataFeedElement"][0]["sameAs"]}
        />

        <label htmlFor="bookId">
          <h4>Book @id</h4>
        </label>
        <div className="description">
          A globally unique ID for the book in URL format. It must be unique to
          your organization. The ID must be stable and not change over time. URL
          format is suggested though not required. It doesn't have to be a
          working link. The domain used for the @id value must be owned by your
          organization.
        </div>
        <input
          type="text"
          id="bookId"
          name="@id"
          placeholder=""
          data-parentname="dataFeedElement"
          data-subparentname="workExample"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["workExample"][0]["@id"]}
        />

        <label htmlFor="isbn">
          <h4>Book ISBN</h4>
        </label>
        <input
          type="text"
          id="isbn"
          name="isbn"
          placeholder=""
          data-parentname="dataFeedElement"
          data-subparentname="workExample"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["workExample"][0]["isbn"]}
        />

        <label htmlFor="edition">
          <h4>Book Edition</h4>
        </label>
        <input
          type="text"
          id="bookEdition"
          name="bookEdition"
          placeholder=""
          data-parentname="dataFeedElement"
          data-subparentname="workExample"
          onChange={this.handleChange}
          value={
            schemaJSON["dataFeedElement"][0]["workExample"][0]["bookEdition"]
          }
        />

        <label htmlFor="format">
          <h4>Book Format</h4>
        </label>
        <select
          id="bookFormat"
          name="bookFormat"
          data-parentname="dataFeedElement"
          data-subparentname="workExample"
          onChange={this.handleChange}
          value={schemaJSON["dataFeedElement"][0]["workExample"][0]["bookFormat"]}
        >
          <option value="">- Book format -</option>
          <option value="http://schema.org/AudiobookFormat">Audiobook</option>
          <option value="http://schema.org/EBook">EBook</option>
          <option value="http://schema.org/Hardcover">Hardcover</option>
          <option value="http://schema.org/Paperback">Paperback</option>
        </select>

        <label htmlFor="inLanguage">
          <h4>Language</h4>
        </label>
        <div className="description">
          The main language of the content in the edition. Use one of the
          two-letter codes from the{" "}
          <a
            href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
            target="_blank"
            rel="noopener noreferrer"
          >
            list of ISO 639-1 alpha-2 codes
          </a>
          .
        </div>
        <input
          type="text"
          id="inLanguage"
          name="inLanguage"
          placeholder=""
          data-parentname="dataFeedElement"
          data-subparentname="workExample"
          onChange={this.handleChange}
          value={
            schemaJSON["dataFeedElement"][0]["workExample"][0]["inLanguage"]
          }
        />

        <label htmlFor="dateModified">
          <h4>Date modifited</h4>
        </label>
        <input
          type="date"
          id="dateModified"
          name="dateModified"
          onChange={this.handleChange}
          value={schemaJSON["dateModified"]}
        />
      </div>
    );
  }
}
