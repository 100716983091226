import React, { Component } from "react";
import axios from "axios";
//import { store } from "react-notifications-component";

import { Box, IconButton } from "@mui/material";

const accountLimits = require("../../../../limits/limits.json");

export default class Subcription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountLimit: {},
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    await this.promisedSetState({
      accountLimit: await this.getAccountLimits(),
    });
    await this.getCustomerPortal();
  };

  getAccountLimits = async () => {
    // retrieve matching account package
    let currentAccountLimit;

    // if accessing page editor via a membership to another account
    if (this.state.accountHolderUsername)
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === this.state.accountHolderSubscription
      );
    else if (localStorage.getItem("productName"))
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === localStorage.getItem("productName")
      );
    else
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === "Free"
      );

    return currentAccountLimit[0];
  };

  getCustomerPortal = async () => {
    await axios
      .put("/api/stripe/getCustomerPortal", {
        customerID: localStorage.getItem("customerID"),
      })
      .then(
        async (response) => {
          let data = response.data;
          if (data.success)
            await this.promisedSetState({ portalUrl: data.portal.url });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    //const accountLimits = JSON.parse(localStorage.getItem("accountLimits"))[0];

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "100px" }}
      >
        <Box style={{ width: "100%", maxWidth: 768 }}>
          <div class="section-title d-flex space-between align-center">
            <h2>Subscription</h2>

            {this.state.portalUrl && (
              <IconButton
                className="pill-button"
                aria-label="refresh"
                color="secondary"
                onClick={() => {
                  window.location.href = this.state.portalUrl;
                }}
              >
                <span className="text">Edit Subscription</span>
              </IconButton>
            )}
          </div>

          <Box>
            <div className="description">
              Edit your subscription, view your billing history, add or change
              your payment method.
            </div>

            <div className="d-flex mt-20">
              <div className="plan col-2">
                <h3>Current Plan: {!this.state.accountLimit ? "Free" : ""}</h3>
                <span>
                  {this.state.accountLimit && this.state.accountLimit.package}
                </span>
              </div>

              <div className="col-2">
                <h3>Package includes:</h3>

                <ul className="features">
                  <li>
                    {this.state.accountLimit &&
                    this.state.accountLimit.maxDomains > 0
                      ? this.state.accountLimit.maxDomains
                      : "Unlimited"}{" "}
                    Domains
                  </li>
                  <li>
                    {this.state.accountLimit &&
                    this.state.accountLimit.maxPages > 0
                      ? this.state.accountLimit.maxPages
                      : "Unlimited"}{" "}
                    Pages
                  </li>
                  {this.state.accountLimit &&
                  this.state.accountLimit.bulkEditor ? (
                    <li>Bulk Editor Access</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
