import React, { Component } from "react";
import { Paper, Box, Grid, TextField, Select, IconButton } from "@mui/material";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DATABASE_API = require("../../../../api/db-functions");

export default class CompanyHours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openTime: "",
      closeTime: "",
      days: [],

      daysChips: "",
      daysSelect: [],
    };

    this.companyHours = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    await DATABASE_API.getUserData();

    const globalVariables = JSON.parse(
      localStorage.getItem("userData")
    ).globalVariables;

    if (globalVariables.companyHours.length > 0) {
      await this.generateFields(globalVariables.companyHours);
      // await this.jQueryScripts(globalVariables.companyHours);
    }
  };

  handleChange = async (event) => {
    const NAME = event.target.name;
    const VALUE = event.target.value;
    const INDEX = parseInt(event.target.getAttribute("data-hours"));
    let companyHours = this.props.state.companyHours;

    if (NAME === "open") {
      await this.promisedSetState({ openTime: VALUE });
      companyHours[INDEX].openTime = VALUE;
    } else if (NAME === "close") {
      await this.promisedSetState({ closeTime: VALUE });
      companyHours[INDEX].closeTime = VALUE;
    }

    this.props.setCustomState("companyHours", companyHours);
  };

  handleSelect = async (event) => {
    const NAME = event.target.name;
    const VALUE = event.target.value;
    const INDEX = parseInt(NAME.split("-")[1]);
    let companyHours = this.props.state.companyHours;

    await this.promisedSetState({ daysSelect: VALUE });
    companyHours[INDEX].days = VALUE;
    this.props.setCustomState("companyHours", companyHours);
  };

  addHours = () => {
    let counter = this.companyHours.length;
    let companyHours = this.props.state.companyHours;

    let hourSet = {
      days: [],
      openTime: "",
      closeTime: "",
    };

    companyHours.push(hourSet);

    this.companyHours.push(this.companyHoursAccordion("", counter));
    this.props.setCustomState("companyHoursDisplay", this.companyHours);
    this.props.setCustomState("companyHours", companyHours);
  };

  generateFields = async (companyHours) => {
    companyHours.map((data, index) =>
      this.companyHours.push(this.companyHoursAccordion(data, index))
    );

    this.props.setCustomState("companyHoursDisplay", this.companyHours);
  };

  companyHoursAccordion = (data, counter) => {
    return (
      <Accordion key={"accordion-" + counter}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              letterSpacing: "0.27px",
              textTransform: "uppercase",
            }}
          >
            {data.days ? data.days.join(", ") : "Hour set #" + (counter + 1)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl sx={{ m: 1 }} style={{ width: "100%" }}>
            <InputLabel id="days-label">Days of the week</InputLabel>
            <Select
              labelId="days-label"
              id={"days-" + counter}
              multiple
              name={"days-" + counter}
              data-hours={counter}
              defaultValue={data.days ? data.days : this.state.daysSelect}
              onChange={this.handleSelect}
              input={<OutlinedInput label="Days of the week" />}
              inputProps={{
                "data-hours": counter,
              }}
              MenuProps={MenuProps}
              fullWidth={true}
            >
              {DAYS.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Open time"
                type="time"
                id={"open-" + counter}
                name="open"
                defaultValue={
                  data.openTime ? data.openTime : this.state.openTime
                }
                onChange={this.handleChange}
                inputProps={{
                  "data-hours": counter,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Close time"
                type="time"
                id={"close-" + counter}
                name="close"
                inputProps={{
                  "data-hours": counter,
                }}
                defaultValue={
                  data.closeTime ? data.closeTime : this.state.closeTime
                }
                onChange={this.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          <div className="d-flex flex-end" style={{ marginTop: "30px" }}>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => this.removeHourSet(counter)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  removeHourSet = (index) => {
    let companyHours = this.props.state.companyHours;

    this.companyHours.splice(index, 1);
    companyHours.splice(index, 1);
    this.props.setCustomState("companyHoursDisplay", this.companyHours);
    this.props.setCustomState("companyHours", companyHours);
  };

  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "45px" }}
      >
        <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
          <Box className="section" sx={{ p: 3 }}>
            <div className="section-title">
              <h3>Company Hours</h3>

              <IconButton
                className="text-button"
                aria-label="delete"
                color="primary"
                onClick={this.addHours}
              >
                <AddCircleIcon fontSize="small" />
                <span className="text">Add hours</span>
              </IconButton>
            </div>

            <Box>{this.props.state.companyHoursDisplay}</Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}
