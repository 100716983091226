import React, { Component } from "react";
import moment from "moment";

export default class WebPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  breadcrumbs = (url) => {
    return url
      .replace(/(^\w+:|^)\/\//, "")
      .split("/")
      .map((path, index, arr) => {
        // if not the last loop
        if (arr.length - 1 !== index) {
          return path + " > ";
        }

        return path;
      });
  };

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="serp google">
        <a href={this.props.pageDetails.published_url} className="url">
          {this.breadcrumbs(this.props.pageDetails.published_url)}
        </a>

        <div className="title">
          <a href={this.props.pageDetails.published_url}>{schemaObject.name}</a>
        </div>

        <div className="meta-description">
          {this.props.pageDetails.publish_date && (
            <span className="date">
              {moment(this.props.pageDetails.publish_date).format(
                "MMM D, YYYY"
              )}{" "}
              -
            </span>
          )}

          {schemaObject.description}
        </div>
      </div>
    );
  }
}
