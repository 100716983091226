import React, { Component } from "react";
//import $ from "jquery";
//import ReactTooltip from "react-tooltip";
//import axios from "axios";

export default class DomainSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableDomains: [],
      currentDomain: "",
      opened: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      let availableDomains = [];

      if (userData.domains) {
        availableDomains = userData.domains.map((domain) => {
          if (window.location.href.indexOf(domain.domainURL) !== -1)
            return this.setState({
              currentDomain: domain.domainURL,
            });
          else
            return (
              <li data-domain={domain.domainURL} key={domain.domainURL}>
                <a href={"/pages/" + domain.domainURL}>{domain.domainURL}</a>
              </li>
            );
        });

        await this.setState({ availableDomains });
      }
    }
  };

  render() {
    return (
      <div className="domain-selector">
        <div
          className={
            "custom-select-dropdown " +
            (this.state.opened ? "opened" : "closed")
          }
          aria-haspopup="true"
          role="button"
          tabIndex="0"
          onClick={() => this.setState({ opened: !this.state.opened })}
        >
          <div className="current-selection">
            <h1>{this.props.currentDomain}</h1>{" "}
            <img src="/images/triangle-black.svg" alt="dropdown indicator" />
          </div>
          <ul className="items">{this.state.availableDomains}</ul>
        </div>
      </div>
    );
  }
}
