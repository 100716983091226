/*
 * This is an example of what the input component would look like for a specific schema
 */

import React, { Component } from "react";
import $ from "jquery";

/*
 * Pull the actual schema JSON here
 */
let schemaJSON = require("./schema.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class LegalService extends Component {
  constructor(props) {
    super(props);

    this.sameAs = [];

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "LegalService" // change this to match the component name
      );

      this.generateInputFields();
    }
    this.props.setSchema(schemaJSON);
    this.jQueryScripts();
    this.setState(this.state); // force update
  };

  jQueryScripts = () => {
    schemaJSON.sameAs.map((same, index) => {
      $("#sameAs-" + index).val(same);
      return 1;
    });
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname === "sameAs") {
      const index = e.target.getAttribute("data-sameas");
      schemaJSON[parentname][index] = value;
    } else if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * sameAs fields
   */
  sameAsFields = (counter) => {
    return (
      <div className="sameAs-fields" key={"sameAs" + counter}>
        <h5>Same as #{counter + 1}</h5>
        <input
          type="text"
          id={"sameAs-" + counter}
          name="URL"
          data-sameas={counter}
          data-parentname="sameAs"
          onChange={this.handleChange}
        />
      </div>
    );
  };

  addSameAs = async () => {
    let counter = this.sameAs.length;

    this.sameAs.push(this.sameAsFields(counter));
    this.setState({ showSameAs: this.sameAs });
    schemaJSON["sameAs"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  generateInputFields = () => {
    schemaJSON.sameAs.map((same, counter) =>
      this.sameAs.push(this.sameAsFields(counter))
    );
  };

  render() {
    return (
      <div className="schema-form">
        <label>
          <h4>Name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Description</h4>
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label>
          <h4>URL</h4>
        </label>
        <input
          type="text"
          id="url"
          name="url"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <label>
          <h4>Logo URL</h4>
        </label>
        <input
          type="text"
          id="logo"
          name="logo"
          onChange={this.handleChange}
          value={schemaJSON["logo"]}
        />

        <label>
          <h4>Image URL</h4>
        </label>
        <input
          type="text"
          id="image"
          name="image"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <label htmlFor="priceRange">
          <h4>Price range</h4>
        </label>
        <div className="description">
          The relative price range of a business, commonly specified by either a
          numerical range (for example, "$10-15") or a normalized number of
          currency signs (for example, "$$$"). Leave this blank if not
          applicable.
        </div>
        <input
          type="text"
          id="priceRange"
          name="priceRange"
          value={schemaJSON["priceRange"]}
          onChange={this.handleChange}
        />
        <div className="flex-container">
          <span className="col-2">
            <label>
              <h4>Telephone</h4>
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              onChange={this.handleChange}
              value={schemaJSON["telephone"]}
            />
          </span>
          <span className="col-2">
            <label>
              <h4>Email</h4>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={this.handleChange}
              value={schemaJSON["email"]}
            />
          </span>
        </div>

        <label>
          <h4>Map URL</h4>
        </label>
        <input
          type="text"
          id="hasMap"
          name="hasMap"
          onChange={this.handleChange}
          value={schemaJSON["hasMap"]}
        />

        <label>
          <h4>Address</h4>
        </label>
        <div className="description">
          The physical location of the business. Include as many properties as
          possible. The more properties you provide, the higher quality the
          result is to users.{" "}
          <a
            href="https://schema.org/PostalAddress"
            target="_blank"
            rel="noopener noreferrer"
          >
            PostalAddress
          </a>
          .
        </div>
        <br />

        <label htmlFor="streetAddress">Street address</label>
        <input
          type="text"
          id="streetAddress"
          name="streetAddress"
          data-parentname="address"
          value={schemaJSON["address"]["streetAddress"]}
          onChange={this.handleChange}
        />
        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="postalCode">Postal code</label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              data-parentname="address"
              value={schemaJSON["address"]["postalCode"]}
              onChange={this.handleChange}
            />
          </span>
          <span className="col-2">
            <label htmlFor="addressLocality">Locality / City</label>
            <input
              type="text"
              id="addressLocality"
              name="addressLocality"
              data-parentname="address"
              value={schemaJSON["address"]["addressLocality"]}
              onChange={this.handleChange}
            />
          </span>
        </div>

        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="addressRegion">Region / State</label>
            <input
              type="text"
              id="addressRegion"
              name="addressRegion"
              data-parentname="address"
              value={schemaJSON["address"]["addressRegion"]}
              onChange={this.handleChange}
            />
          </span>
          <span className="col-2">
            <label htmlFor="addressCountry">Country</label>
            <input
              type="text"
              id="addressCountry"
              name="addressCountry"
              data-parentname="address"
              value={schemaJSON["address"]["addressCountry"]}
              onChange={this.handleChange}
            />
          </span>
        </div>

        <label>
          <h4>Geo coordinates</h4>
        </label>
        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="latitude">Latitude</label>
            <input
              type="number"
              id="latitude"
              name="latitude"
              placeholder="40.761293"
              data-parentname="geo"
              onChange={this.handleChange}
              value={schemaJSON["geo"]["latitude"]}
            />
          </span>
          <span className="col-2">
            <label htmlFor="longitude">Longitude</label>
            <input
              type="number"
              id="longitude"
              name="longitude"
              placeholder="-73.982294"
              data-parentname="geo"
              onChange={this.handleChange}
              value={schemaJSON["geo"]["longitude"]}
            />
          </span>
        </div>

        {/* sameAs */}
        <div className="sameAs-items">
          <label>
            <h4>Same As</h4>
          </label>
          <div className="description">
            URL of a reference Web page that unambiguously indicates the item's
            identity. E.g. the URL of the item's Wikipedia page, Wikidata entry,
            or official website.
          </div>

          <div className="fields-container">{this.sameAs}</div>

          <button
            className="btn small c-white bg-orange b-orange mt-10"
            onClick={this.addSameAs}
          >
            Add sameAs
          </button>
        </div>
      </div>
    );
  }
}
