import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import moment from "moment";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./styles/main.css";
//import "./styles/store.css";

// Error handling components
import PageNotFound from "./views/PageNotFound";
import ErrorBoundary from "./views/ErrorBoundary";

// Components
import Auth from "./views/auth";
import Login from "./views/signin/login";
import Logout from "./views/logout";
import Register from "./views/register/register";
import Recover from "./views/recover/recover";
import Sidebar from "./views/sidebar";

const { COMPONENTS } = require("./components");
const DATABASE_API = require("./api/db-functions.js");

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      domains: [],
      userData: {},
      menuExpanded: false,
      authenticated: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  // Lifecycle
  componentDidMount = async () => {
    this.clearCache();

    localStorage.setItem("menuExpanded", true);
    this.checkForInactiveUser();

    // Intercom
    window.Intercom("boot", {
      app_id: "vi4ebs9q",
      email: localStorage.getItem("user"), // Email address
      user_hash: localStorage.getItem("hmac"), // HMAC using SHA-256
    });

    const USER_DATA_CACHE = JSON.parse(localStorage.getItem("userData"));
    if (USER_DATA_CACHE) {
      await this.promisedSetState({
        userData: USER_DATA_CACHE.data,
        firstName: USER_DATA_CACHE.firstName,
        lastName: USER_DATA_CACHE.lastName,
        domains: USER_DATA_CACHE.domains,
      });

      window.Intercom("update", {
        Domains: USER_DATA_CACHE.domains.length,
      });
    }
  };

  clearCache = () => {
    if (!sessionStorage.getItem("cacheCleared"))
      if ("caches" in window) {
        // Delete all the cache files
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });

        sessionStorage.setItem("cacheCleared", true);

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
  };

  isLoggedIn = () => {
    return localStorage.getItem("authenticated");
  };

  // saves input into local variables
  updateStates = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  checkForInactiveUser = () => {
    setInterval(() => {
      if (localStorage.getItem("lastActivity")) {
        let currentTime = moment().format();
        let lastActivity = localStorage.getItem("lastActivity");

        let a = moment(currentTime);
        let b = moment(lastActivity);
        let hoursDifference = a.diff(b, "hours", true);

        //console.log("hoursDifference", hoursDifference);

        // if the last activity was more than 1 hour ago, sign user out
        if (hoursDifference >= 1) window.location.href = "/logout";
      }
    }, 60000); // runs every minute
  };

  toggleMenu = async () => {
    await this.promisedSetState({ menuExpanded: !this.state.menuExpanded });
  };

  checkSessionStatus = async () => {
    if (!localStorage.getItem("authenticated")) this.unsetAuthenticated();
  };

  setAuthenticated = async () => {
    await this.promisedSetState({ authenticated: true });
    localStorage.setItem("authenticated", true);
  };

  unsetAuthenticated = async () => {
    await DATABASE_API.clearTempTokens();
    localStorage.clear();
    await this.promisedSetState({ authenticated: false });
    if (window.location.pathname !== "/") window.location.href = "/"; // redirect to frontpage
  };

  mainContentClasses = () => {
    let classes = "";
    if (!localStorage.getItem("authenticated")) classes += " no-sidebar";
    //if (this.state.menuExpanded) classes += " expanded";
    //classes += " expanded";
    return classes;
  };

  render() {
    // Gets and outputs desired component
    const CompWrapper = (props) => {
      const params = useParams();
      const Component = COMPONENTS[props.component];

      if(this.isLoggedIn() || props.component === "NewPassword") {
        return <Component state={this.state} {...{ ...props, match: { params } }} />
      }

      return <Navigate to="/" />
    };

    return (
      <Router>
        <ErrorBoundary>
          <ReactNotifications />

          {this.isLoggedIn() && (
            <Sidebar state={this.state} toggleMenu={this.toggleMenu} />
          )}

          <div id="main-content" className={this.mainContentClasses()}>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Login
                    state={this.state}
                    setUsername={this.setUsername}
                    checkSessionStatus={this.checkSessionStatus}
                    setAuthenticated={this.setAuthenticated}
                    updateStates={this.updateStates}
                  />
                }
              />

              <Route
                path="/logout"
                element={
                  <Logout
                    state={this.state}
                    unsetAuthenticated={this.unsetAuthenticated}
                  />
                }
              />

              <Route path="/register" element={<Register />} />
              <Route path="/recover/" element={<Recover />} />

              <Route
                path="/new-password/:verificationToken"
                element={<CompWrapper component="NewPassword" />}
              />

              <Route
                path="/confirmation/:verificationToken"
                element={<CompWrapper component="Confirmation" />}
              />

              <Route
                path="/auth"
                element={
                  <Auth
                    state={this.state}
                    setAuthenticated={this.setAuthenticated}
                  />
                }
              />

              <Route
                path="/dashboard"
                element={<CompWrapper component="Dashboard" />}
              />

              <Route
                path="/admin"
                element={<CompWrapper component="AdminDashboard" />}
              />

              <Route
                path="/:domain/editor/:id"
                element={<CompWrapper component="Editor" />}
              />

              <Route
                path="/:domain/page/:id"
                element={<CompWrapper component="PageEditor" />}
              />

              <Route
                path="/pages/:id"
                element={<CompWrapper component="Pages" />}
              />

              <Route
                exact
                path="/templates"
                element={<CompWrapper component="SchemaTemplates" />}
              />

              <Route
                exact
                path="/templates/create"
                element={<CompWrapper component="CreateNewTemplate" />}
              />

              <Route
                path="/templates/edit/:id"
                element={<CompWrapper component="EditTemplate" />}
              />

              <Route
                exact
                path="/settings"
                element={<CompWrapper component="Settings" />}
              />

              <Route
                exact
                path="/settings/account"
                element={<CompWrapper component="MyAccount" />}
              />

              <Route
                exact
                path="/settings/global-variables"
                element={<CompWrapper component="Defaults" />}
              />

              <Route
                path="/profile"
                element={<CompWrapper component="Profile" />}
              />

              <Route
                path="/users"
                element={<CompWrapper component="Users" />}
              />

              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </div>
        </ErrorBoundary>
      </Router>
    );
  }
}
