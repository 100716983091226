import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
//import { debounce } from "lodash";

import { IconButton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

// Helper functions
// const DATABASE_API = require("../../api/db-functions");

export default class Cache extends Component {
  render() {
    return (
      <div className="refresh mt-20 mb-20 flex-container">
        <IconButton
          className="pill-button"
          aria-label="refresh cache"
          color="primary"
          onClick={this.props.clearAndUpdateCache}
          data-tip="Fetch latest data"
          data-for="refresh-btn"
        >
          <CachedIcon fontSize="small" />
          <span className="text">Refresh</span>
          <ReactTooltip id="refresh-btn" />
        </IconButton>

        <span className="last-cached">
          <strong>Last updated:</strong>{" "}
          {localStorage.getItem(this.props.currentDomain + "-lastCached")}
        </span>
        <ReactTooltip />
      </div>
    );
  }
}
