import React, { Component } from "react";
//import moment from "moment";

// Previews
import Article from "./components/article";
import BlogPosting from "./components/blogPosting";
import ClaimReview from "./components/claimReview";
import Course from "./components/course";
import Recipe from "./components/recipe";
import SoftwareApplication from "./components/softwareApplication";
import WebPage from "./components/webpage";

const componentsMap = {
  Article,
  BlogPosting,
  ClaimReview,
  Course,
  Recipe,
  SoftwareApplication,
  WebPage,
};

export default class GooglePreview extends Component {
  constructor(props) {
    super(props);

    this.state = { schema: {} };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  jQueryScripts = () => {};

  saveState = async (key, value) => {
    await this.promisedSetState({ [key]: value });
  };

  breadcrumbs = (url) => {
    return url
      .replace(/(^\w+:|^)\/\//, "")
      .split("/")
      .map((path, index, arr) => {
        // if not the last loop
        if (arr.length - 1 !== index) {
          return path + " > ";
        }

        return path;
      });
  };

  render() {
    let schemaType;

    if (
      Array.isArray(this.props.schemaObject) &&
      this.props.schemaObject.length
    ) {
      schemaType = this.props.schemaObject[0]["@type"];
    } else if (
      this.props.schemaObject["@graph"] &&
      this.props.schemaObject["@graph"].length >= 1
    ) {
      schemaType = this.props.schemaObject["@graph"][0]["@type"];
    } else {
      schemaType = this.props.schemaObject["@type"];
    }

    const DynamicSchemaForm = componentsMap[schemaType];

    if (DynamicSchemaForm)
      return (
        <DynamicSchemaForm
          schemaObject={this.props.schemaObject}
          pageDetails={this.props.pageDetails}
        />
      );
    else {
      return (
        <div className="no-preview ta-center">
          <h3>Preview currently not available for this schema</h3>
        </div>
        /* <div className="serp google">
          <a href={this.props.pageDetails.published_url} className="url">
            {this.breadcrumbs(this.props.pageDetails.published_url)}
          </a>

          <div className="title">
            <a href={this.props.pageDetails.published_url}>
              {this.props.pageDetails.title}
            </a>
          </div>

          <div className="meta-description">
            {this.props.schemaObject.datePublished ? (
              <span className="date">
                {moment(this.props.schemaObject.datePublished).format(
                  "MMM D, YYYY"
                )}{" "}
                -
              </span>
            ) : (
              ""
            )}

            {this.props.pageDetails.meta.meta_description}
          </div>
        </div>*/
      );
    }
  }
}
