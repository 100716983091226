import React, { Component } from "react";
import axios from "axios";

export default class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      newPasswordConfirm: "",
      passwordMatch: false,
      recoverySuccess: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recoverySuccess = this.recoverySuccess.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {};

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });

    if (this.state.newPassword === this.state.newPasswordConfirm)
      await this.promisedSetState({ passwordMatch: true });
    else await this.promisedSetState({ passwordMatch: false });
  };

  handleSubmit = async (event) => {
    let updateSuccess = await this.updatePassword();
    if (updateSuccess) await this.promisedSetState({ recoverySuccess: true });
  };

  updatePassword = async () => {
    return axios
      .post("/api/user/recovery", {
        token: this.props.match.params.verificationToken,
        password: this.state.newPassword,
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  recoveryForm = (e) => {
    return (
      <div id="recovery-form">
        <div className="section-header">
          <h2 className="section-title">Password Recovery</h2>
          <div className="section-copy">
            Thanks for confirming that it's you! Please enter in your new
            password in order to complete the password recovery process.
          </div>
        </div>

        <div className="form-container">
          {this.state.newPassword !== this.state.newPasswordConfirm ? (
            <div className="error-label">Passwords do not match.</div>
          ) : (
            ""
          )}

          <div className="row">
            <input
              type="password"
              name="newPassword"
              placeholder="New password"
              value={this.state.newPassword}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="row">
            <input
              type="password"
              name="newPasswordConfirm"
              placeholder="Confirm new password"
              value={this.state.newPasswordConfirm}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="row">
            <input
              type="submit"
              className="btn c-black"
              value="Update password"
              onClick={this.handleSubmit}
            />
          </div>
        </div>

        <div className="other-links">
          <ul>
            <li>
              <a href="/">Nevermind, I remember now.</a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  recoverySuccess = (e) => {
    return (
      <div id="registration-success">
        <div className="section-header">
          <h3 className="section-title">
            Success! Your password has been updated.
          </h3>
          <div className="section-copy">
            Please signin using your new password. To return to the login page,
            you may click{" "}
            <a className="bold underline" href="/">
              here
            </a>
            .
          </div>
        </div>
      </div>
    );
  };

  render() {
    const recoveryForm = this.recoveryForm();
    const recoverySuccess = this.recoverySuccess();

    return (
      <div id="register" className="ta-center bg-midnightblue-2 c-white">
        <div className="container">
          <div className="logo">
            <img src="/images/logo-white@2x.png" alt="logo" />
          </div>

          {this.state.recoverySuccess ? recoverySuccess : recoveryForm}
        </div>
      </div>
    );
  }
}
