import React, { Component } from "react";
import validator from "validator";

export default class AdvancedInput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    // grab entire schema script then strip tags
    let schema = this.props.state.code;
    let currentSchema = schema.match(
      /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi
    );

    if (currentSchema) {
      currentSchema = currentSchema.toString();
      currentSchema = currentSchema.replace(/<\/?[^>]+(>|$)/g, "");

      this.setState({
        customSchema: JSON.stringify(JSON.parse(currentSchema), null, 2),
      });
    }
  };

  closeSnackbar = () => {
    this.setState({ error: false });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value });

    if (validator.isJSON(this.state.customSchema))
      this.props.setSchema(JSON.parse(this.state.customSchema), 0, true);
  };

  render() {
    const scriptOpening = "<script type='application/ld+json'>";
    const scriptClosing = "</script>";

    return (
      <div className="advanced-input">
        <h3>Custom Schema</h3>

        <pre className="tag">{scriptOpening}</pre>
        <textarea
          type="text"
          id="customSchema"
          name="customSchema"
          placeholder="Drop in your custom schema"
          onChange={this.handleChange}
          value={this.state.customSchema}
        />
        <pre className="tag">{scriptClosing}</pre>
      </div>
    );
  }
}
