import React, { Component } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import { Box, Button, IconButton, Typography } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";

//import ConnectDomain from "../ConnectDomain";
import DialogModal from "../../../../components/DialogModal";
import Modal from "../../../../components/modal/modal";

const cookies = new Cookies();
const DATABASE_API = require("../../../../api/db-functions.js");
const { hsInstallUrl } = require("../../../../constants");
const accountLimits = require("../../../../limits/limits.json");

export default class Domains extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      accessToken: "",
      selectedDomain: "",
      selectedDomainPortalID: "",
      domainsInPortal: [],
      domainsInDatabase: [],
      memberDomains: [],
      confirmDeleteModal: false,
      addDomainSelectorModal: false,
      domainForDeletion: "",
      finishedReadingFromDatabase: false,
      accountLimits: {},

      // modals
      noDomainsFound: false,

      domainBoxScrollbar: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    const currentInstallDomain = localStorage.getItem("currentInstallDomain");

    await this.promisedSetState({
      accountLimits: await this.getAccountLimits(),
    });

    let getUserDataSuccess = await DATABASE_API.getUserData();

    if (getUserDataSuccess) {
      await this.promisedSetState({
        userData: JSON.parse(localStorage.getItem("userData")),
      });

      // if we're adding a new domain and received a temporary access token
      if (this.state.userData.tempAccessToken && !currentInstallDomain) {
        await this.checkToken();
        await this.setState({ addDomainSelectorModal: true });
        await this.getDomains();
      }

      await this.getSites();
      await this.getMemberDomains();
      await this.setState({ finishedReadingFromDatabase: true });
    }

    // after adding a new domain, send user directly to page index for newly added domain
    if (currentInstallDomain) {
      let success = await DATABASE_API.updateTokensInDatabase(
        localStorage.getItem("user"),
        currentInstallDomain,
        this.state.userData.tempAccessToken,
        this.state.userData.tempRefreshToken
      );

      if (success) {
        setTimeout(function () {
          localStorage.removeItem("currentInstallDomain");
          window.location.href = "/account/" + currentInstallDomain;
        }, 1000);
      }
    }

    this.jQueryScripts();
  };

  toggle = (stateName) => {
    this.setState({ [stateName]: !this.state[stateName] });
  };

  getAccountLimits = async () => {
    let currentAccountLimit;

    // if accessing page editor via a membership to another account
    if (this.state.accountHolderUsername)
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === this.state.accountHolderSubscription
      );
    else if (localStorage.getItem("productName"))
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === localStorage.getItem("productName")
      );
    else
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === "Free"
      );

    return currentAccountLimit[0];
  };

  jQueryScripts = () => {
    $(".custom-select-multi li").on("click", function () {
      $(this).parent().find("li").removeClass("selected");
      $(this).toggleClass("selected");
    });

    $(".option-btn").on("click", function () {
      $(this).parent().parent().find(".options").slideToggle();
    });

    this.domainScrollBarExists();
  };

  domainScrollBarExists = () => {
    let domainBoxHeight = $("ul.available-domains").outerHeight();
    let totalDomainsHeight = 0;

    $("ul.available-domains > *").each(function () {
      totalDomainsHeight += $(this).outerHeight();
    });

    if (totalDomainsHeight > domainBoxHeight)
      this.setState({ domainBoxScrollbar: true });
  };

  checkToken = async () => {
    // check if token is valid and refresh if necessary
    let tokenActive = await DATABASE_API.getTokenInfo(
      this.state.userData.tempAccessToken
    );

    if (!tokenActive) {
      let newTokens = await DATABASE_API.refreshAuthToken(
        this.state.userData.tempAccessToken,
        this.state.userData.tempRefreshToken
      );

      // update tokens locally
      let userData = this.state.userData;
      userData.tempAccessToken = newTokens.accessToken;
      userData.tempRefreshToken = newTokens.refreshToken;

      await this.setState({ userData: userData });

      // update tokens in database
      await DATABASE_API.updateTempTokens(
        localStorage.getItem("user"),
        newTokens.accessToken,
        newTokens.refreshToken
      );
    }
  };

  // gets domains for user from our database
  getSites = async () => {
    if (
      this.state.userData.domains !== undefined ||
      this.state.userData.domains.length !== 0
    ) {
      await this.setState({ domainsInDatabase: this.state.userData.domains });
    }
  };

  // gets domains from hubspot
  getDomains = async () => {
    await axios
      .put("/hubspot/get/domains/", {
        access_token: this.state.userData.tempAccessToken,
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.success) {
            if (data.domains.results)
              await this.setState({ domainsInPortal: data.domains.results });
            else
              this.setState({
                noDomainsFound: true,
                addDomainSelectorModal: false,
              });
          } else {
            await DATABASE_API.clearTempTokens();
            this.setState({
              noDomainsFound: true,
              addDomainSelectorModal: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  getMemberDomains = async () => {
    await axios
      .put("/api/get/memberDomains/", {
        username: localStorage.getItem("user"),
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.success) {
            let allowedAccess = [];
            let domainsInDatabase = this.state.domainsInDatabase;

            window.Intercom("update", {
              "Delegate Access": allowedAccess.length,
            });

            // for each account that this user has access to
            data.data.forEach((account) => {
              // filter and only get domains that user has been added to
              let memberAccount = account.members.filter((member) => {
                return localStorage.getItem("user") === member.username;
              });

              allowedAccess.push(memberAccount[0]);

              // for each of the accounts the user has access to
              // iterate through each of the domains associated with each account
              // add required details into another array
              // then add to state so it can be displayed
              allowedAccess.forEach((memberOfAccount) => {
                const role = memberOfAccount.role;

                memberOfAccount.domains.forEach((domain) => {
                  for (const property in domain) {
                    if (domain[property]) {
                      let access = account.domains.filter((domain) => {
                        return domain.domainURL === property;
                      });

                      if (access.length > 0) {
                        access[0].accountHolderUsername = account.username;
                        access[0].accountHolderSubscription =
                          account.subscription;
                        access[0].accountHolderGlobalVariables =
                          account.globalVariables;
                        access[0].yourRole = role;

                        domainsInDatabase.push(access[0]);
                      }
                    }
                  }
                });
              });
            });

            let userData = JSON.parse(localStorage.getItem("userData"));
            userData.domains = domainsInDatabase;
            await localStorage.setItem("userData", JSON.stringify(userData));

            await this.setState({ memberDomains: domainsInDatabase });
          } else {
            await DATABASE_API.clearTempTokens();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    //const parentname = e.target.getAttribute("data-parentname");

    // if it is a select input
    if (e.target.type === "select-one") {
      if (name === "domainSelect") {
        this.setState({ selectedDomain: value });
      }
    }
  };

  handleHubSpotAuth = async (event) => {
    setTimeout(() => {
      window.location.href = hsInstallUrl;
    }, 1000);
  };

  addDomain = async () => {
    await this.checkToken();

    let domainData = {
      active: true,
      domainURL: this.state.selectedDomain,
      portalID: this.state.selectedDomainPortalID,
      accessToken: this.state.userData.tempAccessToken,
      refreshToken: this.state.userData.tempRefreshToken,
    };
    let updatedDomainsInDatabase = this.state.domainsInDatabase;

    // update new access token and refresh token
    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { $push: { domains: domainData } },
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.success) {
            updatedDomainsInDatabase.push(domainData);
            await this.setState({
              domainsInDatabase: updatedDomainsInDatabase,
            });
            await DATABASE_API.clearTempTokens();
            await this.setState({ addDomainSelectorModal: false });
            await this.jQueryScripts();

            //console.log(updatedDomainsInDatabase);

            //window.history.pushState(null, null, "/dashboard/?hasDomains=true");
            setTimeout(function () {
              window.location.href = "/pages/" + domainData.domainURL;
            }, 1000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateUserData = () => {};

  removeDomain = async () => {
    // filter the unwanted domain out of the current domain array
    let filteredDomains = this.state.domainsInDatabase.filter(
      (domain) => domain.domainURL !== this.state.domainForDeletion
    );

    // update database with new filtered domain array
    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { $set: { domains: filteredDomains } },
      })
      .then(
        (response) => {
          let data = response.data;

          if (data.success) {
            this.setState({ domainsInDatabase: filteredDomains });
            this.setState({ confirmDeleteModel: false });

            if (this.state.domainsInDatabase.length === 0)
              window.history.pushState(
                null,
                null,
                "/dashboard/?hasDomains=false"
              );

            window.location.reload(true);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  activeDomain = (domain) => {
    return (
      <div className="domain-item flex-container" key={domain.domainURL}>
        <a className="domain-info c-black" href={"/pages/" + domain.domainURL}>
          <h4 className="domain-title">{domain.domainURL}</h4>

          {"accountHolderUsername" in domain && (
            <div className="master-username">
              {"Owner: " + domain.accountHolderUsername}
            </div>
          )}

          {"accountHolderSubscription" in domain && (
            <div className="master-plan">
              {"Plan: " + domain.accountHolderSubscription}
            </div>
          )}
        </a>

        {/*<h4 className="portal-id">{domain.portalID}</h4>*/}

        <div className="hover-menu">
          <a
            href={"/pages/" + domain.domainURL}
            className="btn x-small b-orange c-orange h-c-white h-bg-dark-orange"
          >
            Edit
          </a>

          <a
            href={"https://" + domain.domainURL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn x-small b-orange c-orange h-c-white h-bg-dark-orange"
          >
            View Website
          </a>

          {!domain.yourRole && (
            <span
              className="btn x-small b-orange c-orange h-c-white h-bg-dark-orange"
              onClick={() => this.confirmDeletion(domain.domainURL)}
            >
              Disconnect
            </span>
          )}
        </div>

        <div className="btn-container flex-container">
          {domain.yourRole && (
            <div className={"role pill-btn " + domain.yourRole}>
              {domain.yourRole}
            </div>
          )}
        </div>
      </div>
    );
  };

  inactiveDomain = (domain) => {
    return (
      <div className="domain-item flex-container" key={domain.domainURL}>
        <span className="domain-info c-gray">
          <h4 className="domain-title">{domain.domainURL}</h4>
        </span>

        <h4 className="portal-id c-gray">{domain.portalID}</h4>
      </div>
    );
  };

  displayDomains = () => {
    return (
      <div id="domains">
        <div className="domain-container table">
          {this.state.domainsInDatabase.map((domain) =>
            this.activeDomain(domain)
          )}
        </div>
      </div>
    );
  };

  displayDomainList = () => {
    if (this.state.finishedReadingFromDatabase) {
      if (
        this.state.domainsInDatabase !== undefined &&
        this.state.domainsInDatabase.length > 0
      ) {
        return this.displayDomains();
      } else {
        return (
          <Box className="no-domains-found">
            <Typography variant="h6">
              You have not connnected any domains yet. Please connnect a domain
              to get started.
            </Typography>

            <Box sx={{ marginTop: "20px" }}>
              <Button className="primary" onClick={this.handleHubSpotAuth}>
                Connect a domain
              </Button>
              <Button
                id="guideMe"
                className="secondary"
                onClick={() => window.Intercom("showArticle", 6056566)}
              >
                Guide me
              </Button>
            </Box>
          </Box>
        );
      }
    } else {
      return (
        <div className="retreiving-data">
          <div className="progress">
            <div className="progress__item--inf-prog"></div>
          </div>
          <div className="progress-label">Retreiving data...</div>
        </div>
      );
    }
  };

  availableDomains = (domain) => {
    return (
      <li
        data-domain={domain.domain}
        data-portalid={domain.portalId}
        key={domain.id}
        onClick={() =>
          this.availableDomainsSelect(domain.domain, domain.portalId)
        }
        className="domain"
      >
        <div className="name">{domain.domain}</div>

        <div className="tags">
          {domain.isPrimarySitePage && (
            <span className="pill-btn website">Website</span>
          )}
          {domain.isPrimaryLandingPage && (
            <span className="pill-btn landing">Landing</span>
          )}
          {domain.isPrimaryBlogPost && (
            <span className="pill-btn blog">Blog</span>
          )}
        </div>
      </li>
    );
  };
  availableDomainsSelect = (domain, portalId) => {
    this.setState({ selectedDomain: domain, selectedDomainPortalID: portalId });
  };

  closeAddDomainSelectorModel = async () => {
    await this.setState({ addDomainSelectorModal: false });
    await DATABASE_API.clearTempTokens();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
  };

  displayAddDomainSelectorModal = () => {
    if (this.state.finishedReadingFromDatabase) {
      let availableDomains = this.state.domainsInPortal.map((domain) =>
        this.availableDomains(domain)
      );

      return (
        <div className="select-a-domain modal">
          <div className="modal-content">
            <img
              className="close"
              src="/images/close.svg"
              alt="close button"
              onClick={this.closeAddDomainSelectorModel}
            />
            <h2>Select a domain</h2>

            <div className="description">
              We've detected these following domains in your selected portal.
              Please choose a domain from this list to continue. Tags shown
              indicate the domain that is being used as the primary domain.
            </div>

            <ul className="available-domains custom-select-multi">
              {availableDomains}
            </ul>

            {this.state.domainBoxScrollbar && (
              <div class="scroll-downs">
                <div class="mousey">
                  <div class="scroller"></div>
                </div>
              </div>
            )}

            <div className="btn-container ta-right">
              <button
                className={
                  (this.state.selectedDomain ? "" : "disabled") +
                  " btn medium b-orange c-white bg-orange h-bg-dark-orange"
                }
                onClick={
                  this.state.selectedDomain ? this.addDomain : this.doNothing
                }
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  confirmDeletion = (domain) => {
    this.setState({ confirmDeleteModel: true });
    this.setState({ domainForDeletion: domain });
  };

  cancelDeletion = () => {
    this.setState({ confirmDeleteModel: false });
  };

  hasAccess = () => {
    return (
      this.state.domainsInDatabase.length >=
        this.state.accountLimits.maxDomains &&
      this.state.accountLimits.maxDomains > 0
    );
  };

  render() {
    return (
      <div id="sites">
        {this.state.userData.domains &&
        this.state.userData.domains.length > 0 ? (
          <div className="section-title d-flex space-between align-center">
            <h2>Domains</h2>
            <IconButton
              className={
                this.hasAccess() ? "disabled pill-button" : "pill-button"
              }
              aria-label="add"
              color="secondary"
              onClick={
                this.hasAccess() ? this.doNothing : this.handleHubSpotAuth
              }
              data-tip={
                this.hasAccess()
                  ? "Need more domains? Consider upgrading to a higher plan."
                  : ""
              }
              data-for="add-domain-btn"
            >
              <AddIcon fontSize="small" />
              <span className="text">Add Domain</span>
              <ReactTooltip id="add-domain-btn" />
            </IconButton>
          </div>
        ) : (
          <div className="section-title d-flex align-center">
            <h2>Choose a domain</h2>
            <InfoIcon
              data-for="new-domain-tooltip"
              data-tip="Connecting a domain does not change any of your settings or content on the pages. This only allows us to pull down pages or blog articles from HubSpot so that you can interact with them and add / edit schema."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="new-domain-tooltip" />
          </div>
        )}

        {this.displayDomainList()}

        {this.state.addDomainSelectorModal
          ? this.displayAddDomainSelectorModal()
          : ""}

        {this.state.confirmDeleteModel && (
          <Modal
            title="Confirm Removal"
            message={
              "Are you sure you want to remove " +
              this.state.domainForDeletion +
              "?"
            }
            yesAction={this.removeDomain}
            toggle={() =>
              this.setState({
                confirmDeleteModel: !this.state.confirmDeleteModel,
              })
            }
            toggleState={this.state.confirmDeleteModel}
          />
        )}

        {this.state.noDomainsFound && (
          <DialogModal
            title="Something went wrong while retrieving domains"
            message="There was an issue getting domain information from HubSpot. Please try again later."
            toggle={() => this.toggle("noDomainsFound")}
            toggleState={this.state.noDomainsFound}
          />
        )}
      </div>
    );
  }
}
