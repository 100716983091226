import React, { Component } from "react";
// import { debounce } from "lodash";

// Helper functions
// const DATABASE_API = require("../../api/db-functions");

export default class Filters extends Component {
  render() {
    return (
      <div className="filters flex-container">
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Search by page title"
          autoComplete="off"
          onChange={(e) => {
            this.props.setSearchTerm(e.target.value);
          }}
        />

        <select
          id="type"
          name="type"
          onChange={this.props.handleChange}
          value={this.props.state.type}
        >
          <option value="all">Show all page types</option>
          <option value="normal_blog_post">Blog posts</option>
          <option value="landing_page">Landing pages</option>
          <option value="site_page">Standard pages</option>
        </select>

        <select
          id="filter"
          name="filter"
          onChange={this.props.handleChange}
          /*
          onChange={(e) => {
            this.props.webWorkerFilter(e.target.value);
            this.setState({ filter: e.target.value });
          }}
          */
          value={this.props.state.filter}
        >
          <option value="all">Show all status</option>
          <option value="with-schemas">Published schemas</option>
          <option value="missing-schemas">No schemas</option>
          <option value="warnings">Warnings</option>
        </select>
      </div>
    );
  }
}
