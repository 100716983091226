import React, { Component } from "react";
import $ from "jquery";
//import ReactTooltip from "react-tooltip";
import axios from "axios";

//import SupportPIN from "../PIN";

export default class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasProfilePhoto: false,
      userAvatar: null,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (this.userDataExists()) {
      let userData = JSON.parse(localStorage.getItem("userData"));

      //await this.getCustomerPortal();

      await this.promisedSetState({
        subscription: userData.subscription,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.username,
      });
    }

    this.jQueryScripts();
  };

  jQueryScripts = async () => {
    $(".account-menu > .avatar").on("click", async function () {
      $(this).parent().toggleClass("opened");
    });

    document.addEventListener("click", function (evt) {
      const ele = document.getElementById("account-menu");
      const isClickedOutside = !ele.contains(evt.target);

      if (isClickedOutside) $(".account-menu").removeClass("opened");
    });

    $(document).keyup(function (e) {
      if (e.key === "Escape") $(".account-menu").removeClass("opened");
    });
  };

  userDataExists = () => {
    if (localStorage.getItem("userData")) return true;
    else return false;
  };

  getAvatar = () => {
    if (this.userDataExists())
      return JSON.parse(localStorage.getItem("userData")).avatar;
  };
  getAccountType = () => {
    if (this.userDataExists())
      return JSON.parse(localStorage.getItem("userData")).accountType;
  };

  getCustomerPortal = async () => {
    await axios
      .put("/api/stripe/getCustomerPortal", {
        customerID: localStorage.getItem("customerID"),
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.success)
            await this.promisedSetState({ portalUrl: data.portal.url });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  goToCustomPortal = () => {
    window.location.href = this.state.portalUrl;
  };

  defaultAvatar = () => {
    let firstChar = this.state.firstName ? this.state.firstName.charAt(0) : "";
    let lastChar = this.state.lastName ? this.state.lastName.charAt(0) : "";

    return (
      <a href="/profile">
        <div className="default-avatar">
          <div className="initials v-center">
            {firstChar}
            {lastChar}
          </div>
        </div>
      </a>
    );
  };

  render() {
    return (
      <div id="account-menu" className="account-menu flex-container">
        {this.state.subscription === "Free" && this.state.portalUrl && (
          <a
            className="btn medium c-white bg-teal b-teal mr-20"
            href={this.state.portalUrl}
          >
            Subscribe
          </a>
        )}

        <div className="avatar" onClick={this.getCustomerPortal}>
          {this.getAvatar() ? (
            <img
              src={this.getAvatar()}
              alt={this.state.firstName + " " + this.state.lastName + " avatar"}
            />
          ) : (
            <img src="/images/default-user.svg" alt="default user avatar" />
          )}
        </div>

        <div className="dropdown">
          <div className="top">
            <div className="avatar">
              {this.getAvatar() ? (
                <img
                  src={this.getAvatar()}
                  alt={
                    this.state.firstName + " " + this.state.lastName + " avatar"
                  }
                />
              ) : (
                <img src="/images/default-user.svg" alt="default user avatar" />
              )}
            </div>

            <div className="name">
              {this.state.firstName} {this.state.lastName}
            </div>
            <div className="email">{this.state.email}</div>
            {/*<SupportPIN />*/}
          </div>

          <div className="bottom">
            <ul>
              <li>
                <a href="/profile">Edit Profile</a>
              </li>
              <li>
                <a href={this.state.portalUrl}>Manage Subscription</a>
              </li>
              <li>
                <a href="/settings">Settings</a>
              </li>
              <li>
                <a
                  href="https://schemahelper.com/resources"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
              <li>
                <a href="/logout">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
