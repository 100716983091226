import React, { Component } from "react";

export default class ClaimReview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="claim-review">
        <div className="top">
          <div className="title">{schemaObject["name"]}</div>
          <div className="description"></div>
        </div>
        <div className="bottom">
          <div className="claim">
            <span>Claim: </span>
            <span>"{schemaObject["claimReviewed"]}"</span>
          </div>

          <div className="claimed-by">
            <span>Claimed by: </span>
            <span>{schemaObject["itemReviewed"]["author"]["name"]}</span>
          </div>

          <div className="fact-check-by">
            <span>Fact check by {schemaObject["author"]["name"]}: </span>
            <span>{schemaObject["reviewRating"]["alternateName"]}</span>
          </div>
        </div>
      </div>
    );
  }
}
