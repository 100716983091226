import React, { Component } from "react";
import { store } from "react-notifications-component";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./person.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Person extends Component {
  constructor(props) {
    super(props);

    this.sameAs = [];

    this.state = {
      name: "",
      url: "",
      internalURL: "",
      optionalString: "",
      schema: schemaJSON,

      showSameAs: this.sameAs,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Person"
      );

      this.generateInputFields();
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    schemaJSON.sameAs.map((sameAs, index) => {
      $("#sameAs-" + index).val(sameAs);
      return 1;
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentName === "potentialAction") {
      schemaJSON["potentialAction"] = {
        "@type": "SearchAction",
        target: "http://example.com/search?&q={query}",
        query: "required",
      };
    }

    if (parentName === "sameAs") {
      const sameAsNum = e.target.getAttribute("data-sameas-number");
      schemaJSON[parentName][sameAsNum] = UTIL.cleanText(value);
    } else if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = UTIL.cleanText(value);
    else if (parentName) schemaJSON[parentName][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  sameAsFields = (counter, sameAs) => {
    return (
      <div className="sameas-fields" key={"sameas-" + counter}>
        <h5>sameAs #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeSameAs(counter)}
        >
          Remove
        </span>

        <input
          type="text"
          id={"sameAs-" + counter}
          name="sameAs"
          data-parentname="sameAs"
          data-sameas-number={counter}
          placeholder="https://www.facebook.com/SampleOrganization"
          onChange={this.handleChange}
          value={sameAs}
        />
      </div>
    );
  };

  addSameAs = async () => {
    let counter = this.sameAs.length;
    this.sameAs.push(this.sameAsFields(counter));
    this.setState({ showSameAs: this.showSameAs });
    schemaJSON["sameAs"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeSameAs = async (index) => {
    // remove
    this.sameAs.splice(index, 1);
    schemaJSON.sameAs.splice(index, 1);

    // update
    await this.setState({ showSameAs: this.showSameAs, schema: schemaJSON });
    this.props.setSchema(this.state.schema);

    store.addNotification({
      message: "sameAs item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  generateInputFields = () => {
    schemaJSON.sameAs.map((sameAs, counter) =>
      this.sameAs.push(this.sameAsFields(counter, sameAs))
    );
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6082130)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Full name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Page URL</h4>
        </label>
        <input
          type="text"
          id="url"
          name="url"
          placeholder="URL"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <label>
          <h4>Image URL</h4>
        </label>
        <input
          type="text"
          id="image"
          name="image"
          placeholder="Image URL"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <label>
          <h4>Job title</h4>
        </label>
        <input
          type="text"
          id="jobTitle"
          name="jobTitle"
          placeholder="Job Title"
          onChange={this.handleChange}
          value={schemaJSON["jobTitle"]}
        />

        <label>
          <h4>Company name</h4>
        </label>
        <input
          type="text"
          id="companyName"
          data-parentname="worksFor"
          name="name"
          placeholder="Company Name"
          onChange={this.handleChange}
          value={schemaJSON["worksFor"]["name"]}
        />

        {/* sameAs */}
        <div className="sameas-items">
          <label className="d-flex align-center">
            <h4>Same as</h4>
            <InfoIcon
              data-for="sameas-tip"
              data-tip="URL of a reference Web page that unambiguously indicates the item's
            identity. E.g. the URL of the item's Wikipedia page, Wikidata entry,
            or official website."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="sameas-tip" />
          </label>

          <div className="fields-container">{this.sameAs}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addSameAs}
          >
            Add sameAs
          </button>
        </div>
      </div>
    );
  }
}
