import React, { Component } from "react";

export default class PageNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <div className="v-center ta-center">
        <h1 className="mb-10">Page not found</h1>
        <p>Sorry. We couldn't find what you were looking for.</p>

        <a class="btn medium c-white bg-orange b-orange mt-20" href="/">
          Back to Schema Helper
        </a>
      </div>
    );
  }
}
