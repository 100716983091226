import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";

let schemaJSON = require("./video.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class VideoObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      durationMinutes: 0,
      durationSeconds: 0,
      duration: "",
      schema: schemaJSON
    };
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "VideoObject",
        this.props.currentIndex
      );
    }

    this.setState({ schema: schemaJSON });

    if(schemaJSON.duration) {
      const dInitial = schemaJSON.duration.indexOf('T');
      const dMinute = schemaJSON.duration.indexOf('M');
      const dSeconds = schemaJSON.duration.indexOf('S');
      if(dMinute > -1) this.setState({durationMinutes: schemaJSON.duration.substring(dInitial + 1, dMinute)});
      if(dSeconds > -1) this.setState({durationSeconds: schemaJSON.duration.substring(dMinute + 1, dSeconds)});
    }

    this.props.setSchema(schemaJSON, this.props.currentIndex);
  };

  setDuration = async (value, name) => {
    if (name === "durationMinutes")
      await this.setState({ durationMinutes: value });
    if (name === "durationSeconds")
      await this.setState({ durationSeconds: value });

    return (
      "PT" + this.state.durationMinutes + "M" + this.state.durationSeconds + "S"
    );
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");
    let schemaJSON = this.state.schema;

    // Add publisher portion of schema if doesn't exist
    if (!("publisher" in schemaJSON)) {
      schemaJSON["publisher"] = {
        "@type": "Organization",
        name: "",
        logo: {
          "@type": "ImageObject",
          url: "",
          width: "",
          height: "",
        },
      };
    }

    if (name === "durationMinutes")
      schemaJSON["duration"] = await this.setDuration(value, "durationMinutes");
    else if (name === "durationSeconds")
      schemaJSON["duration"] = await this.setDuration(value, "durationSeconds");
    // if there is a parent object with the attribute "data-parentname"
    else if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    // if there is a parent object with the attribute "data-parentname"
    // and there is a sub parent object with the attribute "data-subparentname"
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    // if it is a select input
    else if (e.target.type === "select-one") {
      if (name === "articleType") schemaJSON["@type"] = value;
      else schemaJSON[value] = "";
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    //await this.props.setSchema(schemaJSON, this.props.currentIndex);
    await this.props.setSchema(this.state.schema, this.props.currentIndex);
  };

  render() {
    console.log(this.state.schema)
    return (
      <div
        id={"article-schema-form-" + this.props.currentIndex}
        className="schema-form"
      >
        <label className="d-flex align-center">
          <h4>Video title</h4>
        </label>

        <input
          type="text"
          id={"videoName-" + this.props.currentIndex}
          name="name"
          onChange={this.handleChange}
          value={this.state.schema["name"]}
        />

        <label className="d-flex align-center">
          <h4>Video description</h4>
        </label>
        <textarea
          type="text"
          id={"videoDescription-" + this.props.currentIndex}
          name="description"
          onChange={this.handleChange}
          value={this.state.schema["description"]}
        />

        <label className="d-flex align-center">
          <h4>Content URL</h4>
          <InfoIcon
            data-for="content-url"
            data-tip="This is a direct URL to the video. For example, https://www.example.com/video/123/file.mp4"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="content-url" />
        </label>
        <input
          type="text"
          id={"contentUrl-" + this.props.currentIndex}
          name="contentUrl"
          onChange={this.handleChange}
          value={this.state.schema["contentUrl"]}
        />

        <label className="d-flex align-center">
          <h4>Embed URL</h4>
          <InfoIcon
            data-for="embed-url"
            data-tip="The embed URL of this video. For example, an embed from YouTube would be: https://www.youtube.com/embed/L7OLY4HCctQ"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="embed-url" />
        </label>
        <input
          type="text"
          id={"embedUrl-" + this.props.currentIndex}
          name="embedUrl"
          onChange={this.handleChange}
          value={this.state.schema["embedUrl"]}
        />

        <label className="d-flex align-center">
          <h4>Thumbnail URL</h4>
          <InfoIcon
            data-for="thumbnail-url"
            data-tip="The URL of the thumbnail that you want to be shown."
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="thumbnail-url" />
        </label>
        <input
          type="text"
          id={"thumbnailUrl-" + this.props.currentIndex}
          name="thumbnailUrl"
          onChange={this.handleChange}
          value={this.state.schema["thumbnailUrl"]}
        />

        <div className="flex-container">
          <div className="col-2">
            <label>
              <h4>Upload date</h4>
            </label>
            <input
              type="date"
              id={"uploadDate-" + this.props.currentIndex}
              name="uploadDate"
              onChange={this.handleChange}
              value={this.state.schema["uploadDate"]}
            />
          </div>
          <div className="col-2">
            <label>
              <h4>Publish date</h4>
            </label>
            <input
              type="date"
              id={"datePublished-" + this.props.currentIndex}
              name="datePublished"
              onChange={this.handleChange}
              value={this.state.schema["datePublished"]}
            />
          </div>
        </div>

        <label>
          <h4>Duration</h4>
        </label>
        <div className="flex-container">
          <input
            className="col-2"
            type="number"
            id={"durationMinutes-" + this.props.currentIndex}
            name="durationMinutes"
            min="0"
            placeholder="Minute(s)"
            onChange={this.handleChange}
            value={this.state.durationMinutes}
          />
          <input
            className="col-2"
            type="number"
            id={"durationSeconds-" + this.props.currentIndex}
            name="durationSeconds"
            min="0"
            placeholder="Second(s)"
            onChange={this.handleChange}
            value={this.state.durationSeconds}
          />
        </div>

        <div className="publisher">
          <h4>Publisher</h4>

          <div className="flex-container">
            <input
              className="col-2"
              type="text"
              id={"publisherName-" + this.props.currentIndex}
              name="name"
              data-parentname="publisher"
              placeholder="Publisher name"
              onChange={this.handleChange}
              value={
                this.state.schema["publisher"]
                  ? this.state.schema["publisher"]["name"]
                  : ""
              }
            />

            <input
              className="col-2"
              type="text"
              id={"publisherLogoURL-" + this.props.currentIndex}
              name="url"
              data-parentname="publisher"
              data-subparentname="logo"
              placeholder="Logo URL"
              onChange={this.handleChange}
              value={
                this.state.schema["publisher"]
                  ? this.state.schema["publisher"]["logo"]["url"]
                  : ""
              }
            />
          </div>

          <div className="flex-container">
            <input
              className="col-2"
              type="number"
              id={"logoWidth-" + this.props.currentIndex}
              name="width"
              min="1"
              max="60"
              data-parentname="publisher"
              data-subparentname="logo"
              placeholder="width ≤ 60"
              onChange={this.handleChange}
              value={
                this.state.schema["publisher"]
                  ? this.state.schema["publisher"]["logo"]["width"]
                  : ""
              }
            />

            <input
              className="col-2"
              type="number"
              id={"logoHeight-" + this.props.currentIndex}
              name="height"
              min="1"
              max="60"
              data-parentname="publisher"
              data-subparentname="logo"
              placeholder="height ≤ 60"
              onChange={this.handleChange}
              value={
                this.state.schema["publisher"]
                  ? this.state.schema["publisher"]["logo"]["height"]
                  : ""
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
