import React, { Component } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
//import axios from "axios";

import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import ClassIcon from "@mui/icons-material/Class";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasProfilePhoto: false,
      userAvatar: null,
    };
  }

  componentDidMount = async () => {
    await this.jQueryScripts();
  };

  userDataExists = () => {
    if (localStorage.getItem("userData")) return true;
    else return false;
  };

  getAvatar = () => {
    if (this.userDataExists())
      return JSON.parse(localStorage.getItem("userData")).avatar;
  };
  getAccountType = () => {
    if (this.userDataExists())
      return JSON.parse(localStorage.getItem("userData")).accountType;
  };

  jQueryScripts = () => {
    // add active state to sidebar item based on pathname
    $('a[href="/' + window.location.pathname.split("/")[1] + '"]').addClass(
      "active"
    );
    $('a[href="' + window.location.pathname + '"]').addClass("active");

    $(".account-box .name").on("click", function () {
      $(this).parent().parent().toggleClass("expanded");
      $(this).parent().siblings(".sub-menu").slideToggle();
    });
  };

  defaultAvatar = () => {
    let firstChar = this.props.state.firstName
      ? this.props.state.firstName.charAt(0)
      : "";
    let lastChar = this.props.state.lastName
      ? this.props.state.lastName.charAt(0)
      : "";

    return (
      <a href="/profile">
        <div className="default-avatar">
          <div className="initials v-center">
            {firstChar}
            {lastChar}
          </div>
        </div>
      </a>
    );
  };

  render() {
    return (
      <div
        id="sidebar"
        className="bg-midnightblue c-white"
        /*className={
          this.props.state.menuExpanded
            ? "bg-midnightblue c-white expanded"
            : "bg-midnightblue c-white"
        }
        */
      >
        <a className="logo-box bg-lightseagreen c-white" href="/dashboard">
          <div className="logo">
            <img src="/images/logo-white@2x.png" alt="logo" />
          </div>
          <div className="name">Schema Helper</div>
        </a>

        <ul className="menu">
          {/*<li>
            <div className="menu-depth-1 larger-icon">
              <a className="menu-item" href="/profile">
                <div className="avatar">
                  {this.getAvatar() ? (
                    <img
                      src={this.getAvatar()}
                      alt={
                        this.props.state.firstName +
                        " " +
                        this.props.state.lastName +
                        " avatar"
                      }
                    />
                  ) : (
                    <img
                      src="/images/default-user.svg"
                      alt="default user avatar"
                    />
                  )}
                </div>
                <div className="name">
                  {this.props.state.firstName && this.props.state.firstName}{" "}
                  {this.props.state.lastName && this.props.state.lastName}
                </div>
              </a>

              <div className="menu-depth-2">
                <div className="close"></div>

                <div className="menu-items">
                  <div className="title">Account</div>
                  <ul>
                    <li>
                      <a href="/profile">Edit Profile</a>
                    </li>
                    <li>
                      <a href="/logout">Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          */}

          <li>
            <div className="menu-depth-1">
              <a
                className="menu-item"
                href="/dashboard"
                data-for="dashboard"
                data-tip="Dashboard"
              >
                <div className="icon">
                  <img
                    className="default"
                    src="/images/dashboard.svg"
                    alt="dashboard icon"
                  />
                  <img
                    className="active"
                    src="/images/dashboard-solid.svg"
                    alt="dashboard icon"
                  />
                </div>
                <div className="name">Dashboard</div>
              </a>
              <ReactTooltip id="dashboard" />
            </div>
          </li>

          <li>
            <div className="menu-depth-1">
              <a
                className="menu-item"
                href="/users"
                data-for="users"
                data-tip="Users"
              >
                <div className="icon">
                  {window.location.pathname.includes("users") ? (
                    <PeopleIcon style={{ color: "#ffffff" }} />
                  ) : (
                    <PeopleOutlineIcon style={{ color: "#ffffff" }} />
                  )}
                </div>

                <div className="name">Users</div>
              </a>
              <ReactTooltip id="users" />
            </div>
          </li>

          {this.getAccountType() &&
            this.getAccountType().toLowerCase() === "admin" && (
              <li>
                <div className="menu-depth-1">
                  <a
                    className="menu-item"
                    href="/admin"
                    data-for="admin-dashboard"
                    data-tip="Admin Dashboard"
                  >
                    <div className="icon">
                      <img src="/images/users-white.svg" alt="users icon" />
                    </div>
                    <div className="name">Admin Dashboard</div>
                  </a>
                  <ReactTooltip id="admin-dashboard" />
                </div>
              </li>
            )}

          <li>
            <div className="menu-depth-1">
              <a
                className="menu-item"
                href="/templates"
                rel="noopener noreferrer"
                data-for="templates"
                data-tip="Schema Templates"
              >
                <div className="icon">
                  {window.location.pathname.includes("templates") ? (
                    <ClassIcon />
                  ) : (
                    <ClassOutlinedIcon />
                  )}
                </div>
                <div className="name">Schema Templates</div>
              </a>
              <ReactTooltip id="templates" />
            </div>
          </li>
        </ul>

        <div className="fixed-bottom">
          <ul>
            <li>
              <div className="menu-depth-1">
                <a
                  className="menu-item"
                  href="https://info.schemahelper.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  data-for="help"
                  data-tip="Help / Resources"
                >
                  <div className="icon">
                    <img src="/images/help.svg" alt="help icon" />
                  </div>
                  <div className="name">Help</div>
                </a>
                <ReactTooltip id="help" />
              </div>
            </li>

            <li>
              <div className="menu-depth-1">
                <a className="menu-item" href="/settings">
                  <div className="icon rotate-on-hover">
                    <img
                      className="default"
                      src="/images/settings.svg"
                      alt="settings icon"
                    />
                    <img
                      className="active"
                      src="/images/settings-solid.svg"
                      alt="settings icon"
                    />
                  </div>
                  <div className="name">Settings</div>
                </a>

                <div className="menu-depth-2">
                  <div className="close"></div>

                  <div className="menu-items">
                    <div className="title">Settings</div>

                    <ul>
                      <li>
                        <a href="/profile">Edit Profile</a>
                      </li>
                      <li>
                        <a href="/settings/account">Manage Account</a>
                      </li>
                      <li>
                        <a href="/settings/global-variables">Manage Defaults</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/*<div className="menu-depth-1">
            <div
              className="menu-item menu-toggle"
              onClick={this.props.toggleMenu}
              data-for="expand-sidebar"
              data-tip="Expand sidebar"
            >
              <div className="icon rotate-on-open">
                <img src="/images/arrow-right.svg" alt="logout icon" />
              </div>
              <div className="name">Collapse Menu</div>
            </div>
            <ReactTooltip id="expand-sidebar" />
                  </div>*/}
        </div>
      </div>
    );
  }
}
