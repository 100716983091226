import React, { Component } from "react";
import { Box, Grid, TextField } from "@mui/material";

export default class SignInForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Box mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="username"
                variant="outlined"
                label="Email address"
                helperText=""
                name="username"
                onChange={this.props.handleChange}
                aria-label="email address"
                value={this.props.state.username}
                fullWidth={true}
                data-testid="username"
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                type="password"
                id="password"
                data-testid="password"
                variant="outlined"
                label="Password"
                helperText=""
                name="password"
                onChange={this.props.handleChange}
                aria-label="password"
                value={this.props.state.password}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <input
            type="submit"
            className="btn c-black medium"
            value="Sign in"
            data-testid="signInButton"
            onClick={this.props.handleSubmit}
          />
        </Box>
      </form>
    );
  }
}
