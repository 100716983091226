import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";

// Components & Views
import Header from "./Header";
import CompanyDetails from "./CompanyDetails";
import CompanyHours from "./CompanyHours";
import ArticlePublisher from "./ArticlePublisher";
import FAQ from "./FAQ";

const DATABASE_API = require("../../../api/db-functions.js");

export default class Defaults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receivedResponse: false,
      updateSuccess: false,
      companyHoursDisplay: [],

      companyName: "",
      companyWebsite: "",
      companyPhoneNumber: "",
      companyHours: [],

      streetAddress: "",
      postalCode: "",
      addressLocality: "",
      addressRegion: "",
      addressCountry: "",
      latitude: "",
      longitude: "",

      articleFeaturedImage: "",
      articlePublisher: "",
      articlePublisherLogoURL: "",

      faqs: [],
      faqsDisplay: [],
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    document.title = "Defaults | Settings | Schema Helper";
    /*
    await this.promisedSetState({
      accountLimits: JSON.parse(localStorage.getItem("accountLimits"))[0],
    });
    */
    await DATABASE_API.getUserData();

    const globalVariables = JSON.parse(
      localStorage.getItem("userData")
    ).globalVariables;

    if (typeof globalVariables !== "undefined")
      await this.promisedSetState({
        companyName: globalVariables.companyName,
        companyWebsite: globalVariables.companyWebsite,
        companyPhoneNumber: globalVariables.companyPhoneNumber,
        companyHours: globalVariables.companyHours,

        articleFeaturedImage: globalVariables.articleFeaturedImage,
        articlePublisher: globalVariables.articlePublisher,
        articlePublisherLogoURL: globalVariables.articlePublisherLogoURL,

        faqs: globalVariables.faqs,
      });

    if ("address" in globalVariables) {
      await this.promisedSetState({
        streetAddress: globalVariables.address.streetAddress,
        postalCode: globalVariables.address.postalCode,
        addressLocality: globalVariables.address.addressLocality,
        addressRegion: globalVariables.address.addressRegion,
        addressCountry: globalVariables.address.addressCountry,
        latitude: globalVariables.address.latitude,
        longitude: globalVariables.address.longitude,
      });
    }
  };

  setCustomState = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  handleSave = async (event) => {
    let updateSuccess = await this.updateChanges();
    if (updateSuccess) await this.promisedSetState({ updateSuccess: true });
  };

  updateChanges = () => {
    const UPDATE = {
      "globalVariables.companyName": this.state.companyName,
      "globalVariables.companyWebsite": this.state.companyWebsite,
      "globalVariables.companyPhoneNumber": this.state.companyPhoneNumber,
      "globalVariables.companyHours": this.state.companyHours,

      "globalVariables.address.streetAddress": this.state.streetAddress,
      "globalVariables.address.postalCode": this.state.postalCode,
      "globalVariables.address.addressLocality": this.state.addressLocality,
      "globalVariables.address.addressRegion": this.state.addressRegion,
      "globalVariables.address.addressCountry": this.state.addressCountry,
      "globalVariables.address.latitude": this.state.latitude,
      "globalVariables.address.longitude": this.state.longitude,

      "globalVariables.articleFeaturedImage": this.state.articleFeaturedImage,
      "globalVariables.articlePublisher": this.state.articlePublisher,
      "globalVariables.articlePublisherLogoURL":
        this.state.articlePublisherLogoURL,

      "globalVariables.faqs": this.state.faqs,
    };

    return axios
      .post("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: {
          $set: UPDATE,
        },
      })
      .then(
        async (response) => {
          let data = response;
          let successStatus = data.data.success;

          if (successStatus) {
            Store.addNotification({
              title: "Success",
              message: "Global variables successfully updated.",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: true,
              },
            });
            await this.promisedSetState({ receivedResponse: true });

            setTimeout(function () {
              window.location.reload(true);
            }, 4000);
          } else {
            Store.addNotification({
              title: "Warning",
              message:
                "Could not update global variables. Please try again later.",
              type: "warning",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: true,
              },
            });
          }

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <div id="account" className="content-window">
        <Header title="Manage Defaults" save={this.handleSave} />

        <div className="components">
          <div className="description mt-50 mb-50">
            This page allows you to set the default values for a variety of
            fields that are used when you click the "Autofill" button through
            the Schema Helper app or when you use the Bulk Editor. Filling in
            these fields can save you some time by letting you set the default
            value that'll be used.
          </div>
          {/*<div className="section-title">Account Information</div>*/}

          <CompanyDetails state={this.state} handleChange={this.handleChange} />

          <CompanyHours
            state={this.state}
            handleChange={this.handleChange}
            setCustomState={this.setCustomState}
          />

          <ArticlePublisher
            state={this.state}
            handleChange={this.handleChange}
          />

          <FAQ
            state={this.state}
            handleChange={this.handleChange}
            setCustomState={this.setCustomState}
          />
        </div>
      </div>
    );
  }
}
