import React, { Component } from "react";

let schemaJSON = require("./podcastEpisode.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class PodcastEpisode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      url: "",
      internalURL: "",
      optionalString: "",
      pageDetails: {},
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "PodcastEpisode"
      );
    }

    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");

    if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="podcast-schema-form" className="schema-form">
        <h4>Episode Name</h4>
        <input
          type="text"
          id="podcastName"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <h4>URL</h4>
        <input
          type="text"
          id="url"
          name="url"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <h4>Description</h4>
        <textarea
          type="text"
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <h4>Publish date</h4>
        <input
          type="date"
          id="datePublished"
          name="datePublished"
          onChange={this.handleChange}
          value={schemaJSON["datePublished"]}
        />

        <h4>Image URL</h4>
        <input
          type="text"
          id="image"
          name="image"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <h4>Podcast Series Name</h4>
        <input
          type="text"
          id="partOfSeriesName"
          name="name"
          data-parentname="partOfSeries"
          onChange={this.handleChange}
          value={schemaJSON["partOfSeries"]["name"]}
        />

        <h4>Podcast Series URL</h4>
        <input
          type="text"
          id="partOfSeriesUrl"
          name="url"
          data-parentname="partOfSeries"
          onChange={this.handleChange}
          value={schemaJSON["partOfSeries"]["url"]}
        />
      </div>
    );
  }
}
