import React, { Component } from "react";
import { store } from "react-notifications-component";
//import ReactTooltip from "react-tooltip";

const { SCHEMAS } = require("../../../../schemas/index");

const FREE_SCHEMA_GATE = [
  "Article",
  "BlogPosting",
  "WebSite",
  "FAQPage",
  "AggregateRating",
  "Event",
  "LocalBusiness",
  "Person",
];

const ALLOWED_SCHEMAS = [
  "Article",
  "BlogPosting",
  "BreadcrumbList",
  "FAQPage",
  "LocalBusiness",
  "NewsArticle",
  "Organization",
  "WebSite",
  "WebPage",
];

export default class SchemaSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {};

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: this.state[stateName] });
  };

  selectSchema = (value) => {
    if (SCHEMAS.includes(value)) {
      this.props.appendSchema(value);
      document.getElementById("schemaSelector").value = "";
    }
  };

  hasAccess = (schema) => {
    let plan = JSON.parse(localStorage.getItem("accountLimits"))[0].package;

    if (plan === "Free") return FREE_SCHEMA_GATE.includes(schema);
    else return true;
  };

  noAccessNotificaton = (schema) => {
    store.addNotification({
      message:
        "The schema you selected is not available. Please upgrade your plan to access this schema.",
      type: "warning",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  render() {
    return (
      <div className="schema-selection">
        <label htmlFor="schemaSelector">
          <h3>Schema Selection</h3>
        </label>

        <input
          list="schemas"
          id="schemaSelector"
          name="schemaSelector"
          placeholder="Search or select a schema"
          autoComplete="off"
          onChange={() =>
            this.selectSchema(document.getElementById("schemaSelector").value)
          }
        />

        <datalist id="schemas">
          {SCHEMAS.map(
            (schema) =>
              this.hasAccess(schema) &&
              ALLOWED_SCHEMAS.includes(schema) && (
                <option key={schema} value={schema}></option>
              )
          )}
          {/*SCHEMAS.map((schema) =>
            this.hasAccess(schema) && ALLOWED_SCHEMAS.includes(schema) ? (
              <option key={schema} value={schema}></option>
            ) : (
              <option key={schema} value={schema + " (Not available)"}></option>
            )
            )*/}
        </datalist>
      </div>
    );
  }
}
