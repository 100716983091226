import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import BulkEditorModal from "../../../components/BulkEditorModal";
import BulkEditorProgressModal from "../../../components/BulkEditorProgressModal";

import {
  //Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

// Helper functions
//const DATABASE_API = require("../../api/db-functions");
//const UTIL = require("../../../util/util");
// const HELPER = require("../util/localUtil");

export default class SidebarMulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generateConfirmationToggle: false,
      generateTemplateConfirmationToggle: false,
      removeConfirmationToggle: false,
      bulkEditProgressToggle: false,
      bulkEditorToggle: false,

      selectedTemplate: "",
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  // check if all selected pages exist in article array
  // returns boolean value
  pagesAreArticles = (selectedPages, articles) => {
    return !selectedPages.some((val) => !articles.includes(val));
  };

  // check if all selected pages exist in website / landing pages array
  // returns boolean value
  pagesAreWebsites = (selectedPages, websitePages) => {
    return !selectedPages.some((val) => !websitePages.includes(val));
  };

  selectTemplate = async (template) => {
    await this.promisedSetState({ selectedTemplate: template });
  };

  generateConfirmationModal = (selectedPages, articles, websitePages) => {
    return (
      <Dialog
        open={this.state.generateConfirmationToggle}
        onClose={() => this.toggle("generateConfirmationToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to generate schemas for {selectedPages.length}{" "}
          page(s)?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will overwrite any existing schemas on the pages that you have
            selected.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={() => {
              this.toggle("generateConfirmationToggle");
              this.toggle("bulkEditProgressToggle");

              this.pagesAreArticles(selectedPages, articles) &&
                this.props.generateArticleSchemas();
              this.pagesAreWebsites(selectedPages, websitePages) &&
                this.props.generateWebsiteSchemas();
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.toggle("generateConfirmationToggle")}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  removeConfirmationModal = (selectedPages) => {
    return (
      <Dialog
        open={this.state.removeConfirmationToggle}
        onClose={() => this.toggle("removeConfirmationToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove schemas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove any existing schemas on {selectedPages.length}{" "}
            page(s) that you have selected.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={() => {
              this.props.removeMultipleExistingSchemas();
              this.toggle("removeConfirmationToggle");
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.toggle("removeConfirmationToggle")}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  generateTemplateConfirmationModal = (selectedPages) => {
    return (
      <Dialog
        open={this.state.generateTemplateConfirmationToggle}
        onClose={() => this.toggle("generateTemplateConfirmationToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to generate schemas for {selectedPages.length}{" "}
          page(s)?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will overwrite any existing schemas on the pages that you have
            selected with the <strong>{this.state.selectedTemplate}</strong>{" "}
            template.
          </DialogContentText>
          <DialogContentText>
            You may need to double check the pages after completion for
            completeness. We will attempt to autofill as much information as we
            can. Things such as ratings, reviews, etc. may need to be manually
            updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={async () => {
              await this.props.checkToken();
              this.toggle("generateTemplateConfirmationToggle");
              this.toggle("bulkEditProgressToggle");
              this.props.generateFromTemplate(this.state.selectedTemplate);
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.toggle("generateTemplateConfirmationToggle")}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    //const page = this.props.page;
    const selectedPages = this.props.state.selectedPages;
    const articles = this.props.state.articles;
    const websitePages = this.props.state.websitePages;
    const bulkEditorAccess = this.props.state.accountLimits.bulkEditor;

    return (
      <aside className="right-sidebar multi">
        <div className="image-preview">
          <div className="title">{selectedPages.length} pages selected</div>
        </div>

        <ul className="sidebar-options">
          {/* Generate article schemas for selected pages */}
          {/*this.pagesAreArticles(selectedPages, articles) ? (
            <li
              className={
                (bulkEditorAccess ? "" : "disabled ") +
                "generate-articles c-orange"
              }
              onClick={
                bulkEditorAccess
                  ? () => this.toggle("generateConfirmationToggle")
                  : this.doNothing
              }
              data-tip={
                bulkEditorAccess
                  ? "Automatically generate BlogPosting schemas for the selected pages."
                  : "Automatically generate BlogPosting schemas for the selected pages. Upgrade your plan to enable this feature."
              }
            >
              <img src="/images/Generate-Icon.svg" alt="autogenerate" />
              <span>Generate BlogPosting Schemas</span>
              <ReactTooltip />
            </li>
          ) : (
            ""
          )*/}

          {/* Generate website schemas for selected pages */}
          {/*this.pagesAreWebsites(selectedPages, websitePages) ? (
            <li
              className={(bulkEditorAccess ? "" : "disabled ") + "c-orange"}
              onClick={
                bulkEditorAccess
                  ? () => this.toggle("generateConfirmationToggle")
                  : this.doNothing
              }
              data-tip={
                bulkEditorAccess
                  ? "Automatically generate WebSite schemas for the selected pages."
                  : "Automatically generate WebSite schemas for the selected pages. Upgrade your plan to enable this feature."
              }
            >
              <img src="/images/Generate-Icon.svg" alt="autogenerate" />
              <span>Generate Website Schemas</span>
              <ReactTooltip />
            </li>
          ) : (
            ""
          )*/}

          {/* Bulk editor with template system */}
          <li
            className={
              (bulkEditorAccess ? "" : "disabled ") +
              "generate-articles c-orange "
            }
            onClick={
              bulkEditorAccess
                ? () => this.toggle("bulkEditorToggle")
                : this.doNothing
            }
            data-tip={
              bulkEditorAccess
                ? "Choose a schema template to generate. This will overwrite any existing schema on this page."
                : "Choose a schema template to generate. Upgrade your plan to enable this feature."
            }
          >
            <img src="/images/Generate-Icon.svg" alt="autogenerate" />
            <span>Generate Schema</span>
            <ReactTooltip />
          </li>

          {/* Remove schemas for selected pages */}
          <li
            className="c-orange"
            onClick={() => this.toggle("removeConfirmationToggle")}
            data-tip="Remove schemas for all selected pages"
          >
            <img src="/images/delete.svg" alt="delete icon" />
            <span>Delete Schemas</span>
            <ReactTooltip />
          </li>
        </ul>

        {this.state.generateConfirmationToggle &&
          this.generateConfirmationModal(selectedPages, articles, websitePages)}

        {this.state.removeConfirmationToggle &&
          this.removeConfirmationModal(selectedPages)}

        {/* New bulk editor stuff below */}
        {this.state.generateTemplateConfirmationToggle &&
          this.generateTemplateConfirmationModal(
            selectedPages,
            articles,
            websitePages
          )}

        {this.state.bulkEditorToggle && (
          <BulkEditorModal
            toggleState={this.state.bulkEditorToggle}
            toggle={this.toggle}
            selectTemplate={this.selectTemplate}
            generate={() => this.toggle("generateTemplateConfirmationToggle")}
          />
        )}

        {this.state.bulkEditProgressToggle && (
          <BulkEditorProgressModal
            toggleState={this.state.bulkEditProgressToggle}
            toggle={this.toggle}
            selectedPages={selectedPages}
            completedProgress={this.props.state.completedProgress}
            clearSelectedPages={this.props.clearSelectedPages}
          />
        )}
      </aside>
    );
  }
}
