import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";
//import ReactTooltip from "react-tooltip";

const DATABASE_API = require("../../../../api/db-functions.js");

export default class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    await DATABASE_API.getUserData();

    await this.promisedSetState({
      activity: JSON.parse(localStorage.getItem("userData")).activity,
    });

    await this.jQueryScripts();
  };

  jQueryScripts = () => {
    let activity = this.state.activity;
    this.createSquares();

    // get activity from current year. filter out the rest
    activity = activity.filter((dayActivity) => {
      let year = moment(new Date(dayActivity.date), "MMMM D, YYYY").format(
        "YYYY"
      );

      return parseInt(year) === new Date().getFullYear();
    });

    // iterate through all activity and set level of change per day of year
    activity.forEach((day) => {
      let dayOfYear = moment(new Date(day.date)).dayOfYear();
      let level = 0;

      // set color tier
      if (day.changes <= 4) level = 1;
      else if (day.changes > 4) level = 2;
      else if (day.changes > 10) level = 3;

      // set tier level on cooresponding HTML element
      $("#activity-chart .day-" + dayOfYear).attr("data-level", level);
      $("#activity-chart .day-" + dayOfYear).attr(
        "data-changes",
        "Changes: " + day.changes.toString()
      );
    });
  };

  // Generate squares for each day of the year
  createSquares = () => {
    const squares = document.querySelector(".squares");
    for (var i = 1; i < 365; i++) {
      squares.insertAdjacentHTML(
        "beforeend",
        `<li class="day-${i}" data-level="0"></li>`
      );
    }
  };

  render() {
    return (
      <div className="activity-component">
        <div className="section-title">
          <h2>Your Activity for {new Date().getFullYear()}</h2>
        </div>

        <div id="activity-chart">
          <div className="graph">
            <ul className="months">
              <li>Jan</li>
              <li>Feb</li>
              <li>Mar</li>
              <li>Apr</li>
              <li>May</li>
              <li>Jun</li>
              <li>Jul</li>
              <li>Aug</li>
              <li>Sep</li>
              <li>Oct</li>
              <li>Nov</li>
              <li>Dec</li>
            </ul>
            <ul className="days">
              <li>Sun</li>
              <li>Mon</li>
              <li>Tue</li>
              <li>Wed</li>
              <li>Thu</li>
              <li>Fri</li>
              <li>Sat</li>
            </ul>
            <ul className="squares">{/* Added via JavaScript */}</ul>
          </div>
        </div>
      </div>
    );
  }
}
