import React, { Component } from "react";
import axios from "axios";

import Modal from "../../../../components/modal/modal";

export default class TemplatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaToRemove: "",

      // modals
      removeSchemaToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));
  componentDidMount = async () => {};

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  appendSchema = async (schema) => {
    let schemas = this.state.schemas;
    schemas.push(schema);
    await this.promisedSetState({ schemas: schemas });
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
  };

  templateTable = () => {
    return (
      <ul className="table template-table">
        {this.props.state.schemaTemplates.map((template, index) =>
          this.templateItem(template, index)
        )}
      </ul>
    );
  };

  templateItem = (template, index) => {
    return (
      <li className="template-item flex-container" key={template.id}>
        <h4 className="name">{template.name}</h4>

        <div className="hover-menu">
          <span
            className="btn x-small b-orange c-orange h-bg-dark-orange h-c-white"
            onClick={() =>
              (window.location.href = "/templates/edit/" + template.id)
            }
          >
            Edit
          </span>
          {/*<span className="btn x-small b-orange c-orange h-bg-dark-orange h-c-white">
            View
          </span>*/}
          <span
            className="btn x-small b-orange c-orange h-bg-dark-orange h-c-white"
            onClick={() => this.confirmTemplateRemoval(template.name)}
          >
            Delete
          </span>
        </div>
      </li>
    );
  };

  confirmTemplateRemoval = async (templateName) => {
    await this.promisedSetState({ schemaToRemove: templateName });
    this.toggle("removeSchemaToggle");
  };

  removeTemplate = async (templateName) => {
    let schemaTemplates = this.props.state.schemaTemplates;

    schemaTemplates = schemaTemplates.filter(
      (schema) => schema.name !== templateName
    );

    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { schemaTemplates: schemaTemplates },
      })
      .then(
        (response) => {
          let data = response.data;

          if (data.success) {
            window.location.reload();
            return true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <div className="templates">
        {this.props.state.schemaTemplates.length > 0 ? (
          this.templateTable()
        ) : (
          <h3>You have no templates saved</h3>
        )}

        {this.state.removeSchemaToggle && (
          <Modal
            toggleState={this.state.removeSchemaToggle}
            toggle={() => this.toggle("removeSchemaToggle")}
            title={"Are you sure you want to remove this template?"}
            message={
              "Selecting yes will remove the " +
              this.state.schemaToRemove +
              " template. This will not remove schema from pages that you have already published."
            }
            yesAction={() => this.removeTemplate(this.state.schemaToRemove)}
          />
        )}
      </div>
    );
  }
}
