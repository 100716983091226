import React, { Component } from "react";
//import $ from "jquery";
import ReactCrop from "react-image-crop";
import ReactTooltip from "react-tooltip";

import "react-image-crop/dist/ReactCrop.css";
//import axios from "axios";

export default class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleCropWindow: false,
      toggleUploadWindowModal: false,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1,
      },
      profileImageFile: null,
      profileImageBase64: null,
      croppedImageUrl: null,
      croppedImage: null,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    /*
    $("#update-avatar .avatar").on("click", function () {
      $(".avatar-upload").trigger("click");
    });
    */
  };

  onImageDrop = async (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = async (e) => {
      await this.promisedSetState({
        profileImageFile: file,
        profileImageBase64: reader.result,
        toggleCropWindow: true,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = async (crop) => {
    await this.promisedSetState({ crop });
  };

  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      await this.promisedSetState({ croppedImageUrl: croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        //this.dataURLtoFile(reader.result, "cropped.jpg");

        await this.promisedSetState({
          croppedImageUrl: reader.result,
        });

        //console.log("croppedImageUrl", this.state.croppedImageUrl);
      };
    });
  }

  dataURLtoFile = async (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    await this.promisedSetState({ croppedImage: croppedImage });
    //console.log("dataURLtoFile", this.state.croppedImage);
  };

  cropAndSetImage = async () => {
    await this.promisedSetState({
      profileImageBase64: this.state.croppedImageUrl,
      toggleCropWindow: false,
    });
  };

  useImage = async () => {
    await this.props.updateState("userAvatar", this.state.profileImageBase64);
    await this.props.updateState(
      "userAvatarFilename",
      this.state.profileImageFile.name
    );

    await this.promisedSetState({
      toggleCropWindow: false,
      toggleUploadWindowModal: false,
      profileImageFile: null,
      profileImageBase64: null,
      croppedImageUrl: null,
      croppedImage: null,
    });
  };

  removeImage = async () => {
    await this.props.updateState("userAvatar", null);
    await this.props.updateState("userAvatarFilename", null);
  };

  toggleUploadWindowModal = async () => {
    await this.promisedSetState({
      toggleUploadWindowModal: !this.state.toggleUploadWindowModal,
    });
  };

  reset = async () => {
    await this.promisedSetState({
      toggleCropWindow: false,
      toggleUploadWindowModal: false,
      profileImageFile: null,
      profileImageBase64: null,
      croppedImageUrl: null,
      croppedImage: null,
    });
  };

  displayUploadWindowModal = () => {
    return (
      <div className="crop modal">
        <div className="modal-content">
          <img
            className="close"
            src="/images/close.svg"
            alt="close button"
            onClick={this.reset}
          />

          <h2 className="ta-left">Upload Image</h2>

          {!this.state.toggleCropWindow && !this.state.profileImageBase64 && (
            <div className="select-file ta-center">
              <input
                label="Image"
                type="file"
                name="image"
                accept=".png, .jpg"
                onChange={this.onImageDrop}
                className="avatar-upload v-center"
              />
            </div>
          )}

          {this.state.profileImageBase64 && !this.state.toggleCropWindow && (
            <div className="crop-window">
              <div className="inner">
                <img src={this.state.profileImageBase64} alt="crop preview" />
              </div>
            </div>
          )}

          {this.state.profileImageBase64 && this.state.toggleCropWindow && (
            <div className="crop-window">
              <ReactCrop
                src={this.state.profileImageBase64}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />

              <div className="btn-container">
                <button
                  className="btn medium bg-teal c-white"
                  onClick={this.cropAndSetImage}
                >
                  Crop Image
                </button>
              </div>
            </div>
          )}

          <div className="btn-container ta-right">
            <button
              className={
                (this.state.croppedImageUrl ? "" : "disabled ") +
                "btn medium b-orange c-white bg-orange"
              }
              onClick={
                this.state.croppedImageUrl ? this.useImage : this.doNothing
              }
              data-tip={
                this.state.croppedImageUrl
                  ? ""
                  : "You must select an image before being able to upload it."
              }
              data-for="upload-image"
            >
              Upload Image
            </button>
            <ReactTooltip id="upload-image" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="update-avatar">
        <div className="form-container">
          <div className="row">
            <div className="avatar-selection flex-container">
              {/* if image is selected */}
              {this.props.state.userAvatar ? (
                <span className="avatar" onClick={this.toggleUploadWindowModal}>
                  <div className="inner">
                    <img src={this.props.state.userAvatar} alt="preview" />
                  </div>
                </span>
              ) : (
                <span
                  className="avatar empty"
                  onClick={this.toggleUploadWindowModal}
                >
                  <img
                    className="add-icon"
                    src="/images/Teal-Plus.png"
                    alt="add icon"
                  />
                </span>
              )}

              <div className="avatar-upload-container">
                <div className="file-name">
                  {this.props.state.userAvatarFilename ? (
                    <span className="flex-container">
                      <span className="c-black">
                        {this.props.state.userAvatarFilename}
                      </span>
                      <span className="remove-image" onClick={this.removeImage}>
                        x
                      </span>
                    </span>
                  ) : (
                    "no image uploaded"
                  )}
                </div>

                <button
                  id="upload-image"
                  className="btn small b-orange c-orange bg-white h-bg-orange h-c-white"
                  onClick={this.toggleUploadWindowModal}
                >
                  {this.props.state.userAvatar
                    ? "Change Image"
                    : "Upload Image"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.state.toggleUploadWindowModal && this.displayUploadWindowModal()}
      </div>
    );
  }
}
