import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./localBusiness.json");
const UTIL = require("../../util/util");
const COMP_UTIL = require("./util");
//const LOCAL_UTIL = require("../util");

const TYPE = [
  "AnimalShelter",
  "ArchiveOrganization",
  "AutomotiveBusiness",
  "ChildCare",
  "Dentist",
  "DryCleaningOrLaundry",
  "EmergencyService",
  "EmploymentAgency",
  "EntertainmentBusiness",
  "FinancialService",
  "FoodEstablishment",
  "GovernmentOffice",
  "HealthAndBeautyBusiness",
  "HomeAndConstructionBusiness",
  "InternetCafe",
  "LegalService",
  "LocalBusiness",
  "Library",
  "LodgingBusiness",
  "MedicalBusiness",
  "ProfessionalService",
  "RadioStation",
  "RealEstateAgent",
  "RecyclingCenter",
  "Restaurant",
  "SelfStorage",
  "ShoppingCenter",
  "SportsActivityLocation",
  "Store",
  "TelevisionStation",
  "TouristInformationCenter",
  "TravelAgency",
];

export default class LocalBusiness extends Component {
  constructor(props) {
    super(props);

    this.images = [];
    this.hours = [];
    this.state = {
      schema: schemaJSON,
      showImageFields: this.images,
      showHourFields: this.hours,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      //schemaJSON = this.props.state.schemaToEdit; // load existing schema

      if (Array.isArray(this.props.state.schemaToEdit)) {
        // filter the unwanted schemas out of the current schema array
        let filteredSchema = this.props.state.schemaToEdit.filter((schema) =>
          TYPE.includes(schema["@type"])
        );
        schemaJSON = filteredSchema[0];
      } else if (Array.isArray(this.props.state.schemaToEdit["@graph"])) {
        // filter the unwanted schemas out of the current schema array
        let filteredSchema = this.props.state.schemaToEdit["@graph"].filter(
          (schema) => TYPE.includes(schema["@type"])
        );
        schemaJSON = filteredSchema[0];
      } else schemaJSON = this.props.state.schemaToEdit;
    }

    await this.generateInputFields();
    this.props.setSchema(schemaJSON);
    await COMP_UTIL.jQueryScripts(schemaJSON);
    this.setState(this.state); // force update
    this.updateSessionSchema();
  };

  updateSessionSchema = async () => {
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subparentname = e.target.getAttribute("data-subparentname");

    if (
      (name === "@type" && value === "Restaurant") ||
      value === "FoodEstablishment"
    ) {
      schemaJSON[name] = value;
      await this.addRestaurantFields();
    } else if (parentname === "image") {
      const imageNumber = e.target.getAttribute("data-image-number");
      schemaJSON[parentname][imageNumber] = value;
    } else if (parentname === "openingHoursSpecification") {
      const hoursNum = e.target.getAttribute("data-hours-number");
      if (name === "dayOfWeek") {
        let weekValues = Array.from(
          e.target.selectedOptions,
          (option) => option.value
        );

        schemaJSON[parentname][hoursNum][name] = weekValues;
      } else schemaJSON[parentname][hoursNum][name] = UTIL.cleanText(value);
    } else if (parentname && subparentname)
      schemaJSON[parentname][subparentname][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  addRestaurantFields = async () => {
    schemaJSON["servesCuisine"] = "American";
    schemaJSON["menu"] = "https://restaurant.com/menu";
    await this.updateSessionSchema();
  };

  /*
   * Images
   */
  addImage = async () => {
    let counter = this.images.length;

    this.images.push(
      <div className="image-fields" key={counter}>
        <h5>Image #{counter + 1}</h5>

        <input
          type="text"
          id={"image-" + counter}
          name="name"
          data-parentname="image"
          data-image-number={counter}
          placeholder="Image URL"
          onChange={this.handleChange}
        />
      </div>
    );

    this.setState({
      showImageFields: this.images,
    });

    // add a new faq object to the array of faqs
    schemaJSON["image"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayImageSection = () => {
    return (
      <div className="image-items">
        <hr className="mt-20" />
        <h3>Images</h3>

        <div className="faq-fields-container">{this.images}</div>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addImage}
        >
          Add Image
        </button>
      </div>
    );
  };

  /*
   * Address
   */
  addressFields = () => {
    return (
      <div className="address-fields">
        <label>
          <h4>Street address</h4>
        </label>
        <input
          type="text"
          id="lbstreetAddress"
          name="streetAddress"
          data-parentname="address"
          onChange={this.handleChange}
          value={
            schemaJSON["address"] && schemaJSON["address"]["streetAddress"]
          }
        />

        <div className="flex-container">
          <span className="col-2">
            <label>
              <h4>Region</h4>
            </label>
            <input
              type="text"
              id="lbaddressRegion"
              name="addressRegion"
              data-parentname="address"
              onChange={this.handleChange}
              value={
                schemaJSON["address"] && schemaJSON["address"]["addressRegion"]
              }
            />
          </span>
          <span className="col-2">
            <label>
              <h4>Locality</h4>
            </label>
            <input
              type="text"
              id="lbaddressLocality"
              name="addressLocality"
              data-parentname="address"
              onChange={this.handleChange}
              value={
                schemaJSON["address"] &&
                schemaJSON["address"]["addressLocality"]
              }
            />
          </span>
        </div>

        <div className="flex-container">
          <span className="col-2">
            <label>
              <h4>Postal Code</h4>
            </label>
            <input
              type="text"
              id="lbpostalCode"
              name="postalCode"
              data-parentname="address"
              onChange={this.handleChange}
              value={
                schemaJSON["address"] && schemaJSON["address"]["postalCode"]
              }
            />
          </span>
          <span className="col-2">
            <label>
              <h4>Country</h4>
            </label>
            <input
              type="text"
              id="lbaddressCountry"
              name="addressCountry"
              data-parentname="address"
              onChange={this.handleChange}
              value={
                schemaJSON["address"] && schemaJSON["address"]["addressCountry"]
              }
            />
          </span>
        </div>
      </div>
    );
  };

  /*
   * Hours
   */
  addHours = async () => {
    let counter = this.hours.length;

    this.hours.push(
      <div className="hours-fields" key={counter}>
        <h4>Business hours #{counter + 1}</h4>

        <label>Day(s) of the week</label>
        <select
          name="dayOfWeek"
          id={"dayOfWeek" + counter}
          multiple
          onChange={this.handleChange}
          data-parentname="openingHoursSpecification"
          data-hours-number={counter}
        >
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </select>

        <div className="flex-container">
          <span className="col-2">
            <label>Opens</label>
            <input
              type="time"
              id={"opens-" + counter}
              name="opens"
              max="24:00"
              min="0:00"
              data-parentname="openingHoursSpecification"
              data-hours-number={counter}
              onChange={this.handleChange}
            />
          </span>

          <span className="col-2">
            <label>Closes</label>
            <input
              type="time"
              id={"closes-" + counter}
              name="closes"
              max="24:00"
              min="0:00"
              data-parentname="openingHoursSpecification"
              data-hours-number={counter}
              onChange={this.handleChange}
            />
          </span>
        </div>
      </div>
    );

    this.setState({ showHourFields: this.hours });

    // add a new hours object to the array of hours
    schemaJSON["openingHoursSpecification"].push({
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [],
      opens: "",
      closes: "",
    });

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayHoursSection = () => {
    return (
      <div className="hours-items">
        <hr className="mt-20" />
        <h3>Hours</h3>

        <div className="hours-fields-container">{this.hours}</div>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addHours}
        >
          Add Hours
        </button>
      </div>
    );
  };

  /*
   * Review
   */

  // add a new review object to the schema
  addReview = async () => {
    schemaJSON["review"] = {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "",
        bestRating: "",
      },
      author: {
        "@type": "Person",
        name: "",
      },
    };

    // update session schema
    this.setState({ schema: schemaJSON, review: true });
    this.props.setSchema(this.state.schema);
  };

  displayReviewSection = () => {
    return (
      <div className="review">
        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="ratingValue">Rating value</label>
            <input
              type="number"
              id="ratingValue"
              name="ratingValue"
              min="0"
              max="5"
              placeholder="0-5"
              data-parentname="review"
              data-subparentname="reviewRating"
              onChange={this.handleChange}
              value={schemaJSON["review"]["reviewRating"]["ratingValue"]}
            />
          </span>
          <span className="col-2">
            <label htmlFor="bestRating">Best rating</label>
            <input
              type="number"
              id="bestRating"
              name="bestRating"
              min="0"
              max="5"
              placeholder="0-5"
              data-parentname="review"
              data-subparentname="reviewRating"
              onChange={this.handleChange}
              value={schemaJSON["review"]["reviewRating"]["bestRating"]}
            />
          </span>
        </div>

        <label htmlFor="reviewAuthor">Author of review</label>
        <input
          type="text"
          id="reviewAuthor"
          name="name"
          data-parentname="review"
          data-subparentname="author"
          value={schemaJSON["review"]["author"]["name"]}
          onChange={this.handleChange}
        />
      </div>
    );
  };

  generateInputFields = async () => {
    schemaJSON.image.map((imageItem, index) =>
      this.images.push(
        <div className="image-fields" key={index}>
          <h5>Image #{index + 1}</h5>

          <input
            type="text"
            id={"image-" + index}
            name="name"
            data-parentname="image"
            data-image-number={index}
            placeholder="Image URL"
            onChange={this.handleChange}
            value={imageItem}
          />
        </div>
      )
    );

    schemaJSON.openingHoursSpecification.map((hoursItem, index) =>
      this.hours.push(
        <div className="step-fields" key={index}>
          <h5>Business hours #{index + 1}</h5>

          <label>Day(s) of the week</label>
          <select
            name="dayOfWeek"
            id={"dayOfWeek-" + index}
            multiple
            onChange={this.handleChange}
            data-parentname="openingHoursSpecification"
            data-hours-number={index}
          >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>

          <div className="flex-container">
            <span className="col-2">
              <label>Opens</label>
              <input
                type="time"
                id={"opens-" + index}
                name="opens"
                max="24:00"
                min="0:00"
                data-parentname="openingHoursSpecification"
                data-hours-number={index}
                onChange={this.handleChange}
              />
            </span>

            <span className="col-2">
              <label>Closes</label>
              <input
                type="time"
                id={"closes-" + index}
                name="closes"
                max="24:00"
                min="0:00"
                data-parentname="openingHoursSpecification"
                data-hours-number={index}
                onChange={this.handleChange}
              />
            </span>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <div id="application-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6414187)}
          >
            Learn More
          </span>
        </Typography>

        <label htmlFor="name">
          <h4>Business name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label htmlFor="businessType">
          <h4>Business type</h4>
        </label>
        <div className="description">
          The full definition of LocalBusiness is available at{" "}
          <a
            href="https://schema.org/LocalBusiness"
            rel="noopener noreferrer"
            target="_blank"
          >
            schema.org/LocalBusiness
          </a>
        </div>
        <select
          className="col-2"
          id="businessType"
          name="@type"
          onChange={this.handleChange}
        >
          <option value="LocalBusiness">- Type -</option>
          {TYPE.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        <label htmlFor="type">
          <h4>@id</h4>
        </label>
        <div className="description">
          Globally unique ID of the specific business location in the form of a
          URL. The ID should be stable and unchanging over time. Google Search
          treats the URL as an opaque string and it does not have to be a
          working link.
        </div>
        <input
          type="text"
          id="@id"
          name="@id"
          onChange={this.handleChange}
          value={schemaJSON["@id"]}
        />

        {this.displayImageSection()}

        <hr className="mt-20" />

        <label>
          <h3>Address</h3>
        </label>

        {this.addressFields()}

        <hr className="mt-20" />

        <label>
          <h3>Review</h3>
        </label>
        {!this.state?.schema?.review && (
          <button
            className="btn small c-white bg-orange b-orange mt-20"
            onClick={this.addReview}
          >
            Add Review
          </button>
        )}
        {this.state?.schema?.review && this.displayReviewSection()}

        <hr className="mt-20" />

        <label>
          <h4>Geo coordinates</h4>
        </label>
        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="latitude">Latitude</label>
            <input
              type="number"
              id="latitude"
              name="latitude"
              placeholder="40.761293"
              data-parentname="geo"
              onChange={this.handleChange}
              value={schemaJSON["geo"]["latitude"]}
            />
          </span>
          <span className="col-2">
            <label htmlFor="longitude">Longitude</label>
            <input
              type="number"
              id="longitude"
              name="longitude"
              placeholder="-73.982294"
              data-parentname="geo"
              onChange={this.handleChange}
              value={schemaJSON["geo"]["longitude"]}
            />
          </span>
        </div>
        <label htmlFor="url">
          <h4>URL</h4>
        </label>
        <div className="description">
          The fully-qualified URL of the specific business location. Unlike the
          @id property, this URL property should be a working link.
        </div>
        <input
          type="text"
          id="url"
          name="url"
          value={schemaJSON["url"]}
          onChange={this.handleChange}
        />
        <label htmlFor="telephone">
          <h4>Telephone</h4>
        </label>
        <input
          type="text"
          id="lbtelephone"
          name="telephone"
          value={schemaJSON["telephone"]}
          onChange={this.handleChange}
        />

        {(schemaJSON["@type"] === "Restaurant" ||
          schemaJSON["@type"] === "FoodEstablishment") && (
          <div className="food-related">
            <div className="cuisine">
              <label>
                <h4>Serves Cuisine</h4>
              </label>
              <div className="description">
                The type of cuisine the restaurant serves.
              </div>
              <input
                type="text"
                id="servesCuisine"
                name="servesCuisine"
                onChange={this.handleChange}
                value={schemaJSON["servesCuisine"]}
              />
            </div>

            <label htmlFor="menu">
              <h4>Menu</h4>
            </label>
            <div className="description">
              For food establishments, the fully-qualified URL of the menu.
              Leave this blank if not applicable.
            </div>
            <input
              type="text"
              id="menu"
              name="menu"
              value={schemaJSON["menu"]}
              onChange={this.handleChange}
            />
          </div>
        )}

        <label htmlFor="priceRange">
          <h4>Price range</h4>
        </label>
        <div className="description">
          The relative price range of a business, commonly specified by either a
          numerical range (for example, "$10-15") or a normalized number of
          currency signs (for example, "$$$"). Leave this blank if not
          applicable.
        </div>
        <input
          type="text"
          id="lbpriceRange"
          name="priceRange"
          value={schemaJSON["priceRange"]}
          onChange={this.handleChange}
        />

        {this.displayHoursSection()}
      </div>
    );
  }
}
