import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";

// Components
import Account from "./Account";
import DomainSelector from "./DomainSelector";

export default class Header extends Component {
  render() {
    return (
      <div id="site-header">
        {this.props.title && <h1>{this.props.title}</h1>}
        {this.props.domainSelector && (
          <DomainSelector currentDomain={this.props.currentDomain} />
        )}

        <Account />
      </div>
    );
  }
}
