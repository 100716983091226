import React, { Component } from "react";
import moment from "moment";

export default class BlogPosting extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="article">
        {schemaObject["image"] && (
          <div className="featured-image">
            <img src={schemaObject["image"]} alt={schemaObject["headline"]} />
          </div>
        )}

        <div className="text">
          <div className="title">{schemaObject["headline"]}</div>

          {schemaObject["datePublished"] && (
            <div className="date">
              {moment(schemaObject["datePublished"]).fromNow()}
            </div>
          )}
        </div>
      </div>
    );
  }
}
