import React, { Component } from "react";
//import axios from "axios";
//import Cookies from "universal-cookie";
//const cookies = new Cookies();

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    window.location.href = "/settings/account";
    document.title = "Settings | Schema Helper";
  };

  render() {
    return (
      <div id="settings" className="content-window">
        <div id="site-header">
          <h1>Manage Account</h1>
        </div>

        <div className="components"></div>
      </div>
    );
  }
}
