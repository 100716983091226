import React, { Component } from "react";

export default class Course extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="course">
        <div className="text">
          <div className="title">{schemaObject["name"]}</div>
          <div className="description">{schemaObject["description"]}</div>
          <div className="organization">{schemaObject["provider"]["name"]}</div>
        </div>
      </div>
    );
  }
}
