import React, { Component } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./breadcrumbs.json");
const UTIL = require("../util");

export default class BreadcrumbList extends Component {
  constructor(props) {
    super(props);

    this.breadcrumbs = [];

    this.state = {
      showBreadcrumbs: this.breadcrumbs,
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "BreadcrumbList"
      );

      await this.generateInputFields();
    } else {
      this.props.setSchema(schemaJSON); // load default schema
    }

    await this.promisedSetState(this.state); // force update
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    schemaJSON.itemListElement.map((breadcrumb, index) => {
      $("#name-" + index).val(breadcrumb["name"]);
      $("#item-" + index).val(breadcrumb["item"]);
      $("#position-" + index).val(breadcrumb["position"]);
      return 1;
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    // if there is a parent object with the attribute "data-parentname" and it is a review
    if (parentName === "itemListElement") {
      const breadcrumbNum = e.target.getAttribute("data-breadcrumb-number");
      const subParentName = e.target.getAttribute("data-subparentname");

      if (subParentName) {
        schemaJSON[parentName][breadcrumbNum][subParentName][name] = value;
      } else {
        schemaJSON[parentName][breadcrumbNum][name] = value;
      }
    } else if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = value;
    else if (parentName) schemaJSON[parentName][name] = value;
    else schemaJSON[name] = value;

    // update session schema
    await this.promisedSetState({ schema: schemaJSON });
    await this.props.setSchema(this.state.schema);
  };

  breadcrumbFields = (counter) => {
    return (
      <div className="breadcrumb-fields mb-20" key={counter}>
        <h5>Breadcrumb Item #{counter + 1}</h5>

        <div className="flex-container">
          <div className="col-2">
            <label>
              <h4>Name</h4>
            </label>

            <input
              type="text"
              id={"name-" + counter}
              name="name"
              data-parentname="itemListElement"
              data-breadcrumb-number={counter}
              placeholder="Name"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-2">
            <label>
              <h4>Position #</h4>
            </label>
            <input
              type="number"
              id={"position-" + counter}
              name="position"
              data-parentname="itemListElement"
              data-breadcrumb-number={counter}
              placeholder="Position #"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <label className="d-flex align-center">
          <h4>Item / URL</h4>
          <InfoIcon
            data-for="item-url-tip"
            data-tip="The URL of the webpage that represents the breadcrumb."
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="item-url-tip" />
        </label>

        <input
          type="text"
          id={"item-" + counter}
          name="item"
          data-parentname="itemListElement"
          data-breadcrumb-number={counter}
          placeholder="Item"
          onChange={this.handleChange}
        />

        <div
          className="link c-teal h-c-midnightblue"
          onClick={() => this.removeBreadcrumb(counter)}
        >
          Remove Breadcrumb
        </div>
      </div>
    );
  };

  addBreadcrumb = async () => {
    let counter = this.breadcrumbs.length;
    let breadcrumb = {
      "@type": "ListItem",
      position: 1,
      name: "Award Winners",
    };

    this.breadcrumbs.push(this.breadcrumbFields(counter));

    this.promisedSetState({
      showBreadcrumbs: this.breadcrumbs,
    });

    // add a new faq object to the array of faqs
    schemaJSON["itemListElement"].push(breadcrumb);

    // update session schema
    this.promisedSetState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeBreadcrumb = async (indexToRemove) => {
    let breadcrumbArray = schemaJSON["itemListElement"];

    breadcrumbArray = breadcrumbArray.filter(
      (breadcrumb, index) => index !== indexToRemove
    );
    schemaJSON["itemListElement"] = breadcrumbArray;

    // update session schema
    await this.generateInputFields();
    await this.promisedSetState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  generateInputFields = async () => {
    this.breadcrumbs = [];

    schemaJSON.itemListElement.map((breadcrumb, counter) =>
      this.breadcrumbs.push(this.breadcrumbFields(counter))
    );
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6079657)}
          >
            Learn More
          </span>
        </Typography>

        <div className="breadcrumb-items">
          <h4>Breadcrumb Items</h4>

          <div className="breadcrumb-fields-container">{this.breadcrumbs}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addBreadcrumb}
          >
            Add Breadcrumb
          </button>

          <br />
          <br />
        </div>
      </div>
    );
  }
}
