import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
//import $ from "jquery";

// Components
import SchemaSuggestion from "./suggest-schema";
import AdvancedInput from "./advancedInput";

//const HELPER = require("../helperFunctions");
const { SCHEMAS } = require("../../../schemas/index");
const FREE_SCHEMA_GATE = [
  "Article",
  "BlogPosting",
  "WebSite",
  "FAQPage",
  "AggregateRating",
  "Event",
  "LocalBusiness",
  "Person",
];

export default class UserInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      package: "",
      customSchema: false,

      selectorToggle: false,
      selectorClosed: false,
      selectorSearchValue: "",
    };
  }
  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    setTimeout(function () {
      //HELPER.schemaSelector();
    }, 1000);
  };

  toggleCustomSchema = async () => {
    await this.promisedSetState({ customSchema: !this.state.customSchema });
    this.props.setCustomState("schema", {});
  };

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  setSearchTerm = debounce(async (selectorSearchValue) => {
    await this.promisedSetState({ selectorSearchValue });
  }, 500);

  hasAccess = (schema) => {
    let plan = "";
    if (this.props.state.accountHolderSubscription)
      plan = this.props.state.accountHolderSubscription;
    else plan = JSON.parse(localStorage.getItem("accountLimits"))[0].package;

    if (plan === "Free") return FREE_SCHEMA_GATE.includes(schema);
    else return true;
  };

  resetButton = () => {
    return (
      <button
        className={
          (this.props.state.selectedSchema ? "" : "disabled ") +
          "btn medium c-white bg-teal b-teal"
        }
        onClick={
          this.props.state.selectedSchema ? this.props.reset : this.nothing
        }
        data-for="reset"
        data-tip="Resets the editor. Removes all selected schemas."
      >
        Reset
        <ReactTooltip id="reset" />
      </button>
    );
  };

  selectSchema = async (schema) => {
    if (SCHEMAS.includes(schema)) {
      await this.props.selectSchema(schema);
      await this.props.addSchema(schema);
      document.getElementById("schemaSelector").value = "";
    } else if (schema === "Custom") this.toggleCustomSchema();
  };

  appendSchema = async (event) => {
    let selectedSchema = event.target.getAttribute("data-value");
    await this.props.selectSchema(selectedSchema);
    await this.props.addSchema(selectedSchema);
    await this.setState({
      selectorSearchValue: "",
      selectorToggle: false,
    });
  };

  displaySchemaSelection = () => {
    return (
      <div className="schema-selection">
        <label className="mb-10">
          <h3>Select a schema</h3>
        </label>

        <div className="d-flex align-center">
          <div
            className={
              "dropdown-select half dropdown-select " +
              (this.state.selectorToggle && "open")
            }
            tabIndex="0"
          >
            <div
              className="current"
              onClick={async () => {
                await this.toggle("selectorToggle");
              }}
            >
              - Please select -
            </div>

            <div className="list">
              <div className="dd-search">
                <input
                  id="txtSearchValue"
                  autoComplete="off"
                  placeholder="Search for a Schema"
                  className="dd-searchbox"
                  type="text"
                  onChange={(e) => {
                    this.setSearchTerm(e.target.value);
                  }}
                />
              </div>

              <ul>
                <li
                  className="option selected"
                  data-value=""
                  data-display-text=""
                  tabIndex="0"
                >
                  - Please select -
                </li>

                <li
                  className="option"
                  data-value="custom"
                  onClick={this.toggleCustomSchema}
                >
                  Other (Custom)
                </li>

                {SCHEMAS.map((schema) => {
                  if (
                    schema
                      .toLowerCase()
                      .includes(this.state.selectorSearchValue.toLowerCase())
                  ) {
                    return (
                      <li
                        className={
                          "option" + (this.hasAccess(schema) ? "" : " disabled")
                        }
                        key={schema}
                        data-value={schema}
                        data-display-text=""
                        onClick={
                          this.hasAccess(schema)
                            ? this.appendSchema
                            : this.doNothing
                        }
                      >
                        {schema}
                      </li>
                    );
                  } else return "";
                })}
              </ul>
            </div>
          </div>

          <div className="buttons ml-20">
            {!this.props.state.editSchema && this.resetButton()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="user-input">
        <div className="form">
          <div className="selection">
            {this.props.state.pageDetails.absolute_url &&
              !this.props.state.schemaDetected &&
              this.props.state.selectedSchemas.length === 0 && (
                <div
                  className="suggestion-container"
                  data-for="schema-suggestion-info-icon"
                  data-tip="Calculated based off of keywords found on your page. Think otherwise? Let us know by clicking the messenger icon, tell us which schema you think it should be, and provide us a link to your live webpage."
                >
                  <SchemaSuggestion
                    url={this.props.state.pageDetails.absolute_url}
                    pageType={this.props.state.pageDetails.analytics_page_type}
                  />
                  <ReactTooltip id="schema-suggestion-info-icon" />
                </div>
              )}

            {this.state.customSchema ? (
              <div className="advanced">
                <button
                  className="btn medium c-white bg-teal b-teal"
                  onClick={async () => {
                    await this.promisedSetState({ customSchema: false });
                  }}
                >
                  Back
                </button>

                <AdvancedInput
                  setSchema={this.props.setSchema}
                  state={this.props.state}
                />
              </div>
            ) : (
              this.displaySchemaSelection()
            )}
          </div>
        </div>
      </div>
    );
  }
}
