import React, { Component } from "react";
import $ from "jquery";
import { Store } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./schema.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

const THING = [
  "Action",
  "BioChemEntity",
  "CreativeWork",
  "Event",
  "Intangible",
  "MedicalEntity",
  "Organization",
  "Person",
  "Place",
  "Product",
  "Taxon",
];

export default class WebPage extends Component {
  constructor(props) {
    super(props);

    this.about = [];
    this.mentions = [];

    this.state = {
      name: "",
      url: "",
      schema: schemaJSON,
      showAbout: this.about,
      showMentions: this.mentions,
    };
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "WebPage"
      );
    }

    await this.generateInputFields();
    this.props.setSchema(schemaJSON);
    this.jQueryScripts();
    this.setState(this.state); // force update
  };

  jQueryScripts = () => {
    if ("about" in schemaJSON)
      schemaJSON.about.map((aboutItem, index) => {
        $("#aboutType-" + index).val(aboutItem["@type"]);
        $("#aboutName-" + index).val(aboutItem["name"]);
        $("#aboutSameAs-" + index).val(aboutItem["sameAs"]);
        return 1;
      });

    if ("mentions" in schemaJSON)
      schemaJSON.mentions.map((mentionItem, index) => {
        $("#mentionType-" + index).val(mentionItem["@type"]);
        $("#mentionName-" + index).val(mentionItem["name"]);
        $("#mentionSameAs-" + index).val(mentionItem["sameAs"]);
        return 1;
      });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");

    if (parentname === "about") {
      const number = e.target.getAttribute("data-about-number");

      schemaJSON[parentname][number][name] = UTIL.cleanText(value);
    } else if (parentname === "mentions") {
      const number = e.target.getAttribute("data-mention-number");

      schemaJSON[parentname][number][name] = UTIL.cleanText(value);
    } else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  aboutFields = (counter, item) => {
    return (
      <div className="about-fields repeater-container" key={counter}>
        <h5>About Item #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeAbout(counter)}
        >
          Remove
        </span>

        <label htmlFor="aboutType">
          <h4>Type</h4>
        </label>

        <select
          className="col-2"
          id={"aboutType-" + counter}
          name="@type"
          data-parentname="about"
          data-about-number={counter}
          onChange={this.handleChange}
          value={item?.['@type']}
        >
          <option value="Thing">Thing</option>
          {THING.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        <label>Name</label>
        <input
          type="text"
          id={"aboutName-" + counter}
          name="name"
          data-parentname="about"
          data-about-number={counter}
          onChange={this.handleChange}
          value={item?.name}
        />

        <label>sameAs</label>
        <input
          type="text"
          id={"aboutSameAs-" + counter}
          name="sameAs"
          data-parentname="about"
          data-about-number={counter}
          onChange={this.handleChange}
          value={item?.sameAs}
        />
      </div>
    );
  };

  addAbout = async () => {
    let counter = this.about.length;
    let aboutObject = {
      "@type": "Thing",
      name: "",
    };

    // Add about key if it does exist in schema yet
    if (!("about" in schemaJSON)) schemaJSON["about"] = [];

    this.about.push(this.aboutFields(counter));

    this.setState({
      showAboutFields: this.aboutObject,
    });

    // add a new about object
    schemaJSON["about"].push(aboutObject);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeAbout = async (aboutNumber) => {
    // remove about from about array
    this.about.splice(aboutNumber, 1);
    schemaJSON.about.splice(aboutNumber, 1);

    // update
    await this.setState({
      showAbout: this.about,
      schema: schemaJSON,
    });
    this.props.setSchema(this.state.schema);

    Store.addNotification({
      message: "About item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  mentionFields = (counter, item) => {
    return (
      <div className="mention-fields repeater-container" key={counter}>
        <h5>Mention Item #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeMentions(counter)}
        >
          Remove
        </span>

        <label htmlFor="mentionType">
          <h4>Type</h4>
        </label>

        <select
          className="col-2"
          id={"mentionType-" + counter}
          name="@type"
          data-parentname="mentions"
          data-mention-number={counter}
          onChange={this.handleChange}
          value={item?.['@type']}
        >
          <option value="Thing">Thing</option>
          {THING.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        <label>Name</label>
        <input
          type="text"
          id={"mentionName-" + counter}
          name="name"
          data-parentname="mentions"
          data-mention-number={counter}
          onChange={this.handleChange}
          value={item?.name}
        />

        <label>sameAs</label>
        <input
          type="text"
          id={"mentionSameAs-" + counter}
          name="sameAs"
          data-parentname="mentions"
          data-mention-number={counter}
          onChange={this.handleChange}
          value={item?.sameAs}
        />
      </div>
    );
  };

  addMention = async () => {
    let counter = this.mentions.length;
    let mentionObject = {
      "@type": "Thing",
      name: "",
    };

    // Add about key if it does exist in schema yet
    if (!("mentions" in schemaJSON)) schemaJSON["mentions"] = [];

    this.mentions.push(this.mentionFields(counter));

    this.setState({
      showMentions: this.mentions,
    });

    // add a new about object
    schemaJSON["mentions"].push(mentionObject);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeMentions = async (mentionsNumber) => {
    // remove mention from mentions array
    this.mentions.splice(mentionsNumber, 1);
    schemaJSON.mentions.splice(mentionsNumber, 1);

    // update
    await this.setState({
      showMentions: this.mentions,
      schema: schemaJSON,
    });
    this.props.setSchema(this.state.schema);

    Store.addNotification({
      message: "Mention item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  generateInputFields = async () => {
    this.about = [];
    this.mentions = [];

    if ("about" in schemaJSON)
      schemaJSON.about.map((item, counter) =>
        this.about.push(this.aboutFields(counter, item))
      );

    if ("mentions" in schemaJSON)
      schemaJSON.mentions.map((item, counter) =>
        this.mentions.push(this.mentionFields(counter, item))
      );
  };

  render() {
    return (
      <div id="webpage-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6076934)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Page name</h4>
        </label>
        <input
          type="text"
          id="page-name"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>URL</h4>
        </label>
        <input
          type="text"
          id="url"
          name="url"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <label>
          <h4>Description</h4>
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <div className="about-items">
          <div className="d-flex align-center">
            <h4>About</h4>
            <InfoIcon
              data-for="about-tip"
              data-tip="The subject matter of the content. The theory is, you are raising this topic’s relevance above the others found on the page. This can help machine learning to understand the content hierarchy."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="about-tip" />
          </div>

          <div className="about-fields-container">{this.about}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addAbout}
          >
            Add About
          </button>
        </div>

        <div className="mention-items">
          <div className="d-flex align-center">
            <h4>Mentions</h4>
            <InfoIcon
              data-for="mention-tip"
              data-tip="This helps to tell machines that the content refers to these topics, but they are not the main subject of the content. This could help to “connect the dots” between ideas and topics, showing the close relationship between a bunch of topics."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="mention-tip" />
          </div>

          <div className="mention-fields-container">{this.mentions}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addMention}
          >
            Add Mention
          </button>
        </div>
      </div>
    );
  }
}
