import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";
//import { debounce } from "lodash";

// Helper functions
// const DATABASE_API = require("../../api/db-functions");

export default class Progress extends Component {
  render() {
    return (
      <div className="retreiving-data">
        <div className="progress">
          <div className="progress__item--inf-prog"></div>
        </div>
        <div className="progress-label">{this.props.state.progressStatus}</div>
      </div>
    );
  }
}
