import React, { Component } from "react";
import $ from "jquery";
import { Store } from "react-notifications-component";
import { Typography } from "@mui/material";

let schemaJSON = require("./faq.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class FAQPage extends Component {
  constructor(props) {
    super(props);

    this.faqFields = [];

    this.state = {
      showFaqFields: this.faqFields,
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "FAQPage"
      );

      this.generateInputFields();
    } else {
      // load default schema
      this.props.setSchema(schemaJSON);
    }

    await this.promisedSetState({ pageDetails: this.props.state.pageDetails });
    //console.log("page details", this.state.pageDetails);
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    schemaJSON.mainEntity.map((faqItem, index) => {
      $("#question-" + index).val(faqItem["name"]);
      $("#answer-" + index).val(faqItem["acceptedAnswer"]["text"]);
      return 1;
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    // if there is a parent object with the attribute "data-parentname" and it is a review
    if (parentName === "mainEntity") {
      const faqNumber = e.target.getAttribute("data-faq-number");
      const subParentName = e.target.getAttribute("data-subparentname");

      if (subParentName) {
        schemaJSON[parentName][faqNumber][subParentName][name] =
          UTIL.cleanText(value);
      } else {
        schemaJSON[parentName][faqNumber][name] = UTIL.cleanText(value);
      }
    } else if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = UTIL.cleanText(value);
    else if (parentName) schemaJSON[parentName][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.promisedSetState({ schema: schemaJSON });
    await this.props.setSchema(this.state.schema);

    //console.log(this.state.schema);
  };

  removeFaq = async (faqNumber) => {
    // remove faq from faq array
    this.faqFields.splice(faqNumber, 1);
    schemaJSON.mainEntity.splice(faqNumber, 1);

    // update
    await this.promisedSetState({
      showFaqFields: this.faqFields,
      schema: schemaJSON,
    });
    this.props.setSchema(this.state.schema);

    Store.addNotification({
      message: "FAQ item removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  addFaq = async () => {
    let counter = this.faqFields.length;
    let faqObject = {
      "@type": "Question",
      name: "",
      acceptedAnswer: {
        "@type": "Answer",
        text: "",
      },
    };

    this.faqFields.push(
      <div className="faq-fields" key={counter}>
        <h5>FAQ Item #{counter + 1}</h5>

        <span
          className="remove-item c-teal"
          onClick={() => this.removeFaq(counter)}
        >
          Remove
        </span>

        <input
          type="text"
          id={"question-" + counter}
          name="name"
          data-parentname="mainEntity"
          data-faq-number={counter}
          placeholder="Question"
          onChange={this.handleChange}
        />

        <textarea
          id={"answer-" + counter}
          name="text"
          data-parentname="mainEntity"
          data-subparentname="acceptedAnswer"
          data-faq-number={counter}
          placeholder="Answer"
          onChange={this.handleChange}
        />
      </div>
    );

    this.promisedSetState({
      showFaqFields: this.faqObject,
    });

    // add a new faq object to the array of faqs
    schemaJSON["mainEntity"].push(faqObject);

    // update session schema
    this.promisedSetState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  generateInputFields = () => {
    schemaJSON.mainEntity.map((faqItem, index) =>
      this.faqFields.push(
        <div className="faq-fields" key={index}>
          <h5>FAQ Item #{index + 1}</h5>

          <span
            className="remove-item c-teal"
            onClick={() => this.removeFaq(index)}
          >
            Remove
          </span>

          <input
            type="text"
            id={"question-" + index}
            name="name"
            data-parentname="mainEntity"
            data-faq-number={index}
            placeholder="Question"
            onChange={this.handleChange}
          />

          <textarea
            id={"answer-" + index}
            name="text"
            data-parentname="mainEntity"
            data-subparentname="acceptedAnswer"
            data-faq-number={index}
            placeholder="Answer"
            onChange={this.handleChange}
          />
        </div>
      )
    );
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6082106)}
          >
            Learn More
          </span>
        </Typography>

        <div className="faq-items">
          <h4>FAQ Items</h4>

          <div className="faq-fields-container">{this.faqFields}</div>

          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addFaq}
          >
            Add FAQ
          </button>
        </div>
      </div>
    );
  }
}
