import React, { Component } from "react";
//import axios from "axios";
//import Cookies from "universal-cookie";

//const cookies = new Cookies();

export default class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    this.props.unsetAuthenticated();
  };

  render() {
    return (
      <div id="logout" className="bg-midnightblue-2 ta-center full-height">
        <div className="container">
          <h2 className="c-white">You have been successfully logged out.</h2>
        </div>
      </div>
    );
  }
}
