import React, { Component } from "react";
import axios from "axios";
//import $ from "jquery";

import { Box, Grid, TextField } from "@mui/material";

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",

      userAlreadyExists: false,
      registrationSuccess: false,
      passwordMatch: true,

      acceptedTerms: false,
      displayTermsError: false,

      emptyFields: false,
      displayEmptyFieldsError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkForUser = this.checkForUser.bind(this);
    this.register = this.register.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  toggle = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  componentDidMount = async () => {
    //this.jQueryScripts();
  };

  jQueryScripts = () => {
    let seconds = 5,
      $seconds = document.querySelector("#countdown");
    (function countdown() {
      $seconds.textContent = seconds + " second" + (seconds === 1 ? "" : "s");
      if (seconds-- > 0) setTimeout(countdown, 1000);
    })();
  };

  handleTerms = async (event) => {
    await this.promisedSetState({ acceptedTerms: !this.state.acceptedTerms });

    if (this.state.displayTermsError)
      await this.promisedSetState({ displayTermsError: false });
  };

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });

    if (this.state.password !== "" && this.state.passwordConfirm !== "") {
      if (this.state.password === this.state.passwordConfirm)
        await this.promisedSetState({ passwordMatch: true });
      else await this.promisedSetState({ passwordMatch: false });
    }
  };

  handleSubmit = async (event) => {
    if (this.validateFields())
      if (this.state.acceptedTerms) {
        // check database for the user
        let userExists = await this.checkForUser();

        // if user does exist, notify user
        // if user does not exist, create new account
        if (userExists) this.promisedSetState({ userAlreadyExists: true });
        else if (this.state.passwordMatch) {
          let registrationSuccess = await this.register();
          if (registrationSuccess) {
            await this.promisedSetState({ registrationSuccess: true });
            this.sendUserHome();
          }
        }
      } else await this.promisedSetState({ displayTermsError: true });
    else await this.promisedSetState({ displayEmptyFieldsError: true });
  };

  validateFields = () => {
    if (
      this.state.username !== "" &&
      this.state.password !== "" &&
      this.state.passwordConfirm !== "" &&
      this.state.firstName !== "" &&
      this.state.lastName !== ""
    ) {
      return true;
    } else return false;
  };

  checkForUser = async () => {
    return axios
      .post("/api/get/user", {
        usernameToCheck: this.state.username.toLowerCase(),
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  register = async () => {
    return axios
      .post("/api/user/create", {
        username: this.state.username.toLowerCase(),
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  signUpForm = (e) => {
    return (
      <div id="registration-form">
        <div className="section-header">
          <div className="logo">
            <img src="/images/logo-white-full@2x.png" alt="logo" />
          </div>
          <h2 className="section-title">Register for an account</h2>
          <div className="section-copy"></div>
        </div>

        <Box>
          {this.state.userAlreadyExists && (
            <div className="error-label mb-20">
              An account with this email already exists. Try a different email.
            </div>
          )}

          {!this.state.passwordMatch && (
            <div className="error-label mb-20">Passwords do not match.</div>
          )}

          {this.state.displayTermsError && (
            <div className="error-label mb-20">
              You must accept the Terms of use to complete the registration
              process.
            </div>
          )}

          {this.state.displayEmptyFieldsError && (
            <div className="error-label mb-20">
              All fields must be filled out.
            </div>
          )}

          <Box mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="First name"
                  helperText=""
                  name="firstName"
                  onChange={this.handleChange}
                  aria-label="first name"
                  value={this.state.firstName}
                  fullWidth={true}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  data-testid="firstname"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="Last name"
                  helperText=""
                  name="lastName"
                  onChange={this.handleChange}
                  aria-label="last name"
                  value={this.state.lastName}
                  fullWidth={true}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  data-testid="lastname"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="Email address"
                  helperText=""
                  name="username"
                  onChange={this.handleChange}
                  aria-label="email address"
                  value={this.state.username}
                  fullWidth={true}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  data-testid="email"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  id="outlined-basic"
                  variant="outlined"
                  label="Password"
                  helperText=""
                  name="password"
                  onChange={this.handleChange}
                  aria-label="password"
                  value={this.state.password}
                  fullWidth={true}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  data-testid="password"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  id="outlined-basic"
                  variant="outlined"
                  label="Confirm password"
                  helperText=""
                  name="passwordConfirm"
                  onChange={this.handleChange}
                  aria-label="confirm password"
                  value={this.state.passwordConfirm}
                  fullWidth={true}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  data-testid="passwordConfirm"
                />
              </Grid>
            </Grid>
          </Box>

          <div className="row flex-container mt-20">
            <input
              type="checkbox"
              className=""
              name="terms"
              onClick={this.handleTerms}
              data-testid="terms"
            />
            <span>
              I agree to Schema Helper's{" "}
              <a
                href="https://schemahelper.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              .
            </span>
          </div>

          <div className="row mt-20">
            <input
              type="submit"
              className="btn medium c-black"
              value="Register"
              data-testid="submit"
              onClick={this.handleSubmit}
            />
          </div>
        </Box>

        <div className="other-links">
          <ul>
            <li>
              <a href="/recover" data-testid="recover">
                Forgot password
              </a>
            </li>
            <li>
              <a href="/">I already have an account</a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  registrationSuccess = (e) => {
    return (
      <div id="registration-success">
        <div className="section-header">
          <h2 className="section-title c-white">Thank you for registering!</h2>
          <div className="section-copy">
            Just one more step! Please check your email and click the link to
            verify your email.
          </div>

          <div className="countdown-container mt-20">
            <h3 className="c-white">
              You will be redirected to the login page in{" "}
              <span id="countdown"></span>
            </h3>
          </div>
        </div>
      </div>
    );
  };

  sendUserHome = () => {
    this.jQueryScripts();

    setTimeout(function () {
      window.location.href = "/";
    }, 5000);
  };

  render() {
    const signUpForm = this.signUpForm();
    const registrationSuccess = this.registrationSuccess();

    return (
      <div id="register" className="ta-center bg-midnightblue-2 c-white">
        <div className="container">
          {this.state.registrationSuccess ? registrationSuccess : signUpForm}
        </div>
      </div>
    );
  }
}
