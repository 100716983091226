import React, { Component } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

export default class PageHeadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <Dialog
        open={this.props.toggle}
        onClose={this.props.toggleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">Page Head Preview</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This is a preview of what the head of your page will look like once
            you finalize and publish your schema.
          </DialogContentText>

          <Box style={{ marginTop: "20px" }}>
            <textarea
              id="codePreview"
              className="code"
              value={this.props.code}
              readOnly
            ></textarea>
          </Box>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={this.props.toggleDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
