import React, { Component } from "react";
import axios from "axios";
import { Box } from "@mui/material";

/* Components / Views */
import Modal from "../../../../components/modal/modal";

export default class ManageAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalTitle: "",
      modalMessage: "",
      modalYesAction: null,
      confirmModalToggle: false,
    };

    this.confirmModalToggle = this.confirmModalToggle.bind(this);
    this.wipeAccount = this.wipeAccount.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    document.title = "Manage Account | Settings | Schema Helper";
  };

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  // update state
  updateState = async (key, value) => {
    await this.promisedSetState({
      [key]: value,
    });
  };

  confirmModalToggle = async () => {
    await this.promisedSetState({
      confirmModalToggle: !this.state.confirmModalToggle,
    });
  };

  confirmDelete = async () => {
    await this.promisedSetState({
      confirmModalToggle: true,
      modalTitle: "Are you sure you want to delete your account?",
      modalMessage:
        "All of your account information and data will be wiped. The schemas that you have already published will not be removed. Your subscription will also be canceled. This action cannot be undone.",
    });
  };

  wipeAccount = async () => {
    await this.promisedSetState({
      confirmModalToggle: false,
    });

    this.cancelSubscription();
    this.deleteUser();

    // log user out
    window.location.href = "/logout";
  };

  cancelSubscription = async () => {
    window.Intercom("trackEvent", "Canceled subscription");

    await axios
      .put("/api/stripe/cancelSubscription", {
        subscriptionID: JSON.parse(localStorage.getItem("subscription")).id,
      })
      .then(
        async (response) => {},
        (error) => {
          console.log(error);
        }
      );
  };

  deleteUser = async () => {
    window.Intercom("trackEvent", "User deleted their account");

    return axios
      .put("/api/user/remove", {
        username: localStorage.getItem("user"),
      })
      .then(
        async (response) => {
          return response.data.success;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "100px" }}
      >
        <Box style={{ width: "100%", maxWidth: 768 }}>
          <div className="section-title">
            <h2>Manage Account</h2>
          </div>

          <Box>
            <div className="delete c-red">
              <strong className="clickable" onClick={this.confirmDelete}>
                Delete Account
              </strong>
            </div>

            {this.state.confirmModalToggle && (
              <Modal
                title={this.state.modalTitle}
                message={this.state.modalMessage}
                yesAction={this.wipeAccount}
                toggle={this.confirmModalToggle}
                toggleState={this.state.confirmModalToggle}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}
