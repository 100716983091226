import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";
//import ReactTooltip from "react-tooltip";

import {
  Dialog,
  //DialogContent,
  //DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const defaultState = ["firstName", "lastName", "email", "role"];
const roles = ["Editor"];

export default class addUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    };

    this.userDomains = [];
    this.existingDomains = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    this.props.state.domains.map(
      async (domain, index) => await this.promisedSetState({ [domain]: false })
    );
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
  };

  handleDomainCheckboxChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  validateFields = () => {
    // if fields are not empty, return true
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.role !== ""
    )
      return true;
    else return false;
  };

  updateState = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  addUser = async () => {
    // check if user already exists
    // if user does not exist, add
    if (this.validateEmail())
      if (!this.userExists()) {
        await this.addNewUser();
        this.notifyUser();
      }
      // otherwise display warning
      else
        Store.addNotification({
          message: "A user already exists with the email " + this.state.email,
          type: "warning",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 4000,
            onScreen: false,
            showIcon: true,
          },
        });
  };

  validateEmail = () => {
    if (this.state.email === localStorage.getItem("user")) {
      Store.addNotification({
        message: "You cannot add yourself as a user",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: false,
          showIcon: true,
        },
      });
      return false;
    } else return true;
  };

  userExists = () => {
    let foundMember = this.props.state.members.filter((member) => {
      return member.username === this.state.email;
    });

    if (foundMember.length > 0) return true;
    else return false;
  };

  validateDomains = () => {
    let shadowState = { ...this.state };
    let domainMatchCounter = 0;
    let selectedDomains = [];

    defaultState.forEach((state) => {
      if (state in shadowState) delete shadowState[state];
    });

    // if domain was selected to give access to
    for (const domain in shadowState)
      if (shadowState[domain] === true) selectedDomains.push(domain);

    // if specified user already has domain connected, increment counter
    selectedDomains.forEach((domain) => {
      if (JSON.stringify(this.userDomains).includes(domain)) {
        this.existingDomains.push(domain);
        domainMatchCounter++;
      }
    });

    if (domainMatchCounter > 0) return false;
    else return true;
  };

  getUserDomains = async () => {
    await axios
      .post("/api/get/user", {
        usernameToCheck: this.state.email,
      })
      .then(
        (response) => {
          if ("domains" in response.data)
            this.userDomains = response.data.data.domains;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };

  domainErrorNotification = () => {
    Store.addNotification({
      title: "Could not update user",
      message:
        "This user already has one or more matching domains already connected. Click here to learn how to resolve this issue.",
      type: "danger",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 0,
        onScreen: false,
        showIcon: true,
      },
      onRemoval: (id, removedBy) => {
        window.Intercom("showArticle", 6195505);
      },
    });
  };

  addNewUser = async () => {
    // Get domains
    let domains = [];
    let domainNames = [];

    for (const property in this.state) {
      if (!defaultState.includes(property)) {
        const domain = { [property]: this.state[property] };
        domains.push(domain);

        // if domain value is set to true (allowed), then add domain to domainNames array
        if (this.state[property]) domainNames.push(property);
      }
    }

    await this.promisedSetState({ domainNames: domainNames });

    let memberObject = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.email,
      //team: String,
      role: this.state.role,
      domains: domains,
    };

    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: {
          $push: { members: memberObject },
        },
      })
      .then(
        (response) => {
          if (response.data.success) {
            Store.addNotification({
              message:
                this.state.firstName +
                " " +
                this.state.lastName +
                " has been added.",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
          } else
            Store.addNotification({
              message:
                this.state.firstName +
                " " +
                this.state.lastName +
                " could not be added.",
              type: "warning",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
          return response.data.success;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };

  notifyUser = async () => {
    let domainString = "";

    this.state.domainNames.forEach((domain) => {
      domainString += `\n‣ ${domain}`;
    });

    await axios
      .put("/api/email/send", {
        user: this.state.email,
        subject: "Schema Helper: You have been added to a domain",

        html:
          "<p>Hey " +
          this.state.firstName +
          ",</p><p>You have just been added as a user by " +
          localStorage.getItem("user") +
          ".</p><p>" +
          (domainString ? "You now have access to:\n" + domainString : "") +
          "</p><p><a href='https://app.schemahelper.com'>Sign up / Sign in</a> to get started or <a href='https://info.schemahelper.com/en/articles/6207030-i-ve-been-added-as-a-user-to-a-domain-what-now'>learn more</a. about what it means to be given access to domains.</p>",
      })
      .then(
        (response) => {
          //console.log(response);
          //return response.data.success;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };

  render() {
    return (
      <Dialog
        open={this.props.toggleState}
        onClose={this.props.toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        p={2}
      >
        <DialogTitle id="alert-dialog-title" className="ta-center">
          Add a user
        </DialogTitle>

        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <TextField
              required
              name="firstName"
              aria-label="First name"
              onChange={this.handleChange}
              value={this.state.firstName}
              fullWidth={true}
              label="First name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              name="lastName"
              onChange={this.handleChange}
              aria-label="Last name"
              value={this.state.lastName}
              fullWidth={true}
              label="Last name"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <TextField
              required
              name="email"
              aria-label="Email"
              onChange={this.handleChange}
              value={this.state.email}
              fullWidth={true}
              label="Email address"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="role"
              name="role"
              select
              onChange={this.handleChange}
              fullWidth={true}
              value={this.state.role}
              label="Role"
            >
              {roles.map((role) => (
                <MenuItem value={role.toLowerCase()} key={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={12}>
            <Typography gutterBottom={true}>Domain access</Typography>

            {this.props.state.domains.map((domain, index) => (
              <FormControlLabel
                key={domain}
                control={
                  <Checkbox
                    checked={this.state[domain]}
                    onChange={this.handleDomainCheckboxChange}
                    name={domain}
                    color="primary"
                  />
                }
                label={domain}
              />
            ))}
          </Grid>
        </Grid>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={
              this.validateFields()
                ? { root: "primary" }
                : { root: "primary disabled" }
            }
            onClick={async () => {
              if (this.validateFields()) {
                await this.getUserDomains();

                if (this.validateDomains()) {
                  await this.addUser();
                  await this.props.getAccounts();
                  this.props.toggle();
                } else this.domainErrorNotification();
              }
            }}
          >
            Add
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={this.props.toggle}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
