import React, { Component } from "react";

import {
  //Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

export default class BulkEditorProgressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  bulkEditorCompletionChecker = (selectedPages) => {
    if (this.props.completedProgress >= selectedPages.length) return true;
    else return false;
  };

  render() {
    return (
      <Dialog
        open={this.props.toggleState}
        //onClose={this.bulkEditProgressToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          {this.bulkEditorCompletionChecker(this.props.selectedPages)
            ? "Schema generation complete"
            : "Please wait"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.bulkEditorCompletionChecker(this.props.selectedPages)
              ? "Schema generation is complete. Please review the pages to make sure everything looks right."
              : "Schema generation in progress. Please wait for the entire process to complete."}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {this.bulkEditorCompletionChecker(this.props.selectedPages)
              ? ""
              : this.props.completedProgress +
                " of " +
                this.props.selectedPages.length}
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ marginTop: 2 }}
          >
            <span
              className={
                (this.bulkEditorCompletionChecker(this.props.selectedPages) &&
                  "load-complete ") + " circle-loader"
              }
              style={{ width: "500" }}
            >
              <span className="checkmark draw"></span>
            </span>
          </DialogContentText>
        </DialogContent>
        {this.bulkEditorCompletionChecker(this.props.selectedPages) ? (
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              classes={{
                root: "primary",
              }}
              onClick={() => {
                this.props.toggle("bulkEditProgressToggle");
                this.props.clearSelectedPages();
              }}
            >
              Close
            </Button>
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    );
  }
}
