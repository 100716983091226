import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./claimReview.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class ClaimReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "ClaimReview"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="claim-review-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6079314)}
          >
            Learn More
          </span>
        </Typography>

        <div className="form-group">
          <label>
            <h4>Claim</h4>
          </label>
          <div className="description">
            A short summary of the claim being evaluated. Try to keep this less
            than 75 characters to minimize wrapping when displayed on a mobile
            device.
          </div>
          <input
            type="text"
            id="claimReviewed"
            name="claimReviewed"
            onChange={this.handleChange}
            value={schemaJSON["claimReviewed"]}
          />

          <label>
            <h4>Publish date</h4>
          </label>
          <input
            type="date"
            id="datePublished"
            name="datePublished"
            onChange={this.handleChange}
            value={schemaJSON["datePublished"]}
          />

          <label>
            <h4>URL</h4>
          </label>
          <div className="description">
            Link to the page hosting the full article of the fact check. If the
            page has multiple ClaimReview elements, be sure that the fact check
            has an HTML anchor, and this property points to that anchor.
            Examples: http://example.com/longreview.html or
            http://example.com/summarypage.html#fact1.
          </div>
          <input
            type="text"
            id="url"
            name="url"
            onChange={this.handleChange}
            value={schemaJSON["url"]}
          />

          <label>
            <h4>Organization name</h4>
          </label>
          <div className="description">
            The publisher of the fact check article, not the publisher of the
            claim. The author must be an organization or a person.
          </div>
          <input
            type="text"
            id="authorName"
            name="name"
            onChange={this.handleChange}
            value={schemaJSON["author"]["name"]}
          />

          <label>
            <h4>Ratings</h4>
          </label>
          <div className="description">
            The publisher of the fact check article, not the publisher of the
            claim. The author must be an organization or a person.
          </div>
          <br />

          <div className="flex-container">
            <span className="col-3">
              <label>
                <h4>Rating value</h4>
              </label>
              <input
                type="number"
                id="ratingValue"
                name="ratingValue"
                data-parentname="reviewRating"
                placeholder="Rating value"
                value={schemaJSON["reviewRating"]["ratingValue"]}
                onChange={this.handleChange}
              />
            </span>
            <span className="col-3">
              <label>
                <h4>Best rating value</h4>
              </label>
              <input
                type="number"
                id="bestRating"
                name="bestRating"
                data-parentname="reviewRating"
                placeholder="Best rating value"
                value={schemaJSON["reviewRating"]["bestRating"]}
                onChange={this.handleChange}
              />
            </span>
            <span className="col-3">
              <label>
                <h4>Worst rating value</h4>
              </label>
              <input
                type="number"
                id="worstRating"
                name="worstRating"
                data-parentname="reviewRating"
                placeholder="Worst rating value"
                value={schemaJSON["reviewRating"]["worstRating"]}
                onChange={this.handleChange}
              />
            </span>
          </div>

          <label>
            <h4>Truthfulness</h4>
          </label>
          <div className="description">
            The truthfulness rating assigned to ClaimReview.reviewRating, as a
            human-readible short word or phrase. This value is displayed in the
            fact check in search results. Examples: "True" or "Mostly true".
            <br />
            <br />
            If using a longer sentence, be sure that the beginning of the
            sentence expresses the meaning, in case the sentence is truncated to
            fit the display. For example: "Mostly true in the specifics,
            although the overall claim is somewhat misleading"
          </div>
          <input
            type="text"
            id="alternateName"
            name="alternateName"
            onChange={this.handleChange}
            value={schemaJSON["reviewRating"]["alternateName"]}
          />
        </div>

        <div className="form-group">
          <label>
            <h4>Claimed by</h4>
          </label>
          <div className="description">
            The publisher of the claim. The publisher can be a{" "}
            <strong>Person</strong> or <strong>Organization</strong>.
          </div>
          <input
            type="text"
            id="claimType"
            name="@type"
            data-parentname="itemReviewed"
            data-subparentname="author"
            onChange={this.handleChange}
            value={schemaJSON["itemReviewed"]["author"]["@type"]}
          />

          <label>
            <h4>Author of the claim</h4>
          </label>
          <div className="description">
            The author of the claim, not the author of the fact check.
          </div>
          <input
            type="text"
            id="claimAuthorName"
            name="name"
            data-parentname="itemReviewed"
            data-subparentname="author"
            onChange={this.handleChange}
            value={schemaJSON["itemReviewed"]["author"]["name"]}
          />

          <label>
            <h4>Same as</h4>
          </label>
          <div className="description">
            Indicates the party that is making the claim, regardless of whether
            the party is a Person or Organization. The URL can be:
            <ul>
              <li>
                The homepage of the organization that is making the claim.
              </li>
              <li>
                Another definitive URL that provides information about the party
                that is making the claim, such as a person or organization's
                Wikipedia or Wikidata entry.
              </li>
            </ul>
          </div>
          <input
            type="text"
            id="claimSameAs"
            name="sameAs"
            data-parentname="itemReviewed"
            data-subparentname="author"
            onChange={this.handleChange}
            value={schemaJSON["itemReviewed"]["author"]["sameAs"]}
          />

          <label>
            <h4>Claim publish date</h4>
          </label>
          <input
            type="date"
            id="claimDatePublished"
            name="datePublished"
            data-parentname="itemReviewed"
            onChange={this.handleChange}
            value={schemaJSON["itemReviewed"]["datePublished"]}
          />

          <label>
            <h4>Claim appearance</h4>
          </label>
          <div className="description">
            A link to or inline description in which this claim appears.
          </div>
          <input
            type="text"
            id="claimAppearance"
            name="appearance"
            data-parentname="itemReviewed"
            onChange={this.handleChange}
            value={schemaJSON["itemReviewed"]["appearance"]}
          />
        </div>
      </div>
    );
  }
}
