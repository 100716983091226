import React, { Component } from "react";

import { Box, Paper, TextField } from "@mui/material";

export default class ArticlePublisher extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "45px" }}
      >
        <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
          <Box className="section" sx={{ p: 3 }}>
            <h3>Article Publisher</h3>

            <br />

            <Box mb={4}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Featured Image"
                helperText="This is your fallback featured image. If your article doesn't
           already have a featured image set, it will use this."
                name="articleFeaturedImage"
                onChange={this.props.handleChange}
                aria-label="article featured image"
                value={this.props.state.articleFeaturedImage}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Box>

            <Box mb={4}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Author / Publisher"
                helperText="Your default author or publisher name"
                name="articlePublisher"
                onChange={this.props.handleChange}
                aria-label="article author"
                value={this.props.state.articlePublisher}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Box>

            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Logo URL"
                helperText="Your logo should be 60x60"
                name="articlePublisherLogoURL"
                onChange={this.props.handleChange}
                aria-label="article logo"
                value={this.props.state.articlePublisherLogoURL}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}
