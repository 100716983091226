import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";
//import ReactTooltip from "react-tooltip";

import {
  Dialog,
  //DialogContent,
  //DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const defaultState = [
  "firstName",
  "lastName",
  "email",
  "role",
  "allowedAccess",
  "lostAccess",
  "domainAccessHasChanges",
];

const roles = ["Editor"];

export default class editUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",

      allowedAccess: [],
      lostAccess: [],
      domainAccessHasChanges: false,
    };

    this.userDomains = [];
    this.existingDomains = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    this.props.state.domains.map(
      async (domain, index) => await this.promisedSetState({ [domain]: false })
    );

    // set values based on user
    await this.promisedSetState({
      firstName: this.props.state.editUserFields.firstName,
      lastName: this.props.state.editUserFields.lastName,
      email: this.props.state.editUserFields.email,
      role: this.props.state.editUserFields.role,
    });

    this.props.state.editUserFields.domains.forEach(async (domain) => {
      for (const property in domain) {
        await this.promisedSetState({ [property]: domain[property] });
      }
    });
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
  };

  handleDomainCheckboxChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  validateFields = () => {
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.role !== ""
    )
      return true;
    else return false;
  };

  validateEmail = () => {
    if (this.state.email === localStorage.getItem("user")) {
      Store.addNotification({
        message: "You cannot use your own email",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: false,
          showIcon: true,
        },
      });
      return false;
    } else return true;
  };

  updateState = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  updateExistingUser = async () => {
    if (this.validateEmail()) {
      // get all items in state.
      // add titles of all items in state that are not firstName, lastName, role, to an array (this is the domains)
      // update domains per member
      let memberList = this.props.state.members;

      //Find index of specific object using findIndex method.
      let objIndex = memberList.findIndex(
        (member) => member.username === this.state.email
      );

      // Get domains
      let domains = [];
      let allowedAccess = [];
      let lostAccess = [];
      let previousDomains = this.props.state.editUserFields.domains;

      for (const property in this.state) {
        if (!defaultState.includes(property)) {
          const domain = { [property]: this.state[property] };
          domains.push(domain);
        }
      }

      // figure out which domains have changes
      // save them in appopriate arrays
      previousDomains.forEach((prevDomain) => {
        for (const prevProperty in prevDomain) {
          domains.forEach((newDomain) => {
            for (const newProperty in newDomain) {
              if (prevProperty === newProperty) {
                if (prevDomain[prevProperty] !== newDomain[newProperty]) {
                  if (newDomain[newProperty] === true)
                    allowedAccess.push(newProperty);
                  else if (newDomain[newProperty] === false)
                    lostAccess.push(newProperty);
                }
              }
            }
          });
        }
      });

      if (allowedAccess.length > 0 || lostAccess.length > 0)
        await this.promisedSetState({ domainAccessHasChanges: true });

      await this.promisedSetState({
        allowedAccess: allowedAccess,
        lostAccess: lostAccess,
      });

      // Update object's properties.
      memberList[objIndex].firstName = this.state.firstName;
      memberList[objIndex].lastName = this.state.lastName;
      memberList[objIndex].username = this.state.email;
      memberList[objIndex].role = this.state.role;
      memberList[objIndex].domains = domains;

      await axios
        .put("/api/user/update", {
          usernameToUpdate: localStorage.getItem("user"),
          update: { $set: { members: memberList } },
        })
        .then(
          async (response) => {
            let data = response.data;

            if (data.success) {
              if (this.state.domainAccessHasChanges) {
                await this.notifyUser();
                await this.promisedSetState({ domainAccessHasChanges: false });
              }

              Store.addNotification({
                message:
                  this.state.firstName +
                  " " +
                  this.state.lastName +
                  " has been updated.",
                type: "success",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 4000,
                  onScreen: false,
                  showIcon: true,
                },
              });
            } else
              Store.addNotification({
                message:
                  this.state.firstName +
                  " " +
                  this.state.lastName +
                  " could not be updated.",
                type: "warning",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 4000,
                  onScreen: false,
                  showIcon: true,
                },
              });

            return data.success;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
    }
  };

  getUserDomains = async () => {
    await axios
      .post("/api/get/user", {
        usernameToCheck: this.state.email,
      })
      .then(
        (response) => {
          if ("domains" in response.data)
            this.userDomains = response.data.data.domains;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };

  validateDomains = () => {
    let shadowState = { ...this.state };
    let domainMatchCounter = 0;
    let selectedDomains = [];

    defaultState.forEach((state) => {
      if (state in shadowState) delete shadowState[state];
    });

    // if domain was selected to give access to
    for (const domain in shadowState)
      if (shadowState[domain] === true) selectedDomains.push(domain);

    // if specified user already has domain connected, increment counter
    selectedDomains.forEach((domain) => {
      if (JSON.stringify(this.userDomains).includes(domain)) {
        this.existingDomains.push(domain);
        domainMatchCounter++;
      }
    });

    if (domainMatchCounter > 0) return false;
    else return true;
  };

  domainErrorNotification = () => {
    Store.addNotification({
      title: "Could not update user",
      message:
        "This user already has one or more matching domains already connected. Click here to learn how to resolve this issue.",
      type: "danger",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 0,
        onScreen: false,
        showIcon: true,
      },
      onRemoval: (id, removedBy) => {
        window.Intercom("showArticle", 6195505);
      },
    });
  };

  notifyUser = async () => {
    let allowedDomainString = "";
    let disallowedDomainString = "";

    this.state.allowedAccess.forEach((domain) => {
      allowedDomainString += `\n‣ ${domain}`;
    });

    this.state.lostAccess.forEach((domain) => {
      disallowedDomainString += `\n‣ ${domain}`;
    });

    await axios
      .put("/api/email/send", {
        user: this.state.email,
        subject: "Schema Helper: Your domain access has been updated",
        text:
          "Hey " +
          this.state.firstName +
          ",\n\nYour domain access has been updated by " +
          localStorage.getItem("user") +
          (allowedDomainString
            ? ".\n\nYou now have access to:" + allowedDomainString
            : "") +
          (disallowedDomainString
            ? ".\n\nYou have lost access to:" + disallowedDomainString
            : ""),
      })
      .then(
        (response) => {
          //console.log(response);
          //return response.data.success;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );
  };

  render() {
    return (
      <Dialog
        open={this.props.toggleState}
        onClose={this.props.toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        p={2}
      >
        <DialogTitle id="alert-dialog-title" className="ta-center">
          Editing a user
        </DialogTitle>

        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <TextField
              required
              name="firstName"
              aria-label="First name"
              onChange={this.handleChange}
              value={this.state.firstName}
              fullWidth={true}
              label="First name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              name="lastName"
              onChange={this.handleChange}
              aria-label="Last name"
              value={this.state.lastName}
              fullWidth={true}
              label="Last name"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <TextField
              required
              name="email"
              aria-label="Email"
              onChange={this.handleChange}
              value={this.state.email}
              fullWidth={true}
              label="Email address"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="role"
              name="role"
              select
              onChange={this.handleChange}
              fullWidth={true}
              value={this.state.role}
              label="Role"
            >
              {roles.map((role) => (
                <MenuItem value={role.toLowerCase()} key={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={12}>
            <Typography gutterBottom={true}>Domain access</Typography>

            {this.props.state.domains.map((domain, index) => (
              <FormControlLabel
                key={domain}
                control={
                  <Checkbox
                    checked={this.state[domain]}
                    onChange={this.handleDomainCheckboxChange}
                    name={domain}
                    color="primary"
                  />
                }
                label={domain}
              />
            ))}
          </Grid>
        </Grid>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={
              this.validateFields()
                ? { root: "primary" }
                : { root: "primary disabled" }
            }
            onClick={async () => {
              if (this.validateFields()) {
                await this.getUserDomains();

                if (this.validateDomains()) {
                  await this.updateExistingUser();
                  await this.props.getAccounts();
                  this.props.toggle();
                } else this.domainErrorNotification();
              }
            }}
          >
            Update
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={this.props.toggle}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
