import React, { Component } from "react";
import { store } from "react-notifications-component";
import axios from "axios";

// Components
import Header from "../../components/header/header";
import TemplatesList from "./components/TemplatesList";

import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

//const accountLimits = require("../../limits/limits.json");
const UTIL = require("../../util/util");

export default class SchemaTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaTemplates: [],
      templateToEdit: {},
      indexToEdit: "",

      // modals
      newTemplateToggle: false,
      editTemplateToggle: false,
      pageHeadModalToggle: false,
    };

    this.schemaDrawers = [];
  }

  componentDidMount = async () => {
    document.title = "Schema Templates | Schema Helper";

    // get templates from database on load
    this.setState({ schemaTemplates: await this.getTemplates() });

    /* if (this.state.schemaTemplates.length === 0) {
      for (const template of DEFAULT_TEMPLATES) {
        // check for existing template. if doesnt exist yet, add it
        let existingTemplate = this.state.schemaTemplates.filter(
          (existingTemplate) =>
            existingTemplate.name.toLowerCase() === template.name.toLowerCase()
        );

        if (existingTemplate.length === 0)
          this.addDefaultTemplates(template.name, template.schemas);
      }
    }
    */
  };

  setCustomState = (name, value) => {
    this.setState({ [name]: value });
  };

  toggle = (stateName) => {
    this.setState({ [stateName]: !this.state[stateName] });
  };

  addDefaultTemplates = async (name, schemas) => {
    let schemaTemplates = this.state.schemaTemplates;

    let schemaTemplate = {
      id: await UTIL.uniqueId(),
      name: name,
      schemas: schemas,
    };

    schemaTemplates.unshift(schemaTemplate);
    await this.updateUser();
    this.setState({ schemaTemplates: schemaTemplates });
  };

  getTemplates = async () => {
    return await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          return response.data.data.schemaTemplates;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateUser = async () => {
    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { schemaTemplates: this.state.schemaTemplates },
      })
      .then(
        (response) => {
          let data = response.data;
          if (data.success) return true;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  singleSchemaRemovalNotification = (schema) => {
    store.addNotification({
      message: schema + " removed",
      type: "warning",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  render() {
    return (
      <div id="templates">
        <div className="content-window">
          <Header title="Schema Templates" state={this.state} />

          <Box className="section" sx={{ width: "100%" }}>
            <div className="section-title d-flex space-between align-center">
              <h2>Your templates</h2>
              <IconButton
                className={"pill-button"}
                aria-label="add"
                color="secondary"
                onClick={() => (window.location.href = "/templates/create")}
              >
                <AddIcon fontSize="small" />
                <span className="text">New Template</span>
              </IconButton>
            </div>

            <Box>
              <TemplatesList
                state={this.state}
                setCustomState={this.setCustomState}
                editTemplate={this.editTemplate}
              />
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}
