import React, { Component } from "react";
import axios from "axios";
//import ReactTooltip from "react-tooltip";

export default class PageItem extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = { suggestion: "" };
  }

  componentDidMount = async () => {
    this._isMounted = true;

    let suggestion = await this.getSchemaSuggestion();
    if (this._isMounted) await this.setState({ suggestion: suggestion });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getSchemaSuggestion = async () => {
    return axios
      .put("/brain/suggestSchema", {
        url: this.props.url,
        pageType: this.props.pageType,
      })
      .then(
        (response) => {
          if (response.data.success) return response.data.suggestion;
          else return "Failed to suggest a schema.";
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <div id="schema-suggestion">
        <strong>Schema Suggestion: </strong>
        <span className="capitalize">{this.state.suggestion}</span>
        <img className="icon" src="/images/info.svg" alt="info icon" />
      </div>
    );
  }
}
