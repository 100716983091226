import React, { Component } from "react";

let schemaJSON = require("./schema.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

const itemReviewed = [
  "Book",
  "ListItem",
  "Course",
  "CreativeWorkSeason",
  "CreativeWorkSeries",
  "Episode",
  "Event",
  "Game",
  "HowTo",
  "LocalBusiness",
  "MediaObject",
  "Movie",
  "MusicPlaylist",
  "MusicRecording",
  "Organization",
  "Product",
  "Recipe",
  "SoftwareApplication",
];

export default class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
      hasAddress: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Review" // change this to match the component name
      );

      this.hasAddress();
    }

    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  hasAddress = () => {
    if (schemaJSON["itemReviewed"]["address"])
      this.setState({ hasAddress: true });
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname) {
      if (subParentName)
        schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
      else schemaJSON[parentname][name] = UTIL.cleanText(value);
    } else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  addAddress = async () => {
    let address = {
      "@type": "PostalAddress",
      streetAddress: "123 William St",
      addressLocality: "New York",
      addressRegion: "NY",
      postalCode: "10038",
      addressCountry: "US",
    };

    schemaJSON["itemReviewed"]["address"] = address;

    // update session schema
    await this.setState({ schema: schemaJSON, hasAddress: true });
    this.props.setSchema(this.state.schema);
  };

  addressFields = () => {
    return (
      <div className="address-fields">
        <h3>Address</h3>

        <label>
          <h4>Street address</h4>
        </label>
        <input
          type="text"
          id="streetAddress"
          name="streetAddress"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["streetAddress"]}
        />

        <label>
          <h4>Region</h4>
        </label>
        <input
          type="text"
          id="addressRegion"
          name="addressRegion"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["addressRegion"]}
        />

        <label>
          <h4>Locality</h4>
        </label>
        <input
          type="text"
          id="addressLocality"
          name="addressLocality"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["addressLocality"]}
        />

        <label>
          <h4>Postal Code</h4>
        </label>
        <input
          type="text"
          id="postalCode"
          name="postalCode"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["postalCode"]}
        />

        <label>
          <h4>Country</h4>
        </label>
        <input
          type="text"
          id="addressCountry"
          name="addressCountry"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["addressCountry"]}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="schema-form">
        <label>
          <h4>Name of item being reviewed</h4>
        </label>
        <input
          type="text"
          id="itemReviewedName"
          name="name"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["name"]}
        />

        <label>
          <h4>Type of item</h4>
        </label>
        <select
          className="col-2"
          id="itemReviewedType"
          name="@type"
          data-parentname="itemReviewed"
          onChange={this.handleChange}
          value={schemaJSON["itemReviewed"]["@type"]}
        >
          <option value="">- Type -</option>
          {itemReviewed.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        <label>
          <h4>Image URL</h4>
        </label>
        <input
          type="text"
          id="image"
          name="image"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["image"]}
        />

        <label>
          <h4>Telephone</h4>
        </label>
        <input
          type="number"
          id="telephone"
          name="telephone"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["telephone"]}
        />

        <label>
          <h4>Price Range</h4>
        </label>
        <div className="description">
          The price range of the business, for example $$$.
        </div>
        <input
          type="text"
          id="priceRange"
          name="priceRange"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["priceRange"]}
        />

        {this.state.hasAddress ? (
          this.addressFields()
        ) : (
          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addAddress}
          >
            Add Address
          </button>
        )}

        <h3>Review details</h3>

        <label>
          <h4>Review Rating</h4>
        </label>
        <input
          type="number"
          min="0"
          max="5"
          id="ratingValue"
          name="ratingValue"
          onChange={this.handleChange}
          data-parentname="reviewRating"
          value={schemaJSON["reviewRating"]["ratingValue"]}
        />

        <label>
          <h4>Title of the review</h4>
        </label>
        <input
          type="text"
          id="reviewTitle"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Review Body</h4>
        </label>
        <textarea
          type="text"
          id="reviewBody"
          name="reviewBody"
          onChange={this.handleChange}
          value={schemaJSON["reviewBody"]}
        />

        <label>
          <h4>Author</h4>
        </label>
        <input
          type="text"
          id="authorName"
          name="name"
          data-parentname="author"
          onChange={this.handleChange}
          value={schemaJSON["author"]["name"]}
        />

        <label>
          <h4>Author type</h4>
        </label>
        <select
          className="col-2"
          id="authorType"
          name="@type"
          data-parentname="author"
          onChange={this.handleChange}
          value={schemaJSON["author"]["@type"]}
        >
          <option value="">- Type -</option>
          <option value="Person">Person</option>
          <option value="Organization">Organization</option>
        </select>

        <label>
          <h4>Publisher name</h4>
        </label>
        <input
          type="text"
          id="publisherName"
          name="name"
          data-parentname="publisher"
          onChange={this.handleChange}
          value={schemaJSON["publisher"]["name"]}
        />

        <label>
          <h4>Publisher type</h4>
        </label>
        <select
          className="col-2"
          id="publisherType"
          name="@type"
          data-parentname="publisher"
          onChange={this.handleChange}
          value={schemaJSON["publisher"]["@type"]}
        >
          <option value="">- Type -</option>
          <option value="Person">Person</option>
          <option value="Organization">Organization</option>
        </select>
      </div>
    );
  }
}
