import React, { Component } from "react";
import axios from "axios";

export default class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationToken: "",
      verificationSuccess: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    await this.getVerificationToken();

    let verification = await this.verify();

    if (verification) {
      await this.promisedSetState({ verificationSuccess: true });
      this.sendUserHome();
    }
  };

  startCountdown = () => {
    let seconds = 5,
      $seconds = document.querySelector("#countdown");
    (function countdown() {
      $seconds.textContent = seconds + " second" + (seconds === 1 ? "" : "s");
      if (seconds-- > 0) setTimeout(countdown, 1000);
    })();
  };

  getVerificationToken = async () => {
    await this.promisedSetState({
      verificationToken: this.props.match.params.verificationToken,
    });
  };

  verify = async () => {
    return axios
      .post("/api/user/verify", {
        verificationTokenToCompare: this.state.verificationToken,
      })
      .then(
        (response) => {
          let data = response;
          return data.data.success;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  verifyForm = (e) => {
    return (
      <div id="verification-form">
        <div className="section-header">
          <h2 className="section-title c-white">Verifying your account.</h2>
        </div>
      </div>
    );
  };

  verificationSuccess = (e) => {
    return (
      <div id="verification-success">
        <div className="section-header">
          <h2 className="section-title c-white">Verification successful!</h2>
          <div className="section-copy c-white">
            <div className="countdown-container mt-20">
              <h3 className="c-white">
                You will be redirected to the login page in{" "}
                <span id="countdown"></span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  };

  verificationFailure = (e) => {
    return (
      <div id="verification-failure">
        <div className="section-header">
          <h2 className="section-title c-white">Verification failed</h2>
          <div className="section-copy c-white">
            This could be because of an invalid verification code or this
            account has already been verified.
          </div>
        </div>
      </div>
    );
  };

  sendUserHome = () => {
    this.startCountdown();

    setTimeout(function () {
      window.location.href = "/";
    }, 5000);
  };

  render() {
    return (
      <div id="verify" className="ta-center bg-midnightblue-2 c-white">
        <div className="container">
          {this.state.verificationSuccess
            ? this.verificationSuccess()
            : this.verificationFailure()}
        </div>
      </div>
    );
  }
}
