import React, { Component } from "react";
import $ from "jquery";

let schemaJSON = require("./schema.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

const itemReviewed = [
  "Book",
  "ListItem",
  "Course",
  "CreativeWorkSeason",
  "CreativeWorkSeries",
  "Episode",
  "Event",
  "Game",
  "HowTo",
  "LocalBusiness",
  "MediaObject",
  "Movie",
  "MusicPlaylist",
  "MusicRecording",
  "Organization",
  "Product",
  "Recipe",
  "Restaurant",
  "SoftwareApplication",
];

export default class AggregateRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "AggregateRating" // change this to match the component name
      );
    }

    this.props.setSchema(schemaJSON);
    this.jQueryScripts();
    await this.promisedSetState(this.state); // force update
  };

  jQueryScripts = () => {
    // set selected @type
    $("#itemReviewedType").val(schemaJSON["itemReviewed"]["@type"]);
  };

  hasAddress = () => {
    if (schemaJSON["itemReviewed"]["address"]) return true;
    else return false;
  };

  updateSessionSchema = async () => {
    await this.promisedSetState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (name === "@type" && value === "Restaurant")
      await this.addServesCuisine();

    if (parentname) {
      if (subParentName)
        schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
      else schemaJSON[parentname][name] = UTIL.cleanText(value);
    } else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    await this.updateSessionSchema();
  };

  addServesCuisine = async () => {
    schemaJSON["itemReviewed"]["servesCuisine"] = "";
    await this.updateSessionSchema();
  };

  addAddress = async () => {
    let address = {
      "@type": "PostalAddress",
      streetAddress: "123 William St",
      addressLocality: "New York",
      addressRegion: "NY",
      postalCode: "10038",
      addressCountry: "US",
    };

    schemaJSON["itemReviewed"]["address"] = address;
    await this.updateSessionSchema();
  };

  addressFields = () => {
    return (
      <div className="address-fields">
        <label>
          <h4>Street address</h4>
        </label>
        <input
          type="text"
          id="streetAddress"
          name="streetAddress"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          data-subparentname="address"
          value={schemaJSON["itemReviewed"]["address"]["streetAddress"]}
        />

        <div className="flex-container">
          <span className="col-2">
            <label>
              <h4>Region</h4>
            </label>
            <input
              type="text"
              id="addressRegion"
              name="addressRegion"
              onChange={this.handleChange}
              data-parentname="itemReviewed"
              data-subparentname="address"
              value={schemaJSON["itemReviewed"]["address"]["addressRegion"]}
            />
          </span>
          <span className="col-2">
            <label>
              <h4>Locality</h4>
            </label>
            <input
              type="text"
              id="addressLocality"
              name="addressLocality"
              onChange={this.handleChange}
              data-parentname="itemReviewed"
              data-subparentname="address"
              value={schemaJSON["itemReviewed"]["address"]["addressLocality"]}
            />
          </span>
        </div>

        <div className="flex-container">
          <span className="col-2">
            <label>
              <h4>Postal Code</h4>
            </label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              onChange={this.handleChange}
              data-parentname="itemReviewed"
              data-subparentname="address"
              value={schemaJSON["itemReviewed"]["address"]["postalCode"]}
            />
          </span>
          <span className="col-2">
            <label>
              <h4>Country</h4>
            </label>
            <input
              type="text"
              id="addressCountry"
              name="addressCountry"
              onChange={this.handleChange}
              data-parentname="itemReviewed"
              data-subparentname="address"
              value={schemaJSON["itemReviewed"]["address"]["addressCountry"]}
            />
          </span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="schema-form">
        <label>
          <h4>Name of item being reviewed</h4>
        </label>
        <input
          type="text"
          id="itemReviewedName"
          name="name"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["name"]}
        />

        <label>
          <h4>Type of item</h4>
        </label>
        <select
          className="col-2"
          id="itemReviewedType"
          name="@type"
          data-parentname="itemReviewed"
          onChange={this.handleChange}
          value={schemaJSON["itemReviewed"]["@type"]}
        >
          <option value="">- Type -</option>
          {itemReviewed.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        <label>
          <h4>Image URL</h4>
        </label>
        <input
          type="text"
          id="image"
          name="image"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["image"]}
        />

        {schemaJSON["itemReviewed"]["@type"] === "Restaurant" && (
          <div className="cuisine">
            <label>
              <h4>Serves Cuisine</h4>
            </label>
            <input
              type="text"
              id="servesCuisine"
              name="servesCuisine"
              onChange={this.handleChange}
              data-parentname="itemReviewed"
              value={schemaJSON["itemReviewed"]["servesCuisine"]}
            />
          </div>
        )}

        <label>
          <h4>Telephone</h4>
        </label>
        <input
          type="number"
          id="telephone"
          name="telephone"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["telephone"]}
        />

        {/*
        <label>
          <h4>Price Range</h4>
        </label>
        <div className="description">
          The price range of the business, for example $$$.
        </div>
        <input
          type="text"
          id="priceRange"
          name="priceRange"
          onChange={this.handleChange}
          data-parentname="itemReviewed"
          value={schemaJSON["itemReviewed"]["priceRange"]}
        />*/}

        <hr className="mt-20" />
        <h3>Address</h3>

        {this.hasAddress() ? (
          this.addressFields()
        ) : (
          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addAddress}
          >
            Add Address
          </button>
        )}

        <hr className="mt-20" />

        <h3>Review details</h3>

        <div className="flex-container">
          <span className="col-3">
            {" "}
            <label>
              <h4>Rating Value</h4>
            </label>
            <input
              type="number"
              min="0"
              id="ratingValue"
              name="ratingValue"
              onChange={this.handleChange}
              value={schemaJSON["ratingValue"]}
            />
          </span>
          <span className="col-3">
            <label>
              <h4>Best Rating</h4>
            </label>
            <input
              type="number"
              min="0"
              id="bestRating"
              name="bestRating"
              onChange={this.handleChange}
              value={schemaJSON["bestRating"]}
            />
          </span>
          <span className="col-3">
            <label>
              <h4>Rating Count</h4>
            </label>
            <input
              type="number"
              min="0"
              id="ratingCount"
              name="ratingCount"
              onChange={this.handleChange}
              value={schemaJSON["ratingCount"]}
            />
          </span>
        </div>
      </div>
    );
  }
}
