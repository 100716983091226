import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./recipe.json");
const LOCAL_UTIL = require("../util");
const COMP_UTIL = require("./util");
const UTIL = require("../../util/util");

export default class Recipe extends Component {
  constructor(props) {
    super(props);

    this.images = [];
    this.ingredients = [];
    this.steps = [];

    this.state = {
      schema: schemaJSON,
      showImageFields: this.images,
      showIngredientFields: this.ingredients,
      showStepFields: this.steps,
      prepTimeHours: "",
      prepTimeMinutes: "",
      prepTimeSeconds: "",
      cookTimeHours: "",
      cookTimeMinutes: "",
      cookTimeSeconds: "",
      totalTimeHours: "",
      totalTimeMinutes: "",
      totalTimeSeconds: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    // load existing schema
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Recipe"
      );

      await this.generateInputFields();
    }
    // load default schema
    else {
      this.props.setSchema(schemaJSON);
    }

    await COMP_UTIL.jQueryScripts(schemaJSON);
    this.setState(this.state); // force update

    if(schemaJSON?.image.length) {
      const tempImage = schemaJSON?.image.map((item, counter) => this.imageFields(counter, item))
      this.images = tempImage;
      this.setState({
        showImageFields: this.images,
      });
    }

    if(schemaJSON?.recipeIngredient.length) {
      const tempIngredients = schemaJSON?.recipeIngredient.map((item, counter) => this.ingredientFields(counter, item))
      this.ingredients = tempIngredients;
      this.setState({
        showIngredientFields: this.ingredients,
      });
    }

    if(schemaJSON?.recipeInstructions.length) {
      const tempSteps = schemaJSON?.recipeInstructions.map((item, counter) => this.stepFields(counter, item))
      this.steps = tempSteps;
      this.setState({
        showStepFields: this.steps,
      });
    }
    
    if(schemaJSON.prepTime) {
      const ptInitial = schemaJSON.prepTime.indexOf('T');
      const ptHour = schemaJSON.prepTime.indexOf('H');
      const ptMinute = schemaJSON.prepTime.indexOf('M');
      const ptSeconds = schemaJSON.prepTime.indexOf('S');
      if(ptHour > -1) this.setState({prepTimeHours: schemaJSON.prepTime.substring(ptInitial + 1, ptHour)});
      if(ptMinute > -1) this.setState({prepTimeMinutes: schemaJSON.prepTime.substring(ptHour + 1, ptMinute)});
      if(ptSeconds > -1) this.setState({prepTimeSeconds: schemaJSON.prepTime.substring(ptMinute + 1, ptSeconds)});
    }

    if(schemaJSON.cookTime) {
      const ctInitial = schemaJSON.cookTime.indexOf('T');
      const ctHour = schemaJSON.cookTime.indexOf('H');
      const ctMinute = schemaJSON.cookTime.indexOf('M');
      const ctSeconds = schemaJSON.cookTime.indexOf('S');
      if(ctHour > -1) this.setState({cookTimeHours: schemaJSON.cookTime.substring(ctInitial + 1, ctHour)});
      if(ctMinute > -1) this.setState({cookTimeMinutes: schemaJSON.cookTime.substring(ctHour + 1, ctMinute)});
      if(ctSeconds > -1) this.setState({cookTimeSeconds: schemaJSON.cookTime.substring(ctMinute + 1, ctSeconds)});
    }

    if(schemaJSON.totalTime) {
      const ttInitial = schemaJSON.totalTime.indexOf('T');
      const ttHour = schemaJSON.totalTime.indexOf('H');
      const ttMinute = schemaJSON.totalTime.indexOf('M');
      const ttSeconds = schemaJSON.totalTime.indexOf('S');
      if(ttHour > -1) this.setState({totalTimeHours: schemaJSON.totalTime.substring(ttInitial + 1, ttHour)});
      if(ttMinute > -1) this.setState({totalTimeMinutes: schemaJSON.totalTime.substring(ttHour + 1, ttMinute)});
      if(ttSeconds > -1) this.setState({totalTimeSeconds: schemaJSON.totalTime.substring(ttMinute + 1, ttSeconds)});
    }
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    // if there is a parent object with the attribute "data-parentname" and it is a review
    if (parentname === "image") {
      const imageNumber = e.target.getAttribute("data-image-number");
      schemaJSON[parentname][imageNumber] = UTIL.cleanText(value);
    } else if (parentname === "recipeIngredient") {
      const ingredientNumber = e.target.getAttribute("data-ingredient-number");
      schemaJSON[parentname][ingredientNumber] = UTIL.cleanText(value);
    } else if (parentname === "recipeInstructions") {
      const stepNumber = e.target.getAttribute("data-step-number");
      schemaJSON[parentname][stepNumber][name] = UTIL.cleanText(value);
    } else if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (
      name === "prepTimeHours" ||
      name === "prepTimeMinutes" ||
      name === "prepTimeSeconds"
    ) {
      await this.handleTime(name, value);
      let timeString = "PT";
      if (this.state.prepTimeHours)
        timeString += this.state.prepTimeHours + "H";
      if (this.state.prepTimeMinutes)
        timeString += this.state.prepTimeMinutes + "M";
      if (this.state.prepTimeSeconds)
        timeString += this.state.prepTimeSeconds + "S";

      schemaJSON["prepTime"] = timeString;
    } else if (
      name === "cookTimeHours" ||
      name === "cookTimeMinutes" ||
      name === "cookTimeSeconds"
    ) {
      await this.handleTime(name, value);
      let timeString = "PT";
      if (this.state.cookTimeHours)
        timeString += this.state.cookTimeHours + "H";
      if (this.state.cookTimeMinutes)
        timeString += this.state.cookTimeMinutes + "M";
      if (this.state.cookTimeSeconds)
        timeString += this.state.cookTimeSeconds + "S";

      schemaJSON["cookTime"] = timeString;
    } else if (
      name === "totalTimeHours" ||
      name === "totalTimeMinutes" ||
      name === "totalTimeSeconds"
    ) {
      await this.handleTime(name, value);
      let timeString = "PT";
      if (this.state.totalTimeHours)
        timeString += this.state.totalTimeHours + "H";
      if (this.state.totalTimeMinutes)
        timeString += this.state.totalTimeMinutes + "M";
      if (this.state.totalTimeSeconds)
        timeString += this.state.totalTimeSeconds + "S";

      schemaJSON["totalTime"] = timeString;
    } else if (e.target.type === "select-one") {
      if (name === "articleType") {
        schemaJSON["@type"] = value;
      } else {
        schemaJSON[value] = "";
      }
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  handleTime = async (name, value) => {
    await this.setState({ [name]: value });
  };

  /*
   * Images
   */
  imageFields = (counter, image) => {
    return (
        <div className="image-fields" key={counter}>
            <h5>Image #{counter + 1}</h5>

            <input
                type="text"
                id={"image-" + counter}
                name="name"
                data-parentname="image"
                data-image-number={counter}
                placeholder="Image URL"
                onChange={this.handleChange}
                value={image}
            />
        </div>
    );
  }
  addImage = async () => {
    let counter = this.images.length;

    this.images.push(
      this.imageFields(counter)
    );

    this.setState({
      showImageFields: this.images,
    });

    // add a new faq object to the array of faqs
    schemaJSON["image"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayImageSection = () => {
    return (
      <div className="image-items">
        <h4>Images</h4>

        <div className="faq-fields-container">{this.images}</div>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addImage}
        >
          Add Image
        </button>
      </div>
    );
  };

  /*
   * Ingredients
   */
  ingredientFields = (counter, ingredient) => {
    return (
      <div className="ingredient-fields" key={counter}>
        <h5>Ingredient #{counter + 1}</h5>

        <input
          type="text"
          id={"ingredient-" + counter}
          name="ingredient"
          data-parentname="recipeIngredient"
          data-ingredient-number={counter}
          placeholder="i.e. 3/4 cup sugar"
          onChange={this.handleChange}
          value={ingredient}
        />
      </div>
    );
  }
  addIngredient = async () => {
    let counter = this.ingredients.length;

    this.ingredients.push(
      this.ingredientFields(counter)
    );

    this.setState({
      showIngredientFields: this.ingredients,
    });
    schemaJSON["recipeIngredient"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayIngredientSection = () => {
    return (
      <div className="ingredient-items">
        <h4>Ingredients</h4>

        <div className="faq-fields-container">{this.ingredients}</div>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addIngredient}
        >
          Add Ingredient
        </button>
      </div>
    );
  };

  /*
   * Steps
   */

  stepFields = (counter, step) => {
    return (
      <div className="step-fields" key={counter}>
        <h5>Step #{counter + 1}</h5>

        <label htmlFor={"stepName-" + counter}>Title</label>
        <input
          type="text"
          id={"stepName-" + counter}
          name="name"
          data-parentname="recipeInstructions"
          data-step-number={counter}
          placeholder="Preheat"
          onChange={this.handleChange}
          value={step?.name}
        />

        <label htmlFor={"stepText-" + counter}>Description</label>
        <input
          type="text"
          id={"stepText-" + counter}
          name="text"
          data-parentname="recipeInstructions"
          data-step-number={counter}
          placeholder="Preheat the oven to 350 degrees F. Grease and flour a 9x9 inch pan."
          onChange={this.handleChange}
          value={step?.text}
        />

        <label htmlFor={"stepUrl-" + counter}>URL</label>
        <input
          type="text"
          id={"stepUrl-" + counter}
          name="url"
          data-parentname="recipeInstructions"
          data-step-number={counter}
          placeholder="https://example.com/party-coffee-cake#step1"
          onChange={this.handleChange}
          value={step?.url}
        />

        <label htmlFor={"stepImage-" + counter}>Image URL</label>
        <input
          type="text"
          id={"stepImage-" + counter}
          name="image"
          data-parentname="recipeInstructions"
          data-step-number={counter}
          placeholder="https://example.com/photos/party-coffee-cake/step1.jpg"
          onChange={this.handleChange}
          value={step?.image}
        />
      </div>
    )
  }

  addStep = async () => {
    let counter = this.steps.length;
    let stepObject = {
      "@type": "HowToStep",
      name: "",
      text: "",
    };

    this.steps.push(
      this.stepFields(counter)
    );

    this.setState({
      showStepFields: this.steps,
    });
    schemaJSON["recipeInstructions"].push(stepObject);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayInstructionsSection = () => {
    return (
      <div className="instruction-items">
        <h4>Instructions</h4>

        <div className="instruction-fields-container">{this.steps}</div>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addStep}
        >
          Add Step
        </button>
      </div>
    );
  };

  generateInputFields = async () => {
    schemaJSON.image.map((imageItem, index) =>
      this.images.push(
        <div className="image-fields" key={index}>
          <h5>Image #{index + 1}</h5>

          <input
            type="text"
            id={"image-" + index}
            name="name"
            data-parentname="image"
            data-image-number={index}
            placeholder="Image URL"
            onChange={this.handleChange}
          />
        </div>
      )
    );

    schemaJSON.recipeIngredient.map((ingredientItem, index) =>
      this.ingredients.push(
        <div className="ingredient-fields" key={index}>
          <h5>Ingredient #{index + 1}</h5>

          <input
            type="text"
            id={"ingredient-" + index}
            name="ingredient"
            data-parentname="recipeIngredient"
            data-ingredient-number={index}
            placeholder="i.e. 3/4 cup sugar"
            onChange={this.handleChange}
          />
        </div>
      )
    );

    schemaJSON.recipeInstructions.map((recipeItem, index) =>
      this.steps.push(
        <div className="step-fields" key={index}>
          <h5>Step #{index + 1}</h5>

          <label htmlFor={"stepName-" + index}>Title</label>
          <input
            type="text"
            id={"stepName-" + index}
            name="name"
            data-parentname="recipeInstructions"
            data-step-number={index}
            placeholder="Preheat"
            onChange={this.handleChange}
          />

          <label htmlFor={"stepText-" + index}>Description</label>
          <input
            type="text"
            id={"stepText-" + index}
            name="text"
            data-parentname="recipeInstructions"
            data-step-number={index}
            placeholder="Preheat the oven to 350 degrees F. Grease and flour a 9x9 inch pan."
            onChange={this.handleChange}
          />

          <label htmlFor={"stepUrl-" + index}>URL</label>
          <input
            type="text"
            id={"stepUrl-" + index}
            name="url"
            data-parentname="recipeInstructions"
            data-step-number={index}
            placeholder="https://example.com/party-coffee-cake#step1"
            onChange={this.handleChange}
          />

          <label htmlFor={"stepImage-" + index}>Image URL</label>
          <input
            type="text"
            id={"stepImage-" + index}
            name="image"
            data-parentname="recipeInstructions"
            data-step-number={index}
            placeholder="https://example.com/photos/party-coffee-cake/step1.jpg"
            onChange={this.handleChange}
          />
        </div>
      )
    );
  };

  render() {
    return (
      <div id="recipe-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6414105)}
          >
            Learn More
          </span>
        </Typography>

        <label htmlFor="name">
          <h4>Recipe name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        {this.displayImageSection()}

        <div className="author">
          <h4>Author</h4>

          <div className="flex-container">
            <select
              className="col-2"
              id="authorType"
              name="@type"
              data-parentname="author"
              onChange={this.handleChange}
              value={schemaJSON?.author?.["@type"]}
            >
              <option value="">- Author @type -</option>
              <option value="Person">Person</option>
              <option value="Organization">Organization</option>
            </select>

            <input
              className="col-2"
              type="text"
              id="authorName"
              name="name"
              data-parentname="author"
              placeholder="Author Name"
              onChange={this.handleChange}
              value={schemaJSON["author"]["name"]}
            />
          </div>
        </div>

        <h4>Publish date</h4>
        <input
          type="date"
          id="datePublished"
          name="datePublished"
          onChange={this.handleChange}
          value={schemaJSON["datePublished"]}
        />

        <label htmlFor="name">
          <h4>Description</h4>
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          placeholder=""
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label>
          <h4>Prep time</h4>
        </label>
        <div className="flex-container">
          <span className="col-3">
            <label htmlFor="prepTimeHours">Hours</label>
            <input
              type="number"
              id="prepTimeHours"
              name="prepTimeHours"
              min="0"
              onChange={this.handleChange}
              value={this.state.prepTimeHours}
            />
          </span>
          <span className="col-3">
            <label htmlFor="prepTimeHours">Minutes</label>
            <input
              type="number"
              id="prepTimeMinutes"
              name="prepTimeMinutes"
              min="0"
              onChange={this.handleChange}
              value={this.state.prepTimeMinutes}
            />
          </span>
          <span className="col-3">
            <label htmlFor="prepTimeSeconds">Seconds</label>
            <input
              type="number"
              id="prepTimeSeconds"
              name="prepTimeSeconds"
              min="0"
              onChange={this.handleChange}
              value={this.state.prepTimeSeconds}
            />
          </span>
        </div>

        <label>
          <h4>Cook time</h4>
        </label>
        <div className="flex-container">
          <span className="col-3">
            <label htmlFor="cookTimeHours">Hours</label>
            <input
              type="number"
              id="cookTimeHours"
              name="cookTimeHours"
              min="0"
              onChange={this.handleChange}
              value={this.state.cookTimeHours}
            />
          </span>
          <span className="col-3">
            <label htmlFor="cookTimeMinutes">Minutes</label>
            <input
              type="number"
              id="cookTimeMinutes"
              name="cookTimeMinutes"
              min="0"
              onChange={this.handleChange}
              value={this.state.cookTimeMinutes}
            />
          </span>
          <span className="col-3">
            <label htmlFor="cookTimeSeconds">Seconds</label>
            <input
              type="number"
              id="cookTimeSeconds"
              name="cookTimeSeconds"
              min="0"
              onChange={this.handleChange}
              value={this.state.cookTimeSeconds}
            />
          </span>
        </div>

        <label>
          <h4>Total time</h4>
        </label>
        <div className="flex-container">
          <span className="col-3">
            <label htmlFor="totalTimeHours">Hours</label>
            <input
              type="number"
              id="totalTimeHours"
              name="totalTimeHours"
              min="0"
              onChange={this.handleChange}
              value={this.state.totalTimeHours}
            />
          </span>
          <span className="col-3">
            <label htmlFor="totalTimeMinutes">Minutes</label>
            <input
              type="number"
              id="totalTimeMinutes"
              name="totalTimeMinutes"
              min="0"
              onChange={this.handleChange}
              value={this.state.totalTimeMinutes}
            />
          </span>
          <span className="col-3">
            <label htmlFor="totalTimeSeconds">Seconds</label>
            <input
              type="number"
              id="totalTimeSeconds"
              name="totalTimeSeconds"
              min="0"
              onChange={this.handleChange}
              value={this.state.totalTimeSeconds}
            />
          </span>
        </div>

        <label htmlFor="keywords">
          <h4>Keywords</h4>
        </label>
        <span className="description">
          Separate multiple entries in a keywords list with commas. Don't use a
          tag that should be in recipeCategory or recipeCuisine.
        </span>
        <input
          type="text"
          id="keywords"
          name="keywords"
          placeholder=""
          onChange={this.handleChange}
          value={schemaJSON["keywords"]}
        />

        <label htmlFor="recipeYield">
          <h4>Recipe yield</h4>
        </label>
        <span className="description">
          The quantity produced by the recipe. Specify the number of servings
          produced from this recipe with just a number. If you wish to use a
          different unit (for example, number of items), you may include
          additional yields. This is required if you specify any nutritional
          information per serving (such as nutrition calories).
        </span>
        <input
          type="text"
          id="recipeYield"
          name="recipeYield"
          placeholder=""
          onChange={this.handleChange}
          value={schemaJSON["recipeYield"]}
        />

        <label htmlFor="recipeCategory">
          <h4>Recipe category</h4>
        </label>
        <span className="description">
          The type of meal or course your recipe is about. For example:
          "dinner", "main course", or "dessert, snack".
        </span>
        <input
          type="text"
          id="recipeCategory"
          name="recipeCategory"
          placeholder=""
          onChange={this.handleChange}
          value={schemaJSON["recipeCategory"]}
        />

        <label htmlFor="recipeCuisine">
          <h4>Recipe cuisine</h4>
        </label>
        <span className="description">
          The region associated with your recipe. For example, "French",
          Mediterranean", or "American".
        </span>
        <input
          type="text"
          id="recipeCuisine"
          name="recipeCuisine"
          placeholder=""
          onChange={this.handleChange}
          value={schemaJSON["recipeCuisine"]}
        />

        <label htmlFor="calories">
          <h4>Calories</h4>
        </label>
        <span className="description">
          The number of calories in each serving produced with this recipe. If
          nutrition.calories is defined, recipeYield must be defined with the
          number of servings.
        </span>
        <input
          type="text"
          id="calories"
          name="calories"
          data-parentname="nutrition"
          placeholder="270 calories"
          onChange={this.handleChange}
          value={schemaJSON["nutrition"]["calories"]}
        />

        {this.displayIngredientSection()}
        {this.displayInstructionsSection()}

        <label>
          <h4>Rating</h4>
        </label>
        <div className="flex-container">
          <span className="col-2">
            <label htmlFor="ratingValue">Rating value</label>
            <input
              type="number"
              id="ratingValue"
              name="ratingValue"
              min="0"
              max="5"
              placeholder="5"
              data-parentname="aggregateRating"
              onChange={this.handleChange}
              value={schemaJSON["aggregateRating"]["ratingValue"]}
            />
          </span>
          <span className="col-2">
            <label htmlFor="ratingCount">Rating count</label>
            <input
              type="number"
              id="ratingCount"
              name="ratingCount"
              min="0"
              placeholder="8"
              data-parentname="aggregateRating"
              onChange={this.handleChange}
              value={schemaJSON["aggregateRating"]["ratingCount"]}
            />
          </span>
        </div>
      </div>
    );
  }
}
