import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./product.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idPropName: "",
      offer: "",
      showReviewFields: [],
      schema: schemaJSON,
    };
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Product"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
    await this.setState({ schema: schemaJSON });

    if(schemaJSON?.sku) {
      await this.setState({ idPropName: 'sku' });
    } else if(schemaJSON?.gtin8) {
      await this.setState({ idPropName: 'gtin8' });
    } else if(schemaJSON?.gtin13) {
      await this.setState({ idPropName: 'gtin13' });
    } else if(schemaJSON?.gtin14) {
      await this.setState({ idPropName: 'gtin14' });
    } else if(schemaJSON?.mpn) {
      await this.setState({ idPropName: 'mpn' });
    }

    if(schemaJSON?.offers['@type'] === 'Offer') {
      await this.setState({ offer: 'Offer' });
    } else if(schemaJSON?.offers['@type'] === 'AggregateOffer') {
      await this.setState({ offer: 'AggregateOffer' });
    } else if(schemaJSON?.offers['@type'] === 'None') {
      await this.setState({ offer: 'None' });
    }

    const tempReviews = schemaJSON?.review.map((item, counter) => this.reviewFields(counter, item))

    this.setState({
      showReviewFields: [...this.state.showReviewFields, ...tempReviews],
    });
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");

    // if there is a parent object with the attribute "data-parentname" and it is a review
    if (parentname === "review") {
      const reviewNumber = e.target.getAttribute("data-review-number");
      const subParentName = e.target.getAttribute("data-subparentname");

      if (subParentName) {
        schemaJSON[parentname][reviewNumber][subParentName][name] =
          UTIL.cleanText(value);
      } else {
        schemaJSON[parentname][reviewNumber][name] = UTIL.cleanText(value);
      }
    }
    // if there is a parent object with the attribute "data-parentname"
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    // if it is a select input
    else if (e.target.type === "select-one") {
      this.setState({ idPropName: value });
      schemaJSON[value] = "";
    }
    // default
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  handleOffer = (e) => {
    const { value } = e.target;

    if (value === "Offer") {
      schemaJSON["offers"] = {
        "@type": "Offer",
        url: "",
        priceCurrency: "",
        price: "",
      };
    } else if (value === "AggregateOffer") {
      schemaJSON["offers"] = {
        "@type": "AggregateOffer",
        url: "",
        priceCurrency: "",
        lowPrice: "",
      };
    }

    // update session schema
    this.setState({ schema: schemaJSON });
    this.setState({ offer: value });
    this.props.setSchema(this.state.schema);
  };

  reviewFields = (counter, review) => {
    return(
      <div className="review-fields" key={counter}>
        <h5>Review #{counter + 1}</h5>

        <div className="flex-container">
          <input
            className="col-2"
            type="text"
            id="reivewName"
            name="name"
            data-parentname="review"
            data-review-number={counter}
            placeholder="Review name"
            onChange={this.handleChange}
            value={review?.name}
          />

          <input
            className="col-2"
            type="text"
            id="reviewBody"
            name="reviewBody"
            data-parentname="review"
            data-review-number={counter}
            placeholder="Review body"
            onChange={this.handleChange}
            value={review?.reviewBody}
          />
        </div>

        <div className="flex-container">
          <input
            className="col-2"
            type="number"
            id="ratingValue"
            name="ratingValue"
            data-parentname="review"
            data-subparentname="reviewRating"
            data-review-number={counter}
            placeholder="Rating value"
            onChange={this.handleChange}
            value={review?.reviewRating?.ratingValue}
          />

          <input
            className="col-2"
            type="date"
            id="datePublished"
            name="datePublished"
            data-parentname="review"
            data-review-number={counter}
            onChange={this.handleChange}
            value={review?.datePublished}
          />
        </div>

        <div className="flex-container">
          <input
            className="col-2"
            type="text"
            id="authorName"
            name="name"
            data-parentname="review"
            data-subparentname="author"
            data-review-number={counter}
            placeholder="Author name"
            onChange={this.handleChange}
            value={review?.author?.name}
          />

          <input
            className="col-2"
            type="text"
            id="publisherName"
            name="name"
            data-parentname="review"
            data-subparentname="publisher"
            data-review-number={counter}
            placeholder="Publisher name"
            onChange={this.handleChange}
            value={review?.publisher?.name}
          />
        </div>
      </div>
    )
  }

  addReview = async () => {
    let counter = this.state.showReviewFields.length;
    let reviewObject = {
      "@type": "Review",
      name: "",
      reviewBody: "",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "",
      },
      datePublished: "",
      author: { "@type": "Person", name: "" },
      publisher: { "@type": "Organization", name: "" },
    };

    const temp = this.reviewFields(counter)

    this.setState({
      showReviewFields: [...this.state.showReviewFields, temp],
    });

    schemaJSON["review"].push(reviewObject);
    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  displayOfferFields = () => {
    //console.log(this.state.offer);

    if (this.state.offer === "Offer") {
      return (
        <div className="offers">
          <input
            type="text"
            id="url"
            name="url"
            data-parentname="offers"
            placeholder="URL"
            onChange={this.handleChange}
            value={schemaJSON?.offers?.url}
          />

          <div className="flex-container">
            <input
              className="col-2"
              type="text"
              id="priceCurrency"
              name="priceCurrency"
              data-parentname="offers"
              placeholder="priceCurrency: ex) USD"
              onChange={this.handleChange}
              value={schemaJSON?.offers?.priceCurrency}
            />
            <input
              className="col-2"
              type="text"
              id="price"
              name="price"
              data-parentname="offers"
              placeholder="price"
              onChange={this.handleChange}
              value={schemaJSON?.offers?.price}
            />
          </div>
        </div>
      );
    } else if (this.state.offer === "AggregateOffer") {
      return (
        <div className="offers">
          <input
            type="text"
            id="url"
            name="url"
            placeholder="URL"
            onChange={this.handleChange}
            value={schemaJSON?.offers?.url}
          />

          <div className="flex-container">
            <input
              className="col-2"
              type="text"
              id="priceCurrency"
              name="priceCurrency"
              data-parentname="offers"
              placeholder="priceCurrency: ex) USD"
              onChange={this.handleChange}
              value={schemaJSON?.offers?.priceCurrency}
            />
            <input
              className="col-2"
              type="text"
              id="lowPrice"
              name="lowPrice"
              data-parentname="offers"
              placeholder="Low price"
              onChange={this.handleChange}
              value={schemaJSON?.offers?.lowPrice}
            />
          </div>
        </div>
      );
    }
  };

  displayReviewSection = () => {
    return (
      <div className="reviews">
        <h4>Reviews</h4>

        <button
          className="btn small c-white bg-orange b-orange"
          onClick={this.addReview}
        >
          Add Review
        </button>

        {this.displayReviewFields()}
      </div>
    );
  };

  displayReviewFields = () => {
    return <div className="review-fields-container">{this.reviewFields}</div>;
  };

  render() {
    return (
      <div id="product-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6414157)}
          >
            Learn More
          </span>
        </Typography>

        <div className="flex-container">
          <input
            className="col-2"
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            onChange={this.handleChange}
            value={schemaJSON["name"]}
          />

          <input
            className="col-2"
            type="text"
            id="brand"
            name="brand"
            placeholder="Brand"
            onChange={this.handleChange}
            value={schemaJSON["brand"]}
          />
        </div>

        <textarea
          type="text"
          id="description"
          name="description"
          placeholder="Product Description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <input
          type="text"
          id="image"
          name="image"
          placeholder="Image URL"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <div className="identification">
          <h4>Identification</h4>
          <select id="idPropName" onChange={this.handleChange} value={this.state.idPropName}>
            <option value="">- Identification properties -</option>
            <option value="sku">sku</option>
            <option value="gtin8">gtin8</option>
            <option value="gtin13">gtin13</option>
            <option value="gtin14">gtin14</option>
            <option value="mpn">mpn</option>
          </select>

          {this.state.idPropName ? (
            <input
              type="text"
              id={this.state.idPropName}
              name={this.state.idPropName}
              placeholder="Indentification value"
              onChange={this.handleChange}
              value={schemaJSON[this.state.idPropName]}
            />
          ) : (
            ""
          )}
        </div>

        <div className="offer">
          <h4>Offer type</h4>
          <select id="offer" onChange={this.handleOffer} value={this.state.offer}>
            <option value="">- Offer @type -</option>
            <option value="Offer">Offer</option>
            <option value="AggregateOffer">Aggregate Offer</option>
            <option value="None">None</option>
          </select>

          {this.displayOfferFields()}
        </div>

        <div className="aggregateRating">
          <h4>AggregateRating</h4>
          <div className="flex-container">
            <input
              className="col-2"
              type="number"
              id="ratingValue"
              name="ratingValue"
              data-parentname="aggregateRating"
              placeholder="Aggregate rating value"
              onChange={this.handleChange}
              value={schemaJSON?.aggregateRating?.ratingValue}
            />
            <input
              className="col-2"
              type="number"
              id="ratingCount"
              name="ratingCount"
              data-parentname="aggregateRating"
              placeholder="Number of ratings"
              onChange={this.handleChange}
              value={schemaJSON?.aggregateRating?.ratingCount}
            />
          </div>

          <div className="flex-container">
            <input
              className="col-2"
              type="number"
              id="bestRating"
              name="bestRating"
              data-parentname="aggregateRating"
              placeholder="Highest value allowed"
              onChange={this.handleChange}
              value={schemaJSON?.aggregateRating?.bestRating}
            />
            <input
              className="col-2"
              type="number"
              id="worstRating"
              name="worstRating"
              data-parentname="aggregateRating"
              placeholder="Lowest value allowed"
              onChange={this.handleChange}
              value={schemaJSON?.aggregateRating?.worstRating}
            />
          </div>
        </div>

        {this.displayReviewSection()}

        {this.state.showReviewFields}
      </div>
    );
  }
}
