import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidMount = async () => {};

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("error caught");
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      <div className="v-center ta-center">
        <h1 className="mb-10">Uh oh</h1>
        <p>Something went wrong. Please refresh the page or try again later.</p>

        <a className="btn medium c-white bg-orange b-orange mt-20" href="/">
          Back to Schema Helper
        </a>
      </div>;
    }

    return this.props.children;
  }
}
