import React, { Component } from "react";
import { Typography } from "@mui/material";

let schemaJSON = require("./softwareApplication.json");
const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class SoftwareApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "SoftwareApplication"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="application-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6076676)}
          >
            Learn More
          </span>
        </Typography>

        <label htmlFor="name">
          <h4>Application name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Application name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label htmlFor="operatingSystem">
          <h4>Operating system</h4>
        </label>
        <div className="description">
          The operating system(s) required to use the app (for example, Windows
          7, OSX 10.6, Android 1.6)
        </div>
        <input
          type="text"
          id="operatingSystem"
          name="operatingSystem"
          placeholder="Operating system"
          onChange={this.handleChange}
          value={schemaJSON["operatingSystem"]}
        />

        <label htmlFor="applicationCategory">
          <h4>Application category</h4>
        </label>
        <select
          className="col-2"
          id="applicationCategory"
          name="applicationCategory"
          onChange={this.handleChange}
          value={schemaJSON["applicationCategory"]}
        >
          <option value="">- applicationCategory -</option>
          <option value="GameApplication">GameApplication</option>
          <option value="SocialNetworkingApplication">
            SocialNetworkingApplication
          </option>
          <option value="TravelApplication">TravelApplication</option>
          <option value="ShoppingApplication">ShoppingApplication</option>
          <option value="SportsApplication">SportsApplication</option>
          <option value="LifestyleApplication">LifestyleApplication</option>
          <option value="BusinessApplication">BusinessApplication</option>
          <option value="DesignApplication">DesignApplication</option>
          <option value="DeveloperApplication">DeveloperApplication</option>
          <option value="DriverApplication">DriverApplication</option>
          <option value="EducationalApplication">EducationalApplication</option>
          <option value="HealthApplication">HealthApplication</option>
          <option value="FinanceApplication">FinanceApplication</option>
          <option value="SecurityApplication">SecurityApplication</option>
          <option value="BrowserApplication">BrowserApplication</option>
          <option value="CommunicationApplication">
            CommunicationApplication
          </option>
          <option value="DesktopEnhancementApplication">
            DesktopEnhancementApplication
          </option>
          <option value="EntertainmentApplication">
            EntertainmentApplication
          </option>
          <option value="MultimediaApplication">MultimediaApplication</option>
          <option value="HomeApplication">HomeApplication</option>
          <option value="UtilitiesApplication">UtilitiesApplication</option>
          <option value="ReferenceApplication">ReferenceApplication</option>
        </select>

        <label>
          <h4>Rating</h4>
        </label>
        <div className="flex-container">
          <input
            className="col-2"
            type="number"
            id="ratingValue"
            name="ratingValue"
            min="0"
            max="5"
            data-parentname="aggregateRating"
            placeholder="Rating value"
            onChange={this.handleChange}
            value={schemaJSON?.aggregateRating?.ratingValue}
          />
          <input
            className="col-2"
            type="number"
            id="ratingCount"
            name="ratingCount"
            min="0"
            data-parentname="aggregateRating"
            placeholder="Rating count"
            onChange={this.handleChange}
            value={schemaJSON?.aggregateRating?.ratingCount}
          />
        </div>

        <label>
          <h4>Price</h4>
        </label>
        <div className="flex-container">
          <input
            className="col-2"
            type="number"
            id="price"
            name="price"
            data-parentname="offers"
            placeholder="Price"
            min="0"
            onChange={this.handleChange}
            value={schemaJSON?.offers?.price}
          />
          <input
            className="col-2"
            type="text"
            id="priceCurrency"
            name="priceCurrency"
            data-parentname="offers"
            placeholder="Currency (i.e. USD)"
            onChange={this.handleChange}
            value={schemaJSON?.offers?.priceCurrency}
          />
        </div>
      </div>
    );
  }
}
