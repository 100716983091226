import React, { Component } from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./service.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Service extends Component {
  constructor(props) {
    super(props);

    this.offerCatalog = [];
    this.images = [];

    this.state = {
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Service" // change this to match the component name
      );

      this.generateInputFields();
    }
    this.props.setSchema(schemaJSON);
    this.jQueryScripts();
    this.setState(this.state); // force update
  };

  jQueryScripts = () => {
    schemaJSON.provider.image.map((image, index) => {
      $("#image-" + index).val(image);
      return 1;
    });
    schemaJSON.hasOfferCatalog.itemListElement.map((offer, index) => {
      $("#serviceName-" + index).val(offer["itemOffered"]["name"]);
      return 1;
    });
  };

  /*
   * This is where we handle when users type in values on the front end input fields
   */
  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (parentname === "image") {
      const imageNumber = e.target.getAttribute("data-image-number");
      schemaJSON["provider"][parentname][imageNumber] = value;
    } else if (subParentName === "itemListElement") {
      const offerCatalogNum = e.target.getAttribute("data-offercatalognumber");
      schemaJSON[parentname]["itemListElement"][offerCatalogNum]["itemOffered"][
        name
      ] = UTIL.cleanText(value);
    } else if (parentname === "hasOfferCatalog") {
      schemaJSON[parentname][name] = UTIL.cleanText(value);
    } else if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      schemaJSON[name] = value;
    } else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * Offers
   */
  offerFields = (counter, offerCatalog) => {
    return (
      <div className="offer-fields" key={"offer" + counter}>
        <h5>Offer #{counter + 1}</h5>

        <label>
          <h4>Service Name</h4>
        </label>

        <div className="offer-item removable">
          <input
            type="text"
            id={"serviceName-" + counter}
            name="name"
            data-offercatalognumber={counter}
            data-parentname="hasOfferCatalog"
            data-subparentname="itemListElement"
            onChange={this.handleChange}
            value={offerCatalog?.itemOffered?.name}
          />

          <div
            className="remove-item"
            onClick={() => this.removeOffer(counter)}
          >
            X
          </div>
        </div>
      </div>
    );
  };

  addOffer = async () => {
    let counter = this.offerCatalog.length;
    let offerObject = {
      "@type": "Offer",
      itemOffered: {
        "@type": "Service",
        name: "Apartment light cleaning",
      },
    };

    this.offerCatalog.push(this.offerFields(counter));
    this.setState({ showOfferFields: this.offerCatalog });
    schemaJSON["hasOfferCatalog"]["itemListElement"].push(offerObject);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeOffer = async (indexToRemove) => {
    let offerArray = schemaJSON["hasOfferCatalog"]["itemListElement"];
    offerArray = offerArray.filter((offer, index) => index !== indexToRemove);
    schemaJSON["hasOfferCatalog"]["itemListElement"] = offerArray;

    // update session schema
    this.generateInputFields();
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * Images
   */
  imageFields = (counter, image) => {
    return (
      <div className="image-fields" key={"image" + counter}>
        <h5>Image #{counter + 1}</h5>

        <div className="image-item removable">
          <input
            type="text"
            id={"image-" + counter}
            name="name"
            data-image-number={counter}
            data-parentname="image"
            placeholder="Image URL"
            onChange={this.handleChange}
            value={image}
          />
          <div
            className="remove-item"
            onClick={() => this.removeImage(counter)}
          >
            X
          </div>
        </div>
      </div>
    );
  };

  addImage = async () => {
    let counter = this.images.length;

    this.images.push(this.imageFields(counter));
    this.setState({ showImageFields: this.images });
    schemaJSON["provider"]["image"].push("");

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeImage = async (indexToRemove) => {
    let imageArray = schemaJSON["provider"]["image"];
    imageArray = imageArray.filter((image, index) => index !== indexToRemove);
    schemaJSON["provider"]["image"] = imageArray;

    // update session schema
    this.generateInputFields();
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  generateInputFields = () => {
    this.images = [];
    this.offerCatalog = [];

    schemaJSON.provider.image.map((image, counter) =>
      this.images.push(this.imageFields(counter, image))
    );

    schemaJSON.hasOfferCatalog.itemListElement.map((offerCatalog, counter) =>
      this.offerCatalog.push(this.offerFields(counter, offerCatalog))
    );
  };

  render() {
    console.log(schemaJSON)
    return (
      <div id="service" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6079225)}
          >
            Learn More
          </span>
        </Typography>

        <div className="form-group">
          <div className="flex-container">
            <div className="col-2">
              <label>
                <h4>Service type</h4>
              </label>
              <input
                type="text"
                id="serviceType"
                name="serviceType"
                placeholder="Service type"
                onChange={this.handleChange}
                value={schemaJSON["serviceType"]}
              />
            </div>
            <div className="col-2">
              <label>
                <h4>Provider name</h4>
              </label>
              <input
                type="text"
                id="providerName"
                name="name"
                data-parentname="provider"
                placeholder="Provider name"
                onChange={this.handleChange}
                value={schemaJSON["provider"]["name"]}
              />
            </div>
          </div>

          <div className="flex-container">
            <div className="col-2">
              <label htmlFor="telephone">
                <h4>Telephone</h4>
              </label>
              <input
                type="text"
                id="telephone"
                name="telephone"
                data-parentname="provider"
                value={schemaJSON["provider"]["telephone"]}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-2">
              <label htmlFor="priceRange">
                <h4>Price Range</h4>
              </label>
              <input
                type="text"
                id="priceRange"
                name="priceRange"
                data-parentname="provider"
                value={schemaJSON["provider"]["priceRange"]}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="d-flex align-center">
            <h3>Address</h3>
            <InfoIcon
              data-for="address-tip"
              data-tip="The physical location of the business."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="address-tip" />
          </label>
          <br />

          <label htmlFor="streetAddress">Street address</label>
          <input
            type="text"
            id="streetAddress"
            name="streetAddress"
            data-parentname="provider"
            data-subparentname="address"
            value={schemaJSON["provider"]["address"]["streetAddress"]}
            onChange={this.handleChange}
          />
          <div className="flex-container">
            <span className="col-2">
              <label htmlFor="postalCode">Postal code</label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                data-parentname="provider"
                data-subparentname="address"
                value={schemaJSON["provider"]["address"]["postalCode"]}
                onChange={this.handleChange}
              />
            </span>
            <span className="col-2">
              <label htmlFor="addressLocality">Locality</label>
              <input
                type="text"
                id="addressLocality"
                name="addressLocality"
                data-parentname="provider"
                data-subparentname="address"
                value={schemaJSON["provider"]["address"]["addressLocality"]}
                onChange={this.handleChange}
              />
            </span>
          </div>

          <div className="flex-container">
            <span className="col-2">
              <label htmlFor="addressRegion">Region</label>
              <input
                type="text"
                id="addressRegion"
                name="addressRegion"
                data-parentname="provider"
                data-subparentname="address"
                value={schemaJSON["provider"]["address"]["addressRegion"]}
                onChange={this.handleChange}
              />
            </span>
            <span className="col-2">
              <label htmlFor="addressCountry">Country</label>
              <input
                type="text"
                id="addressCountry"
                name="addressCountry"
                data-parentname="provider"
                data-subparentname="address"
                value={schemaJSON["provider"]["address"]["addressCountry"]}
                onChange={this.handleChange}
              />
            </span>
          </div>
        </div>

        {/* Images */}
        <div className="form-group images-items">
          <label>
            <h3>Images</h3>
          </label>

          <div className="fields-container">{this.images}</div>

          <button
            className="btn small c-white bg-orange b-orange mt-10"
            onClick={this.addImage}
          >
            Add Image
          </button>
        </div>

        <div className="form-group">
          <label className="d-flex align-center">
            <h3>Area served</h3>
            <InfoIcon
              data-for="area-served-tip"
              data-tip="The geographic area where a service or offered item is provided."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="area-served-tip" />
          </label>
          <br />

          <div className="flex-container">
            <div className="col-2">
              <label>
                <h4>@type</h4>
              </label>

              <input
                type="text"
                id="areaServedType"
                name="@type"
                data-parentname="areaServed"
                placeholder="Area served type"
                onChange={this.handleChange}
                value={schemaJSON["areaServed"]["@type"]}
              />
            </div>
            <div className="col-2">
              <label>
                <h4>Name</h4>
              </label>
              <input
                type="text"
                id="areaServedName"
                name="name"
                data-parentname="areaServed"
                placeholder="Area served"
                onChange={this.handleChange}
                value={schemaJSON["areaServed"]["name"]}
              />
            </div>
          </div>
        </div>

        {/* Offer Catalog */}
        <div className="form-group offer-catalog-items">
          <label className="d-flex align-center">
            <h3>Service offerings</h3>
            <InfoIcon
              data-for="service-offerings-tip"
              data-tip="This is where you would enter in your service offerings."
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="service-offerings-tip" />
          </label>
          <br />

          <label>
            <h5>Offer Catalog Title</h5>
          </label>
          <input
            type="text"
            id={"offerCatalogName"}
            name="name"
            data-parentname="hasOfferCatalog"
            value={schemaJSON["hasOfferCatalog"]["name"]}
            onChange={this.handleChange}
          />

          <div className="fields-container">{this.offerCatalog}</div>

          <button
            className="btn small c-white bg-orange b-orange mt-10"
            onClick={this.addOffer}
          >
            Add Offer
          </button>
        </div>
      </div>
    );
  }
}
