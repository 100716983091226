/*
 * Component Import Index
 * primarily used only in App.js for Routes
 */

import Auth from "../views/auth";
import Login from "../views/signin/login";
import Logout from "../views/logout";
import Register from "../views/register/register";
import Recover from "../views/recover/recover";
import NewPassword from "../views/newPassword/newPassword";

import AdminDashboard from "../views/admin/admin-dashboard";
import Sidebar from "../views/sidebar";
import Confirmation from "../views/confirmation/confirmation";
import Dashboard from "../views/dashboard/dashboard";
import Pages from "../views/pages/pages";
import PageEditor from "../views/pageEditor";
import Editor from "../views/Editor";
import Users from "../views/users";

//import AccountAnalytics from "./views/AccountAnalytics";

import Profile from "../views/profile/profile";
import Settings from "../views/settings/settings";
import MyAccount from "../views/settings/Account";
import Defaults from "../views/settings/Defaults";

// Schema Templates
import SchemaTemplates from "../views/SchemaTemplates";
import EditTemplate from "../views/SchemaTemplates/components/EditTemplate";
import CreateNewTemplate from "../views/SchemaTemplates/components/CreateNewTemplate";

export const COMPONENTS = {
  // Auth / Login components
  Auth,
  Login,
  Logout,
  Register,
  Recover,
  NewPassword,
  // Services
  AdminDashboard,
  Sidebar,
  Confirmation,
  Dashboard,
  Pages,
  PageEditor,
  Editor,
  Users,
  // Settings
  Profile,
  Settings,
  MyAccount,
  Defaults,
  // Templating
  SchemaTemplates,
  EditTemplate,
  CreateNewTemplate,
};
