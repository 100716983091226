import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import InfoIcon from "@mui/icons-material/Info";

let schemaJSON = require("./article.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class Article extends Component {
  constructor(props) {
    super(props);

    this.reviewFields = [];

    this.state = {
      idPropName: "",
      offer: "",
      showReviewFields: this.reviewFields,
      schema: schemaJSON,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Article"
      );
    }
    this.props.setSchema(schemaJSON);
    await this.promisedSetState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (name === "url" && !parentname) {
      schemaJSON[name] = UTIL.cleanText(value);
      schemaJSON["mainEntityOfPage"]["@id"] = UTIL.cleanText(value);
    } else if (parentname && subParentName)
      schemaJSON[parentname][subParentName][name] = UTIL.cleanText(value);
    else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else if (e.target.type === "select-one") {
      if (name === "articleType") {
        schemaJSON["@type"] = value;
      } else {
        await this.promisedSetState({ idPropName: value });
        schemaJSON[value] = "";
      }
    }
    // default
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.promisedSetState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="article-schema-form" className="schema-form">
        <label className="d-flex align-center">
          <h4>Headline</h4>
          <InfoIcon
            data-for="headline-tip"
            data-tip="The title of the article"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="headline-tip" />
        </label>
        <input
          type="text"
          id="headline"
          name="headline"
          placeholder="Headline"
          onChange={this.handleChange}
          value={schemaJSON["headline"]}
        />

        <label className="d-flex align-center">
          <h4>Description</h4>
          <InfoIcon
            data-for="description-tip"
            data-tip="A short description of the article. The meta description would work perfectly"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="description-tip" />
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          placeholder="A short description of the article"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label className="d-flex align-center">
          <h4>Page URL</h4>
        </label>
        <input
          type="text"
          id="url"
          name="url"
          placeholder="URL"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <label className="d-flex align-center">
          <h4>Featured Image</h4>
          <InfoIcon
            data-for="featured-image-tip"
            data-tip="URL of the featured image used in the article"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="featured-image-tip" />
        </label>
        <input
          type="text"
          id="image"
          name="image"
          placeholder="Image URL"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <label>
          <h4>Publish date</h4>
        </label>
        <input
          type="date"
          id="datePublished"
          name="datePublished"
          onChange={this.handleChange}
          value={schemaJSON["datePublished"]}
        />

        <label>
          <h4>Modified date</h4>
        </label>
        <input
          type="date"
          id="dateModified"
          name="dateModified"
          onChange={this.handleChange}
          value={schemaJSON["dateModified"]}
        />

        <div className="author">
          <label>
            <h4>Author</h4>
          </label>

          <div className="flex-container">
            <div className="col-2">
              <label>Type</label>

              <select
                id="authorType"
                name="@type"
                data-parentname="author"
                onChange={this.handleChange}
                value={schemaJSON["author"]["@type"]}
              >
                <option value="">- Author @type -</option>
                <option value="Person">Person</option>
                <option value="Organization">Organization</option>
              </select>
            </div>

            <div className="col-2">
              <label>Name</label>

              <input
                type="text"
                id="authorName"
                name="name"
                data-parentname="author"
                placeholder="Author Name"
                onChange={this.handleChange}
                value={schemaJSON["author"]["name"]}
              />
            </div>
          </div>

          <label htmlFor="authorURL">Author URL</label>
          <input
            type="text"
            id="authorURL"
            name="url"
            data-parentname="author"
            placeholder="Author URL"
            onChange={this.handleChange}
            value={schemaJSON["author"]["url"]}
          />
        </div>

        <div className="publisher">
          <label>
            <h4>Publisher</h4>
          </label>

          <div className="flex-container">
            <div className="col-2">
              <label>Name</label>

              <input
                type="text"
                id="name"
                name="name"
                data-parentname="publisher"
                placeholder="Publisher name"
                onChange={this.handleChange}
                value={schemaJSON["publisher"]["name"]}
              />
            </div>

            <div className="col-2">
              <label>Logo URL</label>

              <input
                type="text"
                id="publisherLogoURL"
                name="url"
                data-parentname="publisher"
                data-subparentname="logo"
                placeholder="Logo URL"
                onChange={this.handleChange}
                value={schemaJSON["publisher"]["logo"]["url"]}
              />
            </div>
          </div>

          <div className="flex-container">
            <div className="col-2">
              <label>Logo Width</label>

              <input
                type="number"
                id="logoWidth"
                name="width"
                min="1"
                max="60"
                data-parentname="publisher"
                data-subparentname="logo"
                placeholder="width ≤ 60"
                onChange={this.handleChange}
                value={schemaJSON["publisher"]["logo"]["width"]}
              />
            </div>

            <div className="col-2">
              <label>Logo Height</label>

              <input
                type="number"
                id="logoHeight"
                name="height"
                min="1"
                max="60"
                data-parentname="publisher"
                data-subparentname="logo"
                placeholder="height ≤ 60"
                onChange={this.handleChange}
                value={schemaJSON["publisher"]["logo"]["height"]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
