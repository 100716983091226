import $ from "jquery";
//import ScrollReveal from "scrollreveal";
import axios from "axios";
//import moment from "moment";

const UTIL = require("../../../util/util");
const { SCHEMA_JSON } = require("../../../schemas");
const accountLimits = require("../../../limits/limits.json");

async function hasReachedBottom() {
  const totalPageHeight = document.body.scrollHeight;
  const scrollPoint = window.scrollY + window.innerHeight;
  return scrollPoint >= totalPageHeight; // check if we hit the bottom of the page
}

async function jQueryScripts() {
  //if (navigator.platform.indexOf("Win") > -1)
  //ScrollReveal().reveal(".page-item", { duration: 200, reset: true });

  // click actions
  $(".content-window").click(function (e) {
    // when user clicks on page-item, display sidebar
    if ($(e.target).hasClass("page-item")) {
      // if the element clicked does not have an active state
      if (!$(e.target).hasClass("active")) {
        $(".page-item").removeClass("active");
        $(e.target).addClass("active");
        $("#pages").addClass("right-sidebar-enabled");
      }
    }
    // when user clicks on status or last updated element, display sidebar
    else if (
      $(e.target).is(
        ".status, .status > span, .updated-date, .page-title, .type, .type > span"
      )
    ) {
      // if the element clicked does not have an active state
      if (!$(e.target).parents(".active").length) {
        $(".page-item").removeClass("active");
        $(e.target).closest(".page-item").addClass("active");
        $("#pages").addClass("right-sidebar-enabled");
      }
    }
    // when user clicks anywhere except the sidebar and the filters, close sidebar
    else if (!$(e.target).is(".right-sidebar, .filters, select")) {
      $("#pages").removeClass("right-sidebar-enabled");
      $(".page-item").removeClass("active");
    }
  });

  // when user clicks the select all checkbox in the table header
  $(".table-header .select").on("click", function () {
    let checkBoxes = $(".page-item .select");
    checkBoxes.prop("checked", !checkBoxes.prop("checked"));

    if ($(this).is(":checked")) $(".page-item").addClass("selected");
    else $(".page-item").removeClass("selected");

    // check if there are any checkboxes still checked and toggle class accordingly
    if ($(".page-item input.select").is(":checked"))
      $(".content-window").addClass("right-sidebar-multi");
    else {
      $(".content-window").removeClass("right-sidebar-multi");
    }
  });

  // when user clicks a checkbox in the table
  $(".page-item input.select").on("change", function () {
    if ($(this).is(":checked")) $(this).parent().parent().addClass("selected");
    else $(this).parent().parent().removeClass("selected");

    // check if there are any checkboxes still checked and toggle class accordingly
    if ($(".page-item input.select").is(":checked"))
      $(".content-window").addClass("right-sidebar-multi");
    else {
      $(".content-window").removeClass("right-sidebar-multi");
    }
  });

  // sort by status
  $(".table-header .status").on("click", function () {
    let pages = $(".page-item");

    if ($(this).hasClass("sort-order-2")) {
      pages.sort(function (a, b) {
        return $(b).data("status") - $(a).data("status");
      });
    } else if ($(this).hasClass("sort-order-1")) {
      pages.sort(function (a, b) {
        return $(a).data("status") - $(b).data("status");
      });
    }

    $(".pages-container").html(pages);
    $(this).toggleClass("sort-order-1").toggleClass("sort-order-2");
  });

  // sort by type
  $(".table-header .type").on("click", function () {
    let pages = $(".page-item");

    if ($(this).hasClass("sort-order-2")) {
      pages.sort((a, b) => $(a).data("type").localeCompare($(b).data("type")));
    } else if ($(this).hasClass("sort-order-1")) {
      pages.sort((a, b) => $(b).data("type").localeCompare($(a).data("type")));
    }

    $(".pages-container").html(pages);
    $(this).toggleClass("sort-order-1").toggleClass("sort-order-2");
  });

  // sort by last updated
  $(".table-header .updated").on("click", function () {
    let pages = $(".page-item");

    pages.sort(function (a, b) {
      return (
        new Date($(b).find(".updated-date").text()) -
        new Date($(a).find(".updated-date").text())
      );
    });

    $(".pages-container").html(pages);
  });
}

const hasPagesToGet = async (state) => {
  let hasStandardPages = state.websiteOffset < state.totalWebsitePages;
  let hasArticles = state.articleOffset < state.totalArticles;
  let hasStandardOffset = state.websiteOffset > 0;
  let hasArticleOffset = state.articleOffset > 0;

  return (
    (hasStandardPages && hasStandardOffset) || (hasArticles && hasArticleOffset)
  );
};

const formatSchema = async (schema) => {
  if (schema["@graph"])
    schema["@graph"].forEach((singleSchema) => {
      if (singleSchema["@context"]) delete singleSchema["@context"];
    });

  return schema;
};

/*
 * Get functions
 */

/**
 * @param  {} state
 */
const getAccountLimits = async (state) => {
  let currentAccountLimit;

  // if accessing page editor via a membership to another account
  if (state.accountHolderUsername)
    currentAccountLimit = await accountLimits.filter(
      (plan) => plan.package === state.accountHolderSubscription
    );
  else if (localStorage.getItem("productName"))
    currentAccountLimit = await accountLimits.filter(
      (plan) => plan.package === localStorage.getItem("productName")
    );
  else
    currentAccountLimit = await accountLimits.filter(
      (plan) => plan.package === "Free"
    );

  return currentAccountLimit[0];
};

/**
 * @param  {} state
 * @param  {} currentDomain
 */
const getPages = async (state, currentDomain) => {
  return (
    axios
      //.put("/hubspot/get/pages-v3", {
      .put("/hubspot/get/pages-v2", {
        access_token: state.accessToken,
        domain: currentDomain,
        offset: state.websiteOffset,
      })
      .then(
        (response) => {
          let data = response.data;

          if (data.success) {
            // remove domains that are not the current domain from the pages object
            let filteredDomains = data.pages.objects.filter(
              (page) => page.live_domain === currentDomain
            );

            return {
              success: data.success,
              websitePages: state.websitePages.concat(filteredDomains),
              totalWebsitePages: data.pages.total,
            };
          }
        },
        (error) => {
          console.log(error);
        }
      )
  );
};

/**
 * @param  {} state
 * @param  {} currentDomain
 */
const getArticles = async (state, currentDomain) => {
  //console.log(state.accessToken);
  return (
    axios
      //.put("/hubspot/get/articles-v2", {
      .put("/hubspot/get/articles-v3", {
        access_token: state.accessToken,
        offset: state.articleOffset,
      })
      .then(
        (response) => {
          let data = response.data;
          //console.log(data.articles);

          if (data.success) {
            // remove domains that are not the current domain from the pages object
            //let filteredDomains = data.articles.objects.filter((page) =>
            let filteredDomains = data.articles.results.filter((page) =>
              page.url.includes(currentDomain)
            );

            // add "normal_blog_post" subcategory because hubspot doesnt returnn this anymore for some reason
            filteredDomains = filteredDomains.map((obj) => ({
              ...obj,
              subcategory: "normal_blog_post",
            }));

            return {
              success: data.success,
              articles: state.articles.concat(filteredDomains),
              totalArticles: data.articles.total,
            };
          }
        },
        (error) => {
          console.log(error);
        }
      )
  );
};

const getFilteredPages = async (state) => {
  // trim the objects to only keep what we need
  // this reduces the size of the cache
  let validKeys = [
    // V2 Keys
    "head_html",
    "absolute_url",
    "live_domain",
    "updated",
    "publish_date",
    "current_state",
    "analytics_page_type",
    "blog_post_author",
    "screenshot_preview_url",
    // V3 Keys
    "id",
    "name",
    "metaDescription",
    "headHtml",
    "url",
    "domain",
    "currentState",
    "authorName",
    "featuredImage",
    "subcategory",
    "publishDate",
  ];

  let websitePages = state.websitePages;
  let articles = state.articles;

  for (const page of websitePages) {
    Object.keys(page).forEach(
      (key) => validKeys.includes(key) || delete page[key]
    );
  }

  /*
  for (const page of articles) {
    Object.keys(page).forEach(
      (key) => validKeys.includes(key) || delete page[key]
    );
  }
  */

  return { websitePages: websitePages, articles: articles };
};

/*
 * Schema generation
 */

/**
 * @param  {} page
 * @param  {} state
 */
const generateArticleJSON = async (page, state) => {
  let articleJSON = SCHEMA_JSON.BlogPosting;

  return await axios
    .put("/hubspot/get/blogAuthorLegacy", {
      access_token: state.accessToken,
      blogAuthorId: page.blogAuthorId,
    })
    .then(
      (response) => {
        //return response.data.articles;
        const AUTHOR_DETAILS = response.data.articles;

        // generate Article schema
        articleJSON["headline"] = page.name;
        articleJSON["url"] = page.url ? page.url : page.absolute_url;
        articleJSON["mainEntityOfPage"]["@id"] = page.url
          ? page.url
          : page.absolute_url;
        articleJSON["author"]["@type"] = "Person";
        if(AUTHOR_DETAILS){
          articleJSON["author"]["name"] = AUTHOR_DETAILS.displayName;
          articleJSON["author"]["url"] =
            page.url.substring(0, page.url.lastIndexOf("/")) +
            "/author/" +
            AUTHOR_DETAILS.slug;
        }

        articleJSON["datePublished"] = page.publishDate
          ? UTIL.timeConverter(page.publishDate)
          : UTIL.timeConverter(page.publish_date);
        articleJSON["dateModified"] = UTIL.timeConverter(page.updated);

        if (page.metaDescription || page.meta_description)
          articleJSON["description"] = page.metaDescription
            ? page.metaDescription
            : page.meta_description;
        else articleJSON["description"] = page.name;

        if (
          state.globalVariables &&
          state.globalVariables.articleFeaturedImage
        ) {
          articleJSON["image"] = state.globalVariables.articleFeaturedImage;
        } else if (page.featured_image || page.featuredImage)
          articleJSON["image"] = page.featuredImage
            ? page.featuredImage
            : page.featured_image;

        if (state.globalVariables && state.globalVariables.articlePublisher)
          articleJSON["publisher"]["name"] =
            state.globalVariables.articlePublisher;

        if (
          state.globalVariables &&
          state.globalVariables.articlePublisherLogoURL
        )
          articleJSON["publisher"]["logo"]["url"] =
            state.globalVariables.articlePublisherLogoURL;

        //console.log(articleJSON);

        return articleJSON;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

/**
 * @param  {} page
 */
const generateBreadcrumbListJSON = async (page) => {
  let breadcrumbListJSON = SCHEMA_JSON.BreadcrumbList;
  let url = page.url ? page.url : page.absolute_url;

  // generate BreadcrumbList schema
  // reset breadcrumbs
  breadcrumbListJSON.itemListElement = [];

  // get base url
  let pathArray = url.split("/");
  let baseUrl = pathArray[0] + "//" + pathArray[2];
  pathArray = pathArray.splice(3); // remove first 3 elements (base url)

  // add base url first
  breadcrumbListJSON.itemListElement.push({
    "@type": "ListItem",
    position: 1,
    name: "Home",
    item: baseUrl,
  });

  // iterate through the rest of the paths and append to list
  pathArray.forEach((pathName, index) => {
    // replace all dashes / hyphens wit space
    pathName = pathName.replace(/-/g, " ");

    // fill previous paths
    let previousPaths = "";
    pathArray.forEach((path, i) => {
      if (i < index) previousPaths += "/" + path;
    });

    breadcrumbListJSON.itemListElement.push({
      "@type": "ListItem",
      position: index + 2,
      name: UTIL.toTitleCase(pathName),
      item: baseUrl + previousPaths + "/" + pathName,
    });
  });

  return breadcrumbListJSON;
};

/**
 * @param  {} page
 */
const generateWebsiteJSON = async (page) => {
  let websiteJSON = SCHEMA_JSON.WebSite;

  websiteJSON["name"] = page.name;
  websiteJSON["url"] = page.absolute_url;

  return websiteJSON;
};

/**
 * @param  {} page
 * @param  {} schemasArray
 */
const getNewPageHead = async (page, schemasArray) => {
  // append to head_html
  let previousCodeState = page.head_html;
  if (!previousCodeState) previousCodeState = "";

  // Remove existing schema
  let headWithoutSchema = previousCodeState
    .replace(/<script type="application\/ld\+json">(.|\n)*?<\/script>/gi, "")
    .trim();

  // Create and add new schema
  let openingTags = '\n\n<script type="application/ld+json">\n';
  let endingTags = "\n</script>";
  let newPageHead =
    headWithoutSchema +
    openingTags +
    JSON.stringify(schemasArray, null, 2) +
    endingTags;

  return newPageHead;
};

export {
  // scripts
  jQueryScripts,
  hasReachedBottom,
  // Booleans
  hasPagesToGet,
  // Formatting
  formatSchema,
  // Get functions
  getAccountLimits,
  getPages,
  getArticles,
  getNewPageHead,
  getFilteredPages,
  // Schema generation
  generateArticleJSON,
  generateBreadcrumbListJSON,
  generateWebsiteJSON,
};
