import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";
import { Box, IconButton } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";

export default class PIN extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    // check for support pin
    if (userData.supportPin)
      await this.promisedSetState({ supportPin: userData.supportPin });
    else this.updatePin();
  };

  generatePin = async () => {
    let length = 5;
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  updatePin = async () => {
    let newPin = await this.generatePin();
    await this.promisedSetState({ supportPin: newPin });

    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { supportPin: newPin },
      })
      .then(
        (response) => {
          let data = response.data;
          if (data.success)
            Store.addNotification({
              message: "New support PIN generated",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "100px" }}
      >
        <Box style={{ width: "100%", maxWidth: 768 }}>
          <div className="section-title d-flex space-between align-center">
            <h2>Support PIN</h2>

            <IconButton
              className="pill-button"
              aria-label="refresh"
              color="secondary"
              onClick={this.updatePin}
            >
              <RefreshIcon fontSize="small" />
              <span className="text">Refresh PIN</span>
            </IconButton>
          </div>

          <Box>
            <div className="support-pin flex-container flex-start">
              <strong id="support-pin">{this.state.supportPin}</strong>
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
