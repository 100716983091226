import React, { Component } from "react";
import axios from "axios";
import {
  //Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

//simport Modal from "../modal/modal";

const DEFAULT_TEMPLATES = [
  { name: "Homepage", schemas: ["WebPage", "BreadcrumbList"] },
  { name: "Blog Posts", schemas: ["BlogPosting", "BreadcrumbList"] },
];

export default class BulkEditorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaTemplates: [],

      selectedTemplate: "",

      // toggles
      validTemplate: false,
      dropdownToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    // get templates from database on load
    await this.promisedSetState({ schemaTemplates: await this.getTemplates() });
  };

  getTemplates = async () => {
    return await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          return response.data.data.schemaTemplates;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  getTemplateSelectorData = async () => {
    let value = document.getElementById("templateSelector").value;

    let filtereDefaultTemplate = DEFAULT_TEMPLATES.filter(
      (template) => template.name === value
    );

    let filteredTemplate = this.state.schemaTemplates.filter(
      (template) => template.name === value
    );

    if (filteredTemplate.length > 0 || filtereDefaultTemplate.length > 0) {
      await this.promisedSetState({
        selectedTemplate: value,
        validTemplate: true,
      });
    } else await this.promisedSetState({ validTemplate: false });
  };

  render() {
    return (
      <Dialog
        open={this.props.toggleState}
        //onClose={() => this.props.toggle("bulkEditorToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">Bulk Editor</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <label htmlFor="templateSelector" style={{ marginBottom: "25px" }}>
              Please select a template to use for bulk generation. If you
              haven't already, take a look at the{" "}
              <a href="/settings/global-variables">defaults page</a> where you
              can set your organization's address along with other useful
              information that we can use to autofill fields for you.
            </label>

            <input
              list="templatesList"
              id="templateSelector"
              name="templateSelector"
              placeholder="Search or select a template"
              autoComplete="off"
              onChange={this.getTemplateSelectorData}
            />
            <datalist id="templatesList">
              {DEFAULT_TEMPLATES.map((template) => (
                <option
                  key={template.name}
                  data-template={template.name}
                  value={template.name}
                >
                  A default template from us. This includes:{" "}
                  {template.schemas.map((schema, index) => {
                    if (template.schemas.length - 1 !== index)
                      return schema + ", ";
                    else return schema;
                  })}
                </option>
              ))}

              {this.state.schemaTemplates.map((template) => (
                <option
                  key={template.id}
                  data-template={template.id}
                  value={template.name}
                />
              ))}
            </datalist>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={
              this.state.validTemplate
                ? {
                    root: "primary",
                  }
                : { root: "primary disabled" }
            }
            onClick={
              this.state.validTemplate
                ? () => {
                    this.props.toggle("bulkEditorToggle");
                    this.props.selectTemplate(this.state.selectedTemplate);
                    this.props.generate();
                  }
                : this.doNothing
            }
          >
            Generate
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.props.toggle("bulkEditorToggle")}
          >
            Cancel
          </Button>
        </DialogActions>

        {this.props.processing && (
          <DialogContent>
            <div className="progress">
              <div className="progress__item--inf-prog"></div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    );
  }
}
