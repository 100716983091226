import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";

import { Box } from "@mui/material";

// Components & Views
import Avatar from "./avatar";

export default class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      receivedResponse: false,
      updateSuccess: false,
      userAvatar: null,
      userAvatarFilename: null,
    };

    this.updateState = this.updateState.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    let userInfo = await this.getUserInfo();

    await this.promisedSetState({
      firstName: userInfo.data.firstName,
      lastName: userInfo.data.lastName,
      userAvatar: userInfo.data.avatar,
      userAvatarFilename: userInfo.data.avatarFilename,
      email: localStorage.getItem("user"),
    });
  };

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  // update state
  updateState = async (key, value) => {
    await this.promisedSetState({
      [key]: value,
    });
  };

  handleSubmit = async (event) => {
    let updateSuccess = await this.updateUserInfo();
    if (updateSuccess) await this.promisedSetState({ updateSuccess: true });
  };

  getUserInfo = async () => {
    return axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          let data = response;
          //let successStatus = data.data.success;

          return data.data;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateUserInfo = async () => {
    return axios
      .post("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          username: this.state.email,
          avatar: this.state.userAvatar,
          avatarFilename: this.state.userAvatarFilename,
        },
      })
      .then(
        async (response) => {
          let data = response;
          let successStatus = data.data.success;

          await this.promisedSetState({ receivedResponse: true });

          if (successStatus) {
            Store.addNotification({
              message: "User information successfully updated.",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });

            localStorage.setItem("user", this.state.email);

            // update customer email in stripe
            this.updateCustomerEmail(this.state.email);
          } else {
            Store.addNotification({
              title: "Warning",
              message:
                "Something went wrong while trying to update your information. Try again later.",
              type: "warning",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
          }

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateCustomerEmail = async (newEmail) => {
    await axios
      .put("/api/stripe/updateCustomerEmail", {
        customerID: localStorage.getItem("customerID"),
        newEmail: newEmail,
      })
      .then(
        async (response) => {
          //let data = response.data;
          //console.log("update customer email response:", response);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box style={{ width: "100%", maxWidth: 768 }}>
          <div className="section-title">
            <h2>User</h2>
          </div>

          <Box>
            <Avatar state={this.state} updateState={this.updateState} />

            <div className="form-container">
              <div className="row">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <label>Email Address</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <button
                  className="btn medium bg-orange c-white"
                  onClick={this.handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
