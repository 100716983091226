import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";
//import Cookies from "universal-cookie";
//const cookies = new Cookies();
//import { Store } from "react-notifications-component";

export default class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tempAccessToken: "",
      tempRefreshToken: "",

      error: false,
      errorDescription: "",
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    //const queries = queryString.parse(this.props.location.search);
    const queries = queryString.parse(window.location.search);

    if (queries.error) {
      //console.log(queries.error);
      await this.promisedSetState({
        error: true,
        errorType: queries.error,
        errorDescription: queries.error_description,
      });
    } else if (queries.code)
      await axios
        //.put("/api/auth", {
        .put("/auth/hubspot", {
          code: queries.code,
        })
        .then(
          async (response) => {
            let data = response;

            await this.promisedSetState({
              tempAccessToken: data.data.access_token,
              tempRefreshToken: data.data.refresh_token,
            });

            // check for successStatus
            // if status passes, set user to authenticated
            // save access_token, refresh_token, and expires_in to cookie(?)
            // then redirect to /dashboard

            if (data.data.success) {
              this.props.setAuthenticated();

              await this.updateTempTokens(
                localStorage.getItem("user"),
                data.data.access_token,
                data.data.refresh_token
              );

              await this.getTokenInfo();

              setTimeout(function () {
                window.location.href = "/dashboard";
              }, 2000);
            } else {
              await this.promisedSetState({
                error: true,
                errorType: data.data.errorName,
                errorDescription: data.data.error_message,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
  };

  getTokenInfo = async () => {
    // get user info from hubspot
    await axios
      .put("/hubspot/get/token-info", {
        access_token: this.state.tempAccessToken,
      })
      .then(
        (response) => {
          let data = response.data;
          //localStorage.setItem("user", data.tokenInfo.user);
          localStorage.setItem("user", data.tokenInfo.user);

          if (data.tokenInfo.user) this.props.setAuthenticated();
          else this.props.unsetAuthenticated();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateTempTokens = async (user, access, refresh) => {
    await axios
      .put("/api/user/update", {
        usernameToUpdate: user,
        update: { tempAccessToken: access, tempRefreshToken: refresh },
      })
      .then(
        (response) => {
          //let data = response.data;
          //console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  loginSuccess = (e) => {
    return (
      <div id="loginSuccess">
        <div className="section-header">
          <h3 className="section-title c-white">
            Login successful. Redirecting you to your dashboard.
          </h3>
          <div className="section-copy"></div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="sign-in" className="ta-center">
        <div className="container">
          {this.state.error ? (
            <div className="error-message">
              <h2>Something went wrong!</h2>
              <h3>{this.state.errorType}</h3>
              <div className="description">{this.state.errorDescription}</div>
            </div>
          ) : (
            <img className="load-icon" src="/images/loader.svg" alt="loading" />
          )}
        </div>
      </div>
    );
  }
}
