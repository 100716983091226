import React, { Component } from "react";
import axios from "axios";
import validator from "validator";
import jwt_decode from "jwt-decode";

import SignInForm from "./components/SignInForm";
import { GoogleLogin } from "@react-oauth/google";

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";

const DATABASE_API = require("../../api/db-functions.js");

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      avatar: "",
      googleUserId: "",
      loginFailure: false,
      noBlankFields: true,
      userExistsInDatabase: true,
      authenticating: false,

      termsModal: false,
    };

    this.googleUser = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.signinForm = this.signinForm.bind(this);
    this.loginSuccess = this.loginSuccess.bind(this);
  }

  toggle = (name, value) => {
    this.setState({ [name]: value });
  };

  componentDidMount = async () => {
    if (localStorage.getItem("authenticated"))
      window.location.href = "/dashboard";
  };

  reset = () => {
    this.setState({
      loginFailure: false,
      noBlankFields: true,
      userExistsInDatabase: true,
    });
  };

  // saves input into local variables
  handleChange = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    this.reset();
    await this.props.updateStates(event.target.name, event.target.value);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.signin();
  };

  handleGoogleSignin = async (response) => {
    this.googleUser = response;

    // if google authentication is success and we receive an accessToken,
    if ("email" in response) {
      this.setState({ authenticating: true, noBlankFields: true });

      await this.props.updateStates("username", response.email);

      // check if user exists in the database
      let userExists = await this.userExists(response.email);

      // sign user in
      if (userExists) {
        this.props.setAuthenticated();
        localStorage.setItem("user", response.email.toLowerCase());
        let getUserDataSuccess = await DATABASE_API.getUserData();

        // check if customer exists in stripe. create customer if not
        let customerExists = await DATABASE_API.getCustomer();
        if (!customerExists) await DATABASE_API.createCustomer();

        await DATABASE_API.getSubscriptionDetails();
        await DATABASE_API.getProductName();
        let accountLimitsSuccess = await DATABASE_API.getAccountLimits();
        let getHMACHashSuccess = await DATABASE_API.getHMACHash();

        // redirect to frontpage
        if (getUserDataSuccess && accountLimitsSuccess && getHMACHashSuccess)
          setTimeout(() => (window.location.href = "/dashboard"), 2000);
      }

      // if user does not exist, display terms dialog box
      else await this.setState({ termsModal: true });
    } else this.setState({ authenticating: false });
  };

  // create new user with Google Sign In
  continueAccountCreation = async () => {
    await this.setState({ termsModal: false });
    let createAccountSuccess = await this.createSocialAccount();

    if (createAccountSuccess) {
      this.props.setAuthenticated();
      localStorage.setItem("user", this.props.state.username.toLowerCase());

      let getUserDataSuccess = await DATABASE_API.getUserData();

      // check if customer exists in stripe. create customer if not
      let customerExists = await DATABASE_API.getCustomer();
      if (!customerExists) await DATABASE_API.createCustomer();

      await DATABASE_API.getSubscriptionDetails();
      await DATABASE_API.getProductName();
      let accountLimitsSuccess = await DATABASE_API.getAccountLimits();
      let getHMACHashSuccess = await DATABASE_API.getHMACHash();

      if (getUserDataSuccess && accountLimitsSuccess && getHMACHashSuccess) {
        // redirect to frontpage
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 2000);
      }
    }
  };

  createSocialAccount = () => {
    return axios
      .post("/api/user/create", {
        username: this.googleUser.email.toLowerCase(),
        firstName: this.googleUser.given_name,
        lastName: this.googleUser.family_name,
        googleUserId: this.googleUser.jti,
        avatar: this.googleUser.picture,
        avatarFilename:
          this.googleUser.given_name + " " + this.googleUser.family_name,
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  noBlankFields = () => {
    if (this.props.state.username && this.props.state.password) return true;
    else return false;
  };

  // check database for user.
  userExists = async (username) => {
    return axios
      .put("/api/get/user", {
        usernameToCheck: username ? username : this.props.state.username,
      })
      .then(
        (response) => {
          return response.data.success;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  signin = async () => {
    if (
      validator.isEmail(this.props.state.username) &&
      !validator.isEmpty(this.props.state.password)
    ) {
      this.setState({ authenticating: true, noBlankFields: true });
      let userExists = await this.userExists();

      if (userExists) this.auth();
      else
        this.setState({ authenticating: false, userExistsInDatabase: false });
    } else {
      this.setState({ noBlankFields: false });
    }
  };

  auth = async () => {
    await axios
      .put("/api/auth", {
        usernameToCheck: this.props.state.username.toLowerCase(),
        passwordToCheck: this.props.state.password,
      })
      .then(
        async (response) => {
          let data = response;
          let successStatus = data.data.success;

          if (successStatus) {
            this.props.setAuthenticated();
            localStorage.setItem(
              "user",
              this.props.state.username.toLowerCase()
            );

            let getUserDataSuccess = await DATABASE_API.getUserData();

            // check if customer exists in stripe. create customer if not
            let customerExists = await DATABASE_API.getCustomer();
            if (!customerExists) await DATABASE_API.createCustomer();

            await DATABASE_API.getSubscriptionDetails();
            await DATABASE_API.getProductName();
            let accountLimitsSuccess = await DATABASE_API.getAccountLimits();
            let getHMACHashSuccess = await DATABASE_API.getHMACHash();

            if (
              getUserDataSuccess &&
              accountLimitsSuccess &&
              getHMACHashSuccess
            ) {
              // redirect to frontpage

              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 2000);
            }
          } else this.setState({ loginFailure: true, authenticating: false });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  signinForm = (e) => {
    return (
      <div id="signinForm" className="c-white v-center ta-left">
        <div className="section-header">
          <h3 className="section-title">Sign into Schema Helper</h3>
          <div className="section-copy"></div>
        </div>

        <div className="form-container">
          {this.state.loginFailure && (
            <div className="error-label">Incorrect username or password.</div>
          )}

          {!this.state.noBlankFields && (
            <div className="error-label">
              Username and password fields cannot be blank.
            </div>
          )}

          {!this.state.userExistsInDatabase && (
            <div className="error-label">This username does not exist.</div>
          )}

          <SignInForm
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            state={this.state}
          />

          <div className="other-links ta-left">
            <ul>
              <li>
                <a href="/recover">Forgot password</a>
              </li>
            </ul>
          </div>

          <div className="social-signin">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                this.handleGoogleSignin(
                  jwt_decode(credentialResponse.credential)
                );
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              shape="pill"
            />
          </div>

          {this.state.authenticating ? (
            <div className="retreiving-data">
              <div className="progress">
                <div className="progress__item--inf-prog"></div>
              </div>
              <div className="progress-label">Authenticating...</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  loginSuccess = (e) => {
    return (
      <div id="loginSuccess">
        <div className="section-header">
          <h3 className="section-title">
            Login successful! Redirecting you to your dashboard.
          </h3>
          <div className="section-copy"></div>
          <div className="loading">
            <img className="load-icon" src="/images/loader.svg" alt="loading" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    //const signinForm = this.signinForm();
    //const loginSuccess = this.loginSuccess();

    return (
      <div id="sign-in" className="flex-container">
        <div className="left bg-midnightblue-2 c-white">
          <div className="content">
            <div className="logo ta-center">
              <img src="/images/logo-white-full@2x.png" alt="logo" />
            </div>

            <div className="image ta-center">
              <img
                src="/images/Schemata-Login-Graphic-Dark.svg"
                alt="login graphic dark"
              />
            </div>

            {/*<span className="tagline ta-center">The schema tool.</span>*/}
          </div>

          <div className="other-links fixed-bottom">
            <ul>
              
              <li>
                <a
                  href="https://info.schemahelper.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
              <li>
                <a href="https://schemahelper.com/terms">Terms</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="right bg-midnightblue ta-center">
          {this.signinForm()}

          <div className="other-links fixed-bottom ta-left">
            <ul>
              <li>
                Don’t have an account? <a href="/register">Sign up for free.</a>
              </li>
            </ul>
          </div>
        </div>

        {/*
        <div className="container">
          {localStorage.getItem("authenticated") ? loginSuccess : signinForm}
        </div>
        */}

        {this.state.termsModal && (
          <Dialog
            open={this.state.termsModal}
            onClose={() => this.toggle("termsModal")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="ta-center"
            p={2}
          >
            <DialogTitle id="alert-dialog-title">Terms of Use</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By clicking continue, you are confirming that you have read and
                are agreeing to the{" "}
                <a
                  href="https://schemahelper.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                .
              </DialogContentText>
            </DialogContent>

            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                classes={{
                  root: "primary",
                }}
                onClick={this.continueAccountCreation}
              >
                Continue
              </Button>

              <Button
                classes={{
                  root: "secondary",
                }}
                onClick={() => {
                  this.toggle("termsModal");
                  this.toggle("authenticating");
                  window.location.reload(true);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}
