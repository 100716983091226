import React, { Component } from "react";

/* Components / Views */
import Header from "../../components/header/header";

import UserInfo from "./components/user-info";
import ChangePassword from "./components/change-password";

const UTIL = require("../../util/util");

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    document.title = "Profile | Schema Helper";
    UTIL.updateActivity();
  };

  render() {
    return (
      <div id="profile" className="content-window">
        <Header title="Profile" />

        <div className="components">
          <UserInfo state={this.props.state} />
          <ChangePassword />
        </div>
      </div>
    );
  }
}
