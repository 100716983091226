import React, { Component } from "react";
import axios from "axios";

export default class Recover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      recoverySuccess: false,
      userExists: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkForUser = this.checkForUser.bind(this);
    this.recoverySuccess = this.recoverySuccess.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    // check database for the user
    let userExists = await this.checkForUser();

    if (userExists) {
      await this.promisedSetState({ userExists: true });
      await this.promisedSetState({ recoverySuccess: true });
      await this.sendRecoveryEmail();
    } else this.promisedSetState({ userExists: false });
  };

  checkForUser = async () => {
    return axios
      .post("/api/get/user", {
        usernameToCheck: this.state.username.toLowerCase(),
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  sendRecoveryEmail = async () => {
    return axios
      .post("/api/user/sendRecoveryEmail", {
        user: this.state.username.toLowerCase(),
      })
      .then(
        (response) => {
          let data = response;
          let successStatus = data.data.success;

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  recoveryForm = (e) => {
    return (
      <div id="recovery-form">
        <div className="section-header">
          <div className="logo">
            <img src="/images/logo-white@2x.png" alt="logo" />
          </div>
          <h2 className="section-title">Password Recovery</h2>
          <div className="section-copy">
            To get started with the recovery process, please enter your email
            associated with the account. If the account exists, we'll send you a
            verification link to confirm.
          </div>
        </div>

        <div className="form-container">
          {!this.state.userExists ? (
            <div className="c-yellow">
              We couldn’t find an account associated with that email address.
              Please try again.
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <input
              type="text"
              name="username"
              placeholder="Email"
              value={this.state.username}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="row">
            <input
              type="submit"
              className="btn medium c-black"
              value="Recover Password"
              onClick={this.handleSubmit}
            />
          </div>
        </div>

        <div className="other-links">
          <ul>
            <li>
              <a href="/">I remember my password.</a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  recoverySuccess = (e) => {
    return (
      <div id="registration-success">
        <div className="section-header">
          <div className="logo">
            <img src="/images/logo-white@2x.png" alt="logo" />
          </div>
          <h2 className="section-title">
            A verification email has been sent to {this.state.username}.
          </h2>
          <div className="section-copy">
            Please click on the link in your email to verify and continue the
            recovery process. You may close this window.
          </div>
        </div>
      </div>
    );
  };

  render() {
    const recoveryForm = this.recoveryForm();
    const recoverySuccess = this.recoverySuccess();

    return (
      <div id="register" className="ta-center bg-midnightblue-2 c-white">
        <div className="container">
          {this.state.recoverySuccess ? recoverySuccess : recoveryForm}
        </div>
      </div>
    );
  }
}
