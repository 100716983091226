import React, { Component } from "react";
import {
  //Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  displayToggle = async () => {
    await this.promisedSetState({
      displayToggle: !this.state.displayToggle,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.toggleState}
        onClose={this.props.toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>

        {this.props.message && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.message}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={this.props.yesAction}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={this.props.toggle}
          >
            Cancel
          </Button>
        </DialogActions>

        {this.props.processing && (
          <DialogContent>
            <div className="progress">
              <div className="progress__item--inf-prog"></div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    );
  }

  /*
  render() {
    return (
      <div id="confirmation" className="modal">
        <div className="modal-content">
          <h2>{this.props.title}</h2>

          <div className="description">{this.props.message}</div>

          <div className="buttons">
            <button
              className="btn medium b-orange c-white bg-orange"
              onClick={this.props.yesAction}
            >
              Yes
            </button>

            <button
              className="btn medium b-orange c-orange bg-white"
              onClick={this.props.toggle}
            >
              No
            </button>
          </div>

          <img
            className="close close-btn"
            src="/images/close-x-black.svg"
            alt="close button"
            onClick={this.props.toggle}
          />

          {this.props.processing && (
            <div className="progress">
              <div className="progress__item--inf-prog"></div>
            </div>
          )}
        </div>
      </div>
    );
  }
  */
}
