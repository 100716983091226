import React, { Component } from "react";

// Components / Views
import Header from "../../components/header/header";
import Domains from "./components/Domains";
//import Health from "./account/health";
import Activity from "./components/Activity";

const API = require("../../api/db-functions");
const UTIL = require("../../util/util");
const accountLimits = require("../../limits/limits.json");

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.renderPage = this.renderPage.bind(this);
  }

  componentDidMount = async () => {
    document.title = "Dashboard | Schema Helper";
    UTIL.updateActivity();

    await API.getSubscriptionDetails();
    await API.getProductName();

    let userData = JSON.parse(localStorage.getItem("userData"));

    let fullName =
      userData.firstName && userData.lastName
        ? userData.firstName + " " + userData.lastName
        : userData.avatarFilename;

    // Update the user's qualifications, including "Plan Type" to their current subscription in Intercom
    const userAccountLimit = await this.getAccountLimits();

    window.Intercom("update", {
      //"Plan Type": JSON.parse(localStorage.getItem("accountLimits"))[0].package,
      "Plan Type": userAccountLimit.package,
      name: fullName,
      Verified: userData.isVerified,
      Domains: userData.domains.length,
    });

    // Update database "subscription" key
    await API.updateUserSubscription(
      localStorage.getItem("user"),
      userAccountLimit.package
    );
  };

  getAccountLimits = async () => {
    let currentAccountLimit;

    // if accessing page editor via a membership to another account
    if (this.state.accountHolderUsername)
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === this.state.accountHolderSubscription
      );
    else if (localStorage.getItem("productName"))
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === localStorage.getItem("productName")
      );
    else
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === "Free"
      );

    return currentAccountLimit[0];
  };

  renderPage = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    return (
      <div id="dashboard" className="content-window">
        <Header title="Dashboard" />

        <Domains />

        {userData && userData.domains && userData.domains.length > 0 && (
          <Activity />
        )}

        {/*<Health />*/}
      </div>
    );
  };

  render() {
    return this.renderPage();
  }
}
