import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { debounce } from "lodash";
import { Store } from "react-notifications-component";
import LZString from "lz-string";

// Components / Views
import Header from "../../components/header/header";
import Filters from "./components/filters";
import Cache from "./components/cache";
import Progress from "./components/progress";
import Table from "./components/table";
import Page from "./components/page";
import SidebarSingle from "./components/sidebar-single";
import SidebarMulti from "./components/sidebar-multi";

// Helper functions
const DATABASE_API = require("../../api/db-functions");
const UTIL = require("../../util/util");
const HELPER = require("./helperFunctions");
const GENERATE = require("./schemaGeneration");

const ARTICLE_SUBCATEGORIES = ["imported_blog_post", "normal_blog_post"];

export const DEFAULT_TEMPLATES = [
  { name: "Homepage", schemas: ["WebPage", "BreadcrumbList"] },
  { name: "Blog Posts", schemas: ["BlogPosting", "BreadcrumbList"] },
];

const offsetIncrement = 40;
let currentDomain;

export default class Pages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      schemasUsed: {},
      filter: "all",
      type: "all",
      searchTerm: "",
      totalPages: 0,
      totalPagesWithSchemas: 0,
      totalPagesMissingSchema: 0,

      pages: [],

      pagesFromCache: [],
      articles: [],
      websitePages: [],

      selectedPages: [],
      completedProgress: 0,
      schemaTemplates: [],

      loadingComplete: false,
      loadingOffset: offsetIncrement,
      progressStatus: "",
      publishedPages: [],
      deleteSchema: false,
      refreshSuccess: true,
      globalVariables: {},

      // Used for analytics
      pagesWithSchema: [],
      pagesWithoutSchema: [],
    };

    this.filteredPages = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.addEventListener("scroll", this.loadMore, false);
    currentDomain = this.props.match.params.id;
    document.title = "Pages | Schema Helper | " + currentDomain;
    UTIL.updateActivity();

    await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        async (response) => {
          let data = response.data;

          let previousUserData = JSON.parse(localStorage.getItem("userData"));

          previousUserData.domains = await this.merge(
            previousUserData.domains,
            data.data.domains,
            "domainURL"
          );

          let updatedUserData = previousUserData;

          localStorage.setItem("userData", JSON.stringify(updatedUserData));
          return true;
        },
        (error) => {
          console.log(error);
          return error;
        }
      );

    // Get user data and get token data for current domain
    let userData = JSON.parse(localStorage.getItem("userData"));
    let domains = userData.domains;

    let filteredDomain = domains.filter(function (e) {
      return e.domainURL === currentDomain;
    });

    if (filteredDomain[0])
      await this.promisedSetState({
        accountHolderUsername: filteredDomain[0].accountHolderUsername
          ? filteredDomain[0].accountHolderUsername
          : "",
        accountHolderSubscription: filteredDomain[0].accountHolderSubscription
          ? filteredDomain[0].accountHolderSubscription
          : "",
        accountHolderGlobalVariables: filteredDomain[0]
          .accountHolderGlobalVariables
          ? filteredDomain[0].accountHolderGlobalVariables
          : "",
      });

    await this.promisedSetState({
      userData: userData,
      accessToken: filteredDomain[0].accessToken,
      refreshToken: filteredDomain[0].refreshToken,
      totalPublishedSchemas: filteredDomain[0].totalPublishedSchemas,
      publishedPages: filteredDomain[0].publishedPages,
      accountLimits: await HELPER.getAccountLimits(this.state),
      globalVariables: this.state.accountHolderGlobalVariables
        ? this.state.accountHolderGlobalVariables
        : userData.globalVariables,
    });

    if (userData.activity)
      await this.promisedSetState({ activity: userData.activity });

    // Get pages from cache
    await this.promisedSetState({ progressStatus: "Checking cache..." });
    let localPagesCache = localStorage.getItem(currentDomain + "-pages");
    let localArticlesCache = localStorage.getItem(currentDomain + "-articles");

    if (localPagesCache && localArticlesCache) {
      // load cache
      localPagesCache = JSON.parse(localPagesCache);
      localArticlesCache = JSON.parse(localArticlesCache);

      // check if cache is already decompressed or not
      // if it is compressed, decompress it and reassign values
      if (!Array.isArray(localPagesCache))
        localPagesCache = JSON.parse(LZString.decompress(localPagesCache));

      if (!Array.isArray(localArticlesCache))
        localArticlesCache = JSON.parse(
          LZString.decompress(localArticlesCache)
        );

      await this.promisedSetState({
        websitePages: localPagesCache,
        articles: localArticlesCache,
        pages: localPagesCache.concat(localArticlesCache),
        loadingComplete: true,
      });

      this.sortByLastUpdated();
    } else {
      await this.promisedSetState({
        progressStatus: "No pages in cache. Fetching new pages",
      });

      await this.checkToken();
      await this.clearAndUpdateCache();
    }

    await HELPER.jQueryScripts();

    // get templates from database on load
    await this.promisedSetState({ schemaTemplates: await this.getTemplates() });
  };

  merge = async (a, b, prop) => {
    var reduced = a.filter(
      (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
    );
    return reduced.concat(b);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMore, false);
  }

  checkToken = async () => {
    // check if token is valid and refresh if necessary
    let tokenActive = await DATABASE_API.getTokenInfo(this.state.accessToken);

    if (!tokenActive) {
      let newTokens = await DATABASE_API.refreshAuthToken(
        this.state.accessToken,
        this.state.refreshToken
      );

      await DATABASE_API.updateTokensInDatabase(
        this.state.accountHolderUsername
          ? this.state.accountHolderUsername
          : localStorage.getItem("user"),
        currentDomain,
        newTokens.accessToken,
        newTokens.refreshToken
      );

      // window.location.reload();
      await this.promisedSetState({
        accessToken: newTokens.accessToken,
        refreshToken: newTokens.refreshToken,
      });
    }
  };

  setCustomState = async (key, value) => {
    await this.promisedSetState({ [key]: value });
  };

  clearSelectedPages = async () => {
    await this.promisedSetState({ selectedPages: [], completedProgress: 0 });
  };

  setSearchTerm = debounce(async (searchTerm) => {
    await this.promisedSetState({ searchTerm });
    HELPER.jQueryScripts();
  }, 500);

  loadMore = async () => {
    if (await HELPER.hasReachedBottom()) {
      await this.promisedSetState({
        loadingOffset: this.state.loadingOffset + offsetIncrement,
      });
    }
  };

  getTemplates = async () => {
    return await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          let schemaTemplates = response.data.data.schemaTemplates;

          DEFAULT_TEMPLATES.forEach((template) => {
            schemaTemplates.push(template);
          });

          return schemaTemplates;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  resetSelections = async () => {
    $(".page-item .select:checkbox:enabled").prop("checked", false);
    $(".table-header .select:checkbox:enabled").prop("checked", false);
    $(".page-item").removeClass("selected");
    $("#pages").removeClass("right-sidebar-multi");
  };

  updateSchemaCounter = async (pageId) => {
    let publishedPages = this.state.publishedPages;

    if (this.state.deleteSchema) {
      if (publishedPages.length > 0) {
        publishedPages = publishedPages.filter((id) => id !== pageId);
      }
    } else {
      if (!publishedPages.includes(pageId)) publishedPages.push(pageId);
    }

    await this.promisedSetState({ publishedPages: publishedPages });

    return await axios.put("/api/user/update", {
      usernameToUpdate: localStorage.getItem("user"),
      domainURLToUpdate: this.props.match.params.id,
      update: {
        $set: {
          "domains.$.publishedPages": publishedPages,
        },
      },
    });
  };

  getPages = async () => {
    let pages = await HELPER.getPages(this.state, currentDomain);

    if (pages) {
      await this.promisedSetState({
        websitePages: pages.websitePages,
        totalWebsitePages: pages.totalWebsitePages,
        websiteOffset: this.state.websiteOffset + offsetIncrement,
      });
      return true;
    } else return false;
  };

  getArticles = async () => {
    let pages = await HELPER.getArticles(this.state, currentDomain);

    if (pages) {
      await this.promisedSetState({
        articles: pages.articles,
        totalArticles: pages.totalArticles,
        articleOffset: this.state.articleOffset + offsetIncrement,
      });
      return true;
    } else return false;
  };

  clearAndUpdateCache = async () => {
    await this.promisedSetState({
      loadingComplete: false,
      articleOffset: 0,
      websiteOffset: 0,
      progressStatus: "",
    });
    await this.promisedSetState({
      pages: [],
      selectedPages: [],
      websitePages: [],
      articles: [],
    });
    await localStorage.removeItem(currentDomain + "-pages");
    await localStorage.removeItem(currentDomain + "-articles");

    // refresh tokens if necessary
    await this.checkToken();
    await this.updatePageCache();
  };

  updatePageCache = async () => {
    await this.promisedSetState({ schemasUsed: {} });

    let pagesSuccess = await this.getPages();
    let articleSuccess = await this.getArticles();

    if (pagesSuccess || articleSuccess) {
      let pages = await HELPER.getFilteredPages(this.state); // filter out useless key / value pairs
      let mergedPages = pages.websitePages.concat(pages.articles); // merge pages and articles

      // test each page and append testResult to each page object
      for (let i = 0; i < mergedPages.length; i++) {
        mergedPages[i]["testResult"] = await UTIL.testSchema(
          mergedPages[i].head_html
        );
      }

      await this.promisedSetState({ pages: mergedPages });

      if (await HELPER.hasPagesToGet(this.state)) {
        this.updatePageCache();

        // calculate percentage complete
        let offsets = this.state.websiteOffset + this.state.articleOffset;
        let totalPages =
          this.state.totalWebsitePages + this.state.totalArticles;
        let percentComplete = (offsets / totalPages) * 100;
        if (percentComplete >= 100) percentComplete = 100;

        await this.promisedSetState({
          progressStatus:
            "Retrieving pages: " + parseInt(percentComplete) + "%",
        });
      } else {
        localStorage.setItem(
          currentDomain + "-pages",
          JSON.stringify(this.state.websitePages)
        );

        localStorage.setItem(
          currentDomain + "-articles",
          JSON.stringify(this.state.articles)
        );

        // update last cached date
        localStorage.setItem(currentDomain + "-lastCached", new Date());

        await this.promisedSetState({ loadingComplete: true });
        this.sortByLastUpdated();
        await this.getTotalMissingSchemas();
        await this.updateAnalytics();

        Store.addNotification({
          message: "Cache has been updated",
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 4000,
            onScreen: false,
            showIcon: true,
          },
        });
      }
    }
  };

  hasSchema = async (page) => {
    let pagesWithSchema = this.state.pagesWithSchema;
    let pagesWithoutSchema = this.state.pagesWithoutSchema;

    if (page.head_html && page.head_html.includes("application/ld+json")) {
      pagesWithSchema.push({ id: page.id, type: page.analytics_page_type });
      await this.promisedSetState({ pagesWithSchema });
      return true;
    } else {
      pagesWithoutSchema.push({ id: page.id, type: page.analytics_page_type });
      await this.promisedSetState({ pagesWithoutSchema });
    }
  };

  getTotalMissingSchemas = async () => {
    let missingSchemaCounter = 0;

    // filter the array of pages so that only current selected domain is available
    let filteredPages = this.state.pages.filter(
      (page) => page.live_domain === this.props.match.params.id
    );
    let totalPagesLength = filteredPages.length;

    // loop through and count how many pages have schemas
    filteredPages.map((page) =>
      !this.hasSchema(page) ? missingSchemaCounter++ : ""
    );

    this.promisedSetState({
      totalPages: totalPagesLength,
      totalPagesWithSchemas: totalPagesLength - missingSchemaCounter,
      totalPagesMissingSchema: missingSchemaCounter,
    });

    // if the maximum amount of allow published schemas is anything other than 0, display warning

    if (this.state.accountLimits && this.state.accountLimits.maxPages !== 0) {
      let annoucementMessage =
        "You're using " +
        this.state.publishedPages.length +
        " of " +
        this.state.accountLimits.maxPages +
        " published schemas available for your current plan.";

      Store.addNotification({
        title: annoucementMessage,
        message: "Upgrade to publish more schemas.",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 10000,
          onScreen: false,
          showIcon: true,
        },
        /*
        onRemoval: (id, removedBy) => {
          window.location.href = "/settings/subscription";
        },
        */
      });
    }

    localStorage.setItem(
      this.props.match.params.id + "-total-schemas",
      JSON.stringify({
        totalPages: totalPagesLength,
        totalPagesWithSchemas: totalPagesLength - missingSchemaCounter,
        totalPagesMissingSchema: missingSchemaCounter,
      })
    );

    // add or update total number of pages and total number of pages missing a schema
    axios.put("/api/user/update", {
      usernameToUpdate: localStorage.getItem("user"),
      domainURLToUpdate: this.props.match.params.id,
      update: {
        $set: {
          "domains.$.totalPages": totalPagesLength,
          "domains.$.totalPagesWithSchemas":
            totalPagesLength - missingSchemaCounter,
          "domains.$.pagesMissingSchema": missingSchemaCounter,
          "domains.$.pagesWithSchema": this.state.pagesWithSchema,
          "domains.$.pagesWithoutSchema": this.state.pagesWithoutSchema,
        },
      },
    });
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
    await HELPER.jQueryScripts();
  };

  updateState = async (key, value) => {
    await this.promisedSetState({ [key]: value });
  };

  // filtering
  filter = (pages) => {
    const filter = this.state.filter;
    const type = this.state.type;
    const searchTerm = this.state.searchTerm;
    const loadingOffset = this.state.loadingOffset;

    if (filter === "all")
      if (type !== "all")
        pages = pages.filter(
          (page) =>
            page.subcategory === type &&
            page.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      else
        pages = pages.filter((page) =>
          page.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    // filter and only keep pages that have schemas
    else if (filter === "with-schemas")
      if (type !== "all")
        pages = pages.filter(
          (page) =>
            page.subcategory === type &&
            JSON.stringify(page).includes("application/ld+json") &&
            page.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      else
        pages = pages.filter(
          (page) =>
            JSON.stringify(page).includes("application/ld+json") &&
            page.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    // filter and only keep pages that are missing a schema
    else if (filter === "missing-schemas")
      if (type !== "all")
        pages = pages
          .filter(
            (page) =>
              page.subcategory === type &&
              !JSON.stringify(page).includes("application/ld+json") &&
              page.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice(0, loadingOffset);
      else
        pages = pages
          .filter(
            (page) =>
              !JSON.stringify(page).includes("application/ld+json") &&
              page.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice(0, loadingOffset);
    // filter and only keep pages that have warnings
    else if (filter === "warnings")
      if (type !== "all")
        pages = pages.filter(
          (page) => page.subcategory === type && page.testResult === false
        );
      else pages = pages.filter((page) => page.testResult === false);

    this.filteredPages = pages;

    return pages
      .slice(0, this.state.loadingOffset)
      .map((page, index) => (
        <Page
          key={index}
          page={page}
          currentDomain={currentDomain}
          removeExistingSchema={this.removeExistingSchema}
          toggleSelectedPage={this.toggleSelectedPage}
          selectSinglePage={this.selectSinglePage}
        />
      ));
  };

  sortByLastUpdated = async () => {
    let newPages = this.state.pages;
    newPages.sort((a, b) => new Date(b.updated) - new Date(a.updated));
    await this.promisedSetState({ pages: newPages });
  };

  updateAnalytics = async () => {
    // iterate through all of the pages
    // retrieve it's current schema type
    // update schemasUsed object with current values

    let pages = this.state.pages;

    pages.map(async (page) => {
      let oldPageHead = page.head_html;

      if (oldPageHead) {
        // get current schema for current page
        // grab entire schema script
        try {
          let currentSchema = oldPageHead.match(
            /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi
          );

          if (currentSchema) {
            currentSchema = currentSchema.toString();

            // strip tags
            currentSchema = currentSchema.replace(/<\/?[^>]+(>|$)/g, "");
            currentSchema = JSON.parse(currentSchema);
            let currentSchemaArray = [];

            // if schema is an array of schemas (multiple schemas)
            if (Array.isArray(currentSchema)) {
              currentSchema.forEach((schema) => {
                currentSchemaArray.push(schema["@type"]);
              });
            } else currentSchemaArray.push(currentSchema["@type"]);

            let schemasUsed = this.state.schemasUsed;
            currentSchemaArray.forEach((schema) => {
              if (schema in schemasUsed) {
                if (schemasUsed[schema] > 0) {
                  schemasUsed[schema] = schemasUsed[schema] + 1;
                }
              } else schemasUsed[schema] = 1;
            });

            await this.promisedSetState({ schemasUsed: schemasUsed });
          }
        } catch (err) {
          console.log(err);
        }
      }
    });

    DATABASE_API.updateSchemasUsed(
      localStorage.getItem("user"),
      this.props.match.params.id,
      this.state.schemasUsed
    );
  };

  /*
   * Page elements
   */
  selectSinglePage = (page) => async (e) => {
    await this.promisedSetState({ selectedSinglePage: page });
  };

  toggleSelectedPage = (page) => async (e) => {
    let selectedPagesArray = this.state.selectedPages;
    $(".page-item").removeClass("active");

    // if the checkbox is checked by the user, add page data to array
    // if the checkbox is unchecked by the user, remove page data from array
    if (e.target.checked) selectedPagesArray.push(page);
    else {
      selectedPagesArray = selectedPagesArray.filter(
        (item) => item.id !== page.id
      );
    }

    await this.promisedSetState({ selectedPages: selectedPagesArray });
  };

  // if the checkbox is checked by the user, add all pages to the selected array
  // if user unchecks, de-select all pages
  toggleSelectAllPages = async (event) => {
    let pages = this.filteredPages ? this.filteredPages : this.state.pages;

    if ($(".table-header input[type=checkbox]").prop("checked") === true)
      await this.promisedSetState({ selectedPages: pages });
    else await this.promisedSetState({ selectedPages: [] });
  };

  domainSelector = () => {
    let availableDomains;

    if (this.state.userData.domains) {
      availableDomains = this.state.userData.domains.map((domain) => (
        <li data-domain={domain.domainURL} key={domain.domainURL}>
          <a href={"/pages/" + domain.domainURL}>{domain.domainURL}</a>
        </li>
      ));
    }

    return (
      <div
        className="custom-select-dropdown"
        aria-haspopup="true"
        role="button"
        tabIndex="0"
      >
        <div className="current-selection">
          <h1>{this.props.match.params.id}</h1>{" "}
          <img src="/images/triangle-black.svg" alt="dropdown indicator" />
        </div>
        <ul className="items">{availableDomains}</ul>
      </div>
    );
  };

  /*
   * Schema generation
   */
  generateFromTemplate = async (template) => {
    const filteredTemplate = this.state.schemaTemplates.find(
      (savedTemplate) => savedTemplate.name === template
    );

    const schemasInTemplate = filteredTemplate?.schemas;

    if (schemasInTemplate) {
        // Check and refresh tokens if necessary
        await this.checkToken();

        // Iterate through pages and generate appropriate schema
        for (const page of this.state.selectedPages) {
            let schemaGraph = {
                "@context": "http://schema.org",
                "@graph": [],
            };

            for (const schema of schemasInTemplate) {
                schemaGraph["@graph"].push(await GENERATE.generateSchemaJSON(page, this.state, schema));
            }

            schemaGraph = await HELPER.formatSchema(schemaGraph);
            let newPageHead = await HELPER.getNewPageHead(page, schemaGraph);
            let newTestResult = await UTIL.testSchema(newPageHead);

            await this.updatePage(page, newPageHead);
            await this.updateSelectedPagesInCache(page, newPageHead, newTestResult);

            await this.promisedSetState({
                completedProgress: this.state.completedProgress + 1,
            });
        }
    }

    this.resetSelections();
    this.sortByLastUpdated();
  };

  updateSelectedPagesInCache = async (page, newPageHead, newTestResult) => {
    let localPagesCache = localStorage.getItem(currentDomain + "-pages");
    let localArticlesCache = localStorage.getItem(currentDomain + "-articles");

    // retreive existing cache
    localPagesCache = JSON.parse(
      localStorage.getItem(currentDomain + "-pages")
    );

    localArticlesCache = JSON.parse(
      localStorage.getItem(currentDomain + "-articles")
    );

    //Find index of specific object using findIndex method.
    let pagesIndex = localPagesCache.findIndex((obj) => obj.id === page.id);
    let articlesIndex = localArticlesCache.findIndex(
      (obj) => obj.id === page.id
    );

    if (pagesIndex !== -1) {
      //Update object's head_html property.
      localPagesCache[pagesIndex].head_html = newPageHead;
      localPagesCache[pagesIndex].testResult = newTestResult;

      localStorage.setItem(
        currentDomain + "-pages",
        JSON.stringify(localPagesCache)
      );
    }
    if (articlesIndex !== -1) {
      //Update object's headHtml property.
      localArticlesCache[articlesIndex].headHtml = newPageHead;
      localArticlesCache[articlesIndex].testResult = newTestResult;

      localStorage.setItem(
        currentDomain + "-articles",
        JSON.stringify(localArticlesCache)
      );
    }

    // Merge and update pages
    let mergedPages = localPagesCache.concat(localArticlesCache);
    await this.promisedSetState({
      pages: mergedPages,
      websitePages: localPagesCache,
      articles: localArticlesCache,
    });

    this.sortByLastUpdated();
  };

  updatePage = async (page, newPageHead) => {
    if (
      page.analytics_page_type === "blog-post" ||
      ARTICLE_SUBCATEGORIES.includes(page.subcategory)
    ) {
      await this.updateSchemaCounter(page.id);
      await DATABASE_API.updateActivity(
        localStorage.getItem("user"),
        this.state.activity
      );

      return await DATABASE_API.updateArticle(
        this.state.accessToken,
        page.id,
        newPageHead,
        this.state.selectedSchema,
        ""
      );
    } else if (page.analytics_page_type === "standard-page") {
      await this.updateSchemaCounter(page.id);
      await DATABASE_API.updateActivity(
        localStorage.getItem("user"),
        this.state.activity
      );

      return await DATABASE_API.updatePage(
        this.state.accessToken,
        page.id,
        newPageHead,
        this.state.selectedSchema,
        ""
      );
    }
  };

  // updating pages
  updateArticle = async (page, newPageHead) => {
    if (
      page.analytics_page_type === "blog-post" ||
      ARTICLE_SUBCATEGORIES.includes(page.subcategory)
    ) {
      await this.updateSchemaCounter(page.id);
      await DATABASE_API.updateActivity(
        localStorage.getItem("user"),
        this.state.activity
      );

      return await DATABASE_API.updateArticle(
        this.state.accessToken,
        page.id,
        newPageHead,
        this.state.selectedSchema,
        ""
      );
    }
  };

  updateWebsitePage = async (page, newPageHead) => {
    if (page.analytics_page_type === "standard-page") {
      await this.updateSchemaCounter(page.id);
      await DATABASE_API.updateActivity(
        localStorage.getItem("user"),
        this.state.activity
      );

      return await DATABASE_API.updatePage(
        this.state.accessToken,
        page.id,
        newPageHead,
        this.state.selectedSchema,
        ""
      );
    }
  };

  // removes a single schema
  //removeExistingSchema = (page) => async (e) => {
  removeExistingSchema = async (page) => {
    let newPageHead = page.head_html
      .replace(/<script type="application\/ld\+json">(.|\n)*?<\/script>/gi, "")
      .trim();

    if (
      page.analytics_page_type === "blog-post" ||
      ARTICLE_SUBCATEGORIES.includes(page.subcategory)
    )
      await this.updateArticle(page, newPageHead);
    if (
      page.analytics_page_type === "standard-page" ||
      page.analytics_page_type === "landing-page"
    )
      await this.updateWebsitePage(page, newPageHead);

    await this.updateSelectedPagesInCache(page, newPageHead);
    this.resetSelections();
    this.sortByLastUpdated();
    await this.promisedSetState({ selectedPages: [] });

    Store.addNotification({
      message: "Schema was successfully removed",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  // removes selected schemas
  removeMultipleExistingSchemas = async () => {
    await this.state.selectedPages.map(async (page) => {
      let oldPageHead = page.headHtml ? page.headHtml : page.head_html;
      let newPageHead = oldPageHead
        .replace(
          /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi,
          ""
        )
        .trim();

      if (
        page.analytics_page_type === "blog-post" ||
        ARTICLE_SUBCATEGORIES.includes(page.subcategory)
      )
        await this.updateArticle(page, newPageHead);
      if (
        page.analytics_page_type === "standard-page" ||
        page.analytics_page_type === "landing-page" ||
        page.subcategory === "site_page" ||
        page.subcategory === "landing_page"
      )
        await this.updateWebsitePage(page, newPageHead);
      await this.updateSelectedPagesInCache(page, newPageHead);

      return true;
    });

    this.resetSelections();
    this.sortByLastUpdated();
    await this.promisedSetState({
      selectedPages: [],
      deleteSchema: false,
    });

    Store.addNotification({
      message: "Schemas were successfully removed.",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  render() {
    let pageOutput;

    if (this.state.pages.map !== null)
      pageOutput = this.filter(this.state.pages);

    return (
      <div id="pages" className="content-window">
        <Header domainSelector={true} currentDomain={currentDomain} />

        <Filters
          setSearchTerm={this.setSearchTerm}
          handleChange={this.handleChange}
          state={this.state}
          webWorkerFilter={this.webWorkerFilter}
          updateState={this.updateState}
        />

        {this.state.loadingComplete ? (
          <Cache
            currentDomain={currentDomain}
            clearAndUpdateCache={this.clearAndUpdateCache}
          />
        ) : (
          <Progress state={this.state} />
        )}

        <Table
          toggleSelectAllPages={this.toggleSelectAllPages}
          pageOutput={pageOutput}
        />

        {this.state.selectedSinglePage && (
          <SidebarSingle
            state={this.state}
            page={this.state.selectedSinglePage}
            currentDomain={currentDomain}
            checkToken={this.checkToken}
            updateState={this.updateState}
            clearSelectedPages={this.clearSelectedPages}
            generateFromTemplate={this.generateFromTemplate}
            removeExistingSchema={this.removeExistingSchema}
          />
        )}

        {this.state.selectedPages.length > 0 && (
          <SidebarMulti
            state={this.state}
            updateState={this.updateState}
            clearSelectedPages={this.clearSelectedPages}
            generateFromTemplate={this.generateFromTemplate}
            checkToken={this.checkToken}
            removeMultipleExistingSchemas={this.removeMultipleExistingSchemas}
          />
        )}
      </div>
    );
  }
}
