import React, { Component } from "react";
//import ReactTooltip from "react-tooltip";

// Icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const { COMPONENTS, LOCAL_BUSINESS } = require("../../../schemas");

//const HELPER = require("../helperFunctions");

export default class SchemaDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.state.selectedSchemas.length === 1)
      this.setState({ opened: true });
    else this.setState({ opened: false });
  };

  // Checks list of schemas we support. Returns boolean if schema is allowed
  isSchemaAllowed = (schema) => {
    let allowedComponents = [];
    for (const component in COMPONENTS) allowedComponents.push(component);
    return allowedComponents.includes(schema) ? true : false;
  };

  // Checks list of LocalBusiness schemas we support. Returns boolean if schema is allowed
  isLocalBusinessSchemaAllowed = (schema) => {
    return LOCAL_BUSINESS.includes(schema) ? true : false;
  };

  render() {
    const schema = this.props.schema;
    const index = this.props.index;

    const DynamicSchemaForm = this.isLocalBusinessSchemaAllowed(schema)
      ? COMPONENTS["LocalBusiness"]
      : COMPONENTS[schema];

    return (
      <div
        id={"schema" + index}
        key={"schema" + index}
        data-index={index}
        className={
          "schema-fields-drawer " + (this.state.opened ? "opened" : "closed")
        }
      >
        <h3
          className="title bg-midnightblue c-white"
          onClick={() => this.setState({ opened: !this.state.opened })}
          draggable={true}
          onDragOver={(ev) => ev.preventDefault()}
          onDragStart={this.props.handleDrag}
          onDrop={this.props.handleDrop}
          data-index={index}
        >
          <DragHandleIcon className="draggable" />
          <span>
            {this.isLocalBusinessSchemaAllowed(schema)
              ? "LocalBusiness"
              : schema}
          </span>
          <DeleteForeverOutlinedIcon
            className="delete"
            onClick={() => this.props.removeSingleSchema(schema, index)}
          />
          <ExpandMoreIcon className="expand" />
        </h3>

        <div className="content">
          <DynamicSchemaForm
            key={schema + "-" + index}
            setSchema={this.props.setSchema}
            currentIndex={index}
            state={this.props.state}
          />

          <span
            className="remove-schema c-teal h-c-midnightblue "
            data-schema={schema}
            onClick={() => this.props.removeSingleSchema(schema)}
          >
            Remove Schema Type
          </span>
        </div>
      </div>
    );
  }
}
