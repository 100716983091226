import React, { Component } from "react";

// Components & Views
import Header from "../../../components/header/header";
import ManageAccount from "./Manage";
import Verify from "./Verify";
import PIN from "./PIN";
import Subscription from "./Subscription";

const DATABASE_API = require("../../../api/db-functions");
const UTIL = require("../../../util/util");

export default class MyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    await DATABASE_API.getUserData();
    UTIL.updateActivity();
  };

  render() {
    return (
      <div id="account" className="content-window">
        <Header title="Manage Account" />

        <div className="components">
          {/*<div className="section-title">Account Information</div>*/}

          <Verify />
          <PIN />
          <Subscription />
          <ManageAccount />
        </div>
      </div>
    );
  }
}
