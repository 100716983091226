import React, { Component } from "react";
import { Paper, Box, TextField, IconButton } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DATABASE_API = require("../../../../api/db-functions");

export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: "",
      answer: "",
    };

    this.faqs = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    await DATABASE_API.getUserData();

    const globalVariables = JSON.parse(
      localStorage.getItem("userData")
    ).globalVariables;

    if (globalVariables.faqs.length > 0)
      await this.generateFields(globalVariables.faqs);
  };

  handleChange = async (event) => {
    const NAME = event.target.name;
    const VALUE = event.target.value;
    const INDEX = parseInt(event.target.getAttribute("data-faq"));
    let faqs = this.props.state.faqs;

    if (NAME === "question") {
      await this.promisedSetState({ question: VALUE });
      faqs[INDEX].question = VALUE;
    } else if (NAME === "answer") {
      await this.promisedSetState({ answer: VALUE });
      faqs[INDEX].answer = VALUE;
    }

    this.props.setCustomState("faqs", faqs);
  };

  addFaq = () => {
    let counter = this.faqs.length;
    let faqs = this.props.state.faqs;

    let faqItem = {
      question: "",
      answer: "",
    };

    faqs.push(faqItem);

    this.faqs.push(this.faqAccordion("", counter));
    this.props.setCustomState("faqsDisplay", this.faqs);
    this.props.setCustomState("faqs", faqs);
  };

  generateFields = async (faqs) => {
    faqs.map((data, index) => this.faqs.push(this.faqAccordion(data, index)));
    this.props.setCustomState("faqsDisplay", this.faqs);
  };

  faqAccordion = (data, counter) => {
    return (
      <Accordion key={"accordion-" + counter}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              letterSpacing: "0.27px",
              textTransform: "uppercase",
            }}
          >
            {data.question ? data.question : "FAQ item #" + (counter + 1)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={4}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Question"
              //helperText="This is the question that is frequently asked."
              name="question"
              inputProps={{
                "data-faq": counter,
              }}
              onChange={this.handleChange}
              aria-label="question"
              defaultValue={data.question ? data.question : this.state.question}
              fullWidth={true}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Box>

          <Box mb={4}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Answer"
              //helperText="This is the answer to the frequently asked question."
              name="answer"
              inputProps={{
                "data-faq": counter,
              }}
              onChange={this.handleChange}
              aria-label="answer"
              defaultValue={data.answer ? data.answer : this.state.answer}
              fullWidth={true}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Box>

          <div className="d-flex flex-end" style={{ marginTop: "30px" }}>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => this.removeHourSet(counter)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  removeHourSet = (index) => {
    let faqs = this.props.state.faqs;

    this.faqs.splice(index, 1);
    faqs.splice(index, 1);
    this.props.setCustomState("faqsDisplay", this.faqs);
    this.props.setCustomState("faqs", faqs);
  };

  render() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "45px" }}
      >
        <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
          <Box className="section" sx={{ p: 3 }}>
            <div className="section-title">
              <h3>FAQs</h3>

              <IconButton
                className="text-button"
                aria-label="delete"
                color="primary"
                onClick={this.addFaq}
              >
                <AddCircleIcon fontSize="small" />
                <span className="text">Add FAQ</span>
              </IconButton>
            </div>

            <Box>{this.props.state.faqsDisplay}</Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}
