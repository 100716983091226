import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import LaunchIcon from "@mui/icons-material/Launch";
import CodeIcon from "@mui/icons-material/Code";
//import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DeleteIcon from "@mui/icons-material/Delete";

const UTIL = require("../../../util/util");
const { COMPONENTS } = require("../../../schemas/index");

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  checkSchemas = () => {
    let selectedSchemas = this.props.state.selectedSchemas;
    let allowedComponents = [];
    let success = true;

    for (const component in COMPONENTS) {
      allowedComponents.push(component);
    }

    selectedSchemas.forEach(function (schema) {
      if (!allowedComponents.includes(schema)) success = false;
    });

    return success;
  };

  validateSchema = () => {
    const URL =
      "https://validator.schema.org/#url=" +
      encodeURI(this.props.pageDetails.absolute_url);

    window.open(URL, "_blank").focus();
  };

  render() {
    //const testResults = this.props.state.testResults;

    return (
      <aside className="right-sidebar">
        <div className="page-info">
          {/*<div className="created">
            <h3>Created</h3>
            <span className="text">
              {this.props.pageDetails.created
                ? UTIL.timeConverter(this.props.pageDetails.created)
                : ""}
            </span>
          </div>
              */}
          <div className="updated">
            <h3>Last updated</h3>
            <span className="text">
              {this.props.pageDetails.updated
                ? UTIL.timeConverter(this.props.pageDetails.updated)
                : ""}
            </span>
          </div>
          <div className="updated-by">
            <h3>Last updated by</h3>
            <span className="text">
              {this.props.pageDetails.author_name
                ? this.props.pageDetails.author_name
                : ""}
            </span>
          </div>

          {/*<div className="health">
            <h3>Analysis</h3>
            <span>
              {Object.keys(testResults).length > 0 ? (
                <div className="warning d-flex align-center">
                  <ErrorOutlineIcon
                    style={{
                      color: "#FFCC00",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                  <span className="text">Issues found</span>
                </div>
              ) : (
                <div className="healthy d-flex align-center">
                  <CheckCircleOutlineIcon
                    style={{
                      color: "#4BB543",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  <span className="text">Healthy</span>
                </div>
              )}
            </span>
                  </div>*/}
          <div className="status">
            <h3>Status</h3>
            {UTIL.hasSchema(this.props.pageDetails.head_html) ? (
              <span className="published">Published</span>
            ) : (
              <span className="no-schema">No Schema</span>
            )}
          </div>
        </div>

        <ul className="sidebar-options">
          <a
            className="c-orange"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.pageDetails.absolute_url}
          >
            <LaunchIcon style={{ marginRight: "10px" }} />
            <span>View Page</span>
          </a>

          <span className="c-orange" onClick={this.props.togglePageHeadModal}>
            <CodeIcon style={{ marginRight: "10px" }} />
            <span>View Page Head</span>
          </span>

          {UTIL.hasSchema(this.props.pageDetails.head_html) ? (
            <a
              className="c-orange"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://validator.schema.org/#url=" +
                encodeURI(this.props.pageDetails.absolute_url)
              }
            >
              <FactCheckIcon style={{ marginRight: "10px" }} />
              <span>Validate on Schema.org</span>
            </a>
          ) : (
            <span
              className="disabled c-orange"
              data-for="no-schema-tip"
              data-tip="No schema detected on the current page. Please make sure to publish the page before trying to validate."
            >
              <FactCheckIcon style={{ marginRight: "10px" }} />
              <span>Validate on Schema.org</span>

              <ReactTooltip id="no-schema-tip" />
            </span>
          )}

          {/*<li
            data-tip="Some schemas on this page are not available for editing. This could be because we currently don't support that specific schema. You'll need to edit this manually in the HubSpot page."
            data-for="edit-status"
            className={
              (this.props.schemaDetected ? "" : "disabled ") + "c-orange"
            }
            onClick={
              this.props.schemaDetected ? this.props.editSchema : this.nothing
            }
          >
            <img src="/images/edit.svg" alt="edit icon" />
            <span>Edit Schema</span>
          </li>*/}

          <li
            className={
              (this.props.schemaDetected ? "" : "disabled ") + "c-orange"
            }
            onClick={
              this.props.schemaDetected
                ? this.props.toggleDeleteConfirmModal
                : this.nothing
            }
          >
            <DeleteIcon style={{ marginRight: "10px" }} />
            <span>Delete Schema</span>
          </li>
        </ul>
      </aside>
    );
  }
}
