import React, { Component } from "react";

export default class SoftwareApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="software-app">
        <div className="text">
          <div className="title">{schemaObject["name"]}</div>

          {this.props.pageDetails.meta.meta_description && (
            <div className="description">
              {this.props.pageDetails.meta.meta_description}
            </div>
          )}

          <div className="flex-container">
            <div className="rating-info">
              <div className="label">Rating</div>
              <span className="rating">
                {schemaObject["aggregateRating"]["ratingValue"]}
              </span>

              <span
                className="stars"
                data-rating={schemaObject["aggregateRating"]["ratingValue"]}
              >
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
              </span>

              <span className="count">
                ({schemaObject["aggregateRating"]["ratingCount"]})
              </span>
            </div>

            <div className="price">
              <div className="label">Price</div>
              <span>
                {schemaObject["offers"]["price"] === "0"
                  ? "Free"
                  : schemaObject["offers"]["price"]}
              </span>
            </div>

            <div className="platforms">
              <div className="label">Platforms</div>
              <span>{schemaObject["operatingSystem"]}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
