import { data } from "jquery";
let moment = require("moment");

/* Database API calls */
const axios = require("axios");
const accountLimits = require("../limits/limits.json");
const UTIL = require("../util/util");

async function createCustomer() {
  let userData = JSON.parse(localStorage.getItem("userData"));

  return await axios
    .put("/api/stripe/createCustomer", {
      email: localStorage.getItem("user"),
      name: userData.firstName + " " + userData.lastName,
    })
    .then(
      (response) => {},
      (error) => {
        console.log(error);
      }
    );
}

/**
 * @param  {String} username
 * @returns {Object} Returns the customer data as object.
 */
async function getCustomer(username) {
  return await axios
    .put("/api/stripe/getCustomer", {
      email: username ? username : localStorage.getItem("user"),
    })
    .then(
      (response) => {
        let data = response.data.success ? response.data.customers.data[0] : "";

        if (data) return true;
        else return false;
      },
      (error) => {
        console.log(error);
      }
    );
}

/*
 * Retreives user data from database by username
 */
async function getUserData() {
  return await axios
    .post("/api/get/user", {
      usernameToCheck: localStorage.getItem("user"),
    })
    .then(
      (response) => {
        let data = response.data;
        localStorage.setItem("userData", JSON.stringify(data.data));
        return true;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
}

// check if accessToken is expired
const getTokenInfo = async (accessToken) => {
  return await axios
    .put("/hubspot/get/token-info", {
      access_token: accessToken,
    })
    .then(
      async (response) => {
        let data = response.data;

        return data.success;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

// get new token from HubSpot
// returns new accessToken and refreshToken
const refreshAuthToken = async (accessToken, refreshToken) => {
  return await axios
    .put("/hubspot/get/refreshToken", {
      access_token: accessToken,
      refresh_token: refreshToken,
    })
    .then(
      async (response) => {
        let data = response.data;

        if (data.success) {
          return {
            accessToken: data.tokenInfo.access_token,
            refreshToken: data.tokenInfo.refresh_token,
          };
        }
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

// update new tokens for this account in the database
const updateTokensInDatabase = async (
  username,
  domain,
  accessToken,
  refreshToken
) => {
  return await axios
    .put("/api/user/update", {
      usernameToUpdate: username,
      domainURLToUpdate: domain,
      update: {
        $set: {
          "domains.$.accessToken": accessToken,
          "domains.$.refreshToken": refreshToken,
        },
      },
    })
    .then(
      (response) => {
        let data = response.data;
        return data.success;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

async function getHMACHash() {
  return axios
    .post("/api/encrypt/hmac", {
      username: localStorage.getItem("user"),
    })
    .then(
      (response) => {
        let data = response.data;
        localStorage.setItem("hmac", data.hmacHash);
        return true;
      },
      (error) => {
        console.log(error);
      }
    );
}

// check stripe if user exists and has a subscription
async function getSubscriptionDetails() {
  return axios
    .put("/api/stripe/getCustomer", {
      email: localStorage.getItem("user"),
    })
    .then(
      (response) => {
        let data = response.data.success ? response.data.customers.data[0] : "";
        let subscriptionPlan;

        //console.log("subscription data", data);

        if (data) {
          localStorage.setItem("customerID", data.id);
        }

        // if user exists in stripe, in the database, and has a subscription
        if (data && data.subscriptions.data.length > 0) {
          subscriptionPlan = data.subscriptions.data[0];

          localStorage.setItem(
            "subscription",
            JSON.stringify(subscriptionPlan)
          );
          localStorage.setItem("productID", subscriptionPlan.plan.product);

          return {
            customerID: data.id,
            plan: subscriptionPlan,
            productID: subscriptionPlan.plan.product,
          };
        }
      },
      (error) => {
        console.log(error);
      }
    );
}

async function getProductName() {
  return axios
    .put("/api/stripe/getProduct", {
      productID: localStorage.getItem("productID"),
    })
    .then(
      (response) => {
        let data = response.data;

        if (data.success) {
          localStorage.setItem("productName", data.product.name);
          return data.product.name;
        }
      },
      (error) => {
        console.log(error);
      }
    );
}

// retrieve matching account package
async function getAccountLimits() {
  let currentAccountLimit;

  // if a package matches, grab matching limits from JSON
  if (localStorage.getItem("productName")) {
    currentAccountLimit = accountLimits.filter((plan) =>
      plan.package.includes(localStorage.getItem("productName"))
    );
  }
  // if no subscription exists, grab limits for free account
  else {
    currentAccountLimit = accountLimits;
  }

  localStorage.setItem("accountLimits", JSON.stringify(currentAccountLimit));
  return true;
}

async function clearTempTokens(user) {
  return await axios
    .put("/api/user/update", {
      usernameToUpdate: localStorage.getItem("user"),
      update: { tempAccessToken: "", tempRefreshToken: "" },
    })
    .then(
      (response) => {
        let data = response.data;
        if (data.success) return true;
      },
      (error) => {
        console.log(error);
      }
    );
}

async function updateTempTokens(user, accessToken, refreshToken) {
  return await axios
    .put("/api/user/update", {
      usernameToUpdate: user,
      update: { tempAccessToken: accessToken, tempRefreshToken: refreshToken },
    })
    .then(
      (response) => {
        let data = response.data;
        if (data.success) return true;
      },
      (error) => {
        console.log(error);
      }
    );
}

async function updateUserSubscription(user, subscriptionName) {
  return await axios
    .put("/api/user/update", {
      usernameToUpdate: user,
      update: { subscription: subscriptionName },
    })
    .then(
      (response) => {
        let data = response.data;
        if (data.success) return true;
      },
      (error) => {
        console.log(error);
      }
    );
}

const analyzeSchema = async (testUrl) => {
  return await axios
    .put("/api/test/schema", {
      url: testUrl,
    })
    .then(
      (response) => {
        let testResults = response.data;
        let isCompleteSchemaCheck, schemaObject;

        //console.log(testResults);

        if (testResults.schema[testResults.schemas_found]) {
          // Check if there are any empty properties in the schema
          schemaObject = testResults.schema[testResults.schemas_found][0];
          isCompleteSchemaCheck = Object.keys(schemaObject).every(
            (k) => schemaObject[k] !== ""
          );
        }

        return {
          schemaTestResults: testResults,
          isCompleteSchema: isCompleteSchemaCheck,
          schemaFound: testResults.schemas_found,
          schemaObjectFound: schemaObject,
        };
      },
      (error) => {
        console.log(error);
      }
    );
};

const updateActivity = async (username, userActivity) => {
  let currentDate = moment().format("MMMM D, YYYY");

  // get the index of existing activity date
  // if exists, index will be > 0
  let index = userActivity.findIndex((el) => el.date === currentDate);

  if (index >= 0) {
    userActivity[index] = {
      date: currentDate,
      changes: userActivity[index].changes + 1,
    };
  } else {
    userActivity.push({
      date: currentDate,
      changes: 1,
    });
  }

  userActivity = userActivity.sort(function (a, b) {
    return b.date - a.date;
  });

  await axios.put("/api/user/update", {
    usernameToUpdate: username,
    update: {
      $set: {
        activity: userActivity,
      },
    },
  });

  return true;
};

const updateSchemaCounter = async (
  username,
  domain,
  currentPageId,
  publishedPages,
  deleteSchema
) => {
  if (deleteSchema) {
    if (publishedPages.length > 0) {
      publishedPages = publishedPages.filter((id) => id !== currentPageId);
    }
  } else {
    if (!publishedPages.includes(parseInt(currentPageId)))
      publishedPages.push(currentPageId);
  }

  return await axios.put("/api/user/update", {
    usernameToUpdate: username,
    domainURLToUpdate: domain,
    update: {
      $set: {
        "domains.$.publishedPages": publishedPages,
      },
    },
  });
};

/*
 * Updates array of schemas used for a specific domain
 */
const updateSchemasUsed = async (username, domain, schemas) => {
  return axios.put("/api/user/update", {
    usernameToUpdate: username,
    domainURLToUpdate: domain,
    update: {
      $set: {
        "domains.$.schemasUsed": schemas,
      },
    },
  });
};

const getPageDetails = async (accessToken, pageId) => {
  let details = {};

  try {
    await axios
      .put("/hubspot/get/page", {
        access_token: accessToken,
        id: pageId,
      })
      .then(
        async (response) => {
          let data = response.data;
          if (data.success) {
            details["isPage"] = true;
            details["pageDetails"] = data.page_details;
            details["code"] = data.page_details.head_html;

            if (UTIL.hasSchema(details["code"]))
              details["schemaDetected"] = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  } catch (e) {
    console.log(e);
  }

  try {
    await axios
      .put("/hubspot/get/article", {
        access_token: accessToken,
        id: pageId,
      })
      .then(
        (response) => {
          let data = response.data;

          if (data.success) {
            details["isArticle"] = true;
            details["pageDetails"] = data.page_details;
            details["code"] = data.page_details.head_html;

            if (UTIL.hasSchema(details["code"]))
              details["schemaDetected"] = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  } catch (e) {
    console.log(e);
  }

  return details;
};

const getPageRevisions = async (accessToken, pageId) => {
  return await axios
    .put("/hubspot/get/page/revisions", {
      access_token: accessToken,
      id: pageId,
    })
    .then(
      async (response) => {
        return response.data.articles;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

const updatePageRevision = async (accessToken, pageId, revisionId) => {
  return await axios
    .put("/hubspot/update/page/revisions", {
      access_token: accessToken,
      id: pageId,
      revisionId: revisionId,
    })
    .then(
      async (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

const getArticleRevisions = async (accessToken, postId) => {
  return await axios
    .put("/hubspot/get/article/revisions", {
      access_token: accessToken,
      id: postId,
    })
    .then(
      async (response) => {
        return response.data.articles;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

const updateArticleRevision = async (accessToken, postId, revisionId) => {
  return await axios
    .put("/hubspot/update/article/revisions", {
      access_token: accessToken,
      id: postId,
      revisionId: revisionId,
    })
    .then(
      async (response) => {
        return response;
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
};

// POST article to update. returns success boolean
const updateArticle = async (accessToken, pageId, head, schema) => {
  return await axios
    .put("/hubspot/update/article/", {
      access_token: accessToken,
      id: pageId, // ID comes from URL of the page
      headerHTML: head,
    })
    .then(
      (response) => {
        if (response.data.status === 200) {
          window.Intercom("trackEvent", "Published a schema");

          return true;
        } else {
          console.log(
            "Page update error: " +
              response.data.status +
              " message: " +
              response.data.error_message
          );

          console.log(response.data.error_message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
};

// POST page to update. returns success boolean
const updatePage = async (accessToken, pageId, head, schema) => {
  return await axios
    .put("/hubspot/update/page/", {
      access_token: accessToken,
      id: pageId, // ID comes from URL of the page
      headerHTML: head,
    })
    .then(
      (response) => {
        if (response.data.status === 200) {
          window.Intercom("trackEvent", "Published a schema");

          return true;
        } else {
          console.log("Page update error: ", data.error_message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
};

export {
  createCustomer,
  getUserData,
  getTokenInfo,
  refreshAuthToken,
  updateTokensInDatabase,
  getHMACHash,
  getCustomer,
  getSubscriptionDetails,
  getProductName,
  getAccountLimits,
  updateUserSubscription,
  clearTempTokens,
  updateTempTokens,
  analyzeSchema,
  updateActivity,
  updateSchemaCounter,
  updateSchemasUsed,
  getPageDetails,
  updateArticle,
  updatePage,
  // Revisions
  getPageRevisions,
  updatePageRevision,
  getArticleRevisions,
  updateArticleRevision,
};
