import React, { Component } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";

import { Box, IconButton } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

export default class Verify extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  resendVerifyEmail = async () => {
    await axios
      .put("/api/user/resendVerifyEmail", {
        user: JSON.parse(localStorage.getItem("userData")).username,
      })
      .then(
        (response) => {
          let data = response.data;
          if (data.success)
            Store.addNotification({
              message: "Verification email sent",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
          else {
            Store.addNotification({
              message: "Verification email failed to send",
              type: "danger",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    const userData = JSON.parse(localStorage.getItem("userData"));

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box style={{ width: "100%", maxWidth: 768 }}>
          <div class="section-title d-flex space-between align-center">
            <h2>Verification</h2>

            {!userData.isVerified && (
              <IconButton
                className="pill-button"
                aria-label="refresh"
                color="secondary"
                onClick={this.resendVerifyEmail}
              >
                <SendIcon fontSize="small" />
                <span className="text">Resend Verification</span>
              </IconButton>
            )}
          </div>

          <Box>
            <div className="status flex-container flex-start">
              <strong className="title">Status</strong>

              {userData.isVerified ? (
                <strong className="indicator pl-10 c-green">Verified</strong>
              ) : (
                <strong className="indicator pl-10 c-red">Unverified</strong>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
