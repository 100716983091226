import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import Account from "../../../components/header/Account";
import DomainSelector from "../../../components/header/DomainSelector";

import { Button } from "@mui/material";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      let availableDomains = [];

      if (userData.domains) {
        availableDomains = userData.domains.map((domain) => {
          if (window.location.href.indexOf(domain.domainURL) !== -1)
            return this.setState({ currentDomain: domain.domainURL });
          else
            return (
              <li data-domain={domain.domainURL} key={domain.domainURL}>
                <a href={"/pages/" + domain.domainURL}>{domain.domainURL}</a>
              </li>
            );
        });

        this.setState({ availableDomains });
      }
    }
  };

  render() {
    return (
      <div className="site-header-container">
        <div id="site-header">
          {this.props.title && <h1>{this.props.title}</h1>}
          {this.props.domainSelector && (
            <DomainSelector currentDomain={this.props.currentDomain} />
          )}

          <Account />
        </div>

        {/* top bar */}
        <div className="top-bar flex-container">
          <div className="left ta-left col-2">
            <a
              className="back c-teal"
              href={"/pages/" + this.state.currentDomain}
            >
              <img src="/images/back-teal.svg" alt="back arrow" />
              <span className="text">Back to Index</span>
            </a>
          </div>
          <div className="buttons ta-right col-2">
            <Button
              classes={{
                root: "secondary",
              }}
              onClick={this.props.toggleRevisionsModal}
            >
              Revisions
            </Button>

            <Button
              classes={{
                root:
                  "primary" +
                  (this.props.state.allowedToPublished
                    ? this.props.state.changesMade
                      ? ""
                      : " disabled"
                    : " disabled"),
              }}
              onClick={
                this.props.state.allowedToPublished &&
                this.props.state.changesMade
                  ? this.props.togglePublishConfirmModal
                  : this.doNothing
              }
              data-tip={
                !this.props.state.allowedToPublished
                  ? "You've reached the maximum number of published schemas for this plan. Consider upgrading to get more."
                  : this.props.state.changesMade
                  ? ""
                  : "Nothing to publish. No changes have been made."
              }
              data-for="update-publish"
            >
              Update & Publish
            </Button>

            <ReactTooltip id="update-publish" />
          </div>
        </div>
      </div>
    );
  }
}
