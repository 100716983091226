import $ from "jquery";

const jQueryScripts = async (schemaJSON) => {
  schemaJSON.image.map((item, index) => {
    $("#image-" + index).val(item);
    return 1;
  });

  schemaJSON.recipeIngredient.map((item, index) => {
    $("#ingredient-" + index).val(item);
    return 1;
  });

  schemaJSON.recipeInstructions.map((item, index) => {
    $("#stepName-" + index).val(item["name"]);
    $("#stepText-" + index).val(item["text"]);
    $("#stepUrl-" + index).val(item["url"]);
    $("#stepImage-" + index).val(item["image"]);
    return 1;
  });
};

export { jQueryScripts };
