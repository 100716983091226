import React, { Component } from "react";
import axios from "axios";
//import ReactTooltip from "react-tooltip";
import { Box, TextField, Paper, Grid } from "@mui/material";

// Icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Header from "../EditingHeader";
import SchemaSelector from "../SchemaSelector";
import DialogModal from "../../../../components/DialogModal";
import Modal from "../../../../components/modal/modal";

const UTIL = require("../../../../util/util");

export default class CreateNewTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaTemplates: [],

      templateName: "",
      schemas: [],
      id: "",
      dragId: "",

      schemaIndexToRemove: "",
      schemaToRemove: "",

      // modals
      dialogToggle: false,
      templateNameToggle: false,
      saveSucessToggle: false,
      saveErrorToggle: false,
      removeSchemaToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    document.title = "Create | Schema Templates | Schema Helper";

    // get templates from database on load
    await this.promisedSetState({ schemaTemplates: await this.getTemplates() });
  };

  getTemplates = async () => {
    return await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          return response.data.data.schemaTemplates;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  appendSchema = async (schema) => {
    let schemas = this.state.schemas;
    schemas.push(schema);
    await this.promisedSetState({ schemas: schemas });
  };

  removeSchema = async () => {
    let schemas = this.state.schemas;
    schemas.splice(this.state.schemaIndexToRemove, 1);
    await this.promisedSetState({ schemas: schemas });
    this.toggle("removeSchemaToggle");
  };

  confirmRemoveSchema = async (schema, index) => {
    await this.promisedSetState({
      schemaIndexToRemove: index,
      schemaToRemove: schema,
    });
    this.toggle("removeSchemaToggle");
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
  };

  addTemplate = async (template) => {
    let schemaTemplates = this.state.schemaTemplates;
    schemaTemplates.push(template);
    await this.promisedSetState({ schemaTemplates: schemaTemplates });
  };

  saveTemplate = async () => {
    let schemaTemplate = {
      id: await UTIL.uniqueId(),
      name: this.state.templateName,
      schemas: this.state.schemas,
    };

    let existingTemplate = this.state.schemaTemplates.filter(
      (schema) =>
        schema.name.toLowerCase() === this.state.templateName.toLowerCase()
    );

    // no existing templates with same name exist, so continue to add template
    if (existingTemplate.length === 0 && this.state.templateName !== "") {
      this.addTemplate(schemaTemplate);
      await this.updateUser();
      window.location.href = "/templates";
    }
    // display error message to user stating that there is an existing template with the same name.
    else {
      await this.promisedSetState({ dialogToggle: true });
    }
  };

  updateUser = async () => {
    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { schemaTemplates: this.state.schemaTemplates },
      })
      .then(
        (response) => {
          let data = response.data;
          if (data.success) return true;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // Draggable Events
  handleDrag = async (ev) => {
    await this.promisedSetState({
      dragId: ev.currentTarget.getAttribute("data-index"),
    });
  };

  handleDrop = async (ev) => {
    const replaceId = ev.currentTarget.getAttribute("data-index");
    const dragId = this.state.dragId;

    // swap schemas in drawer / user input field groups
    this.swap(dragId, replaceId, this.state.schemas);

    /*
    // swap schemas in JSON
    if (schema["@graph"])
      schema["@graph"] = this.swap(dragId, replaceId, schema["@graph"]);
    else if (Array.isArray(schema)) this.swap(dragId, replaceId, schema);
    */

    await this.promisedSetState({ dragId: "" });
  };

  swap = (firstIndex, secondIndex, array) => {
    const temp = array[firstIndex];
    array[firstIndex] = array[secondIndex];
    array[secondIndex] = temp;
    return array;
  };

  render() {
    return (
      <div className="new-template">
        <div className="content-window">
          <Header
            title="New Template"
            state={this.state}
            save={this.saveTemplate}
          />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "45px" }}
          >
            <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
              <Box className="section" sx={{ p: 3 }}>
                {/*<h3>Password</h3>*/}

                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="outlined-basic"
                        variant="outlined"
                        name="templateName"
                        aria-label="Template name"
                        onChange={this.handleChange}
                        value={this.state.templateName}
                        fullWidth={true}
                        label="Template Name"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                      <SchemaSelector
                        state={this.state}
                        appendSchema={this.appendSchema}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                      {this.state.schemas.length > 0 ? (
                        <Box>
                          <h3>Schemas</h3>

                          <ul className="template-table">
                            {this.state.schemas.map((schema, index) => (
                              <li className="schema-fields-drawer" key={schema}>
                                <h3
                                  className="title bg-midnightblue c-white"
                                  onClick={() =>
                                    this.promisedSetState({
                                      opened: !this.state.opened,
                                    })
                                  }
                                  draggable={true}
                                  onDragOver={(ev) => ev.preventDefault()}
                                  onDragStart={this.handleDrag}
                                  onDrop={this.handleDrop}
                                  data-index={index}
                                >
                                  <DragHandleIcon className="draggable" />
                                  <span>{schema}</span>
                                  <DeleteForeverOutlinedIcon
                                    className="delete"
                                    onClick={() =>
                                      this.confirmRemoveSchema(schema, index)
                                    }
                                  />
                                  {/*<ExpandMoreIcon className="expand" />*/}
                                </h3>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      ) : (
                        <Box style={{ marginBottom: "25px" }}>
                          You haven't added any schema types yet. Please select
                          one from the dropdown above to get started.
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>

          {this.state.dialogToggle && (
            <DialogModal
              toggleState={this.state.dialogToggle}
              toggle={() => this.toggle("dialogToggle")}
              title={"Template Name is either already in use or left blank"}
              message={"Please choose a different name and try again."}
            />
          )}

          {this.state.saveSucessToggle && (
            <DialogModal
              toggleState={this.state.saveSucessToggle}
              toggle={() => this.toggle("saveSucessToggle")}
              title={"Success"}
              message={"Template successfully saved"}
            />
          )}

          {this.state.saveErrorToggle && (
            <DialogModal
              toggleState={this.state.saveErrorToggle}
              toggle={() => this.toggle("saveErrorToggle")}
              title={"Could not save template"}
              message={
                "Something went wrong while attempting to save the template. Please try again later."
              }
            />
          )}

          {this.state.templateNameToggle && (
            <DialogModal
              toggleState={this.state.templateNameToggle}
              toggle={() => this.toggle("templateNameToggle")}
              title={"Template Name is either already in use or left blank"}
              message={"Please choose a different name and try again."}
            />
          )}

          {this.state.removeSchemaToggle && (
            <Modal
              toggleState={this.state.removeSchemaToggle}
              toggle={() => this.toggle("removeSchemaToggle")}
              title={"Are you sure you want to remove this schema?"}
              message={
                "This will remove the " +
                this.state.schemaToRemove +
                " schema from the current template."
              }
              yesAction={() => this.removeSchema()}
            />
          )}
        </div>
      </div>
    );
  }
}
