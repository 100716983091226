import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
//import axios from "axios";
//import { debounce } from "lodash";

import BulkEditorModal from "../../../components/BulkEditorModal";
import BulkEditorProgressModal from "../../../components/BulkEditorProgressModal";

import {
  //Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  //Typography,
} from "@mui/material";

// Helper functions
//const DATABASE_API = require("../../api/db-functions");
const UTIL = require("../../../util/util");
//const HELPER = require("../util/localUtil");

export default class SidebarSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPages: [],
      selectedTemplate: "",

      confirmationToggle: false,
      removeConfirmationToggle: false,
      generateConfirmationToggle: false,
      generateTemplateConfirmationToggle: false,
      bulkEditProgressToggle: false,
      bulkEditorToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    //let pages = [];
    //this.promisedSetState({ selectedPages: await this.getTemplates() });
  };

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  // check if all selected pages exist in article array
  // returns boolean value
  pageIsArticle = (page, articles) => {
    return articles.some((article) => article.id === page.id);
  };

  // check if all selected pages exist in website / landing pages array
  // returns boolean value
  pagesIsWebsite = (page, websitePages) => {
    return websitePages.some((websitePage) => websitePage.id === page.id);
  };

  selectTemplate = async (template) => {
    await this.promisedSetState({ selectedTemplate: template });
  };

  confirmationToggle = async () => {
    await this.promisedSetState({
      confirmationToggle: !this.state.confirmationToggle,
    });
  };

  removeConfirmationToggle = async () => {
    await this.promisedSetState({
      removeConfirmationToggle: !this.state.removeConfirmationToggle,
    });
  };

  bulkEditorCompletionChecker = (selectedPages) => {
    if (this.props.state.completedProgress >= selectedPages.length) return true;
    else return false;
  };

  confirmationModal = (page) => {
    return (
      <Dialog
        open={this.state.confirmationToggle}
        onClose={this.confirmationToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to generate schemas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will overwrite any existing schemas on the pages that you have
            selected.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={() => {
              this.props.generateSingleArticleSchema(page);
              this.confirmationToggle();
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.confirmationToggle()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  removeConfirmationModal = (page) => {
    return (
      <Dialog
        open={this.state.removeConfirmationToggle}
        onClose={this.removeConfirmationToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove schemas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove the entire schema object from "{page.name}".
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={() => {
              this.props.removeExistingSchema(page);
              this.removeConfirmationToggle();
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.removeConfirmationToggle()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  generateTemplateConfirmationModal = () => {
    return (
      <Dialog
        open={this.state.generateTemplateConfirmationToggle}
        onClose={() => this.toggle("generateTemplateConfirmationToggle")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-center"
        p={2}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to generate schemas for this page?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will overwrite any existing schemas on the pages that you have
            selected with the <strong>{this.state.selectedTemplate}</strong>{" "}
            template.
          </DialogContentText>
          <DialogContentText>
            You may need to double check the pages after completion for
            completeness. We will attempt to autofill as much information as we
            can. Things such as ratings, reviews, etc. may need to be manually
            updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            classes={{
              root: "primary",
            }}
            onClick={async () => {
              await this.props.checkToken();
              await this.props.updateState(
                "selectedPages",
                this.state.selectedPages
              );
              this.toggle("generateTemplateConfirmationToggle");
              this.toggle("bulkEditProgressToggle");
              this.props.generateFromTemplate(this.state.selectedTemplate);
            }}
          >
            Yes
          </Button>

          <Button
            classes={{
              root: "secondary",
            }}
            onClick={() => this.toggle("generateTemplateConfirmationToggle")}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const page = this.props.page;
    const selectedPages = this.props.state.selectedPages;
    const bulkEditorAccess = this.props.state.accountLimits.bulkEditor;

    return (
      <aside className="right-sidebar single">
        <div className="image-preview">
          {page.screenshot_preview_url && (
            <img
              src={page.screenshot_preview_url.replace(
                /^http:\/\//i,
                "https://"
              )}
              alt={page.name + " preview"}
            />
          )}

          {/*page.featuredImage && (
            <img
              src={page.featuredImage.replace(/^http:\/\//i, "https://")}
              alt={page.name + " preview"}
            />
          )*/}

          <div className="title">{page.name}</div>
        </div>

        <ul className="sidebar-options">
          {UTIL.hasSchema(page.head_html) || UTIL.hasSchema(page.headHtml) ? (
            <a
              className="c-orange"
              href={"/" + this.props.currentDomain + "/page/" + page.id}
            >
              <img src="/images/edit.svg" alt="edit icon" />
              <span>Edit Schema</span>
            </a>
          ) : (
            <a
              className="c-orange"
              href={"/" + this.props.currentDomain + "/page/" + page.id}
            >
              <img src="/images/add.svg" alt="add icon" />
              <span>Add Schema</span>
            </a>
          )}

          {/* Bulk editor with template system */}
          <li
            className={
              (bulkEditorAccess ? "" : "disabled ") +
              "generate-articles c-orange "
            }
            onClick={
              bulkEditorAccess
                ? () => {
                    this.promisedSetState({ selectedPages: [page] });
                    this.toggle("bulkEditorToggle");
                  }
                : this.doNothing
            }
            data-tip={
              bulkEditorAccess
                ? "Choose a schema template to generate. This will overwrite any existing schema on this page."
                : "Choose a schema template to generate. Upgrade your plan to enable this feature."
            }
          >
            <img src="/images/Generate-Icon.svg" alt="autogenerate" />
            <span>Generate Schema</span>
            <ReactTooltip />
          </li>

          <li
            className="c-orange"
            onClick={() => this.removeConfirmationToggle()}
          >
            <img src="/images/delete.svg" alt="delete icon" />
            <span>Delete Schema</span>
          </li>
        </ul>

        {this.state.confirmationToggle && this.confirmationModal(page)}
        {this.state.removeConfirmationToggle &&
          this.removeConfirmationModal(page)}

        {/* New bulk editor stuff below */}
        {this.state.generateTemplateConfirmationToggle &&
          this.generateTemplateConfirmationModal()}

        {this.state.bulkEditorToggle && (
          <BulkEditorModal
            toggleState={this.state.bulkEditorToggle}
            toggle={this.toggle}
            selectTemplate={this.selectTemplate}
            generate={() => this.toggle("generateTemplateConfirmationToggle")}
          />
        )}

        {this.state.bulkEditProgressToggle && (
          <BulkEditorProgressModal
            toggleState={this.state.bulkEditProgressToggle}
            toggle={this.toggle}
            selectedPages={selectedPages}
            completedProgress={this.props.state.completedProgress}
            clearSelectedPages={this.props.clearSelectedPages}
          />
        )}
      </aside>
    );
  }
}
