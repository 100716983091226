import React, { Component } from "react";

let schemaJSON = require("./event.json");
const LOCAL_UTIL = require("../util");
//const UTIL = require("../../util/util");

export default class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      image: "",
      internalURL: "",
      startDate: "",
      endDate: "",
      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "Event"
      );
    }
    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");

    if (parentname) schemaJSON[parentname][name] = value;
    else schemaJSON[name] = value;

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {

    console.log(schemaJSON)
    return (
      <div id="website-schema-form" className="schema-form">
        <label>
          <h4>Event name</h4>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>Descrtipion</h4>
        </label>
        <textarea
          type="text"
          id="description"
          name="description"
          onChange={this.handleChange}
          value={schemaJSON["description"]}
        />

        <label>
          <h4>Image URL</h4>
        </label>
        <input
          type="text"
          id="image"
          name="image"
          onChange={this.handleChange}
          value={schemaJSON["image"]}
        />

        <label htmlFor="startDate">Start date</label>
        <input
          type="datetime-local"
          id="startDate"
          name="startDate"
          onChange={this.handleChange}
          value={schemaJSON?.startDate}
        />

        <label htmlFor="startDate">End date</label>
        <input
          type="datetime-local"
          id="endDate"
          name="endDate"
          onChange={this.handleChange}
          value={schemaJSON?.endDate}
        />
      </div>
    );
  }
}
