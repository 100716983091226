import axios from "axios";
//import moment from "moment";

const UTIL = require("../../../util/util");
const { SCHEMA_JSON } = require("../../../schemas");
const DATABASE_API = require("../../../api/db-functions");

let globalVariables = {};

/*
 * Schema generation
 */

/**
 * Master function
 * @param  {} page
 * @param  {} state
 * @param  {} schema
 */
const generateSchemaJSON = async (page, state, schema) => {
    await DATABASE_API.getUserData().then((globalVariables = JSON.parse(localStorage.getItem("userData")).globalVariables));

    if (schema === "AggregateRating") return aggregateRating(page);
    else if (schema === "Article") return article(page, state);
    else if (schema === "Book") return book(page);
    else if (schema === "BlogPosting") return blogposting(page, state);
    else if (schema === "BreadcrumbList") return breadcrumblist(page);
    else if (schema === "ClaimReview") return claimReview(page);
    else if (schema === "Course") return course(page);
    else if (schema === "Event") return event(page);
    else if (schema === "FAQPage") return faqPage(page, state);
    else if (schema === "JobPosting") return jobPosting(page);
    else if (schema === "LegalService") return legalService(page);
    else if (schema === "LocalBusiness") return localBusiness(page);
    else if (schema === "NewsArticle") return newsArticle(page, state);
    else if (schema === "Organization") return organization(page);
    else if (schema === "Person") return person(page);
    else if (schema === "PodcastEpisode") return podcastEpisode(page);
    else if (schema === "Product") return product(page);
    else if (schema === "Recipe") return recipe(page);
    else if (schema === "Review") return review(page);
    else if (schema === "Service") return service(page);
    else if (schema === "SoftwareApplication") return softwareApplication(page);
    else if (schema === "VideoObject") return video(page);
    else if (schema === "WebApplication") return webApplication(page);
    else if (schema === "WebSite") return website(page);
    else if (schema === "WebPage") return webpage(page);
};

/*
 *
 */
const aggregateRating = async (page) => {
    let schemaJSON = SCHEMA_JSON.AggregateRating;
    return schemaJSON;
};

const article = async (page, state) => {
    let articleJSON = SCHEMA_JSON.Article;

    return await axios
        .put("/hubspot/get/blogAuthorLegacy", {
            access_token: state.accessToken,
            blogAuthorId: page.blogAuthorId,
        })
        .then(
            (response) => {
                //return response.data.articles;
                const AUTHOR_DETAILS = response.data.articles;

                // generate Article schema
                articleJSON["headline"] = page.name;
                articleJSON["url"] = page.url ? page.url : page.absolute_url;
                articleJSON["mainEntityOfPage"]["@id"] = page.url ? page.url : page.absolute_url;
                articleJSON["author"]["@type"] = "Person";
                if (AUTHOR_DETAILS) {
                    articleJSON["author"]["name"] = AUTHOR_DETAILS.displayName;
                    articleJSON["author"]["url"] = page.url.substring(0, page.url.lastIndexOf("/")) + "/author/" + AUTHOR_DETAILS.slug;
                }

                articleJSON["datePublished"] = page.publishDate ? UTIL.timeConverter(page.publishDate) : UTIL.timeConverter(page.publish_date);
                articleJSON["dateModified"] = UTIL.timeConverter(page.updated);

                if (page.metaDescription || page.meta_description)
                    articleJSON["description"] = page.metaDescription ? page.metaDescription : page.meta_description;
                else articleJSON["description"] = page.name;

                if (page.featured_image || page.featuredImage) {
                    articleJSON["image"] = page.featuredImage ? page.featuredImage : page.featured_image;
                } else if (state.globalVariables && state.globalVariables.articleFeaturedImage) {
                    articleJSON["image"] = state.globalVariables.articleFeaturedImage;
                }

                if (state.globalVariables && state.globalVariables.articlePublisher)
                    articleJSON["publisher"]["name"] = state.globalVariables.articlePublisher;

                if (state.globalVariables && state.globalVariables.articlePublisherLogoURL)
                    articleJSON["publisher"]["logo"]["url"] = state.globalVariables.articlePublisherLogoURL;

                //console.log(articleJSON);

                return articleJSON;
            },
            (error) => {
                console.log(error);
                return error;
            }
        );
};

const book = async (page) => {
    let schemaJSON = SCHEMA_JSON.Book;
    return schemaJSON;
};

const blogposting = async (page, state) => {
    let articleJSON = SCHEMA_JSON.BlogPosting;

    return await axios
        .put("/hubspot/get/blogAuthorLegacy", {
            access_token: state.accessToken,
            blogAuthorId: page.blogAuthorId,
        })
        .then(
            (response) => {
                //return response.data.articles;
                const AUTHOR_DETAILS = response.data.articles;

                // generate Article schema
                articleJSON["headline"] = page.name;
                articleJSON["url"] = page.url ? page.url : page.absolute_url;
                articleJSON["mainEntityOfPage"]["@id"] = page.url ? page.url : page.absolute_url;
                articleJSON["author"]["@type"] = "Person";
                if (AUTHOR_DETAILS) {
                    articleJSON["author"]["name"] = AUTHOR_DETAILS.displayName;
                    articleJSON["author"]["url"] = page.url.substring(0, page.url.lastIndexOf("/")) + "/author/" + AUTHOR_DETAILS.slug;
                }

                articleJSON["datePublished"] = page.publishDate ? UTIL.timeConverter(page.publishDate) : UTIL.timeConverter(page.publish_date);
                articleJSON["dateModified"] = UTIL.timeConverter(page.updated);

                if (page.metaDescription || page.meta_description)
                    articleJSON["description"] = page.metaDescription ? page.metaDescription : page.meta_description;
                else articleJSON["description"] = page.name;

                if (page.featured_image || page.featuredImage) {
                    articleJSON["image"] = page.featuredImage ? page.featuredImage : page.featured_image;
                } else if (state.globalVariables && state.globalVariables.articleFeaturedImage) {
                    articleJSON["image"] = state.globalVariables.articleFeaturedImage;
                }

                if (state.globalVariables && state.globalVariables.articlePublisher)
                    articleJSON["publisher"]["name"] = state.globalVariables.articlePublisher;

                if (state.globalVariables && state.globalVariables.articlePublisherLogoURL)
                    articleJSON["publisher"]["logo"]["url"] = state.globalVariables.articlePublisherLogoURL;

                //console.log(articleJSON);

                return articleJSON;
            },
            (error) => {
                console.log(error);
                return error;
            }
        );
};

const breadcrumblist = async (page) => {
    let breadcrumbListJSON = SCHEMA_JSON.BreadcrumbList;
    let url = page.url ? page.url : page.absolute_url;

    // generate BreadcrumbList schema
    // reset breadcrumbs
    breadcrumbListJSON.itemListElement = [];

    // get base url
    const fullUrl = new URL(url);
    const baseUrl = fullUrl.origin;
    let pathArray = fullUrl.pathname.split("/");

    if (pathArray.length) {
        pathArray = pathArray.filter((path) => path);
    }

    // add base url first
    breadcrumbListJSON.itemListElement.push({
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: baseUrl,
    });

    // iterate through the rest of the paths and append to list
    pathArray.forEach((path, index) => {
        // replace all dashes / hyphens wit space
        const pathName = path.replace(/-/g, " ");

        // fill previous paths
        let previousPaths = "";
        if (index > 0) {
            for (let i = 0; i < index; i++) {
                previousPaths += "/" + pathArray[i];
            }
        }

        breadcrumbListJSON.itemListElement.push({
            "@type": "ListItem",
            position: index + 2,
            name: index < pathArray.length - 1 ? UTIL.toTitleCase(pathName) : page.name,
            item: baseUrl + previousPaths + "/" + path,
        });
    });

    return breadcrumbListJSON;
};

const claimReview = async (page) => {
    let schemaJSON = SCHEMA_JSON.ClaimReview;
    return schemaJSON;
};

const course = async (page) => {
    let schemaJSON = SCHEMA_JSON.Course;
    return schemaJSON;
};

const event = async (page) => {
    let schemaJSON = SCHEMA_JSON.Event;
    return schemaJSON;
};

const faqPage = async (page, state) => {
    let schemaJSON = SCHEMA_JSON.FAQPage;

    state.globalVariables.faqs.forEach((faq) => {
        let faqItem = {
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
            },
        };

        schemaJSON["mainEntity"].push(faqItem);
    });

    return schemaJSON;
};

const jobPosting = async (page) => {
    let schemaJSON = SCHEMA_JSON.JobPosting;
    return schemaJSON;
};

const legalService = async (page) => {
    let schemaJSON = SCHEMA_JSON.LegalService;
    return schemaJSON;
};

const localBusiness = async (page) => {
    let schemaJSON = SCHEMA_JSON.LocalBusiness;

    schemaJSON["name"] = globalVariables.companyName;
    schemaJSON["url"] = globalVariables.companyWebsite;
    schemaJSON["@id"] = globalVariables.companyWebsite;

    globalVariables.companyHours.forEach((hourSet) => {
        let hoursObject = {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: hourSet.days,
            opens: hourSet.openTime,
            closes: hourSet.closeTime,
        };

        schemaJSON["openingHoursSpecification"].push(hoursObject);
    });

    schemaJSON["address"]["streetAddress"] = globalVariables.address.streetAddress;
    schemaJSON["address"]["addressLocality"] = globalVariables.address.addressLocality;
    schemaJSON["address"]["addressRegion"] = globalVariables.address.addressRegion;
    schemaJSON["address"]["postalCode"] = globalVariables.address.postalCode;
    schemaJSON["address"]["addressCountry"] = globalVariables.address.addressCountry;

    schemaJSON["geo"]["latitude"] = globalVariables.address.latitude;
    schemaJSON["geo"]["longitude"] = globalVariables.address.longitude;

    return schemaJSON;
};

const newsArticle = async (page, state) => {
    let articleJSON = SCHEMA_JSON.NewsArticle;

    return await axios
        .put("/hubspot/get/blogAuthorLegacy", {
            access_token: state.accessToken,
            blogAuthorId: page.blogAuthorId,
        })
        .then(
            (response) => {
                //return response.data.articles;
                const AUTHOR_DETAILS = response.data.articles;

                // generate Article schema
                articleJSON["headline"] = page.name;
                articleJSON["url"] = page.url ? page.url : page.absolute_url;
                articleJSON["mainEntityOfPage"]["@id"] = page.url ? page.url : page.absolute_url;
                articleJSON["author"]["@type"] = "Person";

                if (AUTHOR_DETAILS) {
                    articleJSON["author"]["name"] = AUTHOR_DETAILS.displayName;
                    articleJSON["author"]["url"] = page.url.substring(0, page.url.lastIndexOf("/")) + "/author/" + AUTHOR_DETAILS.slug;
                }

                articleJSON["datePublished"] = page.publishDate ? UTIL.timeConverter(page.publishDate) : UTIL.timeConverter(page.publish_date);
                articleJSON["dateModified"] = UTIL.timeConverter(page.updated);

                if (page.metaDescription || page.meta_description)
                    articleJSON["description"] = page.metaDescription ? page.metaDescription : page.meta_description;
                else articleJSON["description"] = page.name;

                if (page.featured_image || page.featuredImage) {
                    articleJSON["image"] = page.featuredImage ? page.featuredImage : page.featured_image;
                } else if (state.globalVariables && state.globalVariables.articleFeaturedImage) {
                    articleJSON["image"] = state.globalVariables.articleFeaturedImage;
                }

                if (state.globalVariables && state.globalVariables.articlePublisher)
                    articleJSON["publisher"]["name"] = state.globalVariables.articlePublisher;

                if (state.globalVariables && state.globalVariables.articlePublisherLogoURL)
                    articleJSON["publisher"]["logo"]["url"] = state.globalVariables.articlePublisherLogoURL;

                //console.log(articleJSON);

                return articleJSON;
            },
            (error) => {
                console.log(error);
                return error;
            }
        );
};

const organization = async (page) => {
    let schemaJSON = SCHEMA_JSON.Organization;

    schemaJSON["name"] = globalVariables.companyName;
    schemaJSON["url"] = globalVariables.companyWebsite;
    schemaJSON["logo"] = globalVariables.articlePublisherLogoURL;

    return schemaJSON;
};

const person = async (page) => {
    let schemaJSON = SCHEMA_JSON.Person;
    return schemaJSON;
};

const podcastEpisode = async (page) => {
    let schemaJSON = SCHEMA_JSON.PodcastEpisode;
    return schemaJSON;
};

const product = async (page) => {
    let schemaJSON = SCHEMA_JSON.Product;
    return schemaJSON;
};

const recipe = async (page) => {
    let schemaJSON = SCHEMA_JSON.Recipe;
    return schemaJSON;
};

const review = async (page) => {
    let schemaJSON = SCHEMA_JSON.Review;
    return schemaJSON;
};

const service = async (page) => {
    let schemaJSON = SCHEMA_JSON.Service;
    return schemaJSON;
};

const softwareApplication = async (page) => {
    let schemaJSON = SCHEMA_JSON.SoftwareApplication;
    return schemaJSON;
};

const video = async (page) => {
    let schemaJSON = SCHEMA_JSON.Video;
    return schemaJSON;
};

const webApplication = async (page) => {
    let schemaJSON = SCHEMA_JSON.WebApplication;
    return schemaJSON;
};

const website = async (page) => {
    let websiteJSON = SCHEMA_JSON.WebSite;

    websiteJSON["name"] = page.name;
    websiteJSON["url"] = page.absolute_url;

    return websiteJSON;
};

const webpage = async (page) => {
    let schemaJSON = SCHEMA_JSON.WebPage;

    schemaJSON["name"] = page.name;
    schemaJSON["url"] = page.absolute_url;

    return schemaJSON;
};

export { generateSchemaJSON };
