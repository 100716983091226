import React, { Component } from "react";
import axios from "axios";
import ScrollReveal from "scrollreveal";
import { Store } from "react-notifications-component";
//import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

// Components
import Modal from "../../../components/modal/modal";

// Icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
//import InfoIcon from "@mui/icons-material/Info";

import { CircularProgress, IconButton } from "@mui/material";

import AddUserModal from "../components/addUserModal";
import EditUserModal from "../components/editUserModal";

//const DATABASE_API = require("../../../api/db-functions.js");

export default class Members extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],
      finishedReadingFromDatabase: false,

      // functionality
      searchTerm: props.searchTerm,

      // toggles
      addUserModalToggle: false,
      editUserModalToggle: false,
      removeUserModalToggle: false,

      // fields that are passed to other modules
      editUserFields: {},
      removeUserFields: {},

      search: "",

      loadingOffset: 20,
      offsetIncrement: 20,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.addEventListener("scroll", this.loadMore, false);

    await this.promisedSetState({
      accountLimits: JSON.parse(localStorage.getItem("accountLimits"))[0],
    });

    await this.getAccounts();
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMore, false);
  }

  jQueryScripts = async () => {
    if (navigator.platform.indexOf("Win") > -1)
      ScrollReveal().reveal(".table-item", { duration: 200, reset: true });
  };

  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  loadMore = async () => {
    if (this.hasReachedBottom()) {
      await this.promisedSetState({
        loadingOffset: this.state.loadingOffset + this.state.offsetIncrement,
      });
    }
  };

  hasReachedBottom() {
    const totalPageHeight = document.body.scrollHeight;
    const scrollPoint = window.scrollY + window.innerHeight;

    // check if we hit the bottom of the page
    return scrollPoint >= totalPageHeight;
  }

  setSearchTerm = debounce(async (searchTerm) => {
    this.promisedSetState({ searchTerm });
  }, 500);

  /*
   * Modal toggles
   */

  toggleAddUserModal = async () => {
    await this.promisedSetState({
      addUserModalToggle: !this.state.addUserModalToggle,
    });
  };

  toggleEditUserModal = async (account) => {
    let editUserFields = {};

    if (account)
      editUserFields = {
        firstName: account.firstName,
        lastName: account.lastName,
        role: account.role,
        email: account.username,
        domains: account.domains,
      };

    await this.promisedSetState({
      editUserModalToggle: !this.state.editUserModalToggle,
      editUserFields: editUserFields,
    });
  };

  toggleRemoveUserModal = async (account) => {
    let removeUserFields = {};

    if (account)
      removeUserFields = {
        id: account._id,
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.username,
      };

    await this.promisedSetState({
      removeUserModalToggle: !this.state.removeUserModalToggle,
      removeUserFields: removeUserFields,
    });
  };

  // Get accounts from database
  getAccounts = async () => {
    await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.data.members) {
            let domains = [];

            data.data.domains.forEach((domain) => {
              domains.push(domain["domainURL"]);
            });

            await this.promisedSetState({
              members: data.data.members,
              domains: domains,
              finishedReadingFromDatabase: true,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  displaySingleMember = (account) => {
    return (
      <li className="table-item flex-container" key={account._id}>
        <h4 className="title">{account.username ? account.username : "-"}</h4>
        <div className="name">{account.firstName + " " + account.lastName}</div>
        <div className="account-type capitalize">
          {account.role ? account.role : "-"}
        </div>
        <div className="num-domains">
          {
            account.domains.filter((domain) => {
              let allowed = false;
              for (const property in domain) allowed = domain[property];
              return allowed === true;
            }).length
          }
        </div>

        <div className="btn-container flex-container">
          <EditIcon
            className="edit-user btn-icon"
            onClick={() => this.toggleEditUserModal(account)}
          />

          <DeleteForeverOutlinedIcon
            className="remove-user btn-icon"
            onClick={() => this.toggleRemoveUserModal(account)}
          />
        </div>
      </li>
    );
  };

  // filters accounts
  filterAll = (accounts) => {
    let filteredAccounts;

    if (this.state.searchTerm) {
      filteredAccounts = accounts.filter((account) => {
        let searchTerm = this.state.searchTerm;
        let accountDetails = [];
        let result = false;

        if (account.username)
          accountDetails.push(account.username.toLowerCase());

        if (account.firstName)
          accountDetails.push(account.firstName.toLowerCase());

        if (account.lastName)
          accountDetails.push(account.lastName.toLowerCase());

        accountDetails.forEach(function (item) {
          if (item.includes(searchTerm.toLowerCase())) result = true;
        });

        return result;
      });
    } else filteredAccounts = accounts;

    return filteredAccounts;
  };

  removeUser = async () => {
    let members = this.state.members.filter((member) => {
      return this.state.removeUserFields.id !== member._id;
    });

    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: {
          members: members,
        },
      })
      .then(
        async (response) => {
          let data = response.data;

          if (data.success) {
            this.getAccounts();

            await Store.addNotification({
              message:
                this.state.removeUserFields.firstName +
                " " +
                this.state.removeUserFields.lastName +
                " has been removed.",
              type: "success",
              insert: "top",
              container: "top-right",
              dismiss: {
                duration: 4000,
                onScreen: false,
                showIcon: true,
              },
            });

            await this.promisedSetState({
              removeUserModalToggle: false,
              removeUserFields: {},
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    return (
      <div id="accounts">
        <div className="filters d-flex align-center space-between">
          <div className="col-2">
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search by username or full name"
              autoComplete="off"
              onChange={(e) => {
                this.setSearchTerm(e.target.value);
              }}
            />
          </div>

          <IconButton
            className="pill-button"
            aria-label="add"
            color="secondary"
            onClick={async () => {
              await this.promisedSetState({ addUserModalToggle: true });
            }}
          >
            <AddIcon fontSize="small" />
            <span className="text">Add User</span>
          </IconButton>
        </div>

        {/*<h3 className="total">
          Results: {this.filterAll(this.state.members).length}
          </h3>*/}

        <div className="table-header flex-container mt-20">
          <h3>Username</h3>
          <h3>Name</h3>
          <h3>Role</h3>
          <h3># of Domains</h3>
          <h3>&nbsp;</h3>
        </div>

        <ul className="table-container">
          {this.state.finishedReadingFromDatabase ? (
            this.state.members.length > 0 ? (
              this.filterAll(this.state.members)
                .slice(0, this.state.loadingOffset)
                .map((account) => this.displaySingleMember(account))
            ) : (
              "No members found"
            )
          ) : (
            <CircularProgress />
          )}
        </ul>

        {this.state.addUserModalToggle && (
          <AddUserModal
            handleChange={this.handleChange}
            getAccounts={this.getAccounts}
            yesAction={() => {}}
            state={this.state}
            toggle={this.toggleAddUserModal}
            toggleState={this.state.addUserModalToggle}
          />
        )}

        {this.state.editUserModalToggle && (
          <EditUserModal
            handleChange={this.handleChange}
            getAccounts={this.getAccounts}
            yesAction={() => {}}
            state={this.state}
            toggle={this.toggleEditUserModal}
            toggleState={this.state.editUserModalToggle}
          />
        )}

        {this.state.removeUserModalToggle && (
          <Modal
            title={
              "Are you sure you want to remove " +
              this.state.removeUserFields.firstName +
              " " +
              this.state.removeUserFields.lastName +
              "?"
            }
            message="This user will no longer have access to any of the domains in your account."
            yesAction={() => {
              this.removeUser();
            }}
            toggle={this.toggleRemoveUserModal}
            toggleState={this.state.removeUserModalToggle}
          />
        )}
      </div>
    );
  }
}
