import moment from "moment";
//import { connect } from "mongoose";

const timeConverter = (unixtimestamp) => {
  let date = new Date(unixtimestamp);
  return date.toLocaleString();
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

/*
 * Checks the page_head code for an existing schema
 * Returns a boolean value
 */
const hasSchema = (head_html) => {
  if (head_html && head_html.includes("application/ld+json")) return true;
  else return false;
};

const setQueryStringParameter = (name, value) => {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);
  window.history.replaceState(
    {},
    "",
    decodeURIComponent(`${window.location.pathname}?${params}`)
  );
};

const updateActivity = () => {
  localStorage.setItem("lastActivity", moment().format());
};

/*
 * This function attempts to replace specific characters that cause HubSpot's API to throw an error.
 * Returns a "cleansed" version of the string that was provided.
 */
const cleanText = (text) => {
  // replace unicode characters ‘ and ’ with '
  text = text.replace(/’/g, "'");
  text = text.replace(/‘/g, "'");

  // replace unicode characters “ and ” with '
  text = text.replace(/“/g, "'");
  text = text.replace(/”/g, "'");

  // replace " with  '
  text = text.replace(/"/g, "'");
  text = text.replace(/"/g, "'");

  // replace — with -
  text = text.replace(/\u2013|\u2014/g, "-");

  // replace &nbsp; with regular space
  text = text.replace(/\xA0/g, " ");

  return text;
};

// retrieve and return schema type for a single page
const getSchemaType = (page) => {
  let currentSchema;
  let oldPageHead = page.head_html ? page.head_html : page.headHtml;

  // grab entire schema script
  if (oldPageHead) {
    currentSchema = oldPageHead.match(
      /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi
    );
  }

  if (currentSchema) {
    try {
      currentSchema = currentSchema.toString();
      // strip tags
      currentSchema = currentSchema.replace(/<\/?[^>]+(>|$)/g, "");
      currentSchema = JSON.parse(currentSchema);

      let schemasFound = [];
      if (Array.isArray(currentSchema)) {
        currentSchema.forEach((schema) => {
          schemasFound.push(schema["@type"]);
        });

        return schemasFound.join(", ");
      } else if (currentSchema["@graph"]) {
        currentSchema["@graph"].forEach((schema) => {
          schemasFound.push(schema["@type"]);
        });

        return schemasFound.join(", ");
      } else return currentSchema["@type"];
    } catch (err) {
      console.log(err);
    }
  } else return "-";
};

const testSchema = async (head_html) => {
  if (head_html && head_html.includes("application/ld+json")) {
    // grab entire schema script, not including the script tags
    let currentSchema = head_html.match(
      /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi
    );

    if (currentSchema) {
      try {
        currentSchema = currentSchema.toString().replace(/<\/?[^>]+(>|$)/g, "");
        currentSchema = JSON.parse(currentSchema);
      } catch (err) {
        console.log(err);
      }

      let testResult = true;
      const iterate = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === "object") {
            if(obj[key]) iterate(obj[key]);
          } else if (
            obj[key] === "" ||
            obj[key] === null ||
            obj[key] === undefined
          )
            testResult = false;
        });
      };

      if(currentSchema) iterate(currentSchema);
      return testResult;
    }
  }
};

const updateLocalCache = async (state, domain, pageId) => {
  if (state.isArticle) {
    let localArticlesCache = localStorage.getItem(domain + "-articles");

    if (localArticlesCache) {
      localArticlesCache = JSON.parse(localArticlesCache);

      //Find index of specific object using findIndex method.
      let pageIndex = localArticlesCache.findIndex(
        (obj) => parseInt(obj.id) === parseInt(pageId)
      );

      //Update object's name property.
      if ("headHtml" in localArticlesCache[pageIndex])
        localArticlesCache[pageIndex].headHtml = state.code;
      else if ("head_html" in localArticlesCache[pageIndex])
        localArticlesCache[pageIndex].head_html = state.code;

      localStorage.setItem(
        domain + "-articles",
        JSON.stringify(localArticlesCache)
      );
    }
  }

  if (state.isPage) {
    let localPagesCache = localStorage.getItem(domain + "-pages");

    if (localPagesCache) {
      localPagesCache = JSON.parse(localPagesCache);

      //Find index of specific object using findIndex method.
      let pageIndex = localPagesCache.findIndex(
        (obj) => parseInt(obj.id) === parseInt(pageId)
      );

      //Update object's name property.
      localPagesCache[pageIndex].head_html = state.code;

      localStorage.setItem(domain + "-pages", JSON.stringify(localPagesCache));
    }
  }

  return true;
};

export {
  timeConverter,
  toTitleCase,
  uniqueId,
  hasSchema,
  setQueryStringParameter,
  cleanText,
  getSchemaType,
  testSchema,
  updateLocalCache,
  updateActivity,
};
