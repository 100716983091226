import React, { Component } from "react";

export default class Recipe extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  convertTimeString = (time) => {
    time = time.replace("PT", "");
    time = time.replace("H", " hr ");
    time = time.replace("M", " min ");
    time = time.replace("S", " sec ");

    return time;
  };

  render() {
    let schemaObject = this.props.schemaObject;

    if (Array.isArray(this.props.schemaObject))
      schemaObject = this.props.schemaObject[0];
    else if (this.props.schemaObject["@graph"])
      schemaObject = this.props.schemaObject["@graph"][0];

    return (
      <div className="recipe">
        <div className="image">
          <img
            src="https://www.saltedstone.com/hubfs/2018/work/lrw/_thum_lrw.jpg"
            alt="thumbnail"
          />
        </div>
        <div className="text">
          <div className="title">{schemaObject["name"]}</div>
          <div className="author">{schemaObject["author"]["name"]}</div>
          <div className="rating-info">
            <span className="rating">
              {schemaObject["aggregateRating"]["ratingValue"]}
            </span>

            <span
              className="stars"
              data-rating={schemaObject["aggregateRating"]["ratingValue"]}
            >
              <span>★</span>
              <span>★</span>
              <span>★</span>
              <span>★</span>
              <span>★</span>
            </span>

            <span className="count">
              ({schemaObject["aggregateRating"]["ratingCount"]})
            </span>

            <div className="time">
              {this.convertTimeString(schemaObject["totalTime"])}
            </div>

            <div className="description">{schemaObject["description"]}</div>
          </div>
        </div>
      </div>
    );
  }
}
