import React, { Component } from "react";

import { Paper, Box, Grid, TextField } from "@mui/material";

export default class CompanyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
          <Box className="section" sx={{ p: 3 }}>
            <h3>Company Details</h3>

            <Box>
              <TextField
                variant="outlined"
                label="Company name"
                name="companyName"
                onChange={this.props.handleChange}
                value={this.props.state.companyName}
                style={{ marginTop: "20px", marginBottom: "15px" }}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />

              <TextField
                variant="outlined"
                label="Street address"
                name="streetAddress"
                onChange={this.props.handleChange}
                value={this.props.state.streetAddress}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />

              <Grid container spacing={3} style={{ marginTop: "5px" }}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="City"
                    name="addressLocality"
                    onChange={this.props.handleChange}
                    aria-label="Locality"
                    value={this.props.state.addressLocality}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="State / Region"
                    name="addressRegion"
                    onChange={this.props.handleChange}
                    aria-label="Region"
                    value={this.props.state.addressRegion}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Postal Code"
                    name="postalCode"
                    onChange={this.props.handleChange}
                    aria-label="Postal Code"
                    value={this.props.state.postalCode}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    name="addressCountry"
                    onChange={this.props.handleChange}
                    aria-label="Country"
                    value={this.props.state.addressCountry}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Latitude"
                    name="latitude"
                    type="number"
                    onChange={this.props.handleChange}
                    aria-label="Latitude"
                    value={this.props.state.latitude}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Longitude"
                    name="longitude"
                    type="number"
                    onChange={this.props.handleChange}
                    aria-label="Longitude"
                    value={this.props.state.longitude}
                    fullWidth={true}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                label="Company website"
                name="companyWebsite"
                onChange={this.props.handleChange}
                value={this.props.state.companyWebsite}
                style={{ marginTop: "15px" }}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />

              <TextField
                variant="outlined"
                label="Phone number"
                name="companyPhoneNumber"
                onChange={this.props.handleChange}
                value={this.props.state.companyPhoneNumber}
                style={{ marginTop: "15px" }}
                fullWidth={true}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}
