import React, { Component } from "react";
//import axios from "axios";

/* Components / Views */
import Header from "../../components/header/header";
import Members from "./members";

export default class UserDashboard extends Component {
  render() {
    return (
      <div id="dashboard" className="content-window">
        <Header title="Users" />

        {<Members />}
      </div>
    );
  }
}
