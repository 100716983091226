import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./website.json");

const LOCAL_UTIL = require("../util");
const UTIL = require("../../util/util");

export default class WebSite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      url: "",
      internalURL: "",
      optionalString: "",
      pageDetails: {},
      schema: schemaJSON,
    };
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await LOCAL_UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "WebSite"
      );
    }

    this.props.setSchema(schemaJSON);
    this.setState(this.state); // force update
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentname = e.target.getAttribute("data-parentname");

    if (parentname && name === "target") {
      schemaJSON["potentialAction"] = {
        "@type": "SearchAction",
        target: "",
        "query-input": "required name=search_term_string",
      };

      schemaJSON[parentname][name] = value;
    } else if (parentname) schemaJSON[parentname][name] = UTIL.cleanText(value);
    else schemaJSON[name] = UTIL.cleanText(value);

    // update session schema
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  render() {
    return (
      <div id="website-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6307478)}
          >
            Learn More
          </span>
        </Typography>

        <label>
          <h4>Website name</h4>
        </label>
        <input
          type="text"
          id="website-name"
          name="name"
          onChange={this.handleChange}
          value={schemaJSON["name"]}
        />

        <label>
          <h4>URL</h4>
        </label>
        <input
          type="text"
          id="url"
          name="url"
          onChange={this.handleChange}
          value={schemaJSON["url"]}
        />

        <div className="d-flex align-center">
          <label>
            <h4>Internal site search URL</h4>
          </label>
          <InfoIcon
            data-for="site-search-url-tip"
            data-tip="This should end with the search query of '{search_term_string}'"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="site-search-url-tip" />
        </div>
        <input
          type="text"
          id="target"
          data-parentname="potentialAction"
          name="target"
          placeholder="e.g. https://example.com/search?q={search_term_string}"
          value={
            schemaJSON["potentialAction"] &&
            schemaJSON["potentialAction"]["target"]
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
