import $ from "jquery";

const jQueryScripts = async (schemaJSON) => {
  // set selected @type
  $("#businessType").val(schemaJSON["@type"]);

  schemaJSON.image.map((item, index) => {
    $("#image-" + index).val(item);
    return 1;
  });

  schemaJSON.openingHoursSpecification.map((item, index) => {
    $("#opens-" + index).val(item["opens"]);
    $("#closes-" + index).val(item["closes"]);
    $("#dayOfWeek-" + index).val(item["dayOfWeek"]);
    return 1;
  });
};

export { jQueryScripts };
