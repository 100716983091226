import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import ScrollReveal from "scrollreveal";
//import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

//import Loader from "../../components/loader/loader";

import { CircularProgress } from "@mui/material";

const DATABASE_API = require("../../api/db-functions.js");

export default class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishedReadingFromDatabase: false,
      accountLimits: {},
      accounts: [],
      filteredAccountsArray: [],
      search: "",
      pin: "",
      role: "",
      domain: "",
      signInAsUserModalToggle: false,
      editModalToggle: false,
      editSuccess: false,
      customerUsername: "",
      customerName: "",
      customerSupportPin: "",
      domains: [],
      searchTerm: props.searchTerm,
      supportAuthResponse: "",
      loadingOffset: 20,
      offsetIncrement: 20,
      loginFailure: false,
    };

    this.signInAsUserModalToggle = this.signInAsUserModalToggle.bind(this);
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.addEventListener("scroll", this.loadMore, false);

    await this.promisedSetState({
      accountLimits: JSON.parse(localStorage.getItem("accountLimits"))[0],
    });

    this.getAccounts();
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMore, false);
  }

  jQueryScripts = async () => {
    if (navigator.platform.indexOf("Win") > -1)
      ScrollReveal().reveal(".table-item", { duration: 200, reset: true });

    $(".option-btn").on("click", function () {
      $(this).parent().parent().find(".options").slideToggle();
    });
  };

  loadMore = async () => {
    if (this.hasReachedBottom()) {
      await this.promisedSetState({
        loadingOffset: this.state.loadingOffset + this.state.offsetIncrement,
      });
    }
  };

  signInAsUserModalToggle = async (account) => {
    await this.promisedSetState({
      signInAsUserModalToggle: !this.state.signInAsUserModalToggle,
      customerUsername: account.username,
      customerName: account.firstName + " " + account.lastName,
      customerSupportPin: account.customerSupportPin,
    });

    if (this.state.signInAsUserModalToggle === false)
      await this.promisedSetState({ pin: "" });
  };

  editModalToggle = async (account) => {
    await this.promisedSetState({
      editModalToggle: !this.state.editModalToggle,
      customerUsername: account.username,
      customerName: account.firstName + " " + account.lastName,
      customerSupportPin: account.customerSupportPin,
      role: account.accountType,
      domains: account.domains,
    });

    if (this.state.editSuccess === true)
      await this.promisedSetState({ editSuccess: false });
  };

  // gets accounts from database
  getAccounts = async () => {
    await axios.get("/api/get/allUsers", {}).then(
      async (response) => {
        let data = response.data;

        if (data.success && data.data) {
          await this.promisedSetState({
            accounts: data.data,
            finishedReadingFromDatabase: true,
          });

          this.jQueryScripts();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  resetPageLimit = async () => {
    await axios
      .put("/api/user/update", {
        usernameToUpdate: this.state.customerUsername,
        domainURLToUpdate: this.state.domain,
        update: {
          $set: {
            "domains.$.publishedPages": [],
          },
        },
      })
      .then(
        async (response) => {
          let data = response.data;
          if (data.success) await this.promisedSetState({ editSuccess: true });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  hasReachedBottom() {
    const totalPageHeight = document.body.scrollHeight;
    const scrollPoint = window.scrollY + window.innerHeight;

    // check if we hit the bottom of the page
    return scrollPoint >= totalPageHeight;
  }

  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
      loginFailure: false,
      loginSuccess: false,
    });
  };

  setSearchTerm = debounce(async (searchTerm) => {
    await this.promisedSetState({ searchTerm });
  }, 500);

  displaySingleAccount = (account) => {
    return (
      <li className="table-item flex-container" key={account.username}>
        <h4 className="title">{account.username}</h4>
        <div className="name">{account.firstName + " " + account.lastName}</div>
        <div className="account-type capitalize">
          {account.accountType ? account.accountType : "-"}
        </div>
        <div className="num-domains">{account.domains.length}</div>

        <div className="btn-container flex-container">
          {
            <svg
              className="btn-icon"
              onClick={() => this.editModalToggle(account)}
              width="24px"
              height="22px"
              viewBox="0 0 24 22"
              version="1.1"
            >
              <g
                id="Wireframes"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Page-Index---Freemium-Selected"
                  transform="translate(-1190.000000, -353.000000)"
                  stroke="#C47335"
                  strokeWidth="1.5"
                >
                  <g
                    id="RIGHT-SIDE-MENU"
                    transform="translate(1151.500000, 80.000000)"
                  >
                    <g
                      id="Right-Menu-Option"
                      transform="translate(40.500000, 224.000000)"
                    >
                      <g
                        id="Group-5"
                        transform="translate(0.000000, 50.000000)"
                      >
                        <path
                          d="M0.545616987,19.9632727 C0.403435169,19.9632727 0.264162442,19.9076364 0.159798806,19.8036364 C0.0128897146,19.6567273 -0.0380193763,19.4389091 0.029253351,19.2421818 L2.28452608,12.628 C2.31143517,12.5490909 2.35616244,12.4770909 2.41507153,12.4181818 L14.1943443,0.638909091 C14.6063443,0.226909091 15.1539806,6.45947942e-14 15.7365261,6.45947942e-14 C16.3190715,6.45947942e-14 16.8667079,0.226909091 17.2787079,0.638909091 L19.3245261,2.68472727 C19.7365261,3.09636364 19.9630715,3.64436364 19.9630715,4.22654545 C19.9630715,4.80945455 19.7361624,5.35672727 19.3245261,5.76872727 L7.54525335,17.548 C7.48634426,17.6069091 7.4147079,17.6516364 7.33543517,17.6785455 L0.721253351,19.9338182 C0.663798806,19.9534545 0.604526078,19.9632727 0.545616987,19.9632727 Z"
                          id="Path"
                          fillRule="nonzero"
                        ></path>
                        <line
                          x1="2.38307153"
                          y1="12.4175078"
                          x2="7.54542422"
                          y2="17.5477112"
                          id="Path-2"
                        ></line>
                        <line
                          x1="13.212111"
                          y1="1.62023767"
                          x2="18.3423143"
                          y2="6.75044102"
                          id="Path-7"
                        ></line>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          }

          <button
            className="btn small bg-teal c-white"
            onClick={() => this.signInAsUserModalToggle(account)}
          >
            Sign in as user
          </button>
        </div>

        <div className="options">
          <ul>
            <li>Do something</li>
          </ul>
        </div>
      </li>
    );
  };

  signInModal = () => {
    return (
      <div className="crop modal">
        <div className="modal-content ta-left">
          <img
            className="close"
            src="/images/close.svg"
            alt="close button"
            onClick={this.signInAsUserModalToggle}
          />

          <h2>Sign in as user</h2>
          <div className="username">
            Name: <strong>{this.state.customerName}</strong>
          </div>
          <div className="name">
            Username: <strong>{this.state.customerUsername}</strong>
          </div>

          <div className="input mt-20">
            <label htmlFor="pin">
              In order to continue, please request the support PIN from the
              client. They can find this in their settings page.
            </label>
            <input
              type="text"
              id="pin"
              name="pin"
              placeholder="Customer support PIN"
              onChange={this.handleChange}
            />
          </div>

          {this.state.loginFailure
            ? this.state.pin && (
                <div className="error-label mt-20">
                  {this.state.supportAuthResponse}
                </div>
              )
            : ""}

          {this.state.loginSuccess
            ? this.state.pin && (
                <div className="success-label mt-20">
                  {this.state.supportAuthResponse} You will be redirected
                  shortly.
                </div>
              )
            : ""}

          <div className="btn-container ta-right">
            <button
              className="btn medium bg-teal c-white"
              onClick={this.supportAuth}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  editModal = () => {
    return (
      <div className="crop modal">
        <div className="modal-content ta-left">
          <img
            className="close"
            src="/images/close.svg"
            alt="close button"
            onClick={this.editModalToggle}
          />

          <h2>Edit user</h2>
          <div className="username">
            Name: <strong>{this.state.customerName}</strong>
          </div>
          <div className="username">
            Username: <strong>{this.state.customerUsername}</strong>
          </div>
          <div className="role">
            Current role:{" "}
            <strong className="capitalize">{this.state.role}</strong>
          </div>

          <div className="input mt-20">
            <label htmlFor="role">User role</label>
            <select id="role" name="role" onChange={this.handleChange}>
              <option value="" defaultValue>
                - Select a role -
              </option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>

          <div className="input mt-20">
            <label htmlFor="role">Reset page counter</label>
            <select id="domain" name="domain" onChange={this.handleChange}>
              <option value="" defaultValue>
                - Select a domain to reset -
              </option>
              {this.state.domains.map((domain, index) => {
                return (
                  <option key={index} value={domain.domainURL}>
                    {domain.domainURL}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            className="btn medium bg-teal c-white"
            onClick={this.resetPageLimit}
          >
            Reset page limit
          </button>

          {this.state.editSuccess && (
            <div className="success-label mt-20">Changes have been saved.</div>
          )}

          <div className="btn-container ta-right">
            <button
              className="btn medium bg-teal c-white"
              onClick={this.saveUserChanges}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  };

  // returns all pages
  filterAll = (accounts) => {
    let filteredAccounts;

    if (this.state.searchTerm) {
      filteredAccounts = accounts.filter((account) => {
        let searchTerm = this.state.searchTerm;
        let accountDetails = [];
        let result = false;

        if (account.username)
          accountDetails.push(account.username.toLowerCase());

        if (account.firstName)
          accountDetails.push(account.firstName.toLowerCase());

        if (account.lastName)
          accountDetails.push(account.lastName.toLowerCase());

        accountDetails.forEach(function (item) {
          if (item.includes(searchTerm.toLowerCase())) result = true;
        });

        return result;
      });
    } else filteredAccounts = accounts;

    return filteredAccounts;
  };

  supportAuth = async () => {
    await axios
      .put("/api/support/auth", {
        usernameToCheck: this.state.customerUsername.toLowerCase(),
        pinToCheck: this.state.pin,
      })
      .then(
        async (response) => {
          let data = response;
          let successStatus = data.data.success;

          if (successStatus) {
            this.promisedSetState({
              loginFailure: false,
              loginSuccess: true,
              supportAuthResponse: data.data.message,
            });

            this.signInAsUser();
          } else {
            this.promisedSetState({
              loginFailure: true,
              supportAuthResponse: data.data.message,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  signInAsUser = async () => {
    //localStorage.clear();
    localStorage.removeItem("subscription");
    localStorage.removeItem("productID");
    localStorage.removeItem("customerID");
    localStorage.removeItem("productName");
    localStorage.removeItem("accountLimits");

    localStorage.setItem("user", this.state.customerUsername.toLowerCase());

    let getUserDataSuccess = await DATABASE_API.getUserData();
    await DATABASE_API.getSubscriptionDetails();
    await DATABASE_API.getProductName();
    let accountLimitsSuccess = await DATABASE_API.getAccountLimits();
    let getHMACHashSuccess = await DATABASE_API.getHMACHash();

    if (getUserDataSuccess && accountLimitsSuccess && getHMACHashSuccess) {
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 2000);
    }
  };

  saveUserChanges = async () => {
    await axios
      .put("/api/user/update", {
        usernameToUpdate: this.state.customerUsername,
        update: {
          accountType: this.state.role,
        },
      })
      .then(
        async (response) => {
          let data = response.data;
          //console.log(data);

          if (data.success) await this.promisedSetState({ editSuccess: true });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    if (
      JSON.parse(localStorage.getItem("userData")).accountType !== null &&
      JSON.parse(localStorage.getItem("userData")).accountType.toLowerCase() ===
        "admin"
    )
      return (
        <div id="accounts">
          <div className="filters flex-container">
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search by username or full name"
              onChange={(e) => {
                this.setSearchTerm(e.target.value);
              }}
            />
          </div>

          {
            <h3 className="total">
              Results: {this.filterAll(this.state.accounts).length}
            </h3>
          }

          <div className="table-wrap">
            <div className="table-header flex-container mt-20">
              <h3>Username</h3>
              <h3>Name</h3>
              <h3>User Role</h3>
              <h3># of Domains</h3>
              <h3>&nbsp;</h3>
            </div>

            <ul className="table-container">
              {this.state.accounts.length > 0 ? (
                this.filterAll(this.state.accounts)
                  .slice(0, this.state.loadingOffset)
                  .map((account) => this.displaySingleAccount(account))
              ) : (
                <CircularProgress />
              )}
            </ul>

            {this.state.signInAsUserModalToggle && this.signInModal()}
            {this.state.editModalToggle && this.editModal()}
          </div>
        </div>
      );
    else {
      window.location.href = "/dashboard";
    }
  }
}
