const loadSchema = async (schemaToEdit, schemaName, index) => {
  let schemaJSON;

  if (Array.isArray(schemaToEdit)) {
    // filter the unwanted schemas out of the current schema array
    let filteredSchema = schemaToEdit.filter(
      (schema) => schema["@type"] === schemaName
    );
    schemaJSON = filteredSchema[0];
  }
  // if an index is passed through. used for multiple of the same schema per page
  else if (schemaToEdit["@graph"] && schemaToEdit["@graph"][index]) {
    schemaJSON = schemaToEdit["@graph"][index];
  }
  // used if only one schema is allowed per page
  else if (schemaToEdit["@graph"]) {
    let filteredSchema = schemaToEdit["@graph"].filter(
      (schema) => schema["@type"] === schemaName
    );
    schemaJSON = filteredSchema[0];
  } else {
    schemaJSON = schemaToEdit;
  }

  return schemaJSON;
};

export { loadSchema };
