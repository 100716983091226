import React, { Component } from "react";
import axios from "axios";
//import ReactTooltip from "react-tooltip";
import { Box, Paper, TextField, Grid } from "@mui/material";

// Icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragHandleIcon from "@mui/icons-material/DragHandle";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Header from "../EditingHeader";
import SchemaSelector from "../SchemaSelector";
import DialogModal from "../../../../components/DialogModal";
import Modal from "../../../../components/modal/modal";
//import SchemaDrawer from "../SchemaDrawer";

export default class EditTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaTemplates: [],

      templateName: "",
      schemas: [],
      dragId: "",

      schemaIndexToRemove: "",
      schemaToRemove: "",

      // modals
      templateNameToggle: false,
      saveSucessToggle: false,
      saveErrorToggle: false,
      removeSchemaToggle: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    const TEMPLATE_ID = this.props.match.params.id;
    document.title = "Edit | Schema Templates | Schema Helper";

    // get templates from database on load
    await this.promisedSetState({ schemaTemplates: await this.getTemplates() });

    let currentTemplate = this.state.schemaTemplates.filter(
      (template) => template.id === TEMPLATE_ID
    );

    await this.promisedSetState({
      templateName: currentTemplate[0].name,
      schemas: currentTemplate[0].schemas,
    });
  };

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  getTemplates = async () => {
    return await axios
      .post("/api/get/user", {
        usernameToCheck: localStorage.getItem("user"),
      })
      .then(
        (response) => {
          return response.data.data.schemaTemplates;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  appendSchema = async (schema) => {
    let schemas = this.state.schemas;
    schemas.push(schema);
    await this.promisedSetState({ schemas: schemas });
  };

  removeSchema = async () => {
    let schemas = this.state.schemas;
    schemas.splice(this.state.schemaIndexToRemove, 1);
    await this.promisedSetState({ schemas: schemas });
    this.toggle("removeSchemaToggle");
  };

  confirmRemoveSchema = async (schema, index) => {
    await this.promisedSetState({
      schemaIndexToRemove: index,
      schemaToRemove: schema,
    });
    this.toggle("removeSchemaToggle");
  };

  handleChange = async (event) => {
    await this.promisedSetState({ [event.target.name]: event.target.value });
  };

  saveTemplate = async () => {
    if (this.validateTemplateName()) await this.updateUser();
    else this.toggle("templateNameToggle");
  };

  validateTemplateName = () => {
    const TEMPLATE_ID = this.props.match.params.id;

    // check that the current template name is not blank and is not used elsewhere
    // find any matches of templates that are using the same name but not the same ID, current template is ignored.
    let matches = this.state.schemaTemplates.filter((template) => {
      return (
        template.id !== TEMPLATE_ID &&
        this.state.templateName.toLowerCase() === template.name.toLowerCase()
      );
    });

    // if there are matches, then template name is invalid
    if (matches.length > 0 || this.state.templateName === "") return false;
    else return true;
  };

  updateUser = async () => {
    const TEMPLATE_ID = this.props.match.params.id;
    let schemaTemplates = this.state.schemaTemplates;

    // find index
    let index = schemaTemplates.findIndex((el) => el.id === TEMPLATE_ID);

    // update template name
    schemaTemplates[index].name = this.state.templateName;

    await axios
      .put("/api/user/update", {
        usernameToUpdate: localStorage.getItem("user"),
        update: { schemaTemplates: schemaTemplates },
      })
      .then(
        async (response) => {
          let data = response.data;
          if (data.success) {
            await this.promisedSetState({ saveSucessToggle: true });
            return true;
          } else await this.promisedSetState({ saveErrorToggle: true });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // Draggable Events
  handleDrag = async (ev) => {
    await this.promisedSetState({
      dragId: ev.currentTarget.getAttribute("data-index"),
    });
  };

  handleDrop = async (ev) => {
    const replaceId = ev.currentTarget.getAttribute("data-index");
    const dragId = this.state.dragId;

    // swap schemas in drawer / user input field groups
    this.swap(dragId, replaceId, this.state.schemas);

    /*
    // swap schemas in JSON
    if (schema["@graph"])
      schema["@graph"] = this.swap(dragId, replaceId, schema["@graph"]);
    else if (Array.isArray(schema)) this.swap(dragId, replaceId, schema);
    */

    await this.promisedSetState({ dragId: "" });
  };

  swap = (firstIndex, secondIndex, array) => {
    const temp = array[firstIndex];
    array[firstIndex] = array[secondIndex];
    array[secondIndex] = temp;
    return array;
  };

  render() {
    return (
      <div className="new-template">
        <div className="content-window">
          <Header
            title={this.state.templateName}
            state={this.state}
            save={this.saveTemplate}
          />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "45px" }}
          >
            <Paper elevation={3} style={{ width: "100%", maxWidth: 768 }}>
              <Box className="section" sx={{ p: 3 }}>
                {/*<h3>Password</h3>*/}

                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="outlined-basic"
                        variant="outlined"
                        name="templateName"
                        aria-label="Template name"
                        onChange={this.handleChange}
                        value={this.state.templateName}
                        fullWidth={true}
                        label="Template Name"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                      <SchemaSelector
                        state={this.state}
                        appendSchema={this.appendSchema}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                      {this.state.schemas.length > 0 ? (
                        <Box>
                          <h3>Schemas</h3>

                          <ul className="template-table">
                            {this.state.schemas.map((schema, index) => (
                              <li className="schema-fields-drawer" key={schema}>
                                <h3
                                  className="title bg-midnightblue c-white"
                                  onClick={() =>
                                    this.promisedSetState({
                                      opened: !this.state.opened,
                                    })
                                  }
                                  draggable={true}
                                  onDragOver={(ev) => ev.preventDefault()}
                                  onDragStart={this.handleDrag}
                                  onDrop={this.handleDrop}
                                  data-index={index}
                                >
                                  <DragHandleIcon className="draggable" />
                                  <span>{schema}</span>
                                  <DeleteForeverOutlinedIcon
                                    className="delete"
                                    onClick={() =>
                                      this.confirmRemoveSchema(schema, index)
                                    }
                                  />
                                  {/*<ExpandMoreIcon className="expand" />*/}
                                </h3>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      ) : (
                        <Box style={{ marginBottom: "25px" }}>
                          You haven't added any schema types yet. Please select
                          one from the dropdown above to get started.
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>

          {this.state.saveSucessToggle && (
            <DialogModal
              toggleState={this.state.saveSucessToggle}
              toggle={() => this.toggle("saveSucessToggle")}
              title={"Success"}
              message={"Template successfully saved"}
            />
          )}

          {this.state.saveErrorToggle && (
            <DialogModal
              toggleState={this.state.saveErrorToggle}
              toggle={() => this.toggle("saveErrorToggle")}
              title={"Could not save template"}
              message={
                "Something went wrong while attempting to save the template. Please try again later."
              }
            />
          )}

          {this.state.templateNameToggle && (
            <DialogModal
              toggleState={this.state.templateNameToggle}
              toggle={() => this.toggle("templateNameToggle")}
              title={"Template Name is either already in use or left blank"}
              message={"Please choose a different name and try again."}
            />
          )}

          {this.state.removeSchemaToggle && (
            <Modal
              toggleState={this.state.removeSchemaToggle}
              toggle={() => this.toggle("removeSchemaToggle")}
              title={"Are you sure you want to remove this schema?"}
              message={
                "This will remove the " +
                this.state.schemaToRemove +
                " schema from the current template."
              }
              yesAction={() => this.removeSchema()}
            />
          )}
        </div>
      </div>
    );
  }
}
