import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";

let schemaJSON = require("./schema.json");
const UTIL = require("../util");

export default class HowTo extends Component {
  constructor(props) {
    super(props);

    this.supplies = [];
    this.tools = [];
    this.steps = [];

    this.state = {
      showSupplies: this.supplies,
      showTools: this.tools,
      showSteps: this.steps,

      hours: 0,
      minutes: 0,

      schema: schemaJSON,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.state.editSchema && !this.props.state.addingSchema) {
      // load existing schema
      schemaJSON = await UTIL.loadSchema(
        this.props.state.schemaToEdit,
        "HowTo"
      );

      await this.generateInputFields();
    } else {
      this.props.setSchema(schemaJSON); // load default schema
    }

    this.setState(this.state); // force update
    this.jQueryScripts();
  };

  jQueryScripts = () => {
    schemaJSON.supply.map((supply, index) => {
      $("#supplyname-" + index).val(supply["name"]);
      return 1;
    });
    schemaJSON.tool.map((tool, index) => {
      $("#toolname-" + index).val(tool["name"]);
      return 1;
    });
    schemaJSON.step.map((step, index) => {
      $("#stepname-" + index).val(step["name"]);
      $("#steptext-" + index).val(step["text"]);
      $("#stepurl-" + index).val(step["url"]);
      $("#stepimageurl-" + index).val(step["image"]);
      return 1;
    });

    $("#howToTimeHour").val(
      moment.duration(schemaJSON.totalTime)["_data"]["hours"]
    );
    $("#howToTimeMinute").val(
      moment.duration(schemaJSON.totalTime)["_data"]["minutes"]
    );
  };

  handleChange = async (e) => {
    const { name, value } = e.target;
    const parentName = e.target.getAttribute("data-parentname");
    const subParentName = e.target.getAttribute("data-subparentname");

    if (name === "timeHour" || name === "timeMinute") {
      if (name === "timeHour") await this.setState({ hours: value });
      if (name === "timeMinute") await this.setState({ minutes: value });

      schemaJSON["totalTime"] = moment.duration({
        minutes: this.state.minutes,
        hours: this.state.hours,
      });
    } else if (parentName === "supply") {
      const indexNum = e.target.getAttribute("data-supply-number");
      schemaJSON[parentName][indexNum][name] = value;
    } else if (parentName === "tool") {
      const indexNum = e.target.getAttribute("data-tool-number");
      schemaJSON[parentName][indexNum][name] = value;
    } else if (parentName === "step") {
      const indexNum = e.target.getAttribute("data-step-number");
      schemaJSON[parentName][indexNum][name] = value;
    } else if (parentName && subParentName)
      schemaJSON[parentName][subParentName][name] = value;
    else if (parentName) schemaJSON[parentName][name] = value;
    else schemaJSON[name] = value;

    // update session schema
    await this.setState({ schema: schemaJSON });
    await this.props.setSchema(this.state.schema);
  };

  /*
   * Supplies
   */

  supplyFields = (counter, supply) => {
    return (
      <div className="supply-fields removable mb-20" key={counter}>
        <input
          type="text"
          id={"supplyname-" + counter}
          name="name"
          data-parentname="supply"
          data-supply-number={counter}
          placeholder="Name"
          onChange={this.handleChange}
          value={supply?.name}
        />

        <div className="remove-item" onClick={() => this.removeSupply(counter)}>
          X
        </div>
      </div>
    );
  };

  addSupply = async () => {
    let counter = this.supplies.length;
    let supply = {
      "@type": "HowToSupply",
      name: "",
    };

    this.supplies.push(this.supplyFields(counter));
    await this.setState({ showSupplies: this.supplies });

    // add a new object to the appropriate group
    schemaJSON["supply"].push(supply);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeSupply = async (indexToRemove) => {
    let supplyArray = schemaJSON["supply"];

    supplyArray = supplyArray.filter(
      (supply, index) => index !== indexToRemove
    );
    schemaJSON["supply"] = supplyArray;

    // update session schema
    await this.generateInputFields();
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * Tools
   */

  toolFields = (counter, tool) => {
    return (
      <div className="tool-fields removable mb-20" key={counter}>
        <input
          type="text"
          id={"toolname-" + counter}
          name="name"
          data-parentname="tool"
          data-tool-number={counter}
          placeholder="Name"
          onChange={this.handleChange}
          value={tool?.name}
        />

        <div className="remove-item" onClick={() => this.removeTool(counter)}>
          X
        </div>
      </div>
    );
  };

  addTool = async () => {
    let counter = this.tools.length;
    let tool = {
      "@type": "HowToTool",
      name: "",
    };

    this.tools.push(this.toolFields(counter));
    await this.setState({ showTools: this.tools });

    // add a new object to the appropriate group
    schemaJSON["tool"].push(tool);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeTool = async (indexToRemove) => {
    let toolArray = schemaJSON["tool"];

    toolArray = toolArray.filter((tool, index) => index !== indexToRemove);
    schemaJSON["tool"] = toolArray;

    // update session schema
    await this.generateInputFields();
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  /*
   * Steps
   */
  stepFields = (counter, step) => {
    return (
      <div className="step-fields mb-20" key={counter}>
        <h5>Step #{counter + 1}</h5>

        <label>
          <h4>Name</h4>
        </label>
        <input
          type="text"
          id={"stepname-" + counter}
          name="name"
          data-parentname="step"
          data-step-number={counter}
          placeholder="Name"
          onChange={this.handleChange}
          value={step?.name}
        />

        <label>
          <h4>Text</h4>
        </label>
        <input
          type="text"
          id={"steptext-" + counter}
          name="text"
          data-parentname="step"
          data-step-number={counter}
          placeholder="Button your shirt how you'd like to wear it, then drape the tie around your neck."
          onChange={this.handleChange}
          value={step?.text}
        />

        <label>
          <h4>URL</h4>
        </label>
        <input
          type="text"
          id={"stepurl-" + counter}
          name="url"
          data-parentname="step"
          data-step-number={counter}
          placeholder="https://example.com/kitchen#step1"
          onChange={this.handleChange}
          value={step?.url}
        />

        <label className="d-flex align-center">
          <h4>Image URL</h4>
          <InfoIcon
            data-for="stepimage-url-tip"
            data-tip="The URL of the image you want users to see for this step"
            style={{ marginLeft: "5px" }}
          />
          <ReactTooltip id="stepimage-url-tip" />
        </label>
        <input
          type="text"
          id={"stepimageurl-" + counter}
          name="image"
          data-parentname="step"
          data-step-number={counter}
          onChange={this.handleChange}
          value={step?.image}
        />

        <div className="d-flex justify-right">
          <div
            className="link c-teal h-c-midnightblue"
            onClick={() => this.removeStep(counter)}
          >
            Remove Step
          </div>
        </div>
      </div>
    );
  };

  addStep = async () => {
    let counter = this.steps.length;
    let step = {
      "@type": "HowToStep",
      name: "Preparations",
      text: "Button your shirt how you'd like to wear it, then drape the tie around your neck. Make the thick end about 1/3rd longer than the short end. For formal button down shirts, it usually works best with the small end of the tie between 4th and 5th button.",
      image: "https://example.com/1x1/step1.jpg",
      url: "https://example.com/tie#step1",
    };

    this.steps.push(this.stepFields(counter));

    this.setState({
      showSteps: this.steps,
    });

    // add a new faq object to the array of faqs
    schemaJSON["step"].push(step);

    // update session schema
    this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  removeStep = async (indexToRemove) => {
    let stepArray = schemaJSON["step"];

    stepArray = stepArray.filter((step, index) => index !== indexToRemove);
    schemaJSON["step"] = stepArray;

    // update session schema
    await this.generateInputFields();
    await this.setState({ schema: schemaJSON });
    this.props.setSchema(this.state.schema);
  };

  generateInputFields = async () => {
    this.supplies = [];
    this.tools = [];
    this.steps = [];

    schemaJSON.supply.map((supply, counter) =>
      this.supplies.push(this.supplyFields(counter, supply))
    );
    schemaJSON.tool.map((tool, counter) =>
      this.tools.push(this.toolFields(counter, tool))
    );
    schemaJSON.step.map((step, counter) =>
      this.steps.push(this.stepFields(counter, step))
    );
  };

  render() {
    return (
      <div id="howto-schema-form" className="schema-form">
        <Typography
          variant="h6"
          style={{ textAlign: "right", marginBottom: "15px" }}
        >
          <span
            className="link h-c-midnightblue"
            onClick={() => window.Intercom("showArticle", 6076605)}
          >
            Learn More
          </span>
        </Typography>

        <div className="form-group">
          <label>
            <h4>Title</h4>
          </label>
          <input
            type="text"
            id="howto-name"
            name="name"
            onChange={this.handleChange}
            value={schemaJSON["name"]}
          />
          <br />

          <label>
            <h3>Total time</h3>
          </label>
          <br />
          <div className="flex-container">
            <div className="col-2">
              <label>
                <h4>Hours</h4>
              </label>
              <input
                type="number"
                min="0"
                id="howToTimeHour"
                name="timeHour"
                onChange={this.handleChange}
                //value={schemaJSON["name"]}
              />
            </div>
            <div className="col-2">
              <label>
                <h4>Minutes</h4>
              </label>
              <input
                type="number"
                min="0"
                id="howToTimeMinute"
                name="timeMinute"
                onChange={this.handleChange}
                //value={schemaJSON["name"]}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>
            <h3>Image details</h3>
          </label>
          <br />
          <label>
            <h4>Image URL</h4>
          </label>
          <input
            type="text"
            id="image-url"
            name="url"
            data-parentname="image"
            onChange={this.handleChange}
            value={schemaJSON["image"]["url"]}
          />
          <div className="flex-container">
            <span className="col-2">
              <label>
                <h4>Width</h4>
              </label>
              <input
                type="number"
                min="0"
                id="width"
                name="width"
                data-parentname="image"
                onChange={this.handleChange}
                value={schemaJSON["image"]["width"]}
              />
            </span>
            <span className="col-2">
              <label>
                <h4>Height</h4>
              </label>
              <input
                type="number"
                min="0"
                id="height"
                name="height"
                data-parentname="image"
                onChange={this.handleChange}
                value={schemaJSON["image"]["height"]}
              />
            </span>
          </div>
        </div>

        <div className="form-group">
          <label>
            <h3>Estimated Cost</h3>
          </label>
          <br />
          <div className="flex-container">
            <span className="col-2">
              <label>
                <h4>Currency</h4>
              </label>
              <input
                type="text"
                id="currency"
                name="currency"
                data-parentname="estimatedCost"
                onChange={this.handleChange}
                value={schemaJSON["estimatedCost"]["currency"]}
              />
            </span>
            <span className="col-2">
              <label>
                <h4>Value</h4>
              </label>
              <input
                type="number"
                min="0"
                id="value"
                name="value"
                data-parentname="estimatedCost"
                onChange={this.handleChange}
                value={schemaJSON["estimatedCost"]["value"]}
              />
            </span>
          </div>
        </div>

        <div className="form-group">
          <label className="d-flex align-center">
            <h3>Supplies</h3>
            <InfoIcon
              data-for="supply-name"
              data-tip="The supplies needed to complete this HowTo. For example: Tiles, Tile grout, Grout sealer"
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="supply-name" />
          </label>

          <br />
          <div className="supplies-container">{this.supplies}</div>
          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addSupply}
          >
            Add Supply
          </button>
        </div>

        <div className="form-group">
          <label className="d-flex align-center">
            <h3>Tools</h3>
            <InfoIcon
              data-for="tool-name"
              data-tip="The tools needed to complete this HowTo. For example: Bucket, Sponge"
              style={{ marginLeft: "5px" }}
            />
            <ReactTooltip id="tool-name" />
          </label>

          <br />
          <div className="tools-container">{this.tools}</div>
          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addTool}
          >
            Add Tool
          </button>
        </div>

        <div className="form-group">
          <label>
            <h3>Steps</h3>
          </label>
          <br />
          <div className="steps-container">{this.steps}</div>
          <button
            className="btn small c-white bg-orange b-orange"
            onClick={this.addStep}
          >
            Add Step
          </button>
        </div>
      </div>
    );
  }
}
