import React, { Component } from "react";
import GooglePreview from "../../components/serp/GooglePreview";
import $ from "jquery";
import { Store } from "react-notifications-component";

// Components
import Modal from "../../components/modal/modal";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import UserInput2 from "./components/userInput";
import AdvancedInput from "./components/advancedInput";
import SchemaDrawer from "./components/schemaDrawer";
import PageHeadPreviewModal from "./components/PageHeadModal";
import Revisions from "./components/Revisions";

const HELPER = require("./helperFunctions");
const UTIL = require("../../util/util");
const API = require("../../api/db-functions");
const {
  COMPONENTS,
  LOCAL_BUSINESS,
  AUTOFILL,
  MULTIPLES,
} = require("../../schemas/index");
const accountLimits = require("../../limits/limits.json");

export default class PageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isArticle: false,
      isPage: false,
      accessToken: "",
      refreshToken: "",
      code: "",
      pageDetails: {},
      publishConfirm: false,
      deleteConfirm: false,
      schemaDetected: false,
      schemaFound: "",
      analysisComplete: false,
      schemaAdded: false,
      selectedSchema: "",
      selectedSchemas: [],
      internalSchema: true,
      schema: { "@context": "http://schema.org", "@graph": [] },
      editSchema: false,
      schemaToEdit: {},
      schemaObject: {},
      accountLimits: {},
      allowedToPublished: false,
      changesMade: false,
      deleteSchema: false,
      publishedPages: [],
      activity: [],
      globalVariables: {},
      processing: false,
      testResults: {},

      revisions: {},

      accountHolderUsername: "",
      accountHolderSubscription: "",
      accountHolderGlobalVariables: "",

      // draggbles
      dragId: "",

      custom: false,

      // modals
      pageHeadModalToggle: false,
      revisionsModalToggle: false,
      tooManySchemas: false,
    };

    this.schemaDrawers = [];
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {
    let currentDomain = this.props.match.params.domain;
    UTIL.updateActivity();

    // Get user data and get token data for current domai
    const userData = JSON.parse(localStorage.getItem("userData"));
    const domains = userData.domains;
    const domain = domains.filter((e) => e.domainURL === currentDomain)[0];

    // if parent account holder exists, use parent data
    // this happens when users are added as Editors to another account
    await this.promisedSetState({
      accountHolderUsername:
        domain.accountHolderUsername && domain.accountHolderUsername,
      accountHolderSubscription:
        domain.accountHolderSubscription && domain.accountHolderSubscription,
      accountHolderGlobalVariables:
        domain.accountHolderGlobalVariables &&
        domain.accountHolderGlobalVariables,
    });

    await this.promisedSetState({
      accessToken: domain.accessToken,
      refreshToken: domain.refreshToken,
      totalPublishedSchemas: domain.totalPublishedSchemas,
      publishedPages: domain.publishedPages,
      accountLimits: await this.getAccountLimits(),
      globalVariables: userData.globalVariables
        ? userData.globalVariables
        : this.state.accountHolderGlobalVariables,
    });

    if (userData.activity)
      await this.promisedSetState({ activity: userData.activity });

    await this.checkToken();
    await this.getPageDetails();
    await this.getRevisions();

    document.title =
      this.state.pageDetails.name + " | Schema Helper | " + currentDomain;

    // check if total published schemas is less than the account limit
    if (
      this.state.accountLimits &&
      (this.state.publishedPages.length < this.state.accountLimits.maxPages ||
        this.state.accountLimits.maxPages === 0)
    )
      await this.promisedSetState({ allowedToPublished: true });
  };

  setCustomState = async (name, value) => {
    await this.promisedSetState({ [name]: value });
  };

  togglePageHeadModal = async () => {
    await this.promisedSetState({
      pageHeadModalToggle: !this.state.pageHeadModalToggle,
    });
  };

  getRevisions = async () => {
    // get revisions
    if (this.state.isArticle) {
      const revisions = await API.getArticleRevisions(
        this.state.accessToken,
        this.props.match.params.id
      );

      await this.promisedSetState({ revisions: revisions });
    }
    if (this.state.isPage) {
      const revisions = await API.getPageRevisions(
        this.state.accessToken,
        this.props.match.params.id
      );

      await this.promisedSetState({ revisions: revisions });
    }
  };

  toggle = async (stateName) => {
    await this.promisedSetState({ [stateName]: !this.state[stateName] });
  };

  getAccountLimits = async () => {
    let currentAccountLimit;

    // if accessing page editor via a membership to another account
    if (this.state.accountHolderUsername)
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === this.state.accountHolderSubscription
      );
    else if (localStorage.getItem("productName"))
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === localStorage.getItem("productName")
      );
    else
      currentAccountLimit = await accountLimits.filter(
        (plan) => plan.package === "Free"
      );

    return currentAccountLimit[0];
  };

  // check if token is valid and refresh if necessary
  checkToken = async () => {
    let tokenActive = await API.getTokenInfo(this.state.accessToken);

    if (!tokenActive) {
      let newTokens = await API.refreshAuthToken(
        this.state.accessToken,
        this.state.refreshToken
      );

      await API.updateTokensInDatabase(
        this.state.accountHolderUsername
          ? this.state.accountHolderUsername
          : localStorage.getItem("user"),
        this.props.match.params.domain,
        newTokens.accessToken,
        newTokens.refreshToken
      );

      await this.promisedSetState({
        accessToken: newTokens.accessToken,
        refreshToken: newTokens.refreshToken,
      });
    }
  };

  singleSchemaRemovalNotification = (schema) => {
    Store.addNotification({
      message: schema + " removed",
      type: "warning",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  // Draggable Events
  handleDrag = async (ev) => {
    await this.promisedSetState({
      dragId: ev.currentTarget.getAttribute("data-index"),
    });
  };

  handleDrop = async (ev) => {
    const replaceId = ev.currentTarget.getAttribute("data-index");
    const dragId = this.state.dragId;
    let schema = this.state.schema;

    // swap schemas in drawer / user input field groups
    HELPER.swap(dragId, replaceId, this.schemaDrawers);

    // swap schemas in JSON
    if (schema["@graph"])
      schema["@graph"] = HELPER.swap(dragId, replaceId, schema["@graph"]);
    else if (Array.isArray(schema)) HELPER.swap(dragId, replaceId, schema);

    await this.promisedSetState({ schema: schema, dragId: "" });
    this.updateHead();
  };

  getPageDetails = async () => {
    const details = await API.getPageDetails(
      this.state.accessToken,
      this.props.match.params.id
    );

    if (details.isPage) await this.promisedSetState({ isPage: true });
    if (details.isArticle) await this.promisedSetState({ isArticle: true });

    await this.promisedSetState({
      pageDetails: details.pageDetails,
      code: details.code,
      schemaDetected: details.schemaDetected,
    });

    // grab entire schema script
    let currentPageHead = details.pageDetails.head_html;

    if (this.countSchemas(currentPageHead) <= 1) {
      if (currentPageHead) {
        let currentSchema = currentPageHead.match(
          /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi
        );

        if (currentSchema) {
          await this.promisedSetState({ schemaDetected: true });
          this.editSchema();

          // strip tags and set object
          currentSchema = currentSchema.toString();
          currentSchema = currentSchema.replace(/<\/?[^>]+(>|$)/g, "");

          let schemaObject = JSON.parse(currentSchema);
          let schemasFound = [];

          if (Array.isArray(schemaObject)) {
            schemaObject.forEach((schema) => {
              schemasFound.push(schema["@type"]);
            });
          } else if (schemaObject["@graph"]) {
            schemaObject["@graph"].forEach((schema) => {
              schemasFound.push(schema["@type"]);
            });
          } else schemasFound.push(schemaObject["@type"]);

          await this.promisedSetState({
            schemaObject: schemaObject,
            selectedSchemas: schemasFound,
            testResults: await HELPER.schemaAnalysis(schemaObject),
          });
        }
      }
    } else await this.promisedSetState({ tooManySchemas: true });

    return true;
  };

  updateSchemaDrawers = () => {
    this.state.selectedSchemas.forEach((schema, index) => {
      const KEY = schema + "-" + index;

      // if the schema drawer does not already exist, add it to the output
      if (!this.schemaDrawers.some((e) => e.key === KEY)) {
        this.schemaDrawers.push(
          <SchemaDrawer
            key={KEY}
            setSchema={this.setSchema}
            removeSingleSchema={this.removeSingleSchema}
            state={this.state}
            schema={schema}
            index={index}
            handleDrag={this.handleDrag}
            handleDrop={this.handleDrop}
          />
        );
      }
    });
  };

  displaySchema = () => {
    let hasSelectedSchemas = this.state.selectedSchemas.length >= 0;

    if (
      hasSelectedSchemas &&
      HELPER.schemasAllowed(this.state.selectedSchema)
    ) {
      this.updateSchemaDrawers();
    } else if (HELPER.schemasAllowed(this.state.selectedSchema)) {
      const DynamicSchemaForm = COMPONENTS[this.state.selectedSchema];

      return (
        <DynamicSchemaForm setSchema={this.setSchema} state={this.state} />
      );
    } else
      return <AdvancedInput setSchema={this.setSchema} state={this.state} />;
  };

  autofill = async () => {
    await this.removeExistingSchema();

    let autofilledJSON = await HELPER.autofill(
      this.state.selectedSchema,
      this.state.pageDetails,
      this.state.globalVariables
    );

    this.setSchema(autofilledJSON);

    Store.addNotification({
      message: "Autofill complete",
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 4000,
        onScreen: false,
        showIcon: true,
      },
    });
  };

  // Removes all existing schemas from the page
  // Does not publish changes
  removeExistingSchema = async () => {
    if (this.state.code) {
      await this.promisedSetState({
        code: this.state.code
          .replace(
            /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi,
            ""
          )
          .trim(),
      });
    }
  };

  // Removes a single schema from the page when user clicks "Remove Schema Type"
  // Does not publish changes
  removeSingleSchema = async (schema, index) => {
    if (this.state.schema["@graph"]) {
      if (this.state.schema["@graph"].length === 1) await this.reset();
      else {
        let schemaObject = this.state.schema;
        let selectedSchemas = this.state.selectedSchemas;

        schemaObject["@graph"].splice(index, 1);
        this.schemaDrawers.splice(index, 1);
        selectedSchemas.splice(index, 1);

        await this.promisedSetState({
          schema: schemaObject,
          selectedSchemas: selectedSchemas,
        });
      }

      await this.removeExistingSchema();
      await this.updateHead();
      this.singleSchemaRemovalNotification(schema);
      this.updateSchemaDrawers();
    } else if (schema) {
      const filteredSchema = this.state.schema.filter(
        (currentSchema) => currentSchema["@type"] !== schema
      );

      const filteredSchemaList = this.state.selectedSchemas.filter(
        (currentSchema) => currentSchema !== schema
      );

      await this.promisedSetState({
        schema: filteredSchema,
        selectedSchemas: filteredSchemaList,
      });

      await this.removeExistingSchema();
      await this.updateHead();
      this.singleSchemaRemovalNotification(schema);
    }
  };

  // update and push to HubSpot
  updatePage = async () => {
    await this.promisedSetState({ processing: true });
    let updateSuccess = false;
    await this.checkToken();

    if (this.state.isPage) {
      updateSuccess = await API.updatePage(
        this.state.accessToken,
        this.props.match.params.id,
        this.state.code,
        this.state.selectedSchema
      );
    }

    if (this.state.isArticle) {
      updateSuccess = await API.updateArticle(
        this.state.accessToken,
        this.props.match.params.id,
        this.state.code,
        this.state.selectedSchema
      );
    }

    if (updateSuccess) {
      await UTIL.updateLocalCache(
        this.state,
        this.props.match.params.domain,
        this.props.match.params.id
      );

      await API.updateSchemaCounter(
        this.state.accountHolderUsername
          ? this.state.accountHolderUsername
          : localStorage.getItem("user"),
        this.props.match.params.domain,
        this.props.match.params.id,
        this.state.publishedPages,
        this.state.deleteSchema
      );

      if (!this.state.accountHolderUsername)
        await API.updateActivity(
          localStorage.getItem("user"),
          this.state.activity
        );

      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  };

  setSchema = async (newSchema, index, custom) => {
    let schema = this.state.schema;
    let selectedSchemas = this.state.selectedSchemas;

    // if custom schema, pass through
    if (custom) {
      await this.promisedSetState({
        schema: newSchema,
        changesMade: true,
      });
    }

    // if schema object is an array (does not use graph)
    if (
      Array.isArray(schema) &&
      selectedSchemas.length > 0 &&
      HELPER.schemasAllowed(this.state.selectedSchema)
    ) {
      // if schema type already exists, replace it
      if (index) {
        if (schema[index]) schema[index] = newSchema;
        else schema.push(newSchema);
      } else {
        if (schema.some((prop) => prop["@type"] === newSchema["@type"]))
          selectedSchemas.forEach((currentSchema, index) => {
            if (schema[index]["@type"] === newSchema["@type"])
              schema[index] = newSchema;
          });
        // if it does not, add new one
        else schema.push(newSchema);
      }

      await this.promisedSetState({ schema: schema, changesMade: true });
    }
    // if schema object already has a graph
    else if (schema["@graph"]) {
      // if schema type already exists, replace it
      if (schema["@graph"][index]) schema["@graph"][index] = newSchema;
      else if (
        !index &&
        schema["@graph"].some((prop) => prop["@type"] === newSchema["@type"])
      ) {
        selectedSchemas.forEach((currentSchema, index) => {
          if (schema["@graph"][index]["@type"] === newSchema["@type"])
            schema["@graph"][index] = newSchema;
        });
      }

      // if it does not already exist, add new one
      else schema["@graph"].push(newSchema);

      schema = await HELPER.formatSchema(schema);
      await this.promisedSetState({ schema: schema, changesMade: true });
    } else {
      await this.promisedSetState({ schema: newSchema, changesMade: true });
    }

    this.updateHead();
  };

  selectSchema = async (schema) => {
    await this.promisedSetState({ selectedSchema: schema });
    await HELPER.jQueryTabController();
  };

  addSchema = async (selectedSchema) => {
    let selectedSchemas = this.state.selectedSchemas;
    await this.promisedSetState({ addingSchema: true });

    // add selected schema if it is on the allowed list or has not yet been added
    if (
      MULTIPLES.includes(selectedSchema) ||
      !selectedSchemas.includes(selectedSchema)
    ) {
      selectedSchemas.push(selectedSchema);

      await this.promisedSetState({
        schemaIncrementCounter: 1,
        selectedSchemas: selectedSchemas,
        schemaAdded: true,
      });

      this.updateSchemaDrawers();

      Store.addNotification({
        message: selectedSchema + " appended",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: false,
          showIcon: true,
        },
      });

      AUTOFILL.includes(this.state.selectedSchema) && (await this.autofill());

      await HELPER.jQuerySchemaDrawers();
      await HELPER.jQueryTabController();

      await this.promisedSetState({ selectedSchema });
    } else {
      Store.addNotification({
        message: selectedSchema + " already exists",
        type: "warning",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 4000,
          onScreen: false,
          showIcon: true,
        },
      });
    }
  };

  // resets the schema editor
  reset = async () => {
    let oldPageHead = this.state.code;
    let newPageHead = oldPageHead
      .replace(/<script type="application\/ld\+json">(.|\n)*?<\/script>/gi, "")
      .trim();

    await this.promisedSetState({
      code: newPageHead,
      schema: { "@context": "http://schema.org", "@graph": [] },
      selectedSchema: "",
      selectedSchemas: [],
      changesMade: false,
      schemaIncrementCounter: 0,
      schemaAdded: false,
    });

    this.schemaDrawers = [];

    $(".dropdown-select li:first-child").trigger("click");
  };

  // update the page head, but does not publish to HubSpot yet
  updateHead = async () => {
    // await this.removeExistingSchema();
    let previousCodeState = this.state.code ? this.state.code : "";

    if (this.state.code)
      previousCodeState = this.state.code
        .replace(
          /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi,
          ""
        )
        .trim();

    let openingTags = '\n\n<script type="application/ld+json">\n';
    let endingTags = "\n</script>";
    let newHeadCode =
      previousCodeState +
      openingTags +
      JSON.stringify(this.state.schema, null, 2) +
      endingTags;

    await this.promisedSetState({ code: newHeadCode });
    this.forceUpdate();
  };

  countSchemas = (str) => {
    const re = /<script type="application\/ld\+json">(.|\n)*?<\/script>/gi;
    return ((str || "").match(re) || []).length;
  };

  editSchema = async () => {
    let oldPageHead = this.state.pageDetails.head_html;

    // grab entire schema script then strip tags
    let currentSchema = oldPageHead
      .match(/<script type="application\/ld\+json">(.|\n)*?<\/script>/gi)
      .toString();
    currentSchema = currentSchema.replace(/<\/?[^>]+(>|$)/g, "");

    await this.promisedSetState({
      editSchema: true,
      schemaToEdit: JSON.parse(currentSchema),
      schema: JSON.parse(currentSchema),
      //selectedSchema: this.state.testResults.schemaFound,
    });

    // check if current schema is an array (allows multiple schemas)
    // if it's not, set selected schema
    // why? it's the old structure. required for grandfathered users
    if (this.state.internalSchema) {
      let currentSchemaObject = JSON.parse(currentSchema);

      if (
        !Array.isArray(currentSchemaObject) &&
        !Array.isArray(currentSchemaObject["@graph"])
      ) {
        await this.promisedSetState({
          singleSchema: true,
          selectedSchema: this.state.testResults.schemaFound,
        });
      }
    }

    await HELPER.jQueryTabController();
    await HELPER.jQuerySchemaDrawers();

    this.forceUpdate();

    // scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // displays the schema input section where users can select and edit schemas
  displaySchemaSection = () => {
    return (
      <div
        className={
          (this.state.internalSchema && "flex-container") + " schema-input"
        }
      >
        <div className={this.state.internalSchema ? "col-2 t-col-1" : "col-1"}>
          {this.schemaDrawers.length > 0
            ? this.schemaDrawers
            : this.displaySchema()}
        </div>

        {this.state.internalSchema && (
          <div className="preview col-2 t-col-1">
            <div className="serp-preview">
              <GooglePreview
                schemaObject={this.state.schema}
                pageDetails={this.state.pageDetails}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  customSchemaEditor = () => {
    return (
      <div className="schema-input custom-schema">{this.displaySchema()}</div>
    );
  };

  render() {
    return (
      <div id="page-detail">
        <div className="content-window right-sidebar-enabled">
          <Header
            domainSelector={true}
            state={this.state}
            toggleRevisionsModal={() => this.toggle("revisionsModalToggle")}
            togglePublishConfirmModal={async () =>
              await this.promisedSetState({
                publishConfirm: !this.state.publishConfirm,
              })
            }
            currentDomain={this.props.match.params.domain}
          />
          <div className="page-header">
            <h1>{this.state.pageDetails.name}</h1>
          </div>
          {/*
          {this.state.schemaDetected &&
            Object.keys(this.state.testResults).length > 0 && (
              <Analysisv2 state={this.state} />
            )}
            */}
          {/*this.displayWarnings()*/}
          {/* !this.state.schemaDetected && */}
          {!this.state.singleSchema &&
            this.state.internalSchema &&
            HELPER.schemasAllowed(this.state.selectedSchema) !== false && (
              <UserInput2
                state={this.state}
                reset={this.reset}
                autofill={this.autofill}
                selectSchema={this.selectSchema}
                addSchema={this.addSchema}
                setSchema={this.setSchema}
                setCustomState={this.setCustomState}
              />
            )}
          {/* display schema input and preview section when user selects a schema from the dropdown */}
          {/* Valid for schemas that we currently support  */}
          {(this.state.selectedSchema && this.state.schemaAdded) ||
          (this.state.editSchema &&
            this.state.selectedSchemas.length > 0 &&
            HELPER.schemasAllowed(this.state.selectedSchema))
            ? this.displaySchemaSection()
            : ""}

          {/* Valid for schemas that we do not support / custom schemas for advanced users */}
          {this.state.selectedSchemas.length > 0 &&
            !HELPER.schemasAllowed(this.state.selectedSchema) &&
            this.customSchemaEditor()}

          {/* display confirmation modals */}
          {this.state.publishConfirm && (
            <Modal
              title="Are you sure you want to update and publish?"
              message="This will update and publish the page on HubSpot with what you see
            in the page head preview."
              yesAction={this.updatePage}
              toggle={() =>
                this.promisedSetState({
                  publishConfirm: !this.state.publishConfirm,
                })
              }
              processing={this.state.processing}
              toggleState={this.state.publishConfirm}
            />
          )}

          {this.state.deleteConfirm && (
            <Modal
              title="Are you sure you want to remove the existing schema from this page?"
              message=""
              yesAction={() => {
                this.removeExistingSchema();
                this.updatePage();
              }}
              toggle={() =>
                this.promisedSetState({
                  deleteConfirm: !this.state.deleteConfirm,
                  deleteSchema: !this.state.deleteSchema,
                })
              }
              processing={this.state.processing}
              toggleState={this.state.deleteConfirm}
            />
          )}

          <Sidebar
            state={this.state}
            schemaDetected={this.state.schemaDetected}
            pageDetails={this.state.pageDetails}
            editSchema={this.editSchema}
            toggleDeleteConfirmModal={() =>
              this.promisedSetState({
                deleteConfirm: !this.state.deleteConfirm,
                deleteSchema: !this.state.deleteSchema,
              })
            }
            togglePageHeadModal={this.togglePageHeadModal}
          />

          <PageHeadPreviewModal
            code={this.state.code}
            toggle={this.state.pageHeadModalToggle}
            toggleDialog={this.togglePageHeadModal}
          />

          {Object.keys(this.state.revisions).length !== 0 && (
            <Revisions
              state={this.state}
              accessToken={this.state.accessToken}
              postId={this.props.match.params.id}
              toggle={this.state.revisionsModalToggle}
              toggleDialog={this.toggle}
              revisions={this.state.revisions}
            />
          )}

          {this.state.tooManySchemas && (
            <Modal
              title="Error: Multiple declarations of schema"
              message="We've detected more than one declarations of schema on this page and are unable to provide the ability to edit multiple declarations. To continue editing, you will need to remove the current schema from the page. Once removed, you will be able to edit / add schema. Would you like to remove the current schema?"
              yesAction={() => {
                this.removeExistingSchema();
                this.updatePage();
              }}
              toggle={() =>
                this.promisedSetState({
                  tooManySchemas: !this.state.tooManySchemas,
                })
              }
              processing={this.state.processing}
              toggleState={this.state.tooManySchemas}
            />
          )}
        </div>
      </div>
    );
  }
}
