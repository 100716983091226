import React, { Component } from "react";
import axios from "axios";
//import Cookies from "universal-cookie";
//const cookies = new Cookies();
import { Box } from "@mui/material";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      receivedResponse: false,
      updateSuccess: false,
    };
  }

  promisedSetState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  componentDidMount = async () => {};

  // saves input into local variables
  handleChange = async (event) => {
    await this.promisedSetState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    let updateSuccess = await this.updatePassword();
    if (updateSuccess) await this.promisedSetState({ updateSuccess: true });
  };

  updatePassword = async () => {
    return axios
      .post("/api/user/updatePassword", {
        userToUpdate: localStorage.getItem("user"),
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      })
      .then(
        async (response) => {
          let data = response;
          let successStatus = data.data.success;

          await this.promisedSetState({ receivedResponse: true });

          return successStatus;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  render() {
    let updateStatus = this.state.updateSuccess ? (
      <div className="success-label">Password successfully updated.</div>
    ) : (
      <div className="error-label">
        Could not update your password. Please make sure your current password
        is correct.
      </div>
    );

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "100px" }}
      >
        <Box elevation={3} style={{ width: "100%", maxWidth: 768 }}>
          <div className="section-title">
            <h2>Password</h2>
          </div>

          <Box>
            <div className="form-container">
              {this.state.newPassword !== this.state.newPasswordConfirm ? (
                <div className="error-label">Passwords do not match.</div>
              ) : (
                ""
              )}

              {this.state.receivedResponse ? updateStatus : ""}

              <div className="row">
                <label>Current Password</label>
                <input
                  type="password"
                  name="currentPassword"
                  placeholder="Current password"
                  value={this.state.currentPassword}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <label>New Password</label>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="New password"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="newPasswordConfirm"
                  placeholder="Confirm password"
                  value={this.state.newPasswordConfirm}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="row">
                <button
                  className={
                    (this.state.currentPassword &&
                    this.state.newPassword &&
                    this.state.newPasswordConfirm
                      ? ""
                      : "disabled") + " btn medium bg-orange c-white"
                  }
                  onClick={
                    this.state.currentPassword &&
                    this.state.newPassword &&
                    this.state.newPasswordConfirm
                      ? this.handleSubmit
                      : this.nothing
                  }
                >
                  Update password
                </button>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    );
  }
}
